import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table
} from "reactstrap";
import { get, isEmpty, isEqual, map } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import {
  isPromotionActive,
  isPromotionChannelActive
} from "../../utils/case-explorer-utils";
import {
  addCaseToPromotion,
  createOrUpdatePromotion,
  createPromotionChannel,
  deletePromotion,
  deletePromotionChannel,
  removeCaseFromPromotion
} from "../../actions/promotion.actions";
import { onInvalidSubmit } from "../../utils/form-utils";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ConfirmModal from "./ConfirmModal";

const PROMOTION_LIST_PAGE = "PROMOTION_LIST_PAGE";
const PROMOTION_FORM_PAGE = "PROMOTION_FORM_PAGE";
const CHANNEL_LIST_PAGE = "CHANNEL_LIST_PAGE";

const CaseExplorerPromotionModal = (props) => {
  const { caseData, isOpen, toggle } = props;

  const [activePromotion, setActivePromotion] = useState(null);
  const [activePage, setActivePage] = useState(PROMOTION_LIST_PAGE);
  const closeModal = () => {
    toggle();
    setActivePage(PROMOTION_LIST_PAGE);
  };

  const renderSection = () => {
    if (activePage === CHANNEL_LIST_PAGE) {
      return (
        <PromotionChannelPage
          setActivePage={setActivePage}
          closeModal={closeModal}
        />
      );
    }

    if (activePage === PROMOTION_FORM_PAGE) {
      return (
        <PromotionFormPage
          activePromotion={activePromotion}
          setActivePage={setActivePage}
          closeModal={closeModal}
        />
      );
    }

    return (
      <PromotionListPage
        caseData={caseData}
        setActivePage={setActivePage}
        setActivePromotion={setActivePromotion}
        closeModal={closeModal}
      />
    );
  };

  return (
    <Modal
      size={"lg"}
      isOpen={isOpen}
      toggle={toggle}
      className="case-explorer-promotion-modal modal-btn-spaced">
      {renderSection()}
    </Modal>
  );
};

CaseExplorerPromotionModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseData: PropTypes.object
};

const PromotionListPage = (props) => {
  const { caseData, setActivePage, setActivePromotion, closeModal } = props;

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.promotion.isProcessing);
  const promotionUuidSaving = useSelector(
    (state) => state.promotion.promotionUuidSaving
  );
  const allPromotions = useSelector((state) => state.promotions.allPromotions);
  const caseUuid = get(caseData, "caseUuid");

  const onAddCaseToPromotion = async (promotion) => {
    await dispatch(addCaseToPromotion(promotion, caseUuid));
  };

  const onRemoveCaseFromPromotion = async (promotion) => {
    await dispatch(removeCaseFromPromotion(promotion, caseUuid));
  };

  const addPromotion = () => {
    setActivePromotion(null);
    setActivePage(PROMOTION_FORM_PAGE);
  };

  const editPromotion = (p) => {
    setActivePromotion(p);
    setActivePage(PROMOTION_FORM_PAGE);
  };

  const renderAddRemoveButton = (promotion, isActive) => {
    if (saving) {
      return isEqual(promotion.promotion_uuid, promotionUuidSaving) ? (
        <Spinner size="sm" color="primary" />
      ) : null;
    }

    return isActive ? (
      <Button
        color="primary"
        outline
        size="sm"
        className="align-middle"
        onClick={() => onRemoveCaseFromPromotion(promotion)}>
        Remove Case from Promotion
      </Button>
    ) : (
      <Button
        color="primary"
        outline
        size="sm"
        className="align-middle"
        onClick={() => onAddCaseToPromotion(promotion)}>
        Add Case to Promotion
      </Button>
    );
  };

  return (
    <div>
      <ModalHeader toggle={closeModal} className="border-0">
        Add/Remove Case Promotions
      </ModalHeader>
      <ModalBody className="pt-0">
        <Row>
          <Col className={"pb-1"}>
            <Button color="link" onClick={addPromotion}>
              <FontAwesomeIcon icon={faPlus} size={"sm"} className="mr-1" />
              Add New Promotion
            </Button>
          </Col>
        </Row>
        <Table bordered>
          <thead>
            <tr>
              <th className="table-heading-style">promotion name</th>
              <th className="table-heading-style">channel name</th>
              <th className="table-heading-style">date</th>
              <th className="table-heading-style">add / remove</th>
            </tr>
          </thead>
          <tbody>
            {map(allPromotions, (t) => {
              const isActive = isPromotionActive(t, caseUuid);
              return (
                <tr
                  key={`promotion-${t.promotion_uuid}`}
                  className={`table-row-style ${isActive &&
                    "active-promotion"}`}>
                  <td>
                    <Button
                      color="link"
                      onClick={() => editPromotion(t)}
                      className={"p-0"}>
                      {t.promotion_name}
                    </Button>
                  </td>
                  <td>{t.channel_name}</td>
                  <td>
                    {moment(t.promotion_publish_date)
                      .utc()
                      .format("MM/DD/YYYY")}
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {renderAddRemoveButton(t, isActive)}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-start">
        <Button color="primary" outline onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};

PromotionListPage.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  setActivePromotion: PropTypes.func.isRequired,
  caseData: PropTypes.object
};

const PromotionFormPage = (props) => {
  const { closeModal, setActivePage, activePromotion } = props;

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.promotion.isProcessing);
  const allChannels = useSelector((state) => state.promotions.allChannels);
  const [formData, setFormData] = useState({});
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const toggleConfirmDeleteModal = () =>
    setConfirmDeleteModal(!confirmDeleteModal);
  const promotionUuid = get(activePromotion, "promotion_uuid", "");
  const isNewPromotion = isEmpty(promotionUuid);

  const onDeletePromotion = async () => {
    if (!isNewPromotion) {
      await dispatch(deletePromotion(promotionUuid));
      setActivePage(PROMOTION_LIST_PAGE);
    }
  };

  useEffect(() => {
    const publishDate = get(activePromotion, "promotion_publish_date");
    const publishDateAsString = publishDate
      ? moment(publishDate)
          .utc()
          .format("YYYY-MM-DD")
      : "";
    setFormData({
      promotionName: get(activePromotion, "promotion_name", ""),
      promotionPublishDate: publishDateAsString,
      promotionChannel: get(activePromotion, "channel_uuid", ""),
      promotionNote: get(activePromotion, "promotion_notes", "")
    });
  }, [activePromotion]);

  const onSubmitPromotion = async () => {
    const promo = activePromotion || {};
    promo["promotion_name"] = formData.promotionName;
    promo["promotion_publish_date"] = formData.promotionPublishDate;
    promo["channel_uuid"] = formData.promotionChannel;
    promo["promotion_notes"] = formData.promotionNote;
    promo["cases"] = promo.cases || [];

    await dispatch(createOrUpdatePromotion(promo));
    setActivePage(PROMOTION_LIST_PAGE);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <AvForm onValidSubmit={onSubmitPromotion} onInvalidSubmit={onInvalidSubmit}>
      <ModalHeader toggle={closeModal} className="border-0">
        Manage Promotion
      </ModalHeader>
      <ModalBody className="pt-0">
        <AvField
          id="promotionName"
          name="promotionName"
          label="promotion name"
          placeholder="Promotion name"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          value={formData.promotionName || ""}
          onChange={onChange}
          required
        />

        <AvField
          id="promotionPublishDate"
          name="promotionPublishDate"
          label="promotion publish date"
          placeholder="Promotion publish date"
          type="date"
          labelClass="text-11 text-uppercase font-weight-normal"
          value={formData.promotionPublishDate || ""}
          onChange={onChange}
          required
        />

        <AvField
          type="select"
          name="promotionChannel"
          labelClass="text-11 text-uppercase font-weight-normal"
          label="promotion channel"
          id="promotionChannel"
          value={formData.promotionChannel || ""}
          onChange={onChange}
          required>
          <option value="">Select...</option>
          {map(allChannels, (c) => {
            return (
              <option key={`channel-${c.channel_uuid}`} value={c.channel_uuid}>
                {c.channel_name}
              </option>
            );
          })}
        </AvField>

        <AvField
          id="promotionNote"
          name="promotionNote"
          label="promotion note"
          placeholder="Promotion note..."
          type="textarea"
          rows="8"
          labelClass="text-11 text-uppercase font-weight-normal"
          value={formData.promotionNote || ""}
          onChange={onChange}
        />
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
        <div>
          {!isNewPromotion && (
            <Button
              color="danger"
              className={"mr-2"}
              outline
              onClick={toggleConfirmDeleteModal}>
              Delete Promotion
            </Button>
          )}
          <Button
            color="primary"
            outline
            onClick={() => setActivePage(CHANNEL_LIST_PAGE)}>
            Edit Channels
          </Button>
        </div>

        <div>
          <Button
            color="primary"
            outline
            onClick={() => setActivePage(PROMOTION_LIST_PAGE)}
            className="mr-2">
            Go Back
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={saving}>
            Save
          </LoadingButton>
        </div>
      </ModalFooter>

      <ConfirmModal
        toggle={toggleConfirmDeleteModal}
        isOpen={confirmDeleteModal}
        message={`Are you sure you wish to delete this promotion?`}
        onConfirm={onDeletePromotion}
        saving={saving}
      />
    </AvForm>
  );
};

PromotionFormPage.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  activePromotion: PropTypes.object
};

const PromotionChannelPage = (props) => {
  const { closeModal, setActivePage } = props;

  const dispatch = useDispatch();
  const form = useRef();
  const saving = useSelector((state) => state.promotion.isProcessing);
  const allPromotions = useSelector((state) => state.promotions.allPromotions);
  const allChannels = useSelector((state) => state.promotions.allChannels);

  const [newChannelName, setNewChannelName] = useState("");
  const [deleteChannelUuid, setDeleteChannelUuid] = useState("");
  const [confirmDeleteChannelModal, setConfirmDeleteChannelModal] = useState(
    false
  );

  const onNewChannelChange = (event) => {
    const { value } = event.target;
    setNewChannelName(value);
  };

  const onSubmitChannel = async () => {
    await dispatch(createPromotionChannel(newChannelName));

    form.current.reset();
  };

  const startDeleteChannel = (channelUuid) => {
    setDeleteChannelUuid(channelUuid);
    toggleConfirmDeleteChannelModal();
  };

  const onDeleteChannelPromotion = async () => {
    if (deleteChannelUuid) {
      await dispatch(deletePromotionChannel(deleteChannelUuid));
      toggleConfirmDeleteChannelModal();
    }
  };

  const toggleConfirmDeleteChannelModal = () =>
    setConfirmDeleteChannelModal(!confirmDeleteChannelModal);

  return (
    <div>
      <ModalHeader toggle={closeModal} className="border-0">
        Add/Remove Promotion Channels
      </ModalHeader>
      <ModalBody className="pt-0">
        <AvForm
          ref={form}
          onValidSubmit={onSubmitChannel}
          onInvalidSubmit={onInvalidSubmit}>
          <div className="d-flex">
            <AvField
              id="channelName"
              name="channelName"
              label="new channel name"
              placeholder="Add channel name.."
              type="text"
              groupAttrs={{ className: "flex-fill" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={newChannelName}
              onChange={onNewChannelChange}
              required
            />
            <div className="d-flex align-items-end ml-3 pb-3">
              <LoadingButton
                style={{ height: "36px" }}
                outline
                color="primary"
                type="submit"
                loading={saving}
                disabled={saving}>
                Add
              </LoadingButton>
            </div>
          </div>
        </AvForm>

        <Table bordered>
          <thead>
            <tr>
              <th className="table-heading-style">channel name</th>
              <th className="table-heading-style">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {map(allChannels, (t) => {
              return (
                <tr
                  key={`channel-${t.channel_uuid}`}
                  className={`table-row-style`}>
                  <td>{t.channel_name}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      {isPromotionChannelActive(
                        allPromotions,
                        t.channel_uuid
                      ) ? (
                        <span className="text-secondary text-uppercase text-12 font-weight-bold">
                          In Use
                        </span>
                      ) : (
                        <Button
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => startDeleteChannel(t.channel_uuid)}>
                          Remove Channel
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-start">
        <Button
          color="primary"
          outline
          onClick={() => setActivePage(PROMOTION_FORM_PAGE)}>
          Go Back
        </Button>
      </ModalFooter>

      <ConfirmModal
        toggle={toggleConfirmDeleteChannelModal}
        isOpen={confirmDeleteChannelModal}
        message={`Are you sure you wish to delete this channel?`}
        onConfirm={onDeleteChannelPromotion}
        saving={saving}
      />
    </div>
  );
};

PromotionChannelPage.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired
};

export default CaseExplorerPromotionModal;
