import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { filterUsers } from "../../actions/tools.actions";

const ToolsUsersFilterModal = (props) => {
  const { isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.tools.filters);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  const onSubmit = async (event) => {
    event.preventDefault();

    toggle();

    await dispatch(filterUsers(formData));
  };

  const clearFilters = () => {
    toggle();

    dispatch(filterUsers({}));
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Select filters
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="username">
                username
              </Label>
              <Input
                id="username"
                name="username"
                value={formData.username || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="username">
                user uuid
              </Label>
              <Input
                id="userUuid"
                name="userUuid"
                value={formData.userUuid || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="firstName">
                first name
              </Label>
              <Input
                id="firstName"
                name="firstName"
                value={formData.firstName || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="lastName">
                last name
              </Label>
              <Input
                id="lastName"
                name="lastName"
                value={formData.lastName || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="email">
                email address
              </Label>
              <Input
                id="email"
                name="email"
                value={formData.email || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col-6">
              <Label className="text-11 text-uppercase font-weight-normal">
                is partner account
              </Label>
              <Input
                type="select"
                name="isPartner"
                value={formData.isPartner || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-6">
              <Label className="text-11 text-uppercase font-weight-normal">
                is legacy account
              </Label>
              <Input
                type="select"
                name="legacyAccount"
                value={formData.legacyAccount || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                case count (contains at least)
              </Label>
              <Input
                id="approvedCaseCount"
                name="approvedCaseCount"
                value={formData.approvedCaseCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                comment count (contains at least)
              </Label>
              <Input
                id="approvedCommentCount"
                name="approvedCommentCount"
                value={formData.approvedCommentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={clearFilters}>
            Clear filters
          </Button>

          <LoadingButton outline color="primary" type="submit">
            Apply filters
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

ToolsUsersFilterModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

ToolsUsersFilterModal.defaultProps = {};

export default ToolsUsersFilterModal;
