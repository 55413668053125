import { isEmpty } from "lodash";

export const userSignedIn = (auth) => {
  return !isEmpty(auth.uid);
};

export const userMustExists = (user) => {
  return user.uid;
};

export const userIsAdmin = (user, userRoles) => {
  return user.uid && userRoles.isAdmin;
};
