import React from "react";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import { compact, defaultTo, get, map, orderBy, startCase } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";

const CommentReportsModal = (props) => {
  const { reports, isOpen, toggle } = props;

  const onOpened = async () => {};

  const reportsTable = compact(
    map(orderBy(reports, ["reportedAt"], ["desc"]), (n, index) => {
      const reportedAt = get(n, "reportedAt");
      const reportReason = get(n, "reportReason");
      return (
        <tr key={`comment-${index}`}>
          <td className="font-weight-bold align-top" style={{ width: "25%" }}>
            {get(n, "reporterUsername", "N/A")}
          </td>
          <td className="date-column align-top" style={{ width: "25%" }}>
            {reportedAt
              ? moment(reportedAt).format("MMM DD, YYYY h:mm A")
              : "N/A"}
          </td>
          <td className="align-top" style={{ width: "25%" }}>
            {defaultTo(reportReason ? startCase(reportReason) : null, "N/A")}
          </td>
          <td className="align-top">{defaultTo(get(n, "text"), "N/A")}</td>
        </tr>
      );
    })
  );

  const renderBody = () => {
    return (
      <div className="pb-3">
        <div style={{ minHeight: "400px" }}>
          <Table bordered>
            <thead>
              <tr>
                <th>Reporter Username</th>
                <th>Reported At</th>
                <th>Reason</th>
                <th>Text</th>
              </tr>
            </thead>
            <tbody>{reportsTable}</tbody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      size="lg"
      className="notes-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Comment Reports
      </ModalHeader>
      <ModalBody className="pt-0">{renderBody()}</ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-end">
        <Button color="primary" outline onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CommentReportsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  comment: PropTypes.object,
  reports: PropTypes.array
};

export default CommentReportsModal;
