import {
  get,
  isEmpty,
  isEqual,
  isNil,
  isString,
  map,
  omitBy,
  split,
  trim,
  without
} from "lodash";
import {
  adminUserSearchCall,
  campaignCreateCall,
  campaignDeleteTacticCall,
  campaignEditCall,
  campaignGetCall,
  campaignSaveTacticCall,
  campaignSearchCall,
  campaignStateCall,
  campaignTacticStateCall,
  campaignTacticTimelineCall
} from "../api/cloud-functions";
import { showGlobalMessage } from "./global.actions";
import * as ROUTES from "../constants/routes";
import { CAMPAIGN_FILTERS } from "../utils/campaign-utils";

const actionsPrefix = "campaigns";

export const GET_CAMPAIGNS_START = `${actionsPrefix}/GET_CAMPAIGNS_START`;
export const GET_CAMPAIGNS_COMPLETE = `${actionsPrefix}/GET_CAMPAIGNS_COMPLETE`;
export const UPDATE_CAMPAIGN_FILTERS = `${actionsPrefix}/UPDATE_CAMPAIGN_FILTERS`;
export const UPDATE_CAMPAIGN_AUTHOR_FILTER = `${actionsPrefix}/UPDATE_CAMPAIGN_AUTHOR_FILTER`;
export const SAVE_CAMPAIGN_START = `${actionsPrefix}/SAVE_CAMPAIGN_START`;
export const SAVE_CAMPAIGN_COMPLETE = `${actionsPrefix}/SAVE_CAMPAIGN_COMPLETE`;
export const SAVE_CAMPAIGN_STATE_START = `${actionsPrefix}/SAVE_CAMPAIGN_STATE_START`;
export const SAVE_CAMPAIGN_STATE_COMPLETE = `${actionsPrefix}/SAVE_CAMPAIGN_STATE_COMPLETE`;
export const SAVE_TACTIC_STATE_START = `${actionsPrefix}/SAVE_TACTIC_STATE_START`;
export const SAVE_TACTIC_STATE_COMPLETE = `${actionsPrefix}/SAVE_TACTIC_STATE_COMPLETE`;
export const GET_CAMPAIGN_START = `${actionsPrefix}/GET_CAMPAIGN_START`;
export const GET_CAMPAIGN_COMPLETE = `${actionsPrefix}/GET_CAMPAIGN_COMPLETE`;
export const SAVE_CAMPAIGN_TACTIC_START = `${actionsPrefix}/SAVE_CAMPAIGN_TACTIC_START`;
export const SAVE_CAMPAIGN_TACTIC_COMPLETE = `${actionsPrefix}/SAVE_CAMPAIGN_TACTIC_COMPLETE`;
export const DELETE_CAMPAIGN_TACTIC_START = `${actionsPrefix}/DELETE_CAMPAIGN_TACTIC_START`;
export const DELETE_CAMPAIGN_TACTIC_COMPLETE = `${actionsPrefix}/DELETE_CAMPAIGN_TACTIC_COMPLETE`;
export const SET_ACTIVE_CAMPAIGN = `${actionsPrefix}/SET_ACTIVE_CAMPAIGN`;
export const CAMPAIGN_REFRESH = `${actionsPrefix}/CAMPAIGN_REFRESH`;
export const CAMPAIGNS_REFRESH = `${actionsPrefix}/CAMPAIGNS_REFRESH`;
export const CAMPAIGN_CASE_REFRESH = `${actionsPrefix}/CAMPAIGN_CASE_REFRESH`;
export const UPDATE_SEARCH_FILTER = `${actionsPrefix}/UPDATE_SEARCH_FILTER`;
export const GET_AUTHORS_START = `${actionsPrefix}/GET_AUTHORS_START`;
export const GET_AUTHORS_COMPLETE = `${actionsPrefix}/GET_AUTHORS_COMPLETE`;

const SAVE_CREATE_SUCCESS = "The new campaign was created successfully.";
const SAVE_CAMPAIGN_SUCCESS = "The campaign was saved successfully.";
const SAVE_ERROR = "There was an error saving the campaign.  Please try again.";
const SAVE_TACTIC_ERROR =
  "There was an error saving the tactic.  Please check the data and try again.";
const DELETE_TACTIC_ERROR =
  "There was an error deleting the tactic.  Please check the data and try again.";
const SAVE_TACTIC_SUCCESS = "The tactic was saved successfully.";

export const getCampaigns = (pageName, page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_CAMPAIGNS_START
    });

    const filters = omitBy(
      {
        ...getState().campaigns[pageName].filters,
        // stateFilter: getState().campaigns[pageName].stateFilter
        tacticStateFilter: getState().campaigns[pageName].tacticStateFilter
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const searchFilter = getState().campaigns[pageName].searchFilter;
    if (searchFilter && !isEmpty(searchFilter)) {
      filters["searchFilter"] = searchFilter;
    }

    const authorFilter = getState().campaigns.campaignFilter;
    if (isEqual(authorFilter, CAMPAIGN_FILTERS.MINE)) {
      const userUuid = get(getState().user, "user.userUuid");
      if (!isEmpty(userUuid)) {
        filters["authorUuid"] = userUuid;
      }
    }

    const sortDirection = getState().campaigns[pageName].sortDirection;
    const pageSize = getState().campaigns[pageName].pageSize;

    try {
      const results = await campaignSearchCall(
        filters,
        page,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_CAMPAIGNS_COMPLETE,
        results,
        page,
        pageName
      });
    } catch (error) {
      console.log("Error retrieving cases", error);
      dispatch({
        type: GET_CAMPAIGNS_COMPLETE,
        error: error
      });
    }
  };
};

export const getCampaignById = (campaignUuid) => {
  return async function(dispatch) {
    if (isEmpty(campaignUuid)) {
      return dispatch({
        type: SET_ACTIVE_CAMPAIGN,
        activeCampaign: null
      });
    }

    dispatch({
      type: GET_CAMPAIGN_START
    });

    try {
      const result = await campaignGetCall(campaignUuid);

      dispatch({
        type: SET_ACTIVE_CAMPAIGN,
        activeCampaign: result
      });
    } catch (error) {
      dispatch(showGlobalMessage(`error ${error}`));
    }

    dispatch({
      type: GET_CAMPAIGN_COMPLETE
    });
  };
};

export const updateCampaignSearchFilter = (pageName, searchFilter) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SEARCH_FILTER,
      pageName,
      searchFilter
    });
  };
};

export const archiveCampaign = (campaignUuid) => {
  return saveCampaignState(campaignUuid, "archived", "archive");
};

export const reActivateCampaign = (campaignUuid) => {
  return saveCampaignState(campaignUuid, "draft");
};

export const changeTacticState = (campaignUuid) => {
  return saveCampaignState(campaignUuid, "active");
};

export const saveCampaign = (formData) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_CAMPAIGN_START
    });

    let response = null;
    const campaignUuid = get(formData, "campaignUuid");
    const isNew = isEmpty(campaignUuid);
    const campaignPriority = get(formData, "campaignPriority");
    const previewUsers = get(formData, "previewUserUids");
    if (!isEmpty(previewUsers)) {
      formData["previewUserUids"] = without(
        map(split(previewUsers, ","), (s) => trim(s)),
        null,
        ""
      );
    }
    if (!isEmpty(campaignPriority)) {
      const priority = parseInt(campaignPriority);
      formData["campaignPriority"] =
        priority >= 1 || priority <= 3 ? priority : undefined;
    }

    formData["isSponsored"] = get(formData, "isSponsored") === "true";

    try {
      response = isNew
        ? await campaignCreateCall(formData)
        : await campaignEditCall(campaignUuid, formData);

      if (response) {
        if (isNew) {
          dispatch(
            showGlobalMessage(SAVE_CREATE_SUCCESS, "Campaign Created", false)
          );
        } else {
          dispatch({
            type: CAMPAIGN_REFRESH,
            response
          });

          dispatch(
            showGlobalMessage(SAVE_CAMPAIGN_SUCCESS, "Saved Successful", false)
          );
        }
      }
    } catch (error) {
      dispatch(
        showGlobalMessage(SAVE_ERROR, "Campaign Save Error", true, error)
      );
    }

    dispatch({
      type: SAVE_CAMPAIGN_COMPLETE
    });

    return response;
  };
};

const saveCampaignState = (campaignUuid, state) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_CAMPAIGN_STATE_START
    });

    try {
      const response = await campaignStateCall(campaignUuid, state);

      console.log(response);
    } catch (error) {
      console.log("Error saving campaign state", error);
    }

    dispatch({
      type: SAVE_CAMPAIGN_STATE_COMPLETE
    });
  };
};

export const saveCampaignStaticCase = (formData, history) => {
  return saveCampaignCase(formData, "static", history);
};

export const saveCampaignSeriesCase = (formData, history) => {
  return saveCampaignCase(formData, "quiz_series", history);
};

export const saveCampaignClinicalMomentsCase = (formData, history) => {
  return saveCampaignCase(formData, "clinical_moments", history);
};

export const saveCampaignClinicalMomentsCMECase = (formData, history) => {
  return saveCampaignCase(formData, "cme", history);
};

export const saveCampaignQuizOrPollCase = (formData, history) => {
  return saveCampaignCase(formData, "quiz", history);
};

export const savePromoCard = (formData, history) => {
  return saveCampaignCase(formData, "promo_card", history);
};

const saveCampaignCase = (formData, tacticType, history) => {
  return async function(dispatch) {
    const campaignUuid = get(formData, "campaignUuid");
    const isNew = isEmpty(get(formData, "caseUuid"));
    if (isEmpty(campaignUuid)) {
      console.log("Not saving case without a campaignUuid");
      return;
    }

    formData.settings = omitBy(
      formData.settings,
      (v) => isNil(v) || isEmpty(v)
    );

    dispatch({
      type: SAVE_CAMPAIGN_TACTIC_START
    });

    try {
      const response = await campaignSaveTacticCall(formData, tacticType);

      const caseUuid = get(response, "caseUuid");

      await dispatch({
        type: CAMPAIGN_CASE_REFRESH,
        response
      });

      if (isNew) {
        const url = ROUTES.CAMPAIGN_TACTIC_MANAGE.replace(
          ":campaignId",
          campaignUuid
        ).replace(":caseId", caseUuid);

        history.replace(url);
      }

      dispatch(
        showGlobalMessage(
          SAVE_TACTIC_SUCCESS,
          "Saved Successful",
          false,
          null,
          false
        )
      );
    } catch (error) {
      dispatch(
        showGlobalMessage(
          SAVE_TACTIC_ERROR,
          "Tactic Save Error",
          true,
          error,
          false
        )
      );
    }

    dispatch({
      type: SAVE_CAMPAIGN_TACTIC_COMPLETE
    });
  };
};

export const deleteCampaignCase = (caseUuid, campaignUuid, history) => {
  return async function(dispatch) {
    dispatch({
      type: DELETE_CAMPAIGN_TACTIC_START
    });

    try {
      const response = await campaignDeleteTacticCall(caseUuid);
      console.log(response);

      history.replace(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
    } catch (error) {
      dispatch(
        showGlobalMessage(
          DELETE_TACTIC_ERROR,
          "Tactic Delete Error",
          true,
          error
        )
      );
    }

    dispatch({
      type: DELETE_CAMPAIGN_TACTIC_COMPLETE
    });
  };
};

export const saveCampaignCaseDates = (formData) => {
  return async function(dispatch) {
    const caseUuid = get(formData, "caseUuid");
    if (isEmpty(caseUuid)) {
      console.log("Not saving case without a caseUuid");
      return;
    }

    dispatch({
      type: SAVE_CAMPAIGN_TACTIC_START
    });

    try {
      const response = await campaignTacticTimelineCall(formData);
      console.log(response);

      await dispatch({
        type: CAMPAIGN_CASE_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          SAVE_TACTIC_ERROR,
          "Tactic Save Timeline Error",
          true,
          error
        )
      );
    }

    dispatch({
      type: SAVE_CAMPAIGN_TACTIC_COMPLETE
    });
  };
};

export const saveCampaignTacticState = (
  campaignUuid,
  caseUuid,
  pageName,
  state
) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_TACTIC_STATE_START
    });

    try {
      const response = await campaignTacticStateCall(
        campaignUuid,
        caseUuid,
        state
      );

      dispatch({
        type: CAMPAIGNS_REFRESH,
        response,
        pageName
      });
    } catch (error) {
      console.log("Error saving tactic state", error);
    }

    dispatch({
      type: SAVE_TACTIC_STATE_COMPLETE
    });
  };
};

export const updateCampaignAuthorFilter = (campaignFilter) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_CAMPAIGN_AUTHOR_FILTER,
      campaignFilter
    });
  };
};

export const findAuthors = (searchTerm) => {
  return async function(dispatch) {
    dispatch({
      type: GET_AUTHORS_START
    });

    try {
      const results = await adminUserSearchCall({ userTerm: searchTerm });
      const authors = map(get(results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );

      dispatch({
        type: GET_AUTHORS_COMPLETE,
        authors
      });
    } catch (error) {
      console.log(`Error fetching authors: ${error}`);
      dispatch({
        type: GET_AUTHORS_COMPLETE,
        authors: null
      });
    }
  };
};
