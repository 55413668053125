import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import LoadingButton from "../common/LoadingButton";

const ConfirmModal = (props) => {
  const {
    message,
    confirmButtonText,
    cancelButtonText,
    isOpen,
    toggle,
    onConfirm,
    saving
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      <ModalBody className="pt-0 px-5">
        <div className="bordered-text font-weight-bolder text-center">
          {message}
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-between pt-3 px-5 pb-5">
        <Button color="primary" outline onClick={toggle}>
          {cancelButtonText}
        </Button>

        <LoadingButton
          color="primary"
          outline
          onClick={onConfirm}
          loading={saving}>
          {confirmButtonText}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  saving: PropTypes.bool
};

ConfirmModal.defaultProps = {
  message: "Are you sure?",
  confirmButtonText: "Yes",
  cancelButtonText: "Go Back",
  onConfirm: () => {}
};

export default ConfirmModal;
