import { getPromotionChannels, getPromotions } from "../api/cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "promotions";

export const PROMOTIONS_LOAD = `${actionsPrefix}/PROMOTIONS_LOAD`;
export const PROMOTIONS_LOADED = `${actionsPrefix}/PROMOTIONS_LOADED`;
export const PROMOTION_CHANNELS_LOAD = `${actionsPrefix}/PROMOTION_CHANNELS_LOAD`;
export const PROMOTION_CHANNELS_LOADED = `${actionsPrefix}/PROMOTION_CHANNELS_LOADED`;

export const getAllPromotions = () => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTIONS_LOAD,
      isProcessing: true
    });

    try {
      const response = await getPromotions();
      if (isRequestSuccess(response)) {
        dispatch({
          type: PROMOTIONS_LOADED,
          data: response.store,
          isProcessing: false
        });
      } else {
        dispatch({
          type: PROMOTIONS_LOADED,
          data: [],
          isProcessing: false,
          isError: true,
          message: "failed to load promotions"
        });
      }
    } catch (error) {
      dispatch({
        type: PROMOTIONS_LOADED,
        data: [],
        isProcessing: false,
        isError: true,
        message: error.message
      });
    }
  };
};

export const getAllPromotionChannels = () => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_CHANNELS_LOAD
    });

    try {
      const response = await getPromotionChannels();
      if (isRequestSuccess(response)) {
        dispatch({
          type: PROMOTION_CHANNELS_LOADED,
          data: response.store
        });
      }
    } catch (error) {}
  };
};
