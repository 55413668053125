import {
  PROMOTION_CHANNEL_DELETE,
  PROMOTION_CHANNEL_DELETED,
  PROMOTION_CHANNEL_SAVE,
  PROMOTION_CHANNEL_SAVED,
  PROMOTION_DELETE,
  PROMOTION_DELETE_CASE,
  PROMOTION_DELETED,
  PROMOTION_DELETED_CASE,
  PROMOTION_EDIT,
  PROMOTION_LOAD,
  PROMOTION_LOADED,
  PROMOTION_LOADED_ERROR
} from "../actions/promotion.actions";

const INITIAL_STATE = {
  isProcessing: false,
  isError: false,
  message: null,
  updated: false,
  promotionUuidSaving: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMOTION_LOAD:
    case PROMOTION_EDIT:
    case PROMOTION_DELETE:
    case PROMOTION_DELETE_CASE:
      return {
        ...state,
        isProcessing: action.isProcessing,
        promotionUuidSaving: action.promotionUuid
      };
    case PROMOTION_LOADED:
    case PROMOTION_DELETED:
    case PROMOTION_LOADED_ERROR:
    case PROMOTION_DELETED_CASE:
      return {
        ...state,
        isProcessing: false,
        isError: action.isError,
        message: action.message,
        promotionUuidSaving: null
      };
    case PROMOTION_CHANNEL_DELETE:
      return {
        ...state,
        isProcessing: true
      };
    case PROMOTION_CHANNEL_DELETED:
      return {
        ...state,
        isProcessing: false
      };
    case PROMOTION_CHANNEL_SAVE:
      return {
        ...state,
        isProcessing: true
      };
    case PROMOTION_CHANNEL_SAVED:
      return {
        ...state,
        isProcessing: false
      };
    default:
      return state;
  }
};

export default reducer;
