import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { clone, get, isEmpty, set } from "lodash";
import { savePromoCard } from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  getTacticFormData,
  handleSwitchChange
} from "../../../utils/campaign-utils";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit
} from "../../../utils/form-utils";
import "easymde/dist/easymde.min.css";
import { CampaignTacticSettings } from "./CampaignTacticSettings";
import { useHistory } from "react-router-dom";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import { CampaignFormButtons } from "./CampaignForms";

export const CampaignPromotionCardForm = (props) => {
  const { caseData, activeSection, toggleAuthorModal } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid");

  useEffect(() => {
    setFormData(getTacticFormData(campaignUuid, caseData, activeSection, user));
  }, [campaignUuid, caseData, activeSection, user]);

  const onSubmit = async () => {
    await dispatch(savePromoCard(formData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() =>
          handleSwitchChange(onChange, fieldName, !value, formData)
        }
      />
    );
  };
  if (Object.keys(formData).length === 0) {
    return null;
  }

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
      <CampaignTacticSettings
        formData={formData}
        onChange={onChange}
        toggleAuthorModal={toggleAuthorModal}
        showDateFields={true}
        showToggles={false}
        showAuthorFields={false}
        showSponsoredFields={false}>
        <div className="d-flex flex-wrap">
          <FormGroup style={{ width: "20%" }}>
            <Label className="text-11 text-uppercase font-weight-normal">
              Dismissible
            </Label>
            {renderToggleSwitch("features.dismiss_button")}
          </FormGroup>
          <FormGroup style={{ width: "35%" }}>
            <Label className="text-11 text-uppercase font-weight-normal">
              Dismiss on click through
            </Label>
            {renderToggleSwitch("features.dismiss_on_click")}
          </FormGroup>
          <FormGroup style={{ width: "20%" }}>
            <Label className="text-11 text-uppercase font-weight-normal">
              Show in web
            </Label>
            {renderToggleSwitch("features.show_in_web")}
          </FormGroup>
          <FormGroup style={{ width: "20%" }}>
            <Label className="text-11 text-uppercase font-weight-normal">
              Show in mobile app
            </Label>
            {renderToggleSwitch("features.show_in_mobile")}
          </FormGroup>
        </div>
      </CampaignTacticSettings>

      <div className="mt-4 p-3 bg-white">
        <CampaignPromoCardMainElements
          formData={formData}
          onChange={onChange}
        />

        <CampaignFormButtons saving={saving} caseData={caseData} />
      </div>
    </AvForm>
  );
};

CampaignPromotionCardForm.propTypes = {
  caseData: PropTypes.object
};

export const CampaignPromoCardMainElements = (props) => {
  const {
    formData,
    onChange,
    titleLabel = "TITLE (WHAT IS SHOWN IN-APP)",
    captionLabel = "Body",
    prefix = ""
  } = props;

  const [ctaEnabled, setCtaEnabled] = useState(
    formData?.button_text || formData?.button_url
  );

  const toggleCta = () => {
    if (ctaEnabled) {
      onChange({ target: { name: "button_text", value: "" } });
      onChange({ target: { name: "button_url", value: "" } });
    }
    setCtaEnabled(!ctaEnabled);
  };

  const buttonTextValidation = (value) => {
    if (ctaEnabled) {
      if (isEmpty(value)) {
        return "You need to provide button text";
      }

      if (value.length > 33) {
        return "Button text length cannot exceed 33 characters";
      }
    }

    return true;
  };

  const buttonUrlValidation = (value) => {
    if (ctaEnabled) {
      if (isEmpty(value)) {
        return "You need to provide a target url";
      }
    }

    return true;
  };

  return (
    <>
      <AvField
        name={getFieldName("title", prefix)}
        value={getFieldValue(formData, "title", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "title", prefix),
          31
        )}
        label={titleLabel}
        placeholder="Title here"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />

      <AvField
        name={getFieldName("caption", prefix)}
        value={getFieldValue(formData, "caption", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "caption", prefix),
          139
        )}
        label={captionLabel}
        placeholder="Body copy"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />

      <FormGroup className="w-25">
        <Label className="text-11 text-uppercase font-weight-normal">
          CTA Button
        </Label>
        <F1ToggleButton value={ctaEnabled} onClick={toggleCta} />
      </FormGroup>

      {ctaEnabled && (
        <>
          <AvField
            name={getFieldName("button_text", prefix)}
            value={getFieldValue(formData, "button_text", prefix)}
            helpMessage={getFieldValueCharacters(
              getFieldValue(formData, "button_text", prefix),
              33
            )}
            label="Button text"
            placeholder="button"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
            validate={{ myValidation: buttonTextValidation }}
          />

          <AvField
            name={getFieldName("button_url", prefix)}
            value={getFieldValue(formData, "button_url", prefix)}
            helpMessage={getFieldValueCharacters(
              getFieldValue(formData, "button_url", prefix),
              139
            )}
            label="Target url"
            placeholder="Target url"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
            validate={{ myValidation: buttonUrlValidation }}
          />
        </>
      )}
    </>
  );
};
