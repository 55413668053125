import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { get, isEqual, toLower, trim } from "lodash";
import LoadingButton from "../../../components/common/LoadingButton";
import { deleteUser, getUsers } from "../../../actions/tools.actions";

const ConfirmDeleteModal = (props) => {
  const { isOpen, toggle, user } = props;

  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState("");
  const userUid = get(user, "userUid");
  const username = get(user, "username");
  const saving = useSelector((state) => state.tools.userSaving);
  const userSaveError = useSelector((state) => state.tools.userSaveError);
  const userSaveErrorMessage = userSaveError
    ? get(JSON.parse(userSaveError.message), "store.msg")
    : null;

  const onSubmit = async (event) => {
    event.preventDefault();

    const isValid = isEqual(
      trim(toLower(username)),
      trim(toLower(confirmUsername))
    );

    if (isValid) {
      const result = await dispatch(deleteUser(userUid));

      if (result) {
        toggle();

        dispatch(getUsers(0));
      }
    } else {
      setError(true);
    }
  };

  const onChange = (event) => {
    const { value } = event.target;
    setConfirmUsername(value);

    if (error) {
      setError(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>Delete User?</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {userSaveError && (
            <div className="alert alert-danger" role="alert">
              An error occurred when saving the data.
              {userSaveErrorMessage && (
                <>
                  <br />
                  <b>{userSaveErrorMessage}</b>
                </>
              )}
            </div>
          )}

          <div className="text-14 font-weight-bold mb-2">
            To confirm, please type the username in the field below
          </div>

          <FormGroup>
            <Label
              className="text-11 text-uppercase font-weight-normal"
              for="caption">
              {username}
            </Label>
            <Input
              name="confirmUsername"
              value={confirmUsername}
              onChange={onChange}
              type="text"
              invalid={error}
            />
            {error && (
              <FormFeedback>The confirmed username did not match</FormFeedback>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Yes, Delete
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object
};

export default ConfirmDeleteModal;
