import Firebase from "../firebase";

const auth = Firebase.auth;

export const doCreateUserWithEmailAndPassword = (
  email,
  password,
  firstName,
  lastName
) => {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(async (userCred) => {
      await userCred.user.updateProfile({
        displayName: `${firstName} ${lastName}`
      });

      return userCred.user;
    });
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  try {
    return await auth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    throw error;
  }
};

export const doSignOut = () => auth.signOut();
