import {
  compact,
  concat,
  filter,
  find,
  has,
  includes,
  isEqual,
  isNil,
  map,
  toUpper,
  uniq
} from "lodash";
import { CASE_STATES } from "./case-utils";
import { isTagger } from "./permission-utils";

export const TAGGING_FILTERS = {
  UNTAGGED: { statsKey: "pending_tagging", displayName: "Untagged" },
  PENDING_AUTOTAGGING: {
    statsKey: "pending_nlp",
    displayName: "Pending Auto-tagging"
  },
  FLAGGED: {
    statsKey: "flagged_tagger",
    displayName: "Flagged",
    isViewable: (roles) => isTagger(roles)
  },
  TAGGED: {
    statsKey: "approved",
    displayName: "Tagged",
    isViewable: (roles) => isTagger(roles)
  }
};

export const isTaggingActive = (caseState) => {
  const upperCaseState = toUpper(caseState);
  return (
    isEqual(upperCaseState, CASE_STATES.PENDING_TAGGING) ||
    isEqual(upperCaseState, CASE_STATES.FLAGGED_TAGGER)
  );
};

export const filterSpecialities = (
  specialtyUuids,
  removedSpecialtyUuids,
  taggingSpecialties,
  taggingAllSpecialties
) => {
  return compact(
    map(specialtyUuids, (uuid) => {
      if (
        find(taggingSpecialties, (t) => isEqual(t.uuid, uuid)) &&
        !includes(removedSpecialtyUuids, uuid) &&
        has(taggingAllSpecialties, uuid)
      ) {
        return taggingAllSpecialties[uuid];
      }

      return null;
    })
  );
};

export const hasSpeciality = (specialtyUuids, taggingSpecialties) => {
  return !isNil(
    find(specialtyUuids, (uuid) => {
      return !isNil(find(taggingSpecialties, (t) => isEqual(t.uuid, uuid)));
    })
  );
};

export const filterTerms = (existingTerms, addedTerms, removedTerms) => {
  return uniq(
    filter(concat(existingTerms, addedTerms), (t) => !includes(removedTerms, t))
  );
};

export const convertSpecialities = (specialtyUuids, taggingAllSpecialties) => {
  return compact(
    map(specialtyUuids, (uuid) => {
      if (has(taggingAllSpecialties, uuid)) {
        return taggingAllSpecialties[uuid];
      }

      return null;
    })
  );
};
