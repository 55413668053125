import React from "react";

const F1Banner = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { color, children } = props;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className={`bg-${color}`} style={{ padding: "10px" }}>
      {children}
    </div>
  );
};

export default F1Banner;
