import { scrollToTop } from "../utils/form-utils";

const actionsPrefix = "global";

export const SHOW_GLOBAL_MESSAGE = `${actionsPrefix}/SHOW_GLOBAL_MESSAGE`;
export const HIDE_GLOBAL_MESSAGE = `${actionsPrefix}/HIDE_GLOBAL_MESSAGE`;
export const TOGGLE_LOGIN_MODAL = `${actionsPrefix}/TOGGLE_LOGIN_MODAL`;

export const showGlobalMessage = (
  message,
  title = null,
  isError = true,
  error,
  scroll = true
) => {
  return async function(dispatch) {
    dispatch({
      type: SHOW_GLOBAL_MESSAGE,
      title,
      message,
      isError,
      error,
      fixToTop: !scroll
    });

    if (scroll) {
      scrollToTop();
    }
  };
};

export const hideGlobalMessage = () => {
  return async function(dispatch) {
    dispatch({
      type: HIDE_GLOBAL_MESSAGE
    });
  };
};

export const toggleLoginModal = (visible) => {
  return async function(dispatch) {
    dispatch({
      type: TOGGLE_LOGIN_MODAL,
      visible
    });
  };
};
