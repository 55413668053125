import {
  concat,
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  map,
  omitBy,
  size,
  uniq,
  without
} from "lodash";
import {
  ADD_NOTE_COMPLETE,
  ADD_NOTE_START,
  CHANGE_STATE_COMPLETE,
  CHANGE_STATE_START,
  DISABLE_PRIORITY_FILTER,
  EDIT_USER_COMPLETE,
  EDIT_USER_START,
  ENABLE_PRIORITY_FILTER,
  GET_USERS_COMPLETE,
  GET_USERS_START,
  RESET_BULK_USERS,
  SAVE_FILTERS,
  SAVE_PRIORITY_FILTERS_COMPLETE,
  SAVE_PRIORITY_FILTERS_LISTENER,
  SET_ACTIVE_USER,
  SET_ALL_BULK_USERS,
  SET_BULK_USER,
  TAG_CHANGE_COMPLETE,
  TAG_CHANGE_START,
  TAG_MANAGE_COMPLETE,
  TAG_MANAGE_START,
  VERIFICATION_USER_REFRESH
} from "../actions/verification.actions";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  message: null,
  typeStats: null,
  statusStats: null,
  activeUser: null,
  activeVerificationType: null,
  activeVerificationStatus: null,
  totalUsers: null,
  users: {},
  usersPage: {},
  filters: {},
  priorityEnabled: false,
  priorityFilters: {},
  priorityFiltersListener: null,
  currentPage: 0,
  addNoteSaving: false,
  changeStateSaving: false,
  tagsSaving: false,
  bulkUsers: [],
  editSaving: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        usersPage: {},
        pageStats: null,
        activeUser: null,
        bulkUsers: []
      };
    }
    case GET_USERS_COMPLETE: {
      const usersPage = get(action.results, "pageData", []);
      const users = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(usersPage, "pageSize", 10);
      const totalUsers = get(usersPage, "totalSize", 0);
      const currentPage = action.currentPage || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(users);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalUsers;

      return {
        ...state,
        processing: false,
        typeStats: get(action.results, "allStats.verificationTypeStats", {}),
        statusStats: get(
          action.results,
          "allStats.verificationStatusStats",
          {}
        ),
        users: users,
        activeUser: head(users),
        pageSize: pageSize,
        totalUsers: totalUsers,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage,
        activeVerificationType: action.verificationType,
        activeVerificationStatus: action.verificationStatus,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case SAVE_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case SET_ACTIVE_USER: {
      return {
        ...state,
        activeUser: action.user
      };
    }
    case SET_BULK_USER: {
      return {
        ...state,
        bulkUsers: uniq(
          action.active
            ? concat(state.bulkUsers, action.userUid)
            : without(state.bulkUsers, action.userUid)
        )
      };
    }
    case SET_ALL_BULK_USERS: {
      return {
        ...state,
        bulkUsers: action.userUids
      };
    }
    case RESET_BULK_USERS: {
      return {
        ...state,
        bulkUsers: []
      };
    }
    case ADD_NOTE_START: {
      return {
        ...state,
        addNoteSaving: true
      };
    }
    case ADD_NOTE_COMPLETE: {
      return {
        ...state,
        addNoteSaving: false
      };
    }
    case CHANGE_STATE_START: {
      return {
        ...state,
        changeStateSaving: true
      };
    }
    case CHANGE_STATE_COMPLETE: {
      return {
        ...state,
        changeStateSaving: false
      };
    }
    case TAG_CHANGE_START: {
      return {
        ...state,
        tagsSaving: true
      };
    }
    case TAG_CHANGE_COMPLETE: {
      return {
        ...state,
        tagsSaving: false
      };
    }
    case TAG_MANAGE_START: {
      return {
        ...state,
        tagsSaving: true
      };
    }
    case TAG_MANAGE_COMPLETE: {
      return {
        ...state,
        tagsSaving: false
      };
    }
    case EDIT_USER_START: {
      return {
        ...state,
        editSaving: true
      };
    }
    case EDIT_USER_COMPLETE: {
      return {
        ...state,
        editSaving: false
      };
    }
    case VERIFICATION_USER_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const userUuid = get(action.response, "userUuid");
      return {
        ...state,
        users: map(state.users, (c) => {
          if (isEqual(userUuid, c.userUuid)) {
            return action.response;
          } else {
            return c;
          }
        }),
        activeUser: isEqual(get(state.activeUser, "userUuid"), userUuid)
          ? action.response
          : state.activeUser
      };
    }
    case ENABLE_PRIORITY_FILTER: {
      return {
        ...state,
        priorityEnabled: true
      };
    }
    case DISABLE_PRIORITY_FILTER: {
      return {
        ...state,
        priorityEnabled: false
      };
    }
    case SAVE_PRIORITY_FILTERS_COMPLETE: {
      return {
        ...state,
        priorityFilters: omitBy(action.priorityFilters, isEmpty)
      };
    }
    case SAVE_PRIORITY_FILTERS_LISTENER: {
      if (typeof state.priorityFiltersListener === "function") {
        state.priorityFiltersListener();
      }

      return {
        ...state,
        priorityFiltersListener: action.listener
      };
    }
    default:
      return state;
  }
};

export default reducer;
