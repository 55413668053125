import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import { AvField, AvForm, AvInput } from "availity-reactstrap-validation";
import {
  concat,
  defaultTo,
  get,
  join,
  map,
  size,
  split,
  trim,
  uniq,
  clone,
  set,
  without
} from "lodash";
import CampaignDefineAudienceModal from "../../../components/modals/CampaignDefineAudienceModal";
import LoadingButton from "../../../components/common/LoadingButton";
import { saveCampaign } from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import { onInvalidTacticSubmit } from "../../../utils/form-utils";
import "easymde/dist/easymde.min.css";
import { isCampaignEditActive } from "../../../utils/campaign-utils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import CampaignAuthorSearchModal from "./CampaignAuthorSearchModal";

export const CampaignMainForm = (props) => {
  const { campaignUuid } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [audienceModal, setAudienceModal] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  const toggleAudienceModal = () => setAudienceModal(!audienceModal);
  const [previewerModalOpen, setPreviewerModalOpen] = useState(false);
  const togglePreviewerModal = () => setPreviewerModalOpen(!previewerModalOpen);
  const [formData, setFormData] = useState({});
  const activeCampaignUuid = get(activeCampaign, "campaignUuid");
  const targetCountries = get(activeCampaign, "targetCountries", []);
  const targetLanguages = get(activeCampaign, "targetLanguages", []);
  const targetSpecialties = get(activeCampaign, "targetSpecialties", []);
  const totalAudiences =
    size(targetCountries) + size(targetLanguages) + size(targetSpecialties);
  const isActive = !isCampaignEditActive(activeCampaign);

  useEffect(() => {
    setFormData({
      campaignUuid: defaultTo(campaignUuid, ""),
      name: defaultTo(get(activeCampaign, "name"), ""),
      clientName: defaultTo(get(activeCampaign, "clientName"), ""),
      campaignPriority: defaultTo(get(activeCampaign, "campaignPriority"), ""),
      previewUserUids: join(
        without(
          map(get(activeCampaign, "previewUsers"), (u) => u.userUid),
          ""
        ),
        ","
      ),
      isSponsored: defaultTo(get(activeCampaign, "isSponsored"), "").toString()
    });
  }, [activeCampaignUuid, campaignUuid, activeCampaign]);

  const onSubmit = () => {
    dispatch(saveCampaign(formData));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSelectPreviewer = (u) => {
    if (!u || !u.userUid) {
      return;
    }

    const fieldName = "previewUserUids";
    const previewUsers = get(formData, fieldName);
    const values = join(
      without(
        uniq(
          concat(
            map(split(previewUsers, ","), (s) => trim(s)),
            u.userUid
          )
        ),
        "",
        null
      ),
      ","
    );
    setFormData(clone(set(formData, fieldName, values)));
  };

  return (
    <Card className="border-0">
      <CardBody>
        <AvForm
          className="px-2"
          onValidSubmit={onSubmit}
          onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
          <AvField
            name="name"
            label="campaign name"
            placeholder="Campaign name here"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.name || ""}
            onChange={onChange}
            disabled={isActive}
            required
          />
          <AvField
            name="clientName"
            label="client"
            placeholder="Client here"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.clientName || ""}
            onChange={onChange}
            disabled={isActive}
            required
          />
          <AvField
            name="campaignPriority"
            label="campaign priority level"
            placeholder="Enter priority level here"
            type="number"
            min="1"
            max="3"
            errorMessage={"Number between 1 and 3"}
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.campaignPriority || ""}
            disabled={isActive}
            onChange={onChange}
          />
          <div className="d-flex flex-column">
            <Label className="text-11 text-uppercase font-weight-normal">
              preview user ids
            </Label>
            <FormGroup className="d-flex flex-grow-1 flex-row justify-content-between">
              <div style={{ width: "78%" }}>
                <AvInput
                  type="text"
                  name="previewUserUids"
                  value={formData.previewUserUids}
                  placeholder="Enter User Ids here"
                  onChange={onChange}
                />
              </div>
              <Button
                size="md"
                color="primary-dark"
                className="ml-3"
                onClick={togglePreviewerModal}>
                Add Previewer
              </Button>
            </FormGroup>

            <CampaignAuthorSearchModal
              isOpen={previewerModalOpen}
              toggle={togglePreviewerModal}
              selectUser={onSelectPreviewer}
              title={"Find User Previewer"}
            />
          </div>
          <AvField
            id="isSponsored"
            name="isSponsored"
            labelClass="text-11 font-weight-normal"
            label="Sponsored or unsponsored content"
            value={formData.isSponsored || ""}
            onChange={onChange}
            type="select"
            required>
            <option value="" disabled>
              Select...
            </option>
            <option value="true">Sponsored</option>
            <option value="false">Unsponsored</option>
          </AvField>
          <div className="py-2">
            <Button
              size="lg"
              color={totalAudiences > 0 ? "green" : "primary-dark"}
              onClick={() => {
                setShowOverview(totalAudiences > 0);
                toggleAudienceModal();
              }}
              className="mr-3">
              {totalAudiences > 0
                ? `See audience overview`
                : `Select audience targeting`}
            </Button>
          </div>
          <div className="d-flex justify-content-between mt-4 pt-4 border-top">
            <Button
              color="primary"
              outline
              onClick={() => {
                history.push(`${ROUTES.CAMPAIGN_ROOT}`);
              }}>
              Cancel
            </Button>
            <LoadingButton
              outline
              color="primary"
              type="submit"
              loading={saving}
              disabled={saving || isActive}>
              Save
            </LoadingButton>
          </div>
        </AvForm>
      </CardBody>
      {audienceModal && (
        <CampaignDefineAudienceModal
          isOpen={audienceModal}
          toggle={toggleAudienceModal}
          activeCampaign={activeCampaign}
          showOverview={showOverview}
          isEditable={!isActive}
        />
      )}
    </Card>
  );
};
