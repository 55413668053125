// Authentication Routes
export const ROOT = `/`;
export const SIGN_IN = `/signin`;
export const DEV_ONLY_SIGN_UP = `/dev-only-signup`;
export const DEV_ONLY_SIGN_IN = `/dev-only-signin`;
export const TAGGER_SIGN_UP = `/tagger-signup`;
export const TAGGER_SIGN_IN = `/tagger-signin`;
export const SIGN_OUT = `/logout`;

// Moderation Routes
export const MODERATION_ROOT = `/moderation`;
export const MODERATION_CASES = `${MODERATION_ROOT}`;
export const MODERATION_PARTNER_CASES = `${MODERATION_ROOT}/partner-cases`;
export const MODERATION_COMMENTS = `${MODERATION_ROOT}/comments`;
export const MODERATION_COMMENTS_HISTORY = `${MODERATION_ROOT}/comments-history`;
export const TAGGING_LANDING = `${MODERATION_ROOT}/tagging`;

// Verification Routes
export const VERIFICATION_ROOT = `/verification`;
export const VERIFICATION_NPI = `${VERIFICATION_ROOT}/npi`;
export const VERIFICATION_LICENSE = `${VERIFICATION_ROOT}/license`;
export const VERIFICATION_PHOTO_ID = `${VERIFICATION_ROOT}/photo`;
export const VERIFICATION_INSTITUTIONAL = `${VERIFICATION_ROOT}/institutional_email`;
export const VERIFICATION_ALL = `${VERIFICATION_ROOT}/all`;

// Campaign Routes
export const CAMPAIGN_ROOT = `/campaigns`;
export const CAMPAIGN_ACTIVE = `${CAMPAIGN_ROOT}/active`;
export const CAMPAIGN_MANAGE = `${CAMPAIGN_ROOT}/manage`;
export const CAMPAIGN_DRAFTS = `${CAMPAIGN_ROOT}/draft`;
export const CAMPAIGN_REVIEW = `${CAMPAIGN_ROOT}/review`;
export const CAMPAIGN_ARCHIVED = `${CAMPAIGN_ROOT}/archived`;
export const CAMPAIGN_TACTIC_NEW = `${CAMPAIGN_ROOT}/:campaignId/tactic/new/:type`;
export const CAMPAIGN_TACTIC_MANAGE = `${CAMPAIGN_MANAGE}/:campaignId/tactic/:caseId`;
export const CAMPAIGN_PROFILE = `${CAMPAIGN_ROOT}/profile`;
export const CAMPAIGN_COMMENT_QUEUE = `${CAMPAIGN_ROOT}/comment_queue`;

// Admin Routes
export const ADMIN_ROOT = `/admin`;

// Home Routes
export const HOME_ROOT = `/home`;
export const NOT_AUTHORIZED = `${HOME_ROOT}/not-authorized`;

// Case Explorer Routes
export const CASE_EXPLORER_ROOT = `/case-explorer`;

// Case Explorer Routes
export const TOOLS_ROOT = `/tools`;
