import {
  compact,
  defaultTo,
  filter,
  find,
  findIndex,
  flatMap,
  get,
  groupBy,
  has,
  head,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  remove,
  size,
  sortBy,
  toLower,
  toString,
  toUpper
} from "lodash";
import { getFieldName, getFieldValue } from "./form-utils";
import { parseDate } from "./general-utils";
import { getDisplayName } from "./verification-utils";

export const CAMPAIGN_STATES = Object.freeze({
  DRAFT: "draft",
  REVIEW: "review",
  ACTIVE: "active",
  ARCHIVED: "archived"
});

export const CAMPAIGN_TACTIC_STATES = Object.freeze({
  SC_DRAFT: "Draft",
  SC_REVIEW: "Review",
  SC_APPROVED: "Approved",
  SC_ARCHIVED: "Archived"
});

export const CAMPAIGN_TACTIC_STATE_VALUES = Object.freeze({
  SC_DRAFT: "SC_DRAFT",
  SC_REVIEW: "SC_REVIEW",
  SC_APPROVED: "SC_APPROVED",
  SC_ARCHIVED: "SC_ARCHIVED"
});

export const DndItemTypes = {
  IMAGE_BLOCK: "card"
};

export const CAMPAIGN_FILTERS = Object.freeze({
  ALL: "All Campaigns",
  MINE: "My Campaigns"
});

export const CAMPAIGN_PAGE_CONFIG = Object.freeze({
  DRAFT: {
    displayName: "Drafts",
    stateFilter: CAMPAIGN_STATES.DRAFT,
    pageKey: "draftPage"
  },
  REVIEW: {
    displayName: "Review",
    stateFilter: CAMPAIGN_STATES.REVIEW,
    pageKey: "reviewPage"
  },
  ACTIVE: {
    displayName: "Active",
    stateFilter: CAMPAIGN_STATES.ACTIVE,
    pageKey: "activePage"
  },
  ARCHIVED: {
    displayName: "Archived",
    stateFilter: CAMPAIGN_STATES.ARCHIVED,
    pageKey: "archivedPage"
  },
  PROFILE: {
    displayName: "Profile",
    stateFilter: CAMPAIGN_STATES.ARCHIVED,
    pageKey: "profilePage"
  },
  COMMENT_QUEUE: {
    displayName: "Comment Queue",
    stateFilter: CAMPAIGN_STATES.ARCHIVED,
    pageKey: "commentQueue"
  },
  NEW: {
    displayName: "New Campaign",
    pageKey: "new"
  }
});

export const MANAGE_PAGE_CONFIG = Object.freeze({
  MAIN: {
    displayName: "Campaign settings",
    key: "main"
  }
});

export const QUIZ_SERIES_SLIDE_TYPES = {
  FEEDCARD: "Feedcard",
  QUIZ: "Quiz",
  QUIZ_CONCLUSION: "Quiz Conclusion"
};

export const POLL_SERIES_SLIDE_TYPES = {
  FEEDCARD: "Feedcard",
  POLL: "Poll",
  POLL_CONCLUSION: "Poll Conclusion"
};

export const CLINICAL_MOMENTS_SLIDE_TYPES = {
  FEEDCARD: "Feedcard",
  COVER_SLIDE: "Cover slide",
  BASIC: "Basic",
  QUIZ: "Quiz",
  POLL: "Poll",
  CONCLUSION: "Conclusion"
};

export const CLINICAL_MOMENTS_CME_SLIDE_TYPES = {
  FRONT_MATTER: "Front Matter",
  PRE_TEST: "Pre-test",
  ACTIVITY: "Activity",
  POST_TEST: "Post-test",
  SURVEY: "Survey",
  CERTIFICATES: "Certificates"
};

export const CLINICAL_MOMENTS_CME_QUESTION_SLIDE_TYPES = [
  "PRE_TEST",
  "POST_TEST",
  "SURVEY"
];

export const TACTIC_TYPES = {
  IMAGE: {
    displayName: "Single image",
    key: "single_image"
  },
  IMAGE_SERIES: {
    displayName: "Image series",
    key: "image_series"
  },
  TEXT: {
    displayName: "Text",
    key: "text"
  },
  VIDEO: {
    displayName: "Video",
    key: "video"
  },
  QUIZ: {
    displayName: "Quiz (single)",
    key: "quiz"
  },
  QUIZ_SERIES: {
    displayName: "Quiz (multi)",
    key: "quiz_series",
    slideTypes: QUIZ_SERIES_SLIDE_TYPES
  },
  POLL: {
    displayName: "Poll (single)",
    key: "poll"
  },
  POLL_SERIES: {
    displayName: "Poll (multi)",
    key: "poll_series",
    slideTypes: POLL_SERIES_SLIDE_TYPES
  },
  CLINICAL_MOMENT: {
    displayName: "Clinical Moment",
    key: "clinical_moment",
    slideTypes: CLINICAL_MOMENTS_SLIDE_TYPES
  },
  CLINICAL_MOMENT_CME: {
    displayName: "Clinical Moment (CME)",
    key: "clinical_moment_cme",
    slideTypes: CLINICAL_MOMENTS_CME_SLIDE_TYPES
  },
  PROMOTION: {
    displayName: "Promo card",
    key: "promo_card"
  }
};

export const FEED_CARD_TYPES = {
  BASIC: "basic",
  HIGHLIGHT: "highlight",
  CME_HUB: "cme_hub",
  END_OF_FEED: "eof"
};

/**
 * Mapping between Admin tool feed card types and contract FeedCardType
 * and fields state
 *
 * Static cases:
 *   - Without ISI -> FeedCardType.BASIC
 *   - With ISI -> FeedCardType.HIGHLIGHT
 * Quiz/Poll
 *   - Without ISI -> FeedCardType.BASIC, questionOptions not empty
 *   - With ISI -> FeedCardType.HIGHLIGHT
 * QuizSeries/PollSeries
 *   - Without ISI
 *     - "Regular expand/collapse view" -> FeedCardType.BASIC, button_text=null, questionOptions not empty
 *     - "CTA button feedcard" -> FeedCardType.BASIC, button_text=text
 *     - "General sponsored feedcard" -> FeedCardType.HIGHLIGHT
 *   - With ISI -> FeedCardType.HIGHLIGHT
 * CM/CME
 *   - Always FeedCardType.HIGHLIGHT
 * */

export const QUIZ_SERIES_FEEDCARD_TYPES = {
  GENERAL: {
    key: "GENERAL",
    displayName: "General sponsored feedcard",
    value: FEED_CARD_TYPES.HIGHLIGHT
  },
  CTA_BUTTON: {
    key: "CTA_BUTTON",
    displayName: "CTA button feedcard",
    value: FEED_CARD_TYPES.BASIC
  },
  REGULAR: {
    key: "REGULAR",
    displayName: "Regular expand/collapse view",
    value: FEED_CARD_TYPES.BASIC
  }
};

const getFeedcardTypeKey = (feedcardData) => {
  const feedcardType = get(feedcardData, "feed_card_type");
  const buttonText = get(feedcardData, "button_text");

  if (isEqual(feedcardType, FEED_CARD_TYPES.BASIC)) {
    return isNil(buttonText) || isEmpty(buttonText)
      ? QUIZ_SERIES_FEEDCARD_TYPES.REGULAR.key
      : QUIZ_SERIES_FEEDCARD_TYPES.CTA_BUTTON.key;
  }

  return QUIZ_SERIES_FEEDCARD_TYPES.GENERAL.key;
};

export const ISI_FEEDCARD_TYPES = {
  GENERAL: {
    displayName: "General sponsored feedcard",
    value: FEED_CARD_TYPES.HIGHLIGHT
  }
};

export const CASE_TYPES = {
  STATIC: "static",
  QUIZ: "quiz",
  QUIZ_SERIES: "quiz_series",
  CLINICAL_MOMENTS: "clinical_moments",
  CME: "cme",
  PROMOTION: "promo_card"
};

export const STATIC_MEDIA_TYPE = Object.freeze({
  IMAGE: "image",
  VIDEO: "video",
  NO_MEDIA: "none"
});

export const DEFAULT_QUIZ_ANSWER_SHAPE = {
  text: "",
  is_answer: false
};

export const DEFAULT_QUIZ_CME_ANSWER_SHAPE = {
  text: "",
  is_answer: false,
  is_free_form: false
};

export const getTacticState = (tacticState = "SC_DRAFT") => {
  return CAMPAIGN_TACTIC_STATES[tacticState];
};

export const getTacticTypeByKey = (type) => {
  return find(TACTIC_TYPES, (value) => isEqual(value.key, type));
};

export const getTacticFromCampaign = (campaign, caseUuid) => {
  return find(get(campaign, "cases"), (c) => isEqual(c.caseUuid, caseUuid));
};

export const getCampaignTacticType = (caseData) => {
  const caseType = get(caseData, "caseType");
  const contentItems = get(caseData, "contentItems");
  const content = head(contentItems) || caseData;
  const media = get(caseData, "media", []);
  const video = find(media, function(o) {
    return isEqual(o.type, STATIC_MEDIA_TYPE.VIDEO);
  });
  const imageCount = size(media);

  switch (caseType) {
    case CASE_TYPES.CLINICAL_MOMENTS:
      return TACTIC_TYPES.CLINICAL_MOMENT;
    case CASE_TYPES.CME:
      return TACTIC_TYPES.CLINICAL_MOMENT_CME;
    case CASE_TYPES.QUIZ_SERIES:
      if (hasAnySelectedQuizAnswer(contentItems)) {
        return TACTIC_TYPES.QUIZ_SERIES;
      } else {
        return TACTIC_TYPES.POLL_SERIES;
      }
    case CASE_TYPES.QUIZ:
      if (hasSelectedQuizAnswer(content)) {
        return TACTIC_TYPES.QUIZ;
      } else {
        return TACTIC_TYPES.POLL;
      }
    case CASE_TYPES.STATIC:
      if (video) {
        return TACTIC_TYPES.VIDEO;
      } else if (imageCount > 1) {
        return TACTIC_TYPES.IMAGE_SERIES;
      } else if (imageCount === 1) {
        return TACTIC_TYPES.IMAGE;
      } else {
        return TACTIC_TYPES.TEXT;
      }
    case CASE_TYPES.PROMOTION:
      return TACTIC_TYPES.PROMOTION;
    default:
      return TACTIC_TYPES.TEXT;
  }
};

export const hasSelectedQuizAnswer = (caseData) => {
  const questionOptions = get(caseData, "questionOptions");

  return findIndex(questionOptions, (o) => get(o, "isAnswer") === true) >= 0;
};

export const hasAnySelectedQuizAnswer = (contentItems) => {
  const filteredItems = filter(contentItems, (c) => hasSelectedQuizAnswer(c));

  return !isEmpty(filteredItems);
};

export const getCampaignPageConfig = (page) => {
  if (isEmpty(page)) {
    return CAMPAIGN_PAGE_CONFIG.DRAFT;
  }

  switch (page) {
    case "draft":
      return CAMPAIGN_PAGE_CONFIG.DRAFT;
    case "review":
      return CAMPAIGN_PAGE_CONFIG.REVIEW;
    case "active":
      return CAMPAIGN_PAGE_CONFIG.ACTIVE;
    case "archived":
      return CAMPAIGN_PAGE_CONFIG.ARCHIVED;
    case "manage":
      return CAMPAIGN_PAGE_CONFIG.NEW;
    default:
      return CAMPAIGN_PAGE_CONFIG.ACTIVE;
  }
};

export const getCampaignPageConfigFromTacticState = (tacticState) => {
  switch (toLower(tacticState)) {
    case "draft":
      return CAMPAIGN_PAGE_CONFIG.DRAFT;
    case "review":
      return CAMPAIGN_PAGE_CONFIG.REVIEW;
    case "approved":
      return CAMPAIGN_PAGE_CONFIG.ACTIVE;
    case "archived":
      return CAMPAIGN_PAGE_CONFIG.ARCHIVED;
    default:
      return CAMPAIGN_PAGE_CONFIG.DRAFT;
  }
};

export const getTacticFormData = (
  campaignUuid,
  caseData,
  activeSection,
  user
) => {
  const contentItem = head(get(caseData, "contentItems", [])) || {};
  return {
    campaignUuid: campaignUuid,
    caseUuid: defaultTo(get(caseData, "caseUuid"), ""),
    author_uid: defaultTo(get(caseData, "authorUid"), get(user, "userUid")),
    authorName: defaultTo(
      getDisplayName(head(get(caseData, "authors"))),
      getDisplayName(user)
    ),
    ...transformMainContent(contentItem),
    feed_card: transformFeedCardData(contentItem),
    sponsored_content: transformSponsoredContentData(contentItem),
    settings: transformTacticSettings(caseData),
    features: transformFeaturesData(contentItem),
    isi: transformIsiContentData(contentItem),
    mediaType: getMediaType(contentItem, activeSection),
    vimeoFileName: getMediaVideoFilename(contentItem)
  };
};

export const getTacticSeriesFormData = (
  campaignUuid,
  caseData,
  activeSection,
  user
) => {
  const contentItems = sortBy(
    get(caseData, "contentItems", []),
    "displayOrder"
  );
  const firstItem = head(contentItems) || {};
  const summaryItem =
    find(contentItems, (c) => isEqual(c.contentType, "quiz_summary")) ||
    firstItem;
  return {
    campaignUuid: campaignUuid,
    caseUuid: defaultTo(get(caseData, "caseUuid"), ""),
    author_uid: defaultTo(get(caseData, "authorUid"), get(user, "userUid")),
    authorName: defaultTo(
      getDisplayName(head(get(caseData, "authors"))),
      getDisplayName(user)
    ),
    settings: transformTacticSettings(caseData),
    heading: get(firstItem, "heading", ""),
    features: transformFeaturesData(firstItem, contentItems),
    sponsored_content: transformSponsoredContentData(firstItem),
    questions: transformQuestions(contentItems, activeSection),
    conclusion: transformConclusion(summaryItem),
    feed_card: transformFeedCardData(firstItem)
  };
};

export const getTacticClinicalMomentFormData = (
  campaignUuid,
  caseData,
  activeSection,
  user
) => {
  const contentItems = sortBy(
    get(caseData, "contentItems", []),
    "displayOrder"
  );
  const firstItem = head(contentItems) || {};
  return {
    campaignUuid: campaignUuid,
    caseUuid: defaultTo(get(caseData, "caseUuid"), ""),
    author_uid: defaultTo(get(caseData, "authorUid"), get(user, "userUid")),
    authorName: defaultTo(
      getDisplayName(head(get(caseData, "authors"))),
      getDisplayName(user)
    ),
    settings: transformTacticSettings(caseData),
    heading: get(firstItem, "heading", ""),
    features: transformFeaturesData(firstItem, contentItems),
    sponsored_content: transformSponsoredContentData(firstItem),
    slides: transformClinicalMomentSlides(contentItems, activeSection)
  };
};

export const getTacticClinicalMomentCMEFormData = (
  campaignUuid,
  caseData,
  activeSection,
  user
) => {
  const contentItems = sortBy(
    get(caseData, "contentItems", []),
    "displayOrder"
  );
  const firstItem = head(contentItems) || {};
  const cmeCenterCardItem = head(
    remove(contentItems, (c) => isEqual(c.contentType, "cme_hub_card"))
  );
  const cmeFeedCardItem = head(
    remove(contentItems, (c) => isEqual(c.contentType, "feed_card"))
  );
  const passingScore = parseInt(get(caseData, "passingScore", ""));
  return {
    campaignUuid: campaignUuid,
    caseUuid: defaultTo(get(caseData, "caseUuid"), ""),
    author_uid: defaultTo(get(caseData, "authorUid"), get(user, "userUid")),
    authorName: defaultTo(
      getDisplayName(head(get(caseData, "authors"))),
      getDisplayName(user)
    ),
    settings: transformTacticSettings(caseData),
    heading: get(firstItem, "heading", ""),
    features: transformFeaturesData(firstItem, contentItems),
    sponsored_content: transformSponsoredContentData(firstItem),
    slides: transformClinicalMomentCMESlides(
      contentItems,
      activeSection,
      caseData
    ),
    cme1_credits: defaultTo(get(caseData, "cme1Credits"), 0),
    cme_feed_card: {
      feed_activity_type: isNil(cmeFeedCardItem) ? "hidden" : "show",
      feed_card_title: get(cmeFeedCardItem, "feedCardTitle"),
      heading: get(cmeFeedCardItem, "heading"),
      feed_card_media: get(cmeFeedCardItem, "feedCardMedia"),
      feed_card_label: get(cmeFeedCardItem, "feedCardLabel"),
      colour: get(cmeFeedCardItem, "colour")
    },
    cme_center_card: {
      title: get(cmeCenterCardItem, "title"),
      heading: get(cmeCenterCardItem, "heading"),
      media: head(transformMedia(get(cmeCenterCardItem, "media")))
    },
    certificates: map(
      groupBy(defaultTo(get(caseData, "certificates"), []), (c) => c.path),
      (values, key) => {
        return {
          path: key,
          filename: get(head(values), "filename"),
          profession_tree_uuids: compact(map(values, (v) => v.professionUuid))
        };
      }
    ),
    passing_score: passingScore > 0 ? toString(passingScore) : ""
  };
};

const transformTacticSettings = (tacticSettings = {}) => {
  const tacticPriority = parseInt(
    get(tacticSettings, "campaignSettings.tacticPriority", "")
  );

  return {
    name: get(tacticSettings, "campaignSettings.tacticName", ""),
    start_date: parseDate(
      get(tacticSettings, "campaignSettings.startDate"),
      ""
    ),
    end_date: parseDate(get(tacticSettings, "campaignSettings.endDate"), ""),
    tactic_priority: tacticPriority > 0 ? toString(tacticPriority) : 1
  };
};

const transformFeaturesData = (contentItem, contentItems) => {
  return {
    comments_enabled: get(contentItem, "features.commentsEnabled", true),
    comment_queue_enabled: get(
      contentItem,
      "features.commentQueueEnabled",
      true
    ),
    save_enabled: get(contentItem, "features.saveEnabled", true),
    share_enabled: get(contentItem, "features.shareEnabled", true),
    reactions_enabled: get(contentItem, "features.reactionsEnabled", true),
    isi_enabled: hasAnyIsiData(contentItem, contentItems),
    job_code_enabled: hasJobCode(contentItem),
    dismiss_button: get(contentItem, "features.dismissButton", true),
    dismiss_on_click: get(contentItem, "features.dismissOnClick", true),
    show_in_web: get(contentItem, "features.showInWeb", true),
    show_in_mobile: get(contentItem, "features.showInMobile", true)
  };
};

const transformFeedCardData = (contentItem) => {
  const feedCard = {
    button_text: get(contentItem, "buttonText"),
    colour: get(contentItem, "colour"),
    feed_card_label: get(contentItem, "feedCardLabel"),
    feed_card_title: get(contentItem, "feedCardTitle"),
    feed_card_type: get(contentItem, "feedCardType", "basic"),
    heading: get(contentItem, "heading")
  };

  feedCard["feed_card_type_key"] = getFeedcardTypeKey(feedCard);

  const feedCardMedia = get(contentItem, "feedCardMedia");
  if (feedCardMedia) {
    feedCard["feed_card_media"] = feedCardMedia;
  }

  return feedCard;
};

const transformSponsoredContentData = (contentItem) => {
  return {
    disclosure_text: get(contentItem, "sponsoredContent.disclosureText", ""),
    job_code: get(contentItem, "sponsoredContent.jobCode", ""),
    sponsored_text: get(contentItem, "sponsoredContent.sponsoredText", "")
  };
};

const transformIsiContentData = (content = {}) => {
  return {
    isi_embedded_content_link: get(content, "isiEmbeddedContentLink", ""),
    isi_link: get(content, "isiLink", ""),
    isi_text: get(content, "isiText", ""),
    isi_enabled: hasIsiData(content)
  };
};

const transformConclusion = (conclusion = {}) => {
  return {
    caption: get(conclusion, "caption", ""),
    references: get(conclusion, "references", ""),
    external_link: transformExternalLinkData(conclusion),
    isi: transformIsiContentData(conclusion),
    media: transformMedia(get(conclusion, "media")),
    mediaType: getMediaType(conclusion),
    vimeoFileName: getMediaVideoFilename(conclusion)
  };
};

const transformExternalLinkData = (externalLink = {}) => {
  return {
    external_link_text: get(externalLink, "externalLinkText", ""),
    external_link_url: get(externalLink, "externalLinkUrl", "")
  };
};

const transformQuestions = (questions = [], activeSection) => {
  return compact(
    flatMap(questions, (question) => transformQuestion(question, activeSection))
  );
};

const transformQuestion = (question = {}, activeSection) => {
  const isQuiz = isEqual(
    get(activeSection, "key"),
    TACTIC_TYPES.QUIZ_SERIES.key
  );
  const contentType = get(question, "contentType", "");
  const isFeedCard = get(question, "isFeedCard", false);
  const feedcardQuestion = !isFeedCard
    ? null
    : {
        ...transformMainContent(question),
        isi: transformIsiContentData(question),
        slideType: QUIZ_SERIES_SLIDE_TYPES.FEEDCARD,
        displayOrder: get(question, "displayOrder", 10)
      };

  if (isEqual(contentType, "quiz_summary")) {
    const conclusion = transformConclusion(question);
    const hasConclusion =
      !isEmpty(conclusion.caption) ||
      !isEmpty(conclusion.external_link.external_link_text) ||
      !isEmpty(conclusion.external_link.external_link_url);
    const conclusionQuestion = !hasConclusion
      ? null
      : {
          slideType: isQuiz
            ? QUIZ_SERIES_SLIDE_TYPES.QUIZ_CONCLUSION
            : POLL_SERIES_SLIDE_TYPES.POLL_CONCLUSION,
          displayOrder: get(question, "displayOrder", 10)
        };

    return compact([feedcardQuestion, conclusionQuestion]);
  }

  const transformed = {
    ...transformMainContent(question),
    isi: transformIsiContentData(question),
    slideType: getSlideType(question, isQuiz),
    displayOrder: get(question, "displayOrder", 10),
    mediaType: getMediaType(question, activeSection),
    vimeoFileName: getMediaVideoFilename(question)
  };

  return compact([feedcardQuestion, transformed]);
};

const transformClinicalMomentSlides = (slides = [], activeSection) => {
  return compact(
    flatMap(slides, (slide, index) => transformSlide(slide, activeSection))
  );
};

const transformSlide = (question = {}, activeSection) => {
  const slideType = getClinicalMomentSlideType(question);

  if (isEqual(slideType, CLINICAL_MOMENTS_SLIDE_TYPES.FEEDCARD)) {
    return {
      ...transformFeedCardData(question),
      slideType
    };
  }

  const transformed = {
    ...transformMainContent(question),
    feed_card_label: get(question, "feedCardLabel"),
    isi: transformIsiContentData(question),
    slideType: getClinicalMomentSlideType(question),
    displayOrder: get(question, "displayOrder", 10),
    mediaType: getMediaType(question, activeSection),
    vimeoFileName: getMediaVideoFilename(question)
  };

  return transformed;
};

const transformClinicalMomentCMESlides = (
  slides = [],
  activeSection,
  caseData
) => {
  const previousSlides = {};
  const convertedSlides = compact(
    flatMap(slides, (slide, index) => {
      const transformed = transformCMESection(slide, activeSection);
      if (
        includes(CLINICAL_MOMENTS_CME_QUESTION_SLIDE_TYPES, transformed.section)
      ) {
        if (!previousSlides[transformed.section]) {
          const questionSlide = {
            section: transformed.section,
            questions: [transformed]
          };
          previousSlides[transformed.section] = questionSlide;

          return questionSlide;
        } else {
          previousSlides[transformed.section].questions.push(transformed);

          return null;
        }
      }

      if (isEqual("ACTIVITY", transformed.section)) {
        let returnSection = false;
        if (!previousSlides[transformed.section]) {
          const questionSlide = {
            section: transformed.section,
            slides: []
          };
          previousSlides[transformed.section] = questionSlide;
          returnSection = true;
        }

        const activitySection = previousSlides[transformed.section];
        transformed.slideType = getClinicalMomentSlideType(slide);
        if (
          isEqual(
            transformed.slideType,
            CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE
          )
        ) {
          activitySection.titleSlide = {
            ...transformed,
            feed_card_media: get(slide, "feedCardMedia"),
            feed_card_label: get(slide, "feedCardLabel")
          };
        } else {
          activitySection.slides.push(transformed);
        }

        return returnSection ? activitySection : null;
      }

      return transformed;
    })
  );

  const certificates = get(caseData, "certificates");
  if (!isEmpty(certificates)) {
    convertedSlides.push({
      section: toUpper(CLINICAL_MOMENTS_CME_SLIDE_TYPES.CERTIFICATES)
    });
  }

  return convertedSlides;
};

const transformCMESection = (question = {}, activeSection) => {
  const transformed = {
    ...transformMainContent(question),
    isi: transformIsiContentData(question),
    section: toUpper(get(question, "section")),
    displayOrder: get(question, "displayOrder", 10),
    mediaType: getMediaType(question, activeSection),
    vimeoFileName: getMediaVideoFilename(question)
  };

  return transformed;
};

const transformMainContent = (content = {}) => {
  return {
    title: get(content, "title", ""),
    caption: get(content, "caption", ""),
    references: get(content, "references", ""),
    button_text:
      get(content, "buttonText", "") || get(content, "button_text", ""),
    heading: get(content, "heading", ""),
    media: transformMedia(get(content, "media")),
    external_link: transformExternalLinkData(content),
    question_answer_details: get(content, "questionAnswerDetails", ""),
    question_options: transformQuestionOptions(get(content, "questionOptions")),
    button_url: get(content, "buttonUrl", "")
  };
};

const transformQuestionOptions = (questionOptions = []) => {
  return map(sortBy(questionOptions, ["displayOrder"]), (option) => {
    return {
      text: get(option, "text", ""),
      is_answer: get(option, "isAnswer", false),
      is_free_form: get(option, "isFreeForm", false)
    };
  });
};

const getSlideType = (content, isQuiz) => {
  if (!isEmpty(get(content, "title", ""))) {
    return isQuiz ? QUIZ_SERIES_SLIDE_TYPES.QUIZ : POLL_SERIES_SLIDE_TYPES.POLL;
  }

  return QUIZ_SERIES_SLIDE_TYPES.FEEDCARD;
};

const getClinicalMomentSlideType = (content) => {
  const contentType = toUpper(get(content, "contentType"));

  switch (contentType) {
    case "FEED_CARD":
      return CLINICAL_MOMENTS_SLIDE_TYPES.FEEDCARD;
    case "COVER":
      return CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE;
    case "CONCLUSION":
      return CLINICAL_MOMENTS_SLIDE_TYPES.CONCLUSION;
    case "CONTENT":
      return CLINICAL_MOMENTS_SLIDE_TYPES.BASIC;
    case "QUIZ":
      if (hasSelectedQuizAnswer(content)) {
        return CLINICAL_MOMENTS_SLIDE_TYPES.QUIZ;
      } else {
        return CLINICAL_MOMENTS_SLIDE_TYPES.POLL;
      }
    default:
      return CLINICAL_MOMENTS_SLIDE_TYPES.FEEDCARD;
  }
};

export const getClinicalMomentContentType = (slideType) => {
  switch (slideType) {
    case CLINICAL_MOMENTS_SLIDE_TYPES.FEEDCARD:
      return "FEED_CARD";
    case CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE:
      return "COVER";
    case "FRONT_MATTER":
    case CLINICAL_MOMENTS_SLIDE_TYPES.BASIC:
      return "CONTENT";
    case CLINICAL_MOMENTS_SLIDE_TYPES.CONCLUSION:
      return "CONCLUSION";
    case "PRE_TEST":
    case "POST_TEST":
    case "SURVEY":
    case CLINICAL_MOMENTS_SLIDE_TYPES.QUIZ:
    case CLINICAL_MOMENTS_SLIDE_TYPES.POLL:
      return "QUIZ";
    default:
      return null;
  }
};

const transformMedia = (media = []) => {
  return map(media, (m) => {
    return {
      width: get(m, "width", ""),
      height: get(m, "height", ""),
      url: get(m, "url", ""),
      type: get(m, "type", ""),
      mediaUuid: get(m, "mediaUuid", ""),
      filename: get(m, "filename", "")
    };
  });
};

const hasJobCode = (contentItem) => {
  return !isEmpty(get(contentItem, "sponsoredContent.jobCode", ""));
};

const hasAnyIsiData = (contentItem, contentItems) => {
  if (!isEmpty(contentItems)) {
    const filtered = filter(contentItems, (c) => hasAnyIsiData(c));

    return !isEmpty(filtered);
  }

  return hasIsiData(contentItem);
};

const hasIsiData = (contentItem) => {
  return (
    !isEmpty(get(contentItem, "isiEmbeddedContentLink", "")) ||
    !isEmpty(get(contentItem, "isiLink", "")) ||
    !isEmpty(get(contentItem, "isiText", ""))
  );
};

const getMediaType = (caseData, activeSection) => {
  const media = defaultTo(compact(get(caseData, "media")), []);
  const isNew = isEmpty(get(caseData, "caseUuid"));

  if (!isEmpty(media)) {
    const video = getMediaVideoFilename(caseData);
    return video ? STATIC_MEDIA_TYPE.VIDEO : STATIC_MEDIA_TYPE.IMAGE;
  } else if (!isNew) {
    return STATIC_MEDIA_TYPE.NO_MEDIA;
  }

  if (activeSection) {
    if (isEqual(activeSection.key, TACTIC_TYPES.TEXT.key)) {
      return STATIC_MEDIA_TYPE.NO_MEDIA;
    }

    if (isEqual(activeSection.key, TACTIC_TYPES.VIDEO.key)) {
      return STATIC_MEDIA_TYPE.VIDEO;
    }

    return STATIC_MEDIA_TYPE.IMAGE;
  }

  return STATIC_MEDIA_TYPE.NO_MEDIA;
};

const getMediaVideoFilename = (caseData) => {
  const media = defaultTo(compact(get(caseData, "media")), []);
  const video = find(media, function(o) {
    return isEqual(o.type, STATIC_MEDIA_TYPE.VIDEO);
  });

  return video ? video.filename : null;
};

export const isArchiveActive = (campaign) => {
  const campaignTactics = get(campaign, "cases");
  const activeTactic = find(campaignTactics, (c) =>
    isEqual(getTacticState(c.caseState), CAMPAIGN_TACTIC_STATES.SC_APPROVED)
  );
  return !isNil(activeTactic);
};

export const isReactivateActive = (pageConfig) => {
  return isEqual(pageConfig.pageKey, CAMPAIGN_PAGE_CONFIG.ARCHIVED.pageKey);
};

export const isCampaignEditActive = (campaign) => {
  // Cases data is from the campaign index.  We want to use that
  // if available to ensure it's the latest info
  const cases = get(campaign, "cases");
  const casesData = get(campaign, "casesData");
  const campaignTactics = casesData ? casesData : cases;
  const activeTactic = find(campaignTactics, (c) =>
    isEqual(getTacticState(c.caseState), CAMPAIGN_TACTIC_STATES.SC_APPROVED)
  );

  return isNil(activeTactic);
};

export const isIsiActive = (formData) => {
  return getFieldValue(formData, "features.isi_enabled", "", false);
};

export const isJobCodeActive = (formData) => {
  return getFieldValue(formData, "features.job_code_enabled", "", false);
};

export const handleSwitchChange = (
  onChange,
  fieldName,
  fieldValue,
  formData
) => {
  onChange({
    target: {
      name: fieldName,
      value: fieldValue
    }
  });

  if (isEqual("features.job_code_enabled", fieldName) && !fieldValue) {
    onChange({
      target: {
        name: getFieldName("sponsored_content.job_code"),
        value: ""
      }
    });
  }

  if (isEqual("features.isi_enabled", fieldName)) {
    if (!fieldValue) {
      if (has(formData, "questions")) {
        const questions = get(formData, "questions");
        map(questions, (q, index) => {
          if (has(q, "isi")) {
            changeIsiFields(onChange, `questions[${index}]`);
          }
        });
      } else {
        changeIsiFields(onChange);
      }

      onChange({
        target: {
          name: getFieldName("feed_card.feed_card_type"),
          value: FEED_CARD_TYPES.BASIC
        }
      });
    } else {
      onChange({
        target: {
          name: getFieldName("feed_card.feed_card_type"),
          value: FEED_CARD_TYPES.HIGHLIGHT
        }
      });
    }
  }
};

export const changeIsiFields = (onChange, prefix = "") => {
  onChange({
    target: {
      name: getFieldName("isi.isi_embedded_content_link", prefix),
      value: ""
    }
  });
  onChange({
    target: {
      name: getFieldName("isi.isi_link", prefix),
      value: ""
    }
  });
  onChange({
    target: {
      name: getFieldName("isi.isi_text", prefix),
      value: ""
    }
  });
};
