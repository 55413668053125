import { get } from "lodash";
import {
  USER_LISTEN,
  USER_LOAD,
  USER_LOADED,
  USER_ROLES_LISTEN,
  USER_ROLES_LOADED,
  USER_SIGN_OUT
} from "../actions/user.actions";

const INITIAL_STATE = {
  uid: null,
  userRoles: null,
  user: null,
  loading: false,
  saving: false,
  isError: false,
  userListener: null,
  userRolesListener: null
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_LOAD: {
      return {
        ...state,
        loading: true,
        user: null
      };
    }
    case USER_LOADED: {
      return {
        ...state,
        loading: false,
        user: action.user
      };
    }
    case USER_ROLES_LOADED: {
      return {
        ...state,
        userRoles: action.userRoles,
        uid: get(action.userRoles, "uid")
      };
    }
    case USER_SIGN_OUT: {
      if (typeof state.userListener === "function") {
        state.userListener();
      }

      if (typeof state.userRolesListener === "function") {
        state.userRolesListener();
      }

      return { ...INITIAL_STATE };
    }
    case USER_LISTEN: {
      if (typeof state.userListener === "function") {
        state.userListener();
      }

      return {
        ...state,
        userListener: action.userListener
      };
    }
    case USER_ROLES_LISTEN: {
      if (typeof state.userRolesListener === "function") {
        state.userRolesListener();
      }

      return {
        ...state,
        userRolesListener: action.userRolesListener
      };
    }
    default:
      return state;
  }
}

export default userReducer;
