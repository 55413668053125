/** @format */

import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

const SearchSuggestion = ({ text, onPress }) => {
  return (
    <ListGroupItem action onClick={onPress} className="cursor-pointer">
      <span className="text-light-gray">
        Search in{" "}
        <span className="font-weight-bold text-dark-gray">{text}</span>
      </span>
    </ListGroupItem>
  );
};

const SearchSuggestionContainer = ({ data }) => {
  return (
    <ListGroup className="search-suggestion-container text-12">
      {data.map((item, index) => (
        <SearchSuggestion
          key={`search-suggestion-${item.text}-${index}`}
          text={item.text}
          onPress={() => {
            item.onPress();
          }}
        />
      ))}
    </ListGroup>
  );
};

export default SearchSuggestionContainer;
