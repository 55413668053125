import { get, isEmpty, join, map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { convertSpecialities } from "../../../utils/tagging-utils";
import { Alert } from "reactstrap";
import { useSelector } from "react-redux";

const CasePagingBanner = (props) => {
  const { activeCase } = props;

  const taggingAllSpecialties = useSelector(
    (state) => state.reference.taggingAllSpecialties
  );

  const paging = get(activeCase, "isPagingCase", false);
  if (paging) {
    const specialtyUuids = get(activeCase, "specialtyUuids", []);
    const specialties = convertSpecialities(
      specialtyUuids,
      taggingAllSpecialties
    );
    if (isEmpty(specialties)) {
      return null;
    }

    return (
      <Alert color="light-blue">
        Paging -{" "}
        {join(
          map(specialties, (s) => s.name),
          ", "
        )}
      </Alert>
    );
  }

  return null;
};

CasePagingBanner.propTypes = {
  activeCase: PropTypes.object
};

export default CasePagingBanner;
