import React, { Component } from "react";

import "./style.scss";
import LoaderLg from "../assets/loader_lg.svg";
import LoaderSm from "../assets/loader_sm.svg";

class Loading extends Component {
  render() {
    return (
      <div className="loading-backdrop">
        <img src={LoaderLg} alt="loading..." className="loading-loader-lg" />
        <img src={LoaderSm} alt="loading..." className="loading-loader-sm" />
      </div>
    );
  }
}

export default Loading;
