import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import ModalBody from "reactstrap/es/ModalBody";
import { get, isNil, join, map } from "lodash";

const CampaignInfoModal = (props) => {
  const { isOpen, toggle, campaign } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>Campaign Info</ModalHeader>
      <ModalBody>
        <div>
          {map(
            [
              { key: "campaignUuid", label: "campaign uuid" },
              { key: "name", label: "campaign name" },
              { key: "updatedAt", label: "last updated" },
              { key: "cases", label: "tactics" }
            ],
            (v) => {
              if (v.key === "cases") {
                const values = get(campaign, v.key);
                return (
                  <div key={`meta-${v.key}`} className="mb-2">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="text-11 font-weight-bold text-uppercase pl-0">
                            Tactics
                          </th>
                          <th
                            scope="col"
                            className="text-11 font-weight-bold text-uppercase">
                            Case UUID
                          </th>
                          <th
                            scope="col"
                            className="text-11 font-weight-bold text-uppercase">
                            Content UUID(s)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(values, (c) => {
                          const caseUuid = get(c, "caseUuid");
                          const contentUuids = get(c, "contentUuids");
                          return (
                            <tr key={`case-${caseUuid}`}>
                              <th
                                scope="row"
                                className="text-secondary text-13 pl-0">
                                {isNil(c.name) ? "N/A" : c.name}
                              </th>
                              <td className="text-secondary text-13">
                                {caseUuid}
                              </td>
                              <td className="text-secondary text-13">
                                {join(contentUuids, ", ")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                );
              }

              const value = get(campaign, v.key);
              const displayValue = value;
              return (
                <div key={`meta-${v.key}`} className="mb-2">
                  <div className="text-11 font-weight-bold text-uppercase">
                    {v.label}
                  </div>
                  <div className="text-secondary text-13">{displayValue}</div>
                </div>
              );
            }
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

CampaignInfoModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  campaign: PropTypes.object
};

export default CampaignInfoModal;
