import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { CAMPAIGN_SECONDARY_NAVIGATION } from "../../constants/route-constants";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import { useDispatch, useSelector } from "react-redux";
import { getAllProfessionAndSpecialties } from "../../actions/reference.actions";
import "./components/style.scss";
import ProfileLeftNavigation from "./components/ProfileLeftNavigation";
import { CAMPAIGN_PAGE_CONFIG } from "../../utils/campaign-utils";
import { ProfileMainForm } from "./components/ProfileMainForm";
import { findUser, setActiveUser } from "../../actions/profile.actions";
import Loading from "../../components/Loading";

const CampaignProfileManage = (props) => {
  const { userUid } = useParams();
  const { existing = false } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const processing = useSelector((state) => state.profile.processing);

  useEffect(() => {
    dispatch(getAllProfessionAndSpecialties());
  }, [dispatch]);

  useEffect(() => {
    if (userUid) {
      dispatch(findUser(userUid));
    } else {
      dispatch(setActiveUser(null));
    }
  }, [userUid, location, dispatch]);

  useEffect(() => {
    if (!existing) {
      dispatch(setActiveUser(null));
    }
  }, [existing, dispatch]);

  const renderBody = () => {
    return (
      <Row className="mx-0 mb-4">
        <Col className="px-4 pt-5 bg-white min-vh-100" xl="3">
          <ProfileLeftNavigation existing={existing} />
        </Col>
        <Col lx="9" className="mt-5 ml-4">
          <ProfileMainForm existing={existing} userUid={userUid} />
        </Col>
      </Row>
    );
  };

  if (processing) {
    return <Loading />;
  }

  return (
    <div className="campaign-page">
      <SecondaryNavigation
        links={CAMPAIGN_SECONDARY_NAVIGATION}
        active={CAMPAIGN_PAGE_CONFIG.PROFILE.pageKey}
        centerAlign={false}
      />
      <Container>{renderBody()}</Container>
    </div>
  );
};

export default CampaignProfileManage;
