import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEqual, startsWith } from "lodash";
import "./style.scss";
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem
} from "reactstrap";
import {
  ADMIN_MODULE_NAME,
  CAMPAIGN_MODULE_NAME,
  CASE_EXPLORER_MODULE_NAME,
  HOME_MODULE_NAME,
  MODERATION_MODULE_NAME,
  TAGGING_MODULE_NAME,
  USER_SEARCH_MODULE_NAME,
  VERIFICATION_MODULE_NAME
} from "../../constants/route-constants";
import {
  ADMIN_ROOT,
  CAMPAIGN_ROOT,
  CASE_EXPLORER_ROOT,
  HOME_ROOT,
  MODERATION_ROOT,
  SIGN_OUT,
  TAGGING_LANDING,
  TOOLS_ROOT,
  VERIFICATION_ROOT
} from "../../constants/routes";
import { useSelector } from "react-redux";
import {
  isAdmin,
  isCampaignViewable,
  isCaseExplorerViewable,
  isModerationViewable,
  isUserSearchViewable,
  isVerifier
} from "../../utils/permission-utils";
import { Link, useLocation } from "react-router-dom";
import ResponsiveMessageModal from "../modals/ResponsiveMessageModal";
import useWindowDimensions from "./UseWindowsDimensions";

const MOBILE_DEVICE_WIDTH = 767.98;

const GlobalHeader = (props) => {
  const { active } = props;

  const { width } = useWindowDimensions();
  const initialized = useSelector((state) => state.auth.initialized);
  const userRoles = useSelector((state) => state.user.userRoles);

  const [collapsed, setCollapsed] = useState(false);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const [isOpen, setIsOpen] = useState(false);
  const toggleNavItem = () => setIsOpen(!isOpen);

  const [messageModal, setMessageModal] = useState(false);
  const toggleMessageModal = useCallback(() => setMessageModal(!messageModal), [
    messageModal
  ]);

  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (
      width < MOBILE_DEVICE_WIDTH &&
      (startsWith(pathname, "/admin") ||
        startsWith(pathname, "/case-explorer") ||
        startsWith(pathname, "/campaigns") ||
        startsWith(pathname, "/verification") ||
        startsWith(pathname, "/tools"))
    ) {
      toggleMessageModal();
    }
  }, [pathname, width, toggleMessageModal]);

  return (
    <Container fluid={true} className="bg-white containerCollapsed">
      <ResponsiveMessageModal
        toggle={toggleMessageModal}
        isOpen={messageModal}
      />
      <Container className="containerCollapsed">
        <Navbar className="primary-nav" color="white" light expand="md">
          <NavbarToggler
            onClick={toggleNavbar}
            data-toggle="collapse"
            data-target="#navbarCollapsed #containerCollapsed"
          />
          <Link to={HOME_ROOT} className="navbar-brand">
            Figure 1
          </Link>

          <Collapse isOpen={collapsed} navbar>
            <Nav id="navbarCollapsed" className="ml-auto" navbar>
              {!initialized && (
                <div
                  className=".d-none .d-lg-block .d-xl-none"
                  style={{ minHeight: "96px" }}>
                  &nbsp;
                </div>
              )}
              {initialized && (
                <>
                  <NavItem>
                    <Link
                      to={HOME_ROOT}
                      className={
                        isEqual(active, HOME_MODULE_NAME)
                          ? "nav-link active"
                          : "nav-link"
                      }>
                      <div className="icon-home" />
                      <span>Home</span>
                    </Link>
                  </NavItem>
                  {isCampaignViewable(userRoles) && (
                    <NavItem>
                      <Link
                        to={CAMPAIGN_ROOT}
                        className={
                          isEqual(active, CAMPAIGN_MODULE_NAME)
                            ? "nav-link active"
                            : "nav-link"
                        }>
                        <div className="icon-campaigns" />
                        <span>Campaigns</span>
                      </Link>
                    </NavItem>
                  )}
                  {isUserSearchViewable(userRoles) && (
                    <NavItem>
                      <Link
                        to={TOOLS_ROOT}
                        className={
                          isEqual(active, USER_SEARCH_MODULE_NAME)
                            ? "nav-link active"
                            : "nav-link"
                        }>
                        <div className="icon-tools" />
                        <span>User Search</span>
                      </Link>
                    </NavItem>
                  )}
                  {isCaseExplorerViewable(userRoles) && (
                    <NavItem>
                      <Link
                        to={CASE_EXPLORER_ROOT}
                        className={
                          isEqual(active, CASE_EXPLORER_MODULE_NAME)
                            ? "nav-link active"
                            : "nav-link"
                        }>
                        <div className="icon-case-explorer" />
                        <span>Case Explorer</span>
                      </Link>
                    </NavItem>
                  )}
                  {isVerifier(userRoles) && (
                    <NavItem>
                      <Link
                        to={VERIFICATION_ROOT}
                        className={
                          isEqual(active, VERIFICATION_MODULE_NAME)
                            ? "nav-link active"
                            : "nav-link"
                        }>
                        <div className="icon-verification" />
                        <span>Verification</span>
                      </Link>
                    </NavItem>
                  )}
                  {isModerationViewable(userRoles) && (
                    <>
                      <NavItem className="d-none d-xs-none d-sm-none d-md-block d-lg-block">
                        <Link
                          onClick={toggleNavItem}
                          to={MODERATION_ROOT}
                          className={
                            isEqual(active, MODERATION_MODULE_NAME)
                              ? "nav-link moderation active"
                              : "nav-link moderation"
                          }>
                          <div className="icon-moderation" />
                          <span>Moderation & Tagging</span>
                        </Link>
                      </NavItem>
                      <div className="d-xs-block d-sm-none d-md-none">
                        <NavItem>
                          <Link
                            onClick={toggleNavItem}
                            to={MODERATION_ROOT}
                            className={
                              isEqual(active, MODERATION_MODULE_NAME)
                                ? "nav-link moderation active"
                                : "nav-link moderation"
                            }>
                            <div className="icon-moderation" />
                            <span>Moderation</span>
                          </Link>
                          {/*
                          <Collapse isOpen={isOpen}>
                            <SecondaryNavigation
                              links={MODERATION_SECONDARY_NAVIGATION}
                              active={MODERATION_MODULE_NAME}
                              mobile={true}
                            />
                          </Collapse>*/}
                        </NavItem>
                        <NavItem>
                          <Link
                            onClick={toggleNavItem}
                            to={TAGGING_LANDING}
                            className={
                              isEqual(active, TAGGING_MODULE_NAME)
                                ? "nav-link moderation active"
                                : "nav-link moderation"
                            }>
                            <div className="icon-moderation" />
                            <span>Tagging</span>
                          </Link>
                          {/*
                          <Collapse isOpen={isOpen}>
                            <SecondaryNavigation
                              links={MODERATION_SECONDARY_NAVIGATION}
                              active={MODERATION_MODULE_NAME}
                              mobile={true}
                            />
                          </Collapse>*/}
                        </NavItem>
                      </div>
                    </>
                  )}
                  {isAdmin(userRoles) && (
                    <NavItem>
                      <Link
                        to={ADMIN_ROOT}
                        className={
                          isEqual(active, ADMIN_MODULE_NAME)
                            ? "nav-link active"
                            : "nav-link"
                        }>
                        <div className="icon-admin" />
                        <span>Admin</span>
                      </Link>
                    </NavItem>
                  )}
                  <NavItem>
                    <Link to={SIGN_OUT} className={"nav-link"}>
                      <div className="icon-logout" style={{ fontSize: 10 }} />
                      <span>Sign out</span>
                    </Link>
                  </NavItem>
                </>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </Container>
  );
};

GlobalHeader.propTypes = {
  active: PropTypes.string
};

export default GlobalHeader;
