import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PropTypes from "prop-types";
import "./common.scss";

const RowToggler = (props) => {
  const {
    toggle,
    collapse,
    large,
    closedIcon = faAngleUp,
    openedIcon = faAngleDown,
    color = "primary-dark",
    iconColor = "white",
    disabled = false
  } = props;
  return (
    <Button
      onClick={disabled ? () => {} : toggle}
      disabled={disabled}
      type="button"
      className={`row-toggler rounded-circle ${large && "lg"}`}
      color={color}>
      <FontAwesomeIcon
        icon={collapse ? closedIcon : openedIcon}
        color={iconColor}
      />
    </Button>
  );
};

RowToggler.propTypes = {
  toggle: PropTypes.func,
  collapse: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RowToggler;
