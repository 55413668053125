export const PROFILE_TYPE = Object.freeze({
  USER: "Individual",
  FIGURE1_INSTITUTIONAL: "Institutional"
});

export const USER_TYPE_MAPPING = {
  Figure1Member: "USER",
  Figure100: "FIGURE1_100",
  Figure1Friends: "FIGURE1_FRIENDS",
  Figure1Internal: "FIGURE1_INTERNAL",
  Figure1Official: "FIGURE1_OFFICIAL",
  Figure1PaidContributor: "FIGURE1_PAID_CONTRIBUTOR",
  Figure1EditorialPartner: "FIGURE1_EDITORIAL_PARTNER",
  Figure1EditorialSubscriber: "FIGURE1_EDITORIAL_SUBSCRIBER",
  SponsoredUser: "FIGURE1_SPONSORED",
  InstitutionalUser: "FIGURE1_INSTITUTIONAL"
};

export const USER_TYPE_MAPPING_DISPLAY = {
  [USER_TYPE_MAPPING.Figure1Member]: "Figure 1 Member (default)",
  [USER_TYPE_MAPPING.Figure100]: "Figure 100 (Partner Account)",
  [USER_TYPE_MAPPING.Figure1Friends]: "Figure Friends (Partner Account)",
  [USER_TYPE_MAPPING.Figure1Internal]: "Figure 1 Internal (Undiscoverable)",
  [USER_TYPE_MAPPING.Figure1Official]: "Figure 1 Official",
  [USER_TYPE_MAPPING.Figure1PaidContributor]: "Figure 1 Paid Contributor",
  [USER_TYPE_MAPPING.Figure1EditorialPartner]: "Figure 1 Editorial Partner",
  [USER_TYPE_MAPPING.Figure1EditorialSubscriber]:
    "Figure 1 Editorial Subscriber",
  [USER_TYPE_MAPPING.SponsoredUser]: "Sponsored Account",
  [USER_TYPE_MAPPING.InstitutionalUser]: "Institutional Account"
};
