import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const loadElasticConfiguration = async (callback) => {
  getCollectionRef(DB_CONSTANTS.CONFIGURATION)
    .doc(DB_CONSTANTS.ELASTIC_CONFIG)
    .onSnapshot(
      (snapshot) => {
        callback(snapshot.data());
      },
      (error) => {
        console.log("Load elastic search error", error);
      }
    );
};
