import {
  isNil,
  isNumber,
  isEmpty,
  toLower,
  isEqual,
  size,
  isString
} from "lodash";
import moment from "moment";

export const formatNumber = (value) => {
  if (isNumber(value)) {
    return value.toLocaleString();
  }

  if (isString(value)) {
    const n = parseInt(value);
    if (!isNaN(n)) {
      return n.toLocaleString();
    }
  }

  return value;
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
};

export const arrayMove = (array, from, to) => {
  array = [...array];
  arrayMoveMutate(array, from, to);
  return array;
};

export const parseDate = (value, defaultValue = "") => {
  if (isNil(value) || isEmpty(value)) {
    return defaultValue;
  }
  return moment(value)
    .utc()
    .format("YYYY-MM-DD");
};

export const renderLanguage = (value) => {
  const language = toLower(value);
  if (isEqual("en", language)) {
    return "English";
  }

  return value;
};

export const isValidUuid = (value) => {
  return size(value) >= 32 && size(value) <= 40;
};

export const isRequestSuccess = (response) => {
  if (!response) {
    return false;
  }
  return response?.status >= 200 && response?.status < 300;
};