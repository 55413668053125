import { isEqual, get, includes } from "lodash";

export const COMMENT_STATES = Object.freeze({
  APPROVED: "approved",
  FLAGGED: "flagged",
  PENDING_APPROVAL_FLAGGED: "pending_approval_flagged",
  REPORTED: "reported",
  REJECTED: "rejected"
});

export const isApprovalActive = (comment) => {
  return !isEqual(get(comment, "state"), COMMENT_STATES.APPROVED);
};

export const isFlaggedActive = (comment) => {
  return !includes(
    [COMMENT_STATES.FLAGGED, COMMENT_STATES.PENDING_APPROVAL_FLAGGED],
    get(comment, "state")
  );
};

export const isRejectedActive = (comment) => {
  return (
    !isEqual(get(comment, "state"), COMMENT_STATES.REJECTED) &&
    !isEqual(get(comment, "state"), COMMENT_STATES.APPROVED)
  );
};
