import Firebase from "../firebase";
import * as firebase from "firebase";

const firestore = Firebase.firestore;

export const getCollectionRef = (collectionName) => {
  return firestore.collection(collectionName);
};

export const user = (uid) => firestore.collection("usersDB").doc(uid);
export const users = () => firestore.collection("usersDB");

export const getNewTimestamp = () =>
  firebase.firestore.FieldValue.serverTimestamp();

export const caseInfo = (uid) => firestore.collection("casesDB").doc(uid);
export const caseInfos = () => firestore.collection("casesDB");

export const caseTerm = (uid) => firestore.collection("meshTermsDB").doc(uid);
export const caseTerms = () => firestore.collection("meshTermsDB");

export const publication = (uid) =>
  firestore.collection("publicationsDB").doc(uid);
export const publications = () => firestore.collection("publicationsDB");

export const cases = () =>
  firestore
    .collection("casesDB")
    .limit(100)
    .get();

export const reviewedTerm = (uid) =>
  firestore.collection("reviewedTermsDB").doc(uid);
export const reviewedTerms = () => firestore.collection("reviewedTermsDB");
