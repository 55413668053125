import { compact, get, isEmpty, map, startsWith } from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// see https://docs.imgix.com/apis/rendering/size/fit for more fit options
export const getImageUrl = (media, width = 500, height = 500, fit = "max") => {
  const isImage = get(media, "type") === "image";
  const url = get(media, "url");
  if (!isImage || isEmpty(url)) {
    return null;
  }
  // case of base64 encoded images
  if (url.substring(0, 4) === "data") {
    return url;
  }
  if (process.env.NODE_ENV !== "production") {
    return `${url}?w=${width}&h=${height}&fit=${fit}&blur=0`;
  }

  return `${url}?w=${width}&h=${height}&fit=${fit}`;
};

export const getAvatarUrl = (imageUrl) => {
  return `${imageUrl}?mask=corners&w=40&h=40`;
};

export const convertUploadMedia = (media) => {
  return {
    filename: get(media, "filename"),
    type: get(media, "type"),
    height: get(media, "height"),
    width: get(media, "width"),
    url: get(media, "url")
  };
};

export const startImageDownload = (image, setDownloading) => {
  setDownloading(true);
  const url = get(image, "url");
  const filename = get(image, "filename");
  const element = document.createElement("a");
  fetch(new Request(url))
    .then((response) => response.blob())
    .then(function(myBlob) {
      element.href = URL.createObjectURL(myBlob);
      element.download = filename;
      element.click();
      setDownloading(false);
    });
};

export const startMediaDownloadZip = async (media, setDownloading) => {
  setDownloading(true);
  const zip = new JSZip();
  const images = await Promise.all(
    compact(
      map(media, (m) => {
        const url = get(m, "url");
        const filename = get(m, "filename");

        if (isEmpty(url)) {
          return null;
        }

        return fetch(new Request(url)).then(async (response) => {
          return { name: filename, data: await response.blob() };
        });
      })
    )
  );

  map(images, (i) => {
    zip.file(i.name, i.data);
  });

  zip.generateAsync({ type: "blob" }).then(function(content) {
    saveAs(content, "caseImages.zip");
    setDownloading(false);
  });
};

export const getOriginalUrl = (media) => {
  if (
    !media ||
    !media.originalFilename ||
    !media.filename ||
    !media.url ||
    !startsWith(media.url, "https")
  ) {
    return null;
  }

  const baseUrl = media.url.substring(0, media.url.lastIndexOf("/"));

  return `${baseUrl}/${media.filename}`;
};
