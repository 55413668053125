/** @format */

export const logger = (store) => (next) => (action) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("dispatching", action);
    console.log("next state", store.getState());
  }

  return next(action);
};
