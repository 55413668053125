import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroupAddon,
  InputGroup,
  Alert
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import LoadingButton from "../../../components/common/LoadingButton";
import { resetUserPassword } from "../../../actions/tools.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const ResetPasswordModal = (props) => {
  const { isOpen, toggle, user } = props;

  const dispatch = useDispatch();
  const inputRef = useRef();
  const [resetUrl, setResetUrl] = useState("");
  const email = get(user, "email");
  const username = get(user, "username");
  const fetching = useSelector((state) => state.tools.userResetting);
  const userResetError = useSelector((state) => state.tools.userResetError);

  const onResetPassword = async () => {
    const result = await dispatch(resetUserPassword(email));
    if (result && result.resetLink) {
      setResetUrl(result.resetLink);
    }
  };

  const onCopy = () => {
    const el = inputRef.current;
    el.select();
    document.execCommand("copy");
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>Reset user password</ModalHeader>
      <Form>
        <ModalBody>
          {userResetError && (
            <div className="alert alert-danger" role="alert">
              An error occurred. Please try again.
            </div>
          )}
          <p className="mb-1">Generate a reset password link for {username}</p>
          <div className="mb-2">
            <LoadingButton
              loading={fetching}
              disabled={fetching}
              color="primary-dark"
              size="sm"
              onClick={onResetPassword}>
              Generate link
            </LoadingButton>
          </div>
          {resetUrl && (
            <>
              <FormGroup>
                <Label
                  className="text-14 text-uppercase font-weight-normal"
                  for="caption">
                  Reset URL
                </Label>
                <InputGroup>
                  <Input
                    name="resetUrl"
                    value={resetUrl}
                    type="text"
                    readOnly={true}
                    innerRef={inputRef}
                  />

                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={onCopy}>
                      Copy <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <Alert color="warning">
                Please note that the generated URL is only valid for 1 hour from
                generation.
              </Alert>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary-dark"
            size="lg"
            className="ml-3"
            onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object
};

export default ResetPasswordModal;
