import React, { useState } from "react";
import { Badge, Button, Card, CardBody, CardText, Col } from "reactstrap";
import PropTypes from "prop-types";
import {
  get,
  includes,
  isEqual,
  startCase,
  truncate,
  size,
  isEmpty
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faFlag,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  approveComment,
  flagComment
} from "../../../actions/moderation.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  isApprovalActive,
  isFlaggedActive,
  isRejectedActive
} from "../../../utils/comment-utils";
import RejectCommentModal from "../../../components/modals/RejectCommentModal";
import ProfileImage from "../../../components/common/ProfileImage";
import moment from "moment";
import Icon from "../../../components/common/icon";
import CommentReportsModal from "../../../components/modals/CommentReportsModal";
import { getWebCaseUrl } from "../../../utils/case-utils";
import { CAMPAIGN_COMMENTS_SUB_COLLECTIONS } from "../../../db/db-constants";

export const CommentActionButtons = (props) => {
  const { activeComment } = props;
  const dispatch = useDispatch();
  const approvedSaving = useSelector(
    (state) => state.moderation.commentApprovedSaving
  );
  const flaggedSaving = useSelector(
    (state) => state.moderation.commentFlaggedSaving
  );
  const [approveModal, setApproveModal] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleFlagModal = () => setFlagModal(!flagModal);
  const toggleRejectModal = () => setRejectModal(!rejectModal);

  const commentUuid = get(activeComment, "commentUuid");

  const onApprove = async () => {
    await dispatch(approveComment(commentUuid));
    toggleApproveModal();
  };

  const onFlag = async () => {
    await dispatch(flagComment(commentUuid));
    toggleFlagModal();
  };

  return (
    <Card className="border-0 action-button-card">
      <CardBody className="py-0 d-flex flex-md-column flex-column align-items-start justify-content-center">
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round"
          color="approved"
          disabled={!isApprovalActive(activeComment)}
          onClick={toggleApproveModal}>
          <Icon
            icon={"verified-checkmark-t15"}
            size={"medium"}
            color={"white"}
          />
        </Button>
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round my-2"
          color="flagged"
          disabled={!isFlaggedActive(activeComment)}
          onClick={toggleFlagModal}>
          <FontAwesomeIcon icon={faFlag} color="white" size={"sm"} />
        </Button>
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round btn-removed"
          color="removed"
          disabled={!isRejectedActive(activeComment)}
          onClick={toggleRejectModal}>
          <FontAwesomeIcon icon={faTimes} size={"sm"} color="white" />
        </Button>
      </CardBody>
      <ConfirmModal
        toggle={toggleApproveModal}
        isOpen={approveModal}
        message="This comment will be moved to the 'Approved' state. Proceed?"
        onConfirm={onApprove}
        saving={approvedSaving}
      />
      <ConfirmModal
        toggle={toggleFlagModal}
        isOpen={flagModal}
        message="This comment will be moved to the 'Flagged' state. Proceed?"
        onConfirm={onFlag}
        saving={flaggedSaving}
      />
      <RejectCommentModal
        toggle={toggleRejectModal}
        isOpen={rejectModal}
        comment={activeComment}
      />
    </Card>
  );
};

CommentActionButtons.propTypes = {
  activeComment: PropTypes.object
};

export const CommentDetail = (props) => {
  const { comment, allAuthorMetadata } = props;

  const [reportsModal, setReportsModal] = useState(false);
  const toggleReportsModal = () => setReportsModal(!reportsModal);

  const commentData = comment;
  const commentUuid = get(commentData, "commentUuid");
  const caseData = get(commentData, "case");
  const createdAt = get(commentData, "createdAt");
  const updatedAt = get(commentData, "updatedAt");
  const reports = get(commentData, "reports");
  const caseUuid = get(commentData, "caseUuid");
  const isGroupComment = !!get(commentData, "case.groupUuid");
  const title = get(caseData, "title");
  const caption = get(caseData, "caption");
  const caseDisplayTitle = truncate(title || caption, { length: 80 });

  const renderTag = (comment) => {
    const validStates = [
      "approved",
      "flagged",
      "reported",
      "rejected",
      "deleted",
      "alerted",
      "pending_approval",
      "pending_approval_flagged"
    ];

    const state = get(comment, "state");
    const isValidState = includes(validStates, state);
    if (!isValidState) {
      return null;
    }

    return (
      <Badge className={`badge-pill ${state} mr-1`}>
        <FontAwesomeIcon icon={faExclamation} size={"sm"} />
        {renderStateName(state)}
      </Badge>
    );
  };

  const renderStateName = (stateName) => {
    if (
      isEqual(CAMPAIGN_COMMENTS_SUB_COLLECTIONS.FLAGGED.statsKey, stateName)
    ) {
      return CAMPAIGN_COMMENTS_SUB_COLLECTIONS.FLAGGED.displayName;
    }

    return startCase(stateName);
  };

  return (
    <>
      <Card key={`comment-${commentUuid}`} className="comment-card mb-3">
        <CardBody className="mr-0 pr-0 py-3 border-0">
          <div className="comments-flex-container d-flex">
            <Col xl={8} lg={7} md={5} className="pl-2">
              {renderTag(commentData)}
              {!isEqual(updatedAt, createdAt) && (
                <span className="font-italic text-secondary ml-2">
                  Updated at {moment(updatedAt).format("h:mm, MMMM Do, YYYY")}
                </span>
              )}
              <div className="font-weight-bold mt-2 text-14">
                {caseUuid && (
                  <a
                    className="font-weight-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getWebCaseUrl(caseUuid)}>
                    {caseDisplayTitle}
                  </a>
                )}
                {!caseUuid && caseDisplayTitle}
              </div>
              <div className="mt-2 comment-text-block">
                {get(commentData, "text")}
                {isGroupComment && (
                  <div className="mt-2 mb-0 alert bg-other-green text-darkest-gray font-weight-normal px-3 py-2">
                    This comment was posted to a group case
                  </div>
                )}
              </div>

              <div className="font-italic text-secondary text-13 mt-2">
                Commented at {moment(createdAt).format("h:mm, MMMM Do, YYYY")}
              </div>
              {!isEmpty(reports) && (
                <div className="pt-1">
                  <Button
                    className="px-0 text-15 font-weight-500"
                    color="link"
                    onClick={toggleReportsModal}>
                    See reports ({size(reports)})
                  </Button>
                </div>
              )}
            </Col>
            <Col
              xl={3}
              lg={4}
              md={5}
              className="case-author-detail pl-2 border-left">
              <CommentAuthorDetail
                comment={commentData}
                allAuthorMetadata={allAuthorMetadata}
              />
            </Col>
            <Col lg={1} md={2} className="px-0 border-left">
              <CommentActionButtons
                activeComment={commentData}
                iconSize="small"
              />
            </Col>
          </div>
        </CardBody>
      </Card>
      <CommentReportsModal
        isOpen={reportsModal}
        toggle={toggleReportsModal}
        reports={reports}
      />
    </>
  );
};

CommentDetail.propTypes = {
  comment: PropTypes.object,
  allAuthorMetadata: PropTypes.object
};

export const CommentAuthorDetail = (props) => {
  const { comment, allAuthorMetadata } = props;

  const authorUuid = get(comment, "authorUuid");
  const username = get(comment, "authorUsername");
  const avatar = get(comment, "authorAvatar");
  const profession = get(comment, "authorProfessionName");

  const authorMetadata = get(allAuthorMetadata, authorUuid, {});
  const totalComments = get(authorMetadata, "all", 0);
  const totalFlagged = get(authorMetadata, "flagged", 0);
  const totalReported = get(authorMetadata, "reported", 0);
  const totalRejected = get(authorMetadata, "rejected", 0);

  return (
    <Card className="border-0 px-2">
      <div className="d-inline-flex p-0 mb-2">
        <ProfileImage avatar={avatar} username={username} />
        <CardBody className="d-flex flex-column py-0 px-2">
          <div className="mb-0 text-14">{username}</div>
          <div className="text-14 text-secondary">{profession}</div>
        </CardBody>
      </div>
      <CardBody className="m-0 p-0">
        <CardText className="my-0">
          <strong>{totalComments}</strong> Comments
        </CardText>
        <CardText className="my-0">
          <strong>{totalFlagged}</strong> Flagged
        </CardText>
        <CardText className="my-0">
          <strong>{totalReported}</strong> Reported
        </CardText>
        {totalRejected > 0 ? (
          <span className="my-0">
            <strong>{totalRejected}</strong> Rejected
          </span>
        ) : (
          <CardText className="my-0">
            <strong>0</strong> Rejected
          </CardText>
        )}
      </CardBody>
    </Card>
  );
};

CommentAuthorDetail.propTypes = {
  comment: PropTypes.object,
  allAuthorMetadata: PropTypes.object
};
