import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

const ResponsiveMessageModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <h5 className="align-items-center text-center border-bottom p-4">
        Device not supported
      </h5>
      <ModalBody className="px-5 text-center">
        <h5 className="text-secondary">
          Because of the nature of the content presented on this section of the
          Figure 1 website, we cannot provide an adequate user experience on a
          device of this size.
        </h5>
        <h5 className="mt-4 text-secondary text-center">
          We recommend you visit the site using your desktop or tablet browser.
        </h5>
      </ModalBody>
      <ModalFooter className="px-5">
        <Button color="primary" outline onClick={toggle}>
          Close message
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ResponsiveMessageModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default ResponsiveMessageModal;
