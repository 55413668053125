import Firebase from "../firebase";
import {
  get,
  has,
  head,
  isArray,
  isEmpty,
  isEqual,
  isUndefined,
  map,
  omitBy
} from "lodash";
import { scrubMediaField } from "../utils/form-utils";
import { isValidUuid, isRequestSuccess } from "../utils/general-utils";
import { VERIFICATION_STATUSES } from "../utils/verification-utils";

const functions = Firebase.functions;

// Moderation Endpoints

export const stateChangeCaseCall = async (caseUuid, state, reason) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationStateChange"
  );
  const result = await callableFn({ caseUuid, state, reason });
  return result.data;
};

export const addNoteCall = async (caseUuid, text) => {
  const callableFn = functions.httpsCallable("backendAdminModerationNote");
  const result = await callableFn({ caseUuid, text });
  return result.data;
};

export const editCaseCall = async (caseUuid, title, caption, suggestEdit) => {
  const callableFn = functions.httpsCallable("backendAdminModerationEdit");
  const result = await callableFn({ caseUuid, title, caption, suggestEdit });
  return result.data;
};

export const editCaseLanguageCall = async (caseUuid, language) => {
  const callableFn = functions.httpsCallable("backendAdminModerationEdit");
  const result = await callableFn({ caseUuid, language, suggestEdit: false });
  return result.data;
};

export const generalCaseSaveCall = async (caseUuid, type, body = {}) => {
  const callableFn = functions.httpsCallable("backendAdminModerationEdit");
  const result = await callableFn({ caseUuid, type, body });
  return result.data;
};

export const editCaseMediaCall = async (formData) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminModerationEditMedia/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  let response = await fetch(postUrl, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return true;
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

export const reviewEditCall = async (caseUuid, editUuid, action) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationReviewEdit"
  );
  const result = await callableFn({ caseUuid, editUuid, action });
  return result.data;
};

export const tineyeCall = async (url) => {
  const callableFn = functions.httpsCallable("backendAdminModerationTineye");
  const result = await callableFn({ url });
  return result.data;
};

export const caseSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationCaseSearch"
  );

  const specialityKey = "specialtyUuid";
  const subSpecialityKey = "subspecialtyUuid";
  const specialtyUuids = [];
  if (has(filters, specialityKey)) {
    specialtyUuids.push(filters[specialityKey]);
  }
  if (has(filters, subSpecialityKey)) {
    specialtyUuids.push(filters[subSpecialityKey]);
  }

  const searchFilter = omitBy(
    isEmpty(specialtyUuids)
      ? filters
      : {
          ...filters,
          specialtyUuids
        },
    (v, key) => isEqual(key, specialityKey) || isEqual(key, subSpecialityKey)
  );

  const result = await callableFn({
    filters: searchFilter,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const commentSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationCommentSearch"
  );
  const result = await callableFn({
    filters,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const commentStateChangeCaseCall = async (
  commentUuid,
  state,
  reason
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationCommentStateChange"
  );
  const result = await callableFn({ commentUuid, state, reason });
  return result.data;
};

// Moderation Tagging Endpoints

export const tagStateChangeCaseCall = async (caseUuid, state) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationTagStateChange"
  );
  const result = await callableFn({ caseUuid, state });
  return result.data;
};

export const tagSaveCall = async (
  tagType,
  caseUuid,
  addTags = [],
  removeTags = []
) => {
  const callableFn = functions.httpsCallable("backendAdminModerationTagSave");
  const payload = { tagType, caseUuid };
  if (isArray(addTags) && !isEmpty(addTags)) {
    payload.addTags = addTags;
  }
  if (isArray(removeTags) && !isEmpty(removeTags)) {
    payload.removeTags = removeTags;
  }
  const result = await callableFn(payload);
  return result.data;
};

export const labelsSaveCall = async (caseUuid, labels = []) => {
  const callableFn = functions.httpsCallable(
    "backendAdminModerationLabelsSave"
  );
  const payload = { caseUuid, labels };
  const result = await callableFn(payload);
  return result.data;
};

// Verification Endpoints

export const verificationGeneralNavigationCall = async (
  verificationType,
  verificationStatus,
  filters = {},
  page = 0,
  size = 10
) => {
  const callableFn = functions.httpsCallable(
    "backendAdminVerificationGeneralNavigation"
  );
  const cleanFilters = {
    ...filters,
    tagUuids: map(filters.tagUuids, (t) => t.tagUuid),
    countryUuids: map(filters.countryUuids, (t) => t.uuid),
    professionCategories: map(filters.professionCategories, (t) => t.value)
  };

  const result = await callableFn({
    verificationType,
    verificationStatus,
    filters: cleanFilters,
    page,
    size
  });
  return result.data;
};

export const verificationAddNoteCall = async (userUid, userUuid, text) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationNote");
  const result = await callableFn({ userUid, userUuid, text });
  return result.data;
};

export const verificationStateCall = async (
  userUids,
  userUuid,
  verificationState
) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationState");
  const state = isEqual(
    verificationState,
    VERIFICATION_STATUSES.REVIEW_REQUIRED.state
  )
    ? undefined
    : verificationState;
  const flagForReview = isEqual(
    verificationState,
    VERIFICATION_STATUSES.REVIEW_REQUIRED.state
  );
  const result = await callableFn({
    userUids,
    userUuid,
    state,
    flagForReview
  });
  return result.data;
};

export const verificationTagCall = async (userUids, userUuids, tagUuids) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationTag");
  const userUuid = head(userUuids);
  const result = await callableFn({ userUids, userUuids, userUuid, tagUuids });
  return result.data;
};

export const verificationTagManageCall = async (actions) => {
  const callableFn = functions.httpsCallable(
    "backendAdminVerificationTagManage"
  );
  const result = await callableFn({ actions });
  return result.data;
};

export const verificationEditCall = async (userUid, data) => {
  const callableFn = functions.httpsCallable("backendAdminVerificationEdit");

  const convertedData = omitBy(
    {
      userUuid: data.userUuid,
      first_name: data.firstName,
      last_name: data.lastName,
      school_uuid: isValidUuid(data.schoolUuid) ? data.schoolUuid : undefined,
      graduation_year: data.graduationYear,
      npi_number: data.npiNumber,
      medical_license: data.medicalLicense,
      primary_specialty_tree_uuid: isValidUuid(data.primarySpecialty)
        ? data.primarySpecialty
        : undefined,
      state_uuid: isValidUuid(data.stateUuid) ? data.stateUuid : undefined,
      country_uuid: isValidUuid(data.countryUuid) ? data.countryUuid : undefined
    },
    isUndefined
  );

  const result = await callableFn({ ...convertedData, userUid });
  return result.data;
};

// User Endpoints

export const createUserCall = async (email, firstName, lastName) => {
  const callableFn = functions.httpsCallable("backendAdminUserCreate");
  const result = await callableFn({ email, firstName, lastName });
  return result.data;
};

export const updateUserCall = async (userUid, userData) => {
  const callableFn = functions.httpsCallable("backendAdminUserUpdate");
  const result = await callableFn({ ...userData, userUid });
  return result.data;
};

export const deleteUserCall = async (userUid) => {
  const callableFn = functions.httpsCallable("backendAdminUserDelete");
  const result = await callableFn({ userUid });
  return result.data;
};

export const validateEmail = async (email) => {
  const result = await functions.httpsCallable("backendUserValidateEmail")({
    email: email
  });
  return result.data;
};

export const resetUserPasswordCall = async (email, username) => {
  const callableFn = functions.httpsCallable(
    "backendAdminUserResetPasswordEmail"
  );
  const result = await callableFn({ email, username });
  return result.data;
};

// Campaign Endpoints

export const campaignSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignSearch");
  const result = await callableFn({
    filters,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const campaignGetCall = async (campaignUuid) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignGet");
  const result = await callableFn({
    campaignUuid
  });
  return result.data;
};

export const campaignCreateCall = async (data) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignCreate");
  const result = await callableFn(data);
  return result.data;
};

export const campaignEditCall = async (campaignUuid, data) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignEdit");
  const result = await callableFn({ ...data, campaignUuid });
  return result.data;
};

export const campaignStateCall = async (
  campaignUuid,
  state,
  endpoint = "state"
) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignState");
  const result = await callableFn({ campaignUuid, state, endpoint });
  return result.data;
};

export const campaignTacticTimelineCall = async (data) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignTacticSet");
  const result = await callableFn(data);
  return result.data;
};

export const campaignDeleteTacticCall = async (caseUuid) => {
  const callableFn = functions.httpsCallable(
    "backendAdminCampaignTacticDelete"
  );
  const result = await callableFn({ caseUuid });
  return result.data;
};

export const campaignSaveTacticCall = async (data, tacticType = "static") => {
  const callableFn = functions.httpsCallable("backendAdminCampaignTacticSave");
  const questions = get(data, "questions");
  if (!isEmpty(questions)) {
    data.questions = map(questions, (q) => {
      return {
        ...q,
        media: scrubMediaField(q.mediaType, q.media, q.vimeoFileName)
      };
    });
  }

  const slides = get(data, "slides");
  if (!isEmpty(slides)) {
    data.slides = map(slides, (q) => {
      return {
        ...q,
        media: scrubMediaField(q.mediaType, q.media, q.vimeoFileName)
      };
    });
  }

  const conclusion = get(data, "conclusion");
  if (!isEmpty(conclusion)) {
    conclusion.media = scrubMediaField(
      conclusion.mediaType,
      conclusion.media,
      conclusion.vimeoFileName
    );
  }

  data.media = scrubMediaField(data.mediaType, data.media, data.vimeoFileName);

  const result = await callableFn({ ...data, tacticType });
  return result.data;
};

export const campaignTacticStateCall = async (
  campaignUuid,
  caseUuid,
  state
) => {
  const callableFn = functions.httpsCallable("backendAdminCampaignTacticState");
  const result = await callableFn({ campaignUuid, caseUuid, state });
  return result.data;
};

export const campaignImageUploadCall = async (picture) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminCampaignImageUpload/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  const data = new FormData();
  data.append("picture", picture);

  let response = await fetch(postUrl, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return JSON.parse(await response.json());
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

export const campaignCertificateUploadCall = async (picture) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminCampaignCertificateUpload/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  const data = new FormData();
  data.append("certificate", picture);

  let response = await fetch(postUrl, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return JSON.parse(await response.json());
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

// Case Explorer Endpoints

export const caseExplorerSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10,
  sortColumn = "publishedAt"
) => {
  const callableFn = functions.httpsCallable("backendAdminCaseExplorerSearch");

  const result = await callableFn({
    filters: !isEmpty(filters.labels)
      ? {
          ...filters,
          labels: map(filters.labels, (t) => t.value)
        }
      : filters,
    page,
    sortDirection,
    size,
    sortColumn
  });
  return result.data;
};

export const postPromotion = async (data) => {
  const callableFn = functions.httpsCallable("backendPostPromotion");
  let body = {
    promotion_name: data.promotion_name,
    channel_uuid: data.channel_uuid,
    promotion_notes: data.promotion_notes,
    promotion_publish_date: data.promotion_publish_date,
    cases: data.cases
  };
  if (data.promotion_uuid) {
    body["promotion_uuid"] = data.promotion_uuid;
  }
  const result = await callableFn(body);
  return result.data;
};

export const getPromotion = async (promotionId) => {
  const callableFn = functions.httpsCallable("backendGetPromotion");
  const result = await callableFn({ promotionId: promotionId });
  return result.data;
};

export const getPromotions = async () => {
  const callableFn = functions.httpsCallable("backendGetPromotions");
  const result = await callableFn({});
  return result.data;
};

export const getPromotionsByCase = async (caseId) => {
  const callableFn = functions.httpsCallable("backendGetPromotionsByCase");
  const result = await callableFn({ caseId: caseId });
  return result.data;
};

export const deletePromotion = async (promotionId) => {
  const callableFn = functions.httpsCallable("backendDeletePromotion");
  const result = await callableFn({ promotionId: promotionId });
  return result.data;
};

export const removeCaseFromPromotion = async (promoId, caseId) => {
  const callableFn = functions.httpsCallable("backendRemoveCaseFromPromotion");
  const result = await callableFn({ promotionId: promoId, caseId: caseId });
  return result.data;
};

export const getPromotionChannels = async () => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerGetChannels"
  );
  const result = await callableFn({});
  return result.data;
};

export const deletePromotionChannelCall = async (channelUuid) => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerDeleteChannel"
  );
  const result = await callableFn({ channelUuid });
  return result.data;
};

export const savePromotionChannelCall = async (channelName) => {
  const callableFn = functions.httpsCallable(
    "backendAdminCaseExplorerSaveChannel"
  );
  const result = await callableFn({ channelName });
  return result.data;
};

// Tools Users Endpoints

export const toolsUserSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10,
  ids
) => {
  const callableFn = functions.httpsCallable("backendAdminToolsUserSearch");

  const result = await callableFn({
    filters: filters,
    page,
    sortDirection,
    size,
    ids
  });
  return result.data;
};

// Admin User Endpoints

export const adminUserSaveCall = async (formData) => {
  const callableFn = functions.httpsCallable("backendAdminProfileSave");
  const result = await callableFn(formData);
  return result.data;
};

export const adminUserSearchCall = async (
  filters,
  page = 0,
  sortDirection = "desc",
  size = 10
) => {
  const callableFn = functions.httpsCallable("backendAdminProfileSearch");

  const result = await callableFn({
    filters: filters,
    page,
    sortDirection,
    size
  });
  return result.data;
};

export const adminProfileImageUploadCall = async (userUid, type, picture) => {
  const postUrl = `https://us-central1-${
    Firebase.app.app().options.projectId
  }.cloudfunctions.net/backendAdminProfileImageUpload/upload`;
  const userToken = await Firebase.app.auth().currentUser.getIdToken(true);

  const data = new FormData();
  data.append("userUid", userUid);
  data.append("type", type);
  data.append("picture", picture);

  let response = await fetch(postUrl, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  });

  if (isRequestSuccess(response)) {
    return JSON.parse(await response.json());
  } else {
    const json = await response.json();
    throw new Error(json.message);
  }
};

// Group Endpoints

export const groupAddMembersCall = async (groupUuid, usersUuid) => {
  return groupModifyMembersCall("add_members", groupUuid, usersUuid);
};

export const groupRemoveMembersCall = async (groupUuid, usersUuid) => {
  return groupModifyMembersCall("remove_members", groupUuid, usersUuid);
};

const groupModifyMembersCall = async (action, groupUuid, usersUuid) => {
  const callableFn = functions.httpsCallable("backendGroupsModifyMembers");
  const result = await callableFn({ action, groupUuid, usersUuid });
  return result.data;
};
