import React from "react";
import PropTypes from "prop-types";
import { get, head, isEmpty, map, sortBy } from "lodash";
import PlaceholderCaseThumbnail from "../../../assets/images/placeholder_case_thumbnail.png";
import "../../pages.scss";
import { getImageUrl } from "../../../utils/media-utils";

const CasePreviewerColumn = (props) => {
  const { onSetActiveCase, cases, activeCaseId } = props;

  return (
    <div className="case-previewer">
      {map(cases, (c) => {
        const caseId = get(c, "caseUuid");
        const title = get(c, "title");
        const caption = get(c, "caption");
        const media = sortBy(get(c, "media", []), "displayOrder");
        const firstMedia = head(media);
        const imageUrl = getImageUrl(firstMedia, 120, 120, "crop");
        return (
          <div
            key={`preview-${caseId}`}
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              onSetActiveCase(c);
            }}>
            <div
              className={`thumbnail my-2 ${
                caseId === activeCaseId ? "active" : ""
              }`}>
              <img
                key={get(firstMedia, "mediaUuid")}
                src={!isEmpty(imageUrl) ? imageUrl : PlaceholderCaseThumbnail}
                className="img-thumbnail"
                alt=""
              />
              <div>{title || caption}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CasePreviewerColumn.propTypes = {
  onSetActiveCase: PropTypes.func.isRequired,
  cases: PropTypes.array,
  activeCaseId: PropTypes.string
};

export default CasePreviewerColumn;
