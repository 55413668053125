import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";

const ImageDisplayModal = (props) => {
  const { imageUrl, isOpen, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      <ModalBody className="pt-0 px-5">
        <div style={{ width: "100%" }}>
          <img
            className="img-fluid w-100"
            src={imageUrl}
            alt={"image display " + imageUrl}
          />
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-between pt-3 px-5 pb-5">
        {" "}
      </ModalFooter>
    </Modal>
  );
};

ImageDisplayModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string
};

export default ImageDisplayModal;
