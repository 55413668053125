import { compact, find, isEqual, map } from "lodash";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import React from "react";

export const convertCountries = (countryUuids, allCountries) => {
  return compact(
    map(countryUuids, (uuid) => {
      return find(allCountries, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertCountry = (countryUuid, allCountries) => {
  return find(allCountries, (t) => isEqual(t.uuid, countryUuid));
};

export const convertProfessions = (specialityUuids, allProfessions) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allProfessions, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertSpecialties = (specialityUuids, allSpecialties) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allSpecialties, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertSubSpecialties = (specialityUuids, allSubSpecialties) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allSubSpecialties, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const findSpecialty = (container, targetId, targetProp) => {
  let returnVal = null;

  if (container) {
    if (targetId) {
      returnVal = container[targetProp].find(
        (specialty) => specialty.typeUuid === targetId
      );
      return returnVal;
    } else {
      return undefined;
    }
  }
};

export const generateDropdown = (
  target,
  label,
  options,
  placeholder,
  onChange,
  currentValue,
  optionAttrValue = "typeUuid",
  optionAttrLabel = "name",
  isClearable = false
) => {
  return (
    <FormGroup>
      <Label for={target} className="text-11 text-uppercase font-weight-normal">
        {label}
      </Label>
      <Select
        id={target}
        name={target}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={currentValue || ""}
        getOptionLabel={(option) => option[optionAttrLabel]}
        getOptionValue={(option) => option[optionAttrValue]}
        isClearable={isClearable}
      />
    </FormGroup>
  );
};
