import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { userSignedIn } from "../utils/route-utils";
import SignInPage from "./authentication/SigninPage";
import SignUpPage from "./authentication/SignupPage";
import LogoutPage from "./authentication/LogoutPage";
import GoogleSigninPage from "./authentication/GoogleSigninPage";

class UnauthenticatedApp extends React.Component {
  conditionallyRenderDefaultLogin = (auth, defaultRoute) => {
    return userSignedIn(auth) ? (
      <Redirect to={ROUTES.HOME_ROOT} />
    ) : (
      <Redirect to={defaultRoute} />
    );
  };

  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth } = this.props;

    return userSignedIn(auth) ? (
      <Redirect to={ROUTES.HOME_ROOT} />
    ) : (
      pageComponent
    );
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path={ROUTES.ROOT}>
            {this.conditionallyRenderDefaultLogin(
              this.props.auth,
              ROUTES.SIGN_IN
            )}
          </Route>

          <Route exact path={ROUTES.SIGN_IN}>
            {this.conditionallyRenderDefaultOrPage(<GoogleSigninPage />)}
          </Route>

          <Route exact path={ROUTES.DEV_ONLY_SIGN_IN}>
            {this.conditionallyRenderDefaultOrPage(<SignInPage />)}
          </Route>

          <Route exact path={ROUTES.DEV_ONLY_SIGN_UP}>
            {this.conditionallyRenderDefaultOrPage(<SignUpPage />)}
          </Route>

          <Route exact path={ROUTES.TAGGER_SIGN_IN}>
            {this.conditionallyRenderDefaultOrPage(<SignInPage />)}
          </Route>

          <Route exact path={ROUTES.TAGGER_SIGN_UP}>
            {this.conditionallyRenderDefaultOrPage(<SignUpPage />)}
          </Route>

          <Route exact path={ROUTES.SIGN_OUT}>
            <LogoutPage />
          </Route>
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UnauthenticatedApp);
