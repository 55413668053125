import {
  filter,
  find,
  flatMap,
  get,
  isEqual,
  isNil,
  map,
  reduce,
  sortBy
} from "lodash";
import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const loadVerificationTags = async (callback) => {
  getCollectionRef(DB_CONSTANTS.REFERENCE_DATA)
    .doc(DB_CONSTANTS.VERIFICATION_TAGS)
    .onSnapshot(
      (snapshot) => {
        callback(get(snapshot.data(), "all", {}));
      },
      (error) => {
        console.log("Load verification listener error", error);
      }
    );
};

export const loadProfessionAndSpecialties = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.PROFESSIONS)
    .get();

  const professionPromises = map(snapshot.data(), (p) => {
    return getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
      .doc(DB_CONSTANTS.PROFESSIONS)
      .collection(p.professionUuid)
      .get()
      .then((subSnapshot) => {
        return map(subSnapshot.docs, (s) => s.data());
      })
      .then((docs) => {
        const profession = {
          name: p.professionName,
          typeUuid: p.professionUuid,
          label: p.professionLabel,
          categoryName: p.professionCategoryName,
          categoryLabel: p.professionCategoryLabel,
          uuid: get(
            find(
              docs,
              (d) =>
                !isNil(d.profession) &&
                isNil(d.specialty) &&
                isNil(d.subspecialty)
            ),
            "treeUuid"
          ),
          specialties: sortBy(
            map(
              filter(
                docs,
                (d) =>
                  !isNil(d.profession) &&
                  !isNil(d.specialty) &&
                  isNil(d.subspecialty)
              ),
              (sp) => {
                return {
                  name: sp.specialty.specialtyName,
                  uuid: sp.treeUuid,
                  label: sp.specialty.specialtyLabel,
                  typeUuid: sp.specialty.specialtyUuid,
                  subSpecialties: []
                };
              }
            ),
            ["name"]
          )
        };

        // Filter sub specialties and add them to the specialty
        map(
          filter(
            docs,
            (d) =>
              !isNil(d.profession) &&
              !isNil(d.specialty) &&
              !isNil(d.subspecialty)
          ),
          (sub) => {
            const specialty = find(profession.specialties, (s) =>
              isEqual(s.typeUuid, sub.specialty.specialtyUuid)
            );

            if (specialty) {
              specialty.subSpecialties.push({
                name: sub.subspecialty.specialtyName,
                label: sub.subspecialty.specialtyLabel,
                typeUuid: sub.subspecialty.specialtyUuid,
                uuid: sub.treeUuid
              });
            }
          }
        );

        map(profession.specialties, (s) => {
          s.subSpecialties = sortBy(s.subSpecialties, ["name"]);
          s.subSpecialtyUuids = map(s.subSpecialties, (sub) => sub.uuid);
        });

        profession.specialtyUuids = map(
          profession.specialties,
          (sub) => sub.uuid
        );

        return profession;
      });
  });

  const allData = await Promise.all(professionPromises);
  const specialties = flatMap(allData, (d) => {
    return get(d, "specialties", []);
  });
  const professions = sortBy(allData, ["name"]);
  return { professions, specialties };
};

export const loadCountriesAndStates = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA)
    .doc(DB_CONSTANTS.COUNTRIES)
    .get();

  const countries = map(
    filter(get(snapshot.data(), "all", {}), (c) => c.depth === 0),
    (c) => {
      return {
        uuid: c.uuid,
        name: c.name
      };
    }
  );

  const statesSnapshot = await getCollectionRef(
    `${DB_CONSTANTS.REFERENCE_DATA}/${DB_CONSTANTS.COUNTRIES}/subdivisions`
  ).get();

  const states = reduce(
    statesSnapshot.docs,
    (acc, doc) => {
      return {
        ...acc,
        [doc.id]: sortBy(
          map(get(doc.data(), "all", {}), (c) => {
            return {
              uuid: c.uuid,
              name: c.name
            };
          }),
          ["name"]
        )
      };
    },
    {}
  );

  return { countries, states };
};

export const loadAllSchools = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.SCHOOLS)
    .get();

  const allData = get(snapshot.data(), "all", {});
  return reduce(
    allData,
    (acc, s) => {
      const countryUuid = get(s, "countryUuid");
      const subdivisionUuid = get(s, "subdivisionUuid");
      if (isNil(countryUuid)) {
        return acc;
      }

      (acc[countryUuid] || (acc[countryUuid] = [])).push({
        uuid: s.schoolUuid,
        name: s.name,
        countryUuid,
        subdivisionUuid
      });

      return acc;
    },
    {}
  );
};

export const loadTaggingSpecialties = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.SPECIALTIES)
    .get();

  const allSpecialties = get(snapshot.data(), "tagging", {});
  const uploaderSpecialties = filter(allSpecialties, (c) => c.depth === 0);
  const specialties = filter(allSpecialties, (c) => c.depth === 1);
  const subSpecialties = filter(allSpecialties, (c) => c.depth === 2);

  return { allSpecialties, uploaderSpecialties, specialties, subSpecialties };
};

export const loadLabels = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.LABELS)
    .get();

  return get(snapshot.data(), "all", {});
};

export const loadLocales = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.LOCALES)
    .get();

  return get(snapshot.data(), "supported", {});
};

export const loadPlatforms = async () => {
  const snapshot = await getCollectionRef(
    `${DB_CONSTANTS.REFERENCE_DATA_LATEST}/${DB_CONSTANTS.CASE_FILTERS}/${DB_CONSTANTS.PLATFORMS}`
  ).get();

  return map(snapshot.docs, (d) => d.data());
};

export const loadGroups = async () => {
  const snapshot = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_LATEST)
    .doc(DB_CONSTANTS.GROUPS)
    .get();

  return snapshot.exists ? snapshot.data() : {};
};
