import { faAngleRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";
import SearchSuggestionContainer from "./SearchSuggestionContainer";

// Have input that takes search value
// search dropdown has 2 options, Specialties, Profession
// on enter defaults to specialty
// on set
// store searched value
// store type of search
// call callback that passes the value and type back to parent to do something with
export const CATEGORIES = {
  SPECIALTIES: "Specialties",
  PROFESSION: "Profession"
};

const CampaignDefineAudienceSearchBar = ({ onInput }) => {
  const searchValue = useRef("");
  const searchCategory = useRef(CATEGORIES.SPECIALTIES);
  const [dropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef();

  const onCategorySelect = (category) => {
    searchCategory.current = category;
    onEnter();
  };

  const onChange = (e) => {
    searchValue.current = e.target.value.trim();
    setIsDropdownOpen(searchValue.current.length > 0);
  };

  const onEnter = () => {
    setIsDropdownOpen(false);
    onInput(searchCategory.current, searchValue.current);
  };

  const onClear = (e) => {
    if (!inputRef.current.value) {
      searchCategory.current = CATEGORIES.SPECIALTIES;
      searchValue.current = "";
      onEnter();
    }
  };

  const onBlur = () => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 1000);
  };

  const onFocus = (e) => {
    if (e.target.value) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    inputRef.current.addEventListener("search", onClear);
    return () => {
      searchCategory.current = CATEGORIES.SPECIALTIES;
      searchValue.current = "";
      onInput(searchCategory.current, searchValue.current);
      inputRef.current.removeEventListener("search", onClear);
    };
  }, []);

  const categoryOptions = [
    {
      text: CATEGORIES.SPECIALTIES,
      onPress: () => onCategorySelect(CATEGORIES.SPECIALTIES)
    },
    {
      text: CATEGORIES.PROFESSION,
      onPress: () => onCategorySelect(CATEGORIES.PROFESSION)
    }
  ];

  return (
    <div className="d-flex flex-column z-2 search-container">
      <InputGroup>
        <Input
          innerRef={inputRef}
          name="search"
          type="search"
          placeholder="Search"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={(e) => {
            if (e.key === "Enter" && onEnter) {
              onEnter(e);
            } else if (e.key === "Escape" && onClear) {
              onClear(e);
            }
            return false;
          }}
          autoComplete="off"
        />
        <InputGroupText onClick={() => inputRef.current.focus()}>
          <FontAwesomeIcon icon={faSearch} color="#8f9296" />
        </InputGroupText>
      </InputGroup>
      {dropdownOpen && <SearchSuggestionContainer data={categoryOptions} />}
    </div>
  );
};

export default CampaignDefineAudienceSearchBar;
