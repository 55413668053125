import React, { useState } from "react";
import {
  Badge,
  Button,
  NavItem,
  NavLink,
  Pagination,
  Spinner
} from "reactstrap";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCases,
  getCases,
  getCasesForDownload
} from "../../../actions/case-explorer.actions";
import { defaultTo, join, map, get, isString, size, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import CaseExplorerFilterModal from "../../../components/modals/CaseExplorerFilterModal";
import { formatNumber } from "../../../utils/general-utils";

export const CaseExplorerPagination = () => {
  const dispatch = useDispatch();

  const maxCaseToDownload = 1000;
  const [downloading, setDownloading] = useState(false);
  const currentPage = useSelector((state) => state.caseExplorer.currentPage);
  const totalCases = useSelector((state) => state.caseExplorer.totalCases);
  const fromPage = useSelector((state) => state.caseExplorer.fromPage);
  const toPage = useSelector((state) => state.caseExplorer.toPage);
  const hasPrevious = useSelector((state) => state.caseExplorer.hasPrevious);
  const hasNext = useSelector((state) => state.caseExplorer.hasNext);
  const filters = useSelector((state) => state.caseExplorer.filters);
  const sortDirection = useSelector(
    (state) => state.caseExplorer.sortDirection
  );
  const sortColumn = useSelector((state) => state.caseExplorer.sortColumn);
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const clearFilters = () => {
    dispatch(filterCases({}));
  };

  const fields = [
    "caseUuid",
    "author_username",
    "title",
    "caption",
    "commentCount",
    "publishedAt",
    "author_profession_label"
  ];

  const onPreviousPage = () => {
    dispatch(getCases(currentPage - 1));
    return false;
  };

  const onNextPage = () => {
    dispatch(getCases(currentPage + 1));
    return false;
  };

  const sanitizeCsvCell = (cell) => {
    if (!isString(cell)) {
      return cell;
    }

    if (cell.match(/[\s,"]/)) {
      return '"' + cell.replace(/"/g, '""') + '"';
    }

    return cell;
  };

  const generateCsvFile = (casesToDownload) => {
    const rows = [];
    rows.push(join(fields, ","));

    map(casesToDownload, (c) => {
      rows.push(
        join(
          map(fields, (field) => {
            return sanitizeCsvCell(defaultTo(get(c, field), ""));
          }),
          ","
        )
      );
    });

    return join(rows, "\n");
  };

  const downloadCsv = async () => {
    setDownloading(true);
    const cases = await getCasesForDownload(
      maxCaseToDownload,
      filters,
      sortColumn,
      sortDirection
    );
    const element = document.createElement("a");
    const csvFile = generateCsvFile(cases);
    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    element.href = URL.createObjectURL(blob);
    element.download = "cases-export.csv";
    element.click();
    setDownloading(false);
  };

  return (
    <div className="d-flex text-secondary mb-3 justify-content-between">
      <div className="case-explorer-pagination d-flex ">
        <span>
          {totalCases === 0 ? 0 : fromPage}-{toPage} of{" "}
          {formatNumber(totalCases)}
        </span>
        <Pagination>
          <NavItem>
            <NavLink href="#" disabled={!hasPrevious} onClick={onPreviousPage}>
              <div className="ml-3 mr-2">&#8249;</div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" disabled={!hasNext} onClick={onNextPage}>
              <div className="ml-3 mr-2">&#8250;</div>
            </NavLink>
          </NavItem>
        </Pagination>
      </div>
      <div>
        <Button
          color="link"
          disabled={totalCases > 250000 || totalCases === 0}
          onClick={downloadCsv}
          className="px-4">
          {downloading && <Spinner size="sm" color="link" />}
          {!downloading && (
            <>
              <FontAwesomeIcon icon={faDownload} size={"sm"} className="mr-1" />{" "}
              Export to CSV
            </>
          )}
        </Button>

        <Button color="link" onClick={toggleFilterModal}>
          <FontAwesomeIcon icon={faPlusCircle} size={"sm"} className="mr-1" />
          Filters{" "}
          {!isEmpty(filters) && <Badge color="primary">{size(filters)}</Badge>}
        </Button>
        {!isEmpty(filters) && (
          <Button className="ml-2" color="link" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </div>

      <CaseExplorerFilterModal
        toggle={toggleFilterModal}
        isOpen={filterModal}
      />
    </div>
  );
};

CaseExplorerPagination.propTypes = {};
