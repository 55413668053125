import moment from "moment";
import {
  compact,
  concat,
  defaultTo,
  find,
  get,
  isEmpty,
  isEqual,
  join,
  size,
  toLower,
  toUpper,
  includes,
  isNil,
  omitBy
} from "lodash";

export const VERIFICATION_TYPES = {
  NPI: { statsKey: "npi", displayName: "NPI" },
  LICENSE: {
    statsKey: "license",
    displayName: "Medical License"
  },
  PHOTO: {
    statsKey: "photo",
    displayName: "Photo ID"
  },
  INSTITUTIONAL_EMAIL: {
    statsKey: "institutional_email",
    displayName: "Institutional Email"
  }
};

export const VERIFICATION_TABS = {
  INFO: { tabName: "info", displayName: "Verification Info" },
  NOTES: {
    tabName: "notes",
    displayName: "Notes"
  },
  HISTORY: {
    tabName: "history",
    displayName: "History"
  }
};

export const VERIFICATION_STATUSES = {
  REVIEW_REQUIRED: {
    displayName: "Temporarily verified",
    state: "review_required"
  },
  PENDING_MANUAL_VERIFICATION: {
    displayName: "Pending",
    state: "pending_manual_verification"
  },
  INFO_NEEDED: { displayName: "Info Needed", state: "info_needed" },
  UNVERIFIABLE: { displayName: "Unverifiable", state: "unverifiable" },
  VERIFIED: { displayName: "Verified", state: "verified" },
  UPDATED_INFO: { displayName: "Updated Info", state: "updated_info" }
};

export const VERIFICATION_TAG_COLOR = {
  ID_REQUIRED: { tagName: "ID required", colorClassName: "primary" },
  INVALID_PHOTO: { tagName: "Invalid photo", colorClassName: "secondary" },
  LICENSE_NOT_MATCH: {
    tagName: "License not a match",
    colorClassName: "success"
  },
  PROFESSION_NOT_MATCH: {
    tagName: "Profession not a match",
    colorClassName: "danger"
  },
  SPECIALTY_NOT_MATCH: {
    tagName: "Specialty not a match",
    colorClassName: "warning"
  },
  NOT_IN_DB: { tagName: "Not in db", colorClassName: "info" }
};

export const convertVerificationStatus = (verificationStatus) => {
  return (
    VERIFICATION_STATUSES[toUpper(verificationStatus)] ||
    VERIFICATION_STATUSES.PENDING_MANUAL_VERIFICATION
  );
};

export const getDisplayName = (user) => {
  if (isNil(user)) {
    return null;
  }

  const displayName = get(user, "displayName");
  const firstName = get(user, "firstName");
  const lastName = get(user, "lastName");

  // Temporary check to handle display name being 'None None'
  if (isEmpty(displayName) || includes(toLower(displayName), "none")) {
    return `${firstName} ${lastName}`;
  }

  return displayName;
};

const getTreeRoot = (user) => {
  return isNil(get(user, "primarySpecialty.tree"))
    ? "tree"
    : "primarySpecialty.tree";
};

export const getProfessionName = (user, def = null) => {
  const root = getTreeRoot(user);
  return defaultTo(get(user, `${root}.profession.professionName`), def);
};

export const getProfessionUuid = (user, def = null) => {
  const root = getTreeRoot(user);
  return defaultTo(get(user, `${root}.profession.professionUuid`), def);
};

export const getSpecialtyName = (
  user,
  def = null,
  includeSubspecialty = false
) => {
  const root = getTreeRoot(user);
  const specialty = get(user, `${root}.specialty.specialtyName`);

  if (includeSubspecialty) {
    const subSpecialty = getSubSpecialtyName(user);
    const specialties = compact(concat(specialty, subSpecialty));

    if (!isEmpty(specialties)) {
      return join(specialties, " | ");
    }
  }

  return defaultTo(specialty, def);
};

export const getSpecialtyUuid = (user, def = null) => {
  const root = getTreeRoot(user);
  return defaultTo(get(user, `${root}.specialty.specialtyUuid`), def);
};

export const getSubSpecialtyName = (user, def = null) => {
  const root = getTreeRoot(user);
  return defaultTo(get(user, `${root}.subspecialty.specialtyName`), def);
};

export const getSubSpecialtyUuid = (user, def = null) => {
  const root = getTreeRoot(user);
  return defaultTo(get(user, `${root}.subspecialty.specialtyUuid`), def);
};

export const getUserNumber = (user, verificationType) => {
  switch (verificationType) {
    case VERIFICATION_TYPES.NPI.statsKey:
      const npi = get(user, "npi.npiNumber");
      return npi ? `#${npi}` : "";
    case VERIFICATION_TYPES.LICENSE.statsKey:
      const license = get(user, "license.licenseNumber");
      return license ? `#${license}` : "";
    case VERIFICATION_TYPES.PHOTO.statsKey:
    default:
      return "";
  }
};

export const isNpiUser = (verificationType) => {
  return VERIFICATION_TYPES.NPI.statsKey === verificationType;
};

export const isLicenseUser = (verificationType) => {
  return VERIFICATION_TYPES.LICENSE.statsKey === verificationType;
};

export const getVerificationTagColor = (tagName) => {
  const lowerTagName = toLower(tagName);
  const config = find(VERIFICATION_TAG_COLOR, (o) =>
    isEqual(toLower(o.tagName), lowerTagName)
  );

  return config ? config.colorClassName : "info";
};

export const getUsersRefreshPage = (currentPage, users) => {
  if (size(users) > 1 || currentPage === 0) {
    return currentPage;
  }

  return currentPage - 1;
};

export const isPriorityUser = (user, filters) => {
  const priorityFilters = omitBy(filters, isEmpty);
  if (isNil(user) || isEmpty(priorityFilters)) {
    return false;
  }

  const professionCategories = get(priorityFilters, "professionCategories");
  const professionUuid = get(priorityFilters, "professionUuid");
  const specialtyUuid = get(priorityFilters, "specialtyUuid");
  const countryUuids = get(priorityFilters, "countryUuids");
  const requestDateFrom = get(priorityFilters, "requestDateFrom");
  const requestDateTo = get(priorityFilters, "requestDateTo");
  const approvedCaseCount = get(priorityFilters, "approvedCaseCount");
  const approvedCommentCount = get(priorityFilters, "approvedCommentCount");
  const deletedCaseCount = get(priorityFilters, "deletedCaseCount");
  const deletedCommentCount = get(priorityFilters, "deletedCommentCount");
  const dateLastUploaded = get(priorityFilters, "dateLastUploaded");
  const dateLastCommented = get(priorityFilters, "dateLastCommented");

  if (!isEmpty(professionCategories)) {
    const userCategory = get(
      user,
      "primarySpecialty.tree.profession.professionCategoryLabel"
    );
    const match = find(professionCategories, (p) =>
      isEqual(p.value, userCategory)
    );
    if (!match) {
      return false;
    }
  }

  if (!isEmpty(professionUuid)) {
    if (
      !isEqual(
        professionUuid,
        get(user, "primarySpecialty.tree.profession.professionUuid")
      )
    ) {
      return false;
    }
  }

  if (!isEmpty(specialtyUuid)) {
    if (
      !isEqual(
        specialtyUuid,
        get(user, "primarySpecialty.tree.specialty.specialtyUuid")
      )
    ) {
      return false;
    }
  }

  if (!isEmpty(countryUuids)) {
    const userCountry = get(user, "countryUuid");
    const match = find(countryUuids, (p) => isEqual(p.uuid, userCountry));
    if (!match) {
      return false;
    }
  }

  if (!isEmpty(requestDateFrom)) {
    if (
      !moment(requestDateFrom).isBefore(
        moment(get(user, "verificationCreatedAt"))
      )
    ) {
      return false;
    }
  }

  if (!isEmpty(requestDateTo)) {
    if (
      !moment(requestDateTo).isAfter(moment(get(user, "verificationCreatedAt")))
    ) {
      return false;
    }
  }

  if (!isEmpty(approvedCaseCount)) {
    const userCount = parseInt(get(user, "approvedCaseCount"));
    if (isNaN(userCount) || userCount < parseInt(approvedCaseCount)) {
      return false;
    }
  }

  if (!isEmpty(approvedCommentCount)) {
    const userCount = parseInt(get(user, "approvedCommentCount"));
    if (isNaN(userCount) || userCount < parseInt(approvedCommentCount)) {
      return false;
    }
  }

  if (!isEmpty(deletedCaseCount)) {
    const userCount = parseInt(get(user, "deletedCaseCount"));
    if (isNaN(userCount) || userCount > parseInt(deletedCaseCount)) {
      return false;
    }
  }

  if (!isEmpty(deletedCommentCount)) {
    const userCount = parseInt(get(user, "deletedCommentCount"));
    if (isNaN(userCount) || userCount > parseInt(deletedCommentCount)) {
      return false;
    }
  }

  if (!isEmpty(dateLastUploaded)) {
    if (
      !moment(dateLastUploaded).isBefore(moment(get(user, "dateLastUploaded")))
    ) {
      return false;
    }
  }

  if (!isEmpty(dateLastCommented)) {
    if (
      !moment(dateLastCommented).isBefore(
        moment(get(user, "dateLastCommented"))
      )
    ) {
      return false;
    }
  }

  return true;
};
