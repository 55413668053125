import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import {
  CaseAuthorMetaData,
  CaseMetaDataSection
} from "../../pages/moderation/components/CaseSections";

const CaseAuthorDetailsModal = (props) => {
  const { isOpen, toggle, activeCase, authorMetadata } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      <ModalBody className="px-4">
        <CaseMetaDataSection activeCase={activeCase} />
        <CaseAuthorMetaData authorMetadata={authorMetadata} />
      </ModalBody>
    </Modal>
  );
};

CaseAuthorDetailsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  activeUser: PropTypes.object
};

export default CaseAuthorDetailsModal;
