import {
  GET_USER_COMPLETE,
  GET_USER_START,
  SAVE_PROFILE_COMPLETE,
  SAVE_PROFILE_START,
  SET_ACTIVE_USER
} from "../actions/profile.actions";

const INITIAL_STATE = {
  processing: false,
  searching: false,
  isError: false,
  userSaving: false,
  message: null,
  activeUser: null,
  searchNoResults: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_START: {
      return {
        ...state,
        searching: true,
        savedSearchTerm: action.searchTerm || ""
      };
    }
    case GET_USER_COMPLETE: {
      return {
        ...state,
        searching: false,
        activeUser: action.user,
        searchNoResults: action.searchNoResults
      };
    }
    case SET_ACTIVE_USER: {
      return {
        ...state,
        activeUser: action.user
      };
    }
    case SAVE_PROFILE_START: {
      return {
        ...state,
        userSaving: true
      };
    }
    case SAVE_PROFILE_COMPLETE: {
      return {
        ...state,
        userSaving: false,
        userSaveError: action.userSaveError || false
      };
    }
    default:
      return state;
  }
};

export default reducer;
