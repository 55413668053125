import { CHECK_INITIALIZED, SET_AUTH_USER } from "../actions/auth.actions";

const INITIAL_STATE = {
  initialized: false,
  authUser: null,
  uid: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        uid: action.authUid,
        authUser: action.authUser,
        initialized: true
      };
    }
    case CHECK_INITIALIZED: {
      return {
        ...state,
        initialized: true
      };
    }
    default:
      return state;
  }
};

export default authReducer;
