import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { get } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "easymde/dist/easymde.min.css";
import { onInvalidSubmit } from "../../utils/form-utils";
import { saveCampaign } from "../../actions/campaigns.actions";
import { CAMPAIGN_MANAGE } from "../../constants/routes";
import { useHistory } from "react-router-dom";

const NewCampaignModal = (props) => {
  const { isOpen, toggle } = props;
  const [formData, setFormData] = useState({
    name: "",
    clientName: ""
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const saving = useSelector((state) => state.campaigns.saving);

  const onSubmit = () => {
    dispatch(saveCampaign(formData)).then((response) => {
      const newCampaignUuid = get(response, "campaignUuid");
      if (newCampaignUuid) {
        history.push(`${CAMPAIGN_MANAGE}/${newCampaignUuid}`);
      } else {
        toggle();
      }
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onOpened = () => {
    setFormData({
      name: "",
      clientName: "",
      previewUserUids: "",
      campaignPriority: "",
      isSponsored: ""
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        New Campaign
      </ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody className="pt-0">
          <AvField
            id="name"
            name="name"
            label="campaign name"
            placeholder="Enter Campaign name here"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.name || ""}
            onChange={onChange}
            required
          />
          <AvField
            id="clientName"
            name="clientName"
            label="client"
            placeholder="Enter Client name here"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.clientName || ""}
            onChange={onChange}
            required
          />
          {/*<AvField*/}
          {/*  id="previewUserUids"*/}
          {/*  name="previewUserUids"*/}
          {/*  label="preview user ids"*/}
          {/*  placeholder="Enter User Ids here"*/}
          {/*  type="text"*/}
          {/*  labelClass="text-11 text-uppercase font-weight-normal"*/}
          {/*  value={formData.previewUserUids || ""}*/}
          {/*  onChange={onChange}*/}
          {/*/>*/}
          <AvField
            name="campaignPriority"
            label="campaign priority level"
            placeholder="Enter priority level here"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.campaignPriority || ""}
            onChange={onChange}
            type="number"
            min="1"
            max="3"
            errorMessage={"Number between 1 and 3"}
          />
          <AvField
            id="isSponsored"
            name="isSponsored"
            labelClass="text-11 font-weight-normal"
            label="Sponsored or unsponsored content"
            value={formData.isSponsored || ""}
            onChange={onChange}
            type="select"
            required>
            <option value="" disabled>Select...</option>
            <option value={true}>Sponsored</option>
            <option value={false}>Unsponsored</option>
          </AvField>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-5 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>
          <LoadingButton
            outline
            color="primary"
            loading={saving}
            disabled={saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

NewCampaignModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

NewCampaignModal.defaultProps = {};

export default NewCampaignModal;
