import DB_CONSTANTS from "./db-constants";
import { getCollectionRef, getNewTimestamp } from "./firestore";
import moment from "moment";
import {
  isEmpty,
  map,
  get,
  isNil,
  defaultTo,
  head,
  uniq,
  reduce,
  compact,
  filter
} from "lodash";
import { LOCK_EXPIRE_TIME } from "../utils/case-utils";

export const loadProfileForUid = async (uid) => {
  try {
    const userRef = getCollectionRef(`${DB_CONSTANTS.USERS}`);
    const userDoc = await userRef.doc(uid).get();
    if (userDoc.exists) {
      return userDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("There was an error fetching the profile for uid: ", uid);
  }
};

export const createSignupUserDoc = async (
  userUid,
  email,
  firstName,
  lastName
) => {
  await getCollectionRef(`${DB_CONSTANTS.USERS}`)
    .doc(userUid)
    .set(
      {
        userUid,
        email,
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`
      },
      { merge: true }
    );
};

export const getUserDocByUid = async (uid) => {
  try {
    const userDoc = await getCollectionRef(`${DB_CONSTANTS.USERS}`)
      .doc(uid)
      .get();

    if (userDoc.exists) {
      return userDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error(
      "There was an error updating last seen for profile with uid: ",
      uid
    );
  }
};

export const getUserAndRoles = async (uid) => {
  const userPromise = getCollectionRef(`${DB_CONSTANTS.USERS}`)
    .doc(uid)
    .get();
  const userRolePromise = getCollectionRef(`${DB_CONSTANTS.USER_ROLES}`)
    .doc(uid)
    .get();

  const results = await Promise.all([userPromise, userRolePromise]);
  const userDoc = results[0];
  const userRolesDoc = results[1];

  return {
    user: userDoc.data(),
    userRoles: userRolesDoc.data()
  };
};

export const getUserByEmail = async (email) => {
  const querySnapshot = await getCollectionRef(DB_CONSTANTS.USERS)
    .where("email", "==", email)
    .get();

  if (querySnapshot.empty) {
    return null;
  }

  const userDoc = head(querySnapshot.docs);
  const user = userDoc.data();

  return {
    ...user,
    userUid: userDoc.id
  };
};

export const getAdminUsers = async () => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USER_ROLES}`);
  const snapshot = await ref.get();
  const allUsers = map(snapshot.docs, (d) => d.data());
  return filter(allUsers, (u) => !!u?.email?.includes('@figure1.com'));
};

export const saveUserRolesToDB = async (uid, roles) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USER_ROLES}`).doc(uid);

  try {
    await ref.set(roles, { merge: true });

    return true;
  } catch (error) {
    console.error("There was an error saving roles for uid: ", uid, error);

    return false;
  }
};

export const subscribeToUser = async (uid, listener) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USERS}`).doc(uid);

  return await ref.onSnapshot(listener, (onObservableNextOrError) => {
    console.error("subscribeToUser listener: ", onObservableNextOrError);
  });
};

export const subscribeToUserRoles = async (uid, listener) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.USER_ROLES}`).doc(uid);

  return await ref.onSnapshot(listener, (onObservableNextOrError) => {
    console.error("subscribeToUserRoles listener: ", onObservableNextOrError);
  });
};

export const subscribeToAdminUserCaseLocks = async (listener) => {
  const ref = getCollectionRef(`${DB_CONSTANTS.ADMIN_USER_CASE_LOCKS}`);

  console.log("Removing all previous locks that expired...");
  await removeExpiredLocks();

  return await ref.onSnapshot(listener, (onObservableNextOrError) => {
    console.error(
      "subscribeToAdminUserCaseLocks listener: ",
      onObservableNextOrError
    );
  });
};

export const lockCaseForUser = async (user, caseUuid) => {
  const uid = get(user, "userUid");
  const displayName = defaultTo(get(user, "displayName"), null);
  const firstName = defaultTo(get(user, "firstName"), null);
  if (
    isNil(uid) ||
    isNil(caseUuid) ||
    (isEmpty(displayName) && isEmpty(firstName))
  ) {
    return;
  }

  // Cleanup any previous locks that expired
  const querySnapshot = await getCollectionRef(
    DB_CONSTANTS.ADMIN_USER_CASE_LOCKS
  )
    .where("caseUuid", "==", caseUuid)
    .get();
  const updatePromises = map(querySnapshot.docs, (d) => {
    return d.ref.delete();
  });
  await Promise.all(updatePromises);

  const ref = getCollectionRef(`${DB_CONSTANTS.ADMIN_USER_CASE_LOCKS}`).doc(
    uid
  );

  await ref.set({
    uid,
    caseUuid,
    displayName,
    firstName,
    lockTime: getNewTimestamp()
  });
};

export const removeLockForUser = async (user) => {
  const uid = get(user, "userUid");
  if (isNil(uid)) {
    return;
  }

  await getCollectionRef(`${DB_CONSTANTS.ADMIN_USER_CASE_LOCKS}`)
    .doc(uid)
    .delete();
};

export const fetchUsersByUuids = async (userUuids = []) => {
  const results = await Promise.all(
    map(uniq(userUuids), (userUuid) =>
      getCollectionRef(`${DB_CONSTANTS.USERS}`)
        .where("userUuid", "==", userUuid)
        .get()
    )
  );

  const allUsers = reduce(
    results,
    (acc, user) => {
      return user.size > 0 && user.docs[0].get("userUuid")
        ? { ...acc, [user.docs[0].get("userUuid")]: user.docs[0].data() }
        : { ...acc };
    },
    {}
  );

  return allUsers;
};

const removeExpiredLocks = async () => {
  const querySnapshot = await getCollectionRef(
    DB_CONSTANTS.ADMIN_USER_CASE_LOCKS
  ).get();

  const updatePromises = compact(
    map(querySnapshot.docs, (d) => {
      const lock = d.data();
      if (lock && lock.lockTime) {
        const expireTime = lock.lockTime.seconds + LOCK_EXPIRE_TIME;
        const currentTime = moment().unix();

        if (currentTime < expireTime) {
          return null;
        }
      }

      return d.ref.delete();
    })
  );

  await Promise.all(updatePromises);
};

export const subscribeToAdminVerificationPriorityConfig = async (listener) => {
  return getCollectionRef(DB_CONSTANTS.ADMIN_CONFIGURATION)
    .doc(DB_CONSTANTS.ADMIN_PRIORITY_CONFIGURATION)
    .onSnapshot(
      (snapshot) => {
        listener(snapshot.exists ? snapshot.data() : {});
      },
      (error) => {
        console.log("Load admin config error", error);
      }
    );
};

export const saveAdminVerificationPriorityConfig = async (config) => {
  const ref = getCollectionRef(DB_CONSTANTS.ADMIN_CONFIGURATION).doc(
    DB_CONSTANTS.ADMIN_PRIORITY_CONFIGURATION
  );

  await ref.set(config, { merge: true });
};
