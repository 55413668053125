import React, { useState } from "react";
import {
  Alert,
  Button,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { rejectCase } from "../../actions/moderation.actions";
import { get, isEmpty, isEqual, map } from "lodash";
import LoadingButton from "../common/LoadingButton";

const RejectModal = (props) => {
  const { isOpen, toggle, caseDoc, refreshCases, rejectAllowed = true } = props;
  const [formData, setFormData] = useState({ reason: "" });
  const [secondStepActive, setSecondStepActive] = useState(false);

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.moderation.rejectedSaving);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const caseUuid = get(caseDoc, "caseUuid");

  const onReject = async () => {
    await dispatch(rejectCase(caseUuid, formData.reason, refreshCases));

    toggle();
  };

  const onOpened = () => {
    setFormData({ reason: "" });
    setSecondStepActive(false);
  };

  const deleteValues = {
    selfie: "Selfie",
    inappropriate_content: "Inappropriate content",
    suspected_homework: "Suspected homework",
    underage_nudity: "Underage nudity",
    tineye: "Tineye",
    delete_no_email: "Delete without sending an email"
  };

  const draftValues = {
    need_clinical_info: "Need more clinical info",
    not_direct_care: "Not in direct care",
    inappropriate_paging: "Inappropriate paging",
    non_clinical_photos: "Remove image",
    unsupported_language: "Unsupported language"
  };

  const isPermanentDelete = () => {
    return !isEmpty(deleteValues[formData.reason]);
  };

  const renderFirstStep = () => {
    return (
      <Form>
        <ModalBody className="pt-0 px-5">
          <div className="bordered-text font-weight-bolder">
            <div className="w-75">
              State the reason why you are deleting this case:
            </div>
          </div>
          <div className="py-4">
            {map(deleteValues, (value, k) => {
              return (
                <FormGroup key={k} className="mb-0">
                  <CustomInput
                    type="radio"
                    name="reason"
                    id={`delete-${k}`}
                    value={k}
                    checked={isEqual(formData.reason, k)}
                    onChange={onChange}
                    label={value}
                  />
                </FormGroup>
              );
            })}
            <div className="bordered-text">
              <Alert className="mt-3 mb-0" color="danger">
                Selecting one of these options will permanently delete the case.
              </Alert>
            </div>
          </div>

          {rejectAllowed && (
            <div className="pb-4">
              {map(draftValues, (value, k) => {
                return (
                  <FormGroup key={k} className="mb-0">
                    <CustomInput
                      type="radio"
                      name="reason"
                      id={`reject-${k}`}
                      value={k}
                      checked={isEqual(formData.reason, k)}
                      onChange={onChange}
                      label={value}
                    />
                  </FormGroup>
                );
              })}
              <div className="bordered-text">
                <Alert className="mt-3 mb-0" color="primary">
                  Selecting one of these options will send the case back to the
                  user's drafts.
                </Alert>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="border-0 justify-content-between px-5 pt-3 px-5 pb-5">
          <Button color="primary" outline onClick={toggle}>
            Go Back
          </Button>
          <Button
            color="primary"
            outline
            disabled={isEmpty(formData.reason)}
            onClick={() => setSecondStepActive(true)}>
            Next
          </Button>
        </ModalFooter>
      </Form>
    );
  };

  const renderSecondaryStep = () => {
    return (
      <>
        <ModalBody className="pt-0 px-5">
          <div className="bordered-text font-weight-bolder text-center">
            {isPermanentDelete()
              ? `"${
                  deleteValues[formData.reason]
                }" will permanently delete this case from the database. Proceed?`
              : `Are you sure you want to select "${
                  draftValues[formData.reason]
                }"? Yes will send it back to user’s drafts.`}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-between pt-3 px-5 pb-5">
          <Button
            color="primary"
            outline
            onClick={() => setSecondStepActive(false)}>
            Go Back
          </Button>

          <LoadingButton
            color="primary"
            outline
            onClick={onReject}
            loading={saving}>
            Yes
          </LoadingButton>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onOpened={onOpened}
      className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      {secondStepActive && renderSecondaryStep()}
      {!secondStepActive && renderFirstStep()}
    </Modal>
  );
};

RejectModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object,
  refreshCases: PropTypes.func,
  rejectAllowed: PropTypes.bool
};

export default RejectModal;
