import React, { useState } from "react";
import { Button, Collapse, FormGroup, Label } from "reactstrap";
import { get, isEmpty } from "lodash";
import { AvField } from "availity-reactstrap-validation";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import PropTypes from "prop-types";
import "easymde/dist/easymde.min.css";
import RowToggler from "../../../components/common/RowToggler";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters
} from "../../../utils/form-utils";
import { handleSwitchChange } from "../../../utils/campaign-utils";
import CampaignTacticDateModal from "../../../components/modals/CampaignTacticDateModal";
import CampaignAuthorSearchModal from "./CampaignAuthorSearchModal";
import { getDisplayName } from "../../../utils/verification-utils";

export const CampaignTacticSettings = (props) => {
  const {
    formData,
    onChange,
    includeSeriesField = false,
    seriesFieldLabel = "Quiz multi banner",
    includeIsiField = true,
    includeCMECreditField = false,
    showDateFields = false,
    showToggles = true,
    showAuthorFields = true,
    showSponsoredFields = true
  } = props;

  const caseUuid = getFieldValue(formData, "caseUuid");
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);

  const [timelineModalOpen, setTimelineModalOpen] = useState(false);
  const toggleTimelineModal = () => setTimelineModalOpen(!timelineModalOpen);

  const [authorModalOpen, setAuthorModalOpen] = useState(false);
  const toggleAuthorModal = () => setAuthorModalOpen(!authorModalOpen);

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() =>
          handleSwitchChange(onChange, fieldName, !value, formData)
        }
      />
    );
  };

  const selectAuthor = (user) => {
    const authorUid = get(user, "userUid");
    const authorName = getDisplayName(user);
    if (!isEmpty(authorUid) && !isEmpty(authorName)) {
      onChange({ target: { name: "author_uid", value: authorUid } });
      onChange({ target: { name: "authorName", value: authorName } });
    }
  };

  return (
    <div className="bg-white p-3">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">Tactic Settings</div>
        <RowToggler toggle={toggleCollapse} collapse={collapse} large={true} />
      </div>
      <Collapse isOpen={collapse} className="pt-2 slide-body">
        <div className="d-flex">
          <div className="flex-fill mr-3">
            <AvField
              name={getFieldName("settings.name")}
              value={getFieldValue(formData, "settings.name")}
              label="Tactic name"
              placeholder="Enter tactic name here"
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
          </div>
          <div className="w-25">
            <AvField
              name={getFieldName("settings.tactic_priority")}
              value={getFieldValue(
                formData,
                "settings.tactic_priority",
                null,
                1
              )}
              label="Tactic priority"
              placeholder="Enter priority number"
              type="number"
              min="1"
              max="10"
              errorMessage={"Number between 1 and 10"}
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
          </div>
        </div>

        {(!isEmpty(caseUuid) || showDateFields) && (
          <div className="d-flex flex-wrap">
            <FormGroup className="mr-3">
              <Label className="text-11 text-uppercase font-weight-normal">
                Tactic start date
              </Label>
              <div>
                {getFieldValue(formData, "settings.start_date") || "N/A"}
              </div>
            </FormGroup>
            <FormGroup className="mx-3">
              <Label className="text-11 text-uppercase font-weight-normal">
                Tactic end date
              </Label>
              <div>{getFieldValue(formData, "settings.end_date") || "N/A"}</div>
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <Button
                size="md"
                color="primary-dark"
                className="ml-3"
                onClick={toggleTimelineModal}>
                Edit Tactic Timeline
              </Button>
            </FormGroup>

            <CampaignTacticDateModal
              isOpen={timelineModalOpen}
              toggle={toggleTimelineModal}
              caseUuid={caseUuid}
              startDate={getFieldValue(formData, "settings.start_date")}
              endDate={getFieldValue(formData, "settings.end_date")}
              onChangeTacticSettingsFormData={onChange}
            />
          </div>
        )}

        {showToggles && (
          <div className="d-flex flex-wrap">
            <FormGroup className="w-25">
              <Label className="text-11 text-uppercase font-weight-normal">
                commenting
              </Label>
              {renderToggleSwitch("features.comments_enabled")}
            </FormGroup>
            <FormGroup className="w-25">
              <Label className="text-11 text-uppercase font-weight-normal">
                comment queue
              </Label>
              {renderToggleSwitch("features.comment_queue_enabled")}
            </FormGroup>
            <FormGroup className="w-25">
              <Label className="text-11 text-uppercase font-weight-normal">
                saving
              </Label>
              {renderToggleSwitch("features.save_enabled")}
            </FormGroup>
            <FormGroup className="w-25">
              <Label className="text-11 text-uppercase font-weight-normal">
                sharing
              </Label>
              {renderToggleSwitch("features.share_enabled")}
            </FormGroup>
            <FormGroup className="w-25">
              <Label className="text-11 text-uppercase font-weight-normal">
                reactions
              </Label>
              {renderToggleSwitch("features.reactions_enabled")}
            </FormGroup>
            {includeIsiField && (
              <FormGroup className="w-25">
                <Label className="text-11 text-uppercase font-weight-normal">
                  isi
                </Label>
                {renderToggleSwitch("features.isi_enabled")}
              </FormGroup>
            )}
          </div>
        )}

        {showAuthorFields && (
          <div className="d-flex flex-wrap">
            <FormGroup className="mr-3">
              <Label className="text-11 text-uppercase font-weight-normal">
                Author attribution
              </Label>
              <div>
                {getFieldValue(formData, "authorName") || ""}{" "}
                <span className="text-12 text-secondary">
                  ({getFieldValue(formData, "author_uid") || "N/A"})
                </span>
              </div>
            </FormGroup>
            <FormGroup className="d-flex align-items-center">
              <Button
                size="md"
                color="primary-dark"
                className="ml-3"
                onClick={toggleAuthorModal}>
                Edit Author
              </Button>
            </FormGroup>

            <CampaignAuthorSearchModal
              isOpen={authorModalOpen}
              toggle={toggleAuthorModal}
              selectUser={selectAuthor}
            />
          </div>
        )}

        {showSponsoredFields && (
          <>
            <AvField
              name={getFieldName("sponsored_content.sponsored_text")}
              value={getFieldValue(
                formData,
                "sponsored_content.sponsored_text"
              )}
              label="sponsored or promoted text"
              placeholder="Enter sponsored text here..."
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "sponsored_content.sponsored_text"),
                25
              )}
              onChange={onChange}
            />
            <AvField
              name={getFieldName("sponsored_content.disclosure_text")}
              value={getFieldValue(
                formData,
                "sponsored_content.disclosure_text"
              )}
              label="tooltip disclosure"
              placeholder="Enter disclosure here..."
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "sponsored_content.disclosure_text"),
                98
              )}
              onChange={onChange}
            />
          </>
        )}

        {includeSeriesField && (
          <AvField
            name={getFieldName("heading")}
            value={getFieldValue(formData, "heading")}
            label={seriesFieldLabel}
            placeholder="Enter banner text here..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            helpMessage={getFieldValueCharacters(
              getFieldValue(formData, "heading"),
              30
            )}
            onChange={onChange}
            required
          />
        )}

        {includeCMECreditField && (
          <AvField
            name={getFieldName("cme1_credits")}
            value={getFieldValue(formData, "cme1_credits")}
            label="CME Credits"
            placeholder="Enter value number"
            type="number"
            min="0"
            step="0.25"
            errorMessage={"Credits must be 0 or greater"}
            labelClass="text-11 text-uppercase font-weight-normal"
            groupAttrs={{ className: "w-25" }}
            onChange={onChange}
            required
          />
        )}

        {props.children}
      </Collapse>
    </div>
  );
};

CampaignTacticSettings.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  includeSeriesField: PropTypes.bool,
  seriesFieldLabel: PropTypes.string,
  includeIsiField: PropTypes.bool
};
