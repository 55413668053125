import { isEmpty, isNil, isString, omitBy } from "lodash";
import {
  caseSearchCall,
  labelsSaveCall,
  tagSaveCall,
  tagStateChangeCaseCall
} from "../api/cloud-functions";
import { CASE_REFRESH } from "./cases.actions";
import {
  getCasesRefreshPage,
  getCasesSortDirection
} from "../utils/case-utils";
import { showGlobalMessage } from "./global.actions";

const actionsPrefix = "tagging";

export const GET_CASES_START = `${actionsPrefix}/GET_CASES_START`;
export const GET_CASES_COMPLETE = `${actionsPrefix}/GET_CASES_COMPLETE`;
export const UPDATE_SEARCH_TEXT = `${actionsPrefix}/UPDATE_SEARCH_TEXT`;
export const UPDATE_STATE_FILTER = `${actionsPrefix}/UPDATE_STATE_FILTER`;
export const UPDATE_CASE_FILTERS = `${actionsPrefix}/UPDATE_CASE_FILTERS`;
export const UPDATE_SORT_DIRECTION = `${actionsPrefix}/UPDATE_SORT_DIRECTION`;
export const SET_ACTIVE_CASE = `${actionsPrefix}/SET_ACTIVE_CASE`;
export const SAVE_SPECIALTY_TAGS_START = `${actionsPrefix}/SAVE_SPECIALTY_TAGS_START`;
export const SAVE_SPECIALTY_TAGS_COMPLETE = `${actionsPrefix}/SAVE_SPECIALTY_TAGS_COMPLETE`;
export const SAVE_MESH_TAGS_START = `${actionsPrefix}/SAVE_MESH_TAGS_START`;
export const SAVE_MESH_TAGS_COMPLETE = `${actionsPrefix}/SAVE_MESH_TAGS_COMPLETE`;
export const SAVE_LABEL_START = `${actionsPrefix}/SAVE_LABEL_START`;
export const SAVE_LABEL_COMPLETE = `${actionsPrefix}/SAVE_LABEL_COMPLETE`;
export const APPROVE_START = `${actionsPrefix}/APPROVE_START`;
export const APPROVE_COMPLETE = `${actionsPrefix}/APPROVE_COMPLETE`;
export const FLAG_START = `${actionsPrefix}/FLAG_START`;
export const FLAG_COMPLETE = `${actionsPrefix}/FLAG_COMPLETE`;
export const REJECT_START = `${actionsPrefix}/REJECT_START`;
export const REJECT_COMPLETE = `${actionsPrefix}/REJECT_COMPLETE`;
export const FORCE_START = `${actionsPrefix}/FORCE_START`;
export const FORCE_COMPLETE = `${actionsPrefix}/FORCE_COMPLETE`;

const STATE_CHANGE_ERROR_TITLE = "State Change Error";
const STATE_CHANGE_ERROR_MESSAGE =
  "The attempt to transition the case state failed.  Please try again.";

export const getCases = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_CASES_START
    });

    const filters = omitBy(
      {
        ...getState().tagging.filters,
        stateFilter: getState().tagging.stateFilter,
        searchTextFilter: getState().tagging.searchTextFilter
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const stateSortDirections = getState().tagging.stateSortDirections;
    const sortDirection = getCasesSortDirection(
      filters.stateFilter,
      stateSortDirections
    );
    const pageSize = getState().tagging.pageSize;
    const currentPage = !isNil(page)
      ? page
      : getCasesRefreshPage(
          getState().tagging.currentPage,
          getState().tagging.cases
        );

    try {
      const results = await caseSearchCall(
        filters,
        currentPage,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_CASES_COMPLETE,
        results,
        page: currentPage,
        resetCaseIndex: isNil(page)
      });
    } catch (error) {
      console.log("Error retrieving cases", error);
      dispatch({
        type: GET_CASES_COMPLETE,
        error: error
      });
    }
  };
};

export const searchCasesByString = (searchTextFilter, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SEARCH_TEXT,
      searchTextFilter
    });

    dispatch(getCases(page));
  };
};

export const getCasesByState = (stateFilter, page) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_STATE_FILTER,
      stateFilter
    });

    dispatch(getCases(page));
  };
};

export const filterCases = (filters, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_CASE_FILTERS,
      filters
    });

    dispatch(getCases(page));
  };
};

export const setCasesSortDirection = (sortDirection) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SORT_DIRECTION,
      sortDirection
    });

    dispatch(getCases(0));
  };
};

export const setActiveCase = (activeCase) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ACTIVE_CASE,
      activeCase
    });
  };
};

export const saveSpecialtyTags = (
  caseUuid,
  addedSpecialties,
  removedSpecialties
) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_SPECIALTY_TAGS_START
    });

    try {
      const response = await tagSaveCall(
        "specialties",
        caseUuid,
        addedSpecialties,
        removedSpecialties
      );

      dispatch({
        type: CASE_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the speciality tag.  Please try again.",
          "Save Tag Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: SAVE_SPECIALTY_TAGS_COMPLETE
    });
  };
};

export const saveMeshTags = (caseUuid, addedTerms, removedTerms) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_MESH_TAGS_START
    });

    try {
      const response = await tagSaveCall(
        "mesh",
        caseUuid,
        addedTerms,
        removedTerms
      );

      dispatch({
        type: CASE_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the mesh tag.  Please try again.",
          "Save Tag Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: SAVE_MESH_TAGS_COMPLETE
    });
  };
};

export const saveLabels = (caseUuid, labelUuids) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_LABEL_START
    });

    try {
      const response = await labelsSaveCall(caseUuid, labelUuids);

      dispatch({
        type: CASE_REFRESH,
        response
      });
    } catch (error) {
      console.log(error);
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the case labels.  Please try again.",
          "Save Label Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: SAVE_LABEL_COMPLETE
    });
  };
};

export const approveCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: APPROVE_START
    });

    try {
      await tagStateChangeCaseCall(caseUuid, "mesh/approve");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: APPROVE_COMPLETE
    });
  };
};

export const flagCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: FLAG_START
    });

    try {
      await tagStateChangeCaseCall(caseUuid, "flag");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: FLAG_COMPLETE
    });
  };
};

export const rejectCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: REJECT_START
    });

    try {
      await tagStateChangeCaseCall(caseUuid, "reject");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: REJECT_COMPLETE
    });
  };
};

export const forceCaseToManual = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: FORCE_START
    });

    try {
      await tagStateChangeCaseCall(caseUuid, "state/update");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: FORCE_COMPLETE
    });
  };
};
