import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  CustomInput,
  FormGroup,
  Label
} from "reactstrap";
import PropTypes from "prop-types";
import {
  compact,
  concat,
  defaultTo,
  filter,
  find,
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  join,
  map,
  size,
  sortBy
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faExclamationTriangle,
  faFlag,
  faImages,
  faLock,
  faPlusCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import RejectModal from "../../../components/modals/RejectModal";
import ImageDisplayModal from "../../../components/modals/ImageDisplayModal";
import {
  approveCase,
  approveCaseImmediately,
  editCaseLanguage,
  flagCase,
  partnerCaseStateChange,
  reportCase,
  reviewEditCase,
  submitSuggestionCase
} from "../../../actions/moderation.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  CASE_FILTERS,
  formatSuggestedEditMedia,
  getBadgeCssClass,
  hasSuggestedEditsToSubmit,
  isCaseApproved,
  isCaseReported,
  isEditActive,
  isFlaggedActive,
  isModerationApprovalActive,
  isModeratorEdit,
  isPartnerCaseActive,
  isRejectAllowed,
  isRejectedActive,
  isSuggestionApprovalAllowed,
  SUGGESTED_EDIT_TYPES,
  updateMediaUrlsFromSuggestion
} from "../../../utils/case-utils";
import { getDisplayName } from "../../../utils/verification-utils";
import TineyeModal from "../../../components/modals/TineyeModal";
import MediaViewerModal from "../../../components/modals/MediaViewerModal";
import NotesViewerModal from "../../../components/modals/NotesViewerModal";
import CaptionEditModal from "../../../components/modals/CaptionEditModal";
import ProfileImage from "../../../components/common/ProfileImage";
import {
  getAvatarUrl,
  getImageUrl,
  getOriginalUrl
} from "../../../utils/media-utils";
import TaggingFilterModal from "../../../components/modals/TaggingFilterModal";
import moment from "moment";
import CaseCommentsViewerModal from "../../../components/modals/CaseCommentsViewerModal";
import CaseAuthorDetailsModal from "../../../components/modals/CaseAuthorDetailsModal";
import Icon from "../../../components/common/icon";
import { isModeratorManager } from "../../../utils/permission-utils";
import LoadingButton from "../../../components/common/LoadingButton";
import PartnerCaseBadgeLinkModal from "../../../components/modals/PartnerCaseBadgeLinkModal";
import CasePagingBanner from "./CasePagingBanner";

export const CaseActionButtons = (props) => {
  const { activeCase, refreshCases, caseLock, classes } = props;
  const dispatch = useDispatch();
  const moderatorUid = useSelector((state) => state.user.uid);
  const userRoles = useSelector((state) => state.user.userRoles);
  const moderationManager = isModeratorManager(userRoles);
  const approvedSaving = useSelector(
    (state) => state.moderation.approvedSaving
  );
  const approveImmediateSaving = useSelector(
    (state) => state.moderation.approveImmediateSaving
  );
  const flaggedSaving = useSelector((state) => state.moderation.flaggedSaving);
  const suggestSaving = useSelector((state) => state.moderation.suggestSaving);
  const reportedSaving = useSelector(
    (state) => state.moderation.reportedSaving
  );
  const [approveImmediateModal, setApproveImmediateModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [suggestModal, setSuggestModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const toggleApproveImmediateModal = () =>
    setApproveImmediateModal(!approveImmediateModal);
  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleFlagModal = () => setFlagModal(!flagModal);
  const toggleSuggestModal = () => setSuggestModal(!suggestModal);
  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleReportModal = () => setReportModal(!reportModal);

  const caseUuid = get(activeCase, "caseUuid");
  const caseState = get(activeCase, "caseState");
  const suggestedEditToSubmit =
    !moderationManager && hasSuggestedEditsToSubmit(activeCase, moderatorUid);

  const onApproveImmediate = async () => {
    await dispatch(approveCaseImmediately(caseUuid, refreshCases));
    toggleApproveImmediateModal();
  };

  const onApprove = async () => {
    await dispatch(approveCase(caseUuid, refreshCases));
    toggleApproveModal();
  };

  const onFlag = async () => {
    await dispatch(flagCase(caseUuid, refreshCases));
    toggleFlagModal();
  };

  const onReport = async () => {
    await dispatch(reportCase(caseUuid, refreshCases));
    toggleReportModal();
  };

  const onSuggest = async () => {
    await dispatch(submitSuggestionCase(caseUuid, refreshCases));
    toggleSuggestModal();
  };

  const renderDefaultButtons = () => {
    return (
      <>
        <Button
          title={"Move case to 'Pending Tagging'"}
          className="btn-round"
          color="approved"
          disabled={!isNil(caseLock) || !isModerationApprovalActive(caseState)}
          onClick={toggleApproveModal}>
          <Icon
            icon={"verified-checkmark-t15"}
            size={"large"}
            color={"white"}
          />
        </Button>
        {!isCaseApproved(caseState) && (
          <Button
            title={"Move case to 'Flagged'"}
            className="btn-round mx-3"
            color="flagged"
            disabled={!isNil(caseLock) || !isFlaggedActive(caseState)}
            onClick={toggleFlagModal}>
            <FontAwesomeIcon icon={faFlag} color="white" />
          </Button>
        )}
        {isCaseApproved(caseState) && (
          <Button
            title={"Move case to 'Reported'"}
            className="btn-round mx-3"
            color="reported"
            disabled={!isNil(caseLock)}
            onClick={toggleReportModal}>
            <FontAwesomeIcon icon={faExclamationTriangle} color="white" />
          </Button>
        )}
        <Button
          title={"Move case to 'Rejected'"}
          className="btn-round"
          color="removed"
          disabled={!isNil(caseLock) || !isRejectedActive(caseState)}
          onClick={toggleRejectModal}>
          <FontAwesomeIcon icon={faTimes} size={"lg"} color="white" />
        </Button>
      </>
    );
  };

  const renderReportedButtons = () => {
    return (
      <>
        <Button
          title={"Move case to 'Approved' and skip tagging"}
          className="btn-round"
          color="approved"
          disabled={!isNil(caseLock)}
          onClick={toggleApproveImmediateModal}>
          <Icon
            icon={"verified-checkmark-t15"}
            size={"large"}
            color={"white"}
          />
        </Button>
        <Button
          title={"Approve and send to tagging"}
          className="btn-round mx-3"
          color="pending"
          disabled={!isNil(caseLock)}
          onClick={toggleApproveModal}>
          <FontAwesomeIcon icon={faArrowRight} color="white" />
        </Button>
        <Button
          title={"Move case to 'Rejected'"}
          className="btn-round"
          color="removed"
          disabled={!isNil(caseLock)}
          onClick={toggleRejectModal}>
          <FontAwesomeIcon icon={faTimes} size={"lg"} color="white" />
        </Button>
      </>
    );
  };

  return (
    <Card className={`my-3 action-button-card ${classes}`}>
      <CardBody className="px-2 d-flex justify-content-center">
        {!suggestedEditToSubmit &&
          !isCaseReported(caseState) &&
          renderDefaultButtons()}
        {!suggestedEditToSubmit &&
          isCaseReported(caseState) &&
          renderReportedButtons()}

        {suggestedEditToSubmit && (
          <Button
            color="primary"
            outline
            size="sm"
            onClick={toggleSuggestModal}>
            Submit Suggested Edit
          </Button>
        )}
      </CardBody>
      <ConfirmModal
        toggle={toggleApproveImmediateModal}
        isOpen={approveImmediateModal}
        message="This case will be moved to the 'Approved' state and skip re-tagging. Proceed?"
        onConfirm={onApproveImmediate}
        saving={approveImmediateSaving}
      />
      <ConfirmModal
        toggle={toggleApproveModal}
        isOpen={approveModal}
        message="This case will be moved to the 'Pending Tagging' state. Proceed?"
        onConfirm={onApprove}
        saving={approvedSaving}
      />
      <ConfirmModal
        toggle={toggleFlagModal}
        isOpen={flagModal}
        message="This case will be moved to the 'Flagged' state. Proceed?"
        onConfirm={onFlag}
        saving={flaggedSaving}
      />
      <ConfirmModal
        toggle={toggleReportModal}
        isOpen={reportModal}
        message="This case will be moved to the 'Reported' state. Proceed?"
        onConfirm={onReport}
        saving={reportedSaving}
      />
      <ConfirmModal
        toggle={toggleSuggestModal}
        isOpen={suggestModal}
        message="This case will be moved to the 'Suggested Edits' queue where you will no longer have access
        to view it.  Are you ready to proceed?"
        onConfirm={onSuggest}
        saving={suggestSaving}
      />
      <RejectModal
        toggle={toggleRejectModal}
        isOpen={rejectModal}
        caseDoc={activeCase}
        refreshCases={refreshCases}
        rejectAllowed={isRejectAllowed(caseState)}
      />
    </Card>
  );
};

CaseActionButtons.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  refreshCases: PropTypes.func
};

export const CaseMetaDataSection = (props) => {
  const { activeCase } = props;

  return (
    <Card className="my-3">
      <CardBody>
        {map(
          [
            { key: "caseUuid", label: "case uuid" },
            { key: "caseState", label: "case state" },
            { key: "createdAt", label: "created", type: "date" },
            { key: "updatedAt", label: "last updated", type: "date" }
          ],
          (v) => {
            const value = get(activeCase, v.key);
            const displayValue =
              v.type === "date"
                ? moment(value).format("MMM DD, YYYY [at] hh:mm A")
                : value;
            return (
              <div key={`meta-${v.key}`} className="mb-2">
                <div className="text-11 font-weight-bold text-uppercase">
                  {v.label}
                </div>
                <div className="text-secondary text-13">{displayValue}</div>
              </div>
            );
          }
        )}
      </CardBody>
    </Card>
  );
};

CaseMetaDataSection.propTypes = {
  activeCase: PropTypes.object
};

export const CaseAuthorMetaData = (props) => {
  const { authorMetadata } = props;

  return (
    <Card className="my-3 bg-primary author-meta-detail">
      <CardBody className="text-white">
        <div className="d-flex align-items-center justify-content-md-between">
          <div className="w-25 text-right meta-count">
            {get(authorMetadata, CASE_FILTERS.APPROVED.statsKey, 0)}
          </div>
          <span className="w-25 text-center"> | </span>
          <span className="flex-grow-1 meta-label">Approved</span>
        </div>
        <div className="d-flex align-items-center justify-content-md-between">
          <div className="w-25 text-right meta-count">
            {get(authorMetadata, CASE_FILTERS.REJECTED.statsKey, 0)}
          </div>
          <span className="w-25 text-center"> | </span>
          <span className="flex-grow-1 meta-label">Rejected</span>
        </div>
        <div className="d-flex align-items-center justify-content-md-between">
          <div className="w-25 text-right meta-count">
            {get(authorMetadata, CASE_FILTERS.FLAGGED.statsKey, 0)}
          </div>
          <span className="w-25 text-center"> | </span>
          <span className="flex-grow-1 meta-label">Flagged</span>
        </div>
        <div className="d-flex align-items-center justify-content-md-between">
          <div className="w-25 text-right meta-count">
            {get(authorMetadata, CASE_FILTERS.QUEUE.statsKey, 0)}
          </div>
          <span className="w-25 text-center"> | </span>
          <span className="flex-grow-1 meta-label">Queued</span>
        </div>
      </CardBody>
    </Card>
  );
};

CaseAuthorMetaData.propTypes = {
  authorMetadata: PropTypes.object
};

export const CaseAuthorDetailSmall = (props) => {
  const { activeCase, author, authorMetadata } = props;
  const [caseAuthorDetailsModal, setCaseAuthorDetailsModal] = useState(false);
  const toggleCaseAuthorDetailsModal = () =>
    setCaseAuthorDetailsModal(!caseAuthorDetailsModal);

  return (
    <>
      <CaseAuthorDetailShort
        classes="d-sm-inline-flex d-md-none"
        author={author}
        button={
          <Button
            className="pl-2 text-right flex-grow-1"
            color="link"
            onClick={toggleCaseAuthorDetailsModal}>
            More info
          </Button>
        }
      />
      <CaseAuthorDetailsModal
        activeCase={activeCase}
        authorMetaData={authorMetadata}
        toggle={toggleCaseAuthorDetailsModal}
        isOpen={caseAuthorDetailsModal}
      />
    </>
  );
};

export const PartnerCaseButtons = (props) => {
  const { author, activeCase, refreshCases } = props;

  const dispatch = useDispatch();
  const partnerCaseSaving = useSelector(
    (state) => state.moderation.partnerCaseSaving
  );

  const [badgeModal, setBadgeModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);

  const toggleBadgeModal = () => setBadgeModal(!badgeModal);
  const toggleArchiveModal = () => setArchiveModal(!archiveModal);
  const togglePublishModal = () => setPublishModal(!publishModal);

  const isPartner = get(author, "isPartner");
  const caseState = get(activeCase, "caseState");
  const caseUuid = get(activeCase, "caseUuid");
  const partnerCaseActive = isPartnerCaseActive(caseState);

  if (!isPartner) {
    return null;
  }
  const onPublishCase = async () => {
    await dispatch(partnerCaseStateChange(caseUuid, "approved", refreshCases));
    togglePublishModal();
  };

  const onArchiveCase = async () => {
    await dispatch(partnerCaseStateChange(caseUuid, "archived", refreshCases));
    toggleArchiveModal();
  };

  return (
    <div className="my-4">
      <div className="d-flex justify-content-center mb-3">
        <LoadingButton
          color={"primary-dark"}
          className="btn-lg w-100"
          onClick={toggleBadgeModal}
          type="button">
          Add badge or link
        </LoadingButton>
      </div>
      <div className="d-flex justify-content-center">
        <LoadingButton
          color={"secondary"}
          className="btn-lg w-100"
          onClick={partnerCaseActive ? toggleArchiveModal : togglePublishModal}
          type="button">
          {partnerCaseActive ? "Archive case" : "Publish case"}
        </LoadingButton>
      </div>

      <ConfirmModal
        toggle={toggleArchiveModal}
        isOpen={archiveModal}
        message="Are you sure you want to archive this partner case?"
        onConfirm={onArchiveCase}
        saving={partnerCaseSaving}
      />
      <ConfirmModal
        toggle={togglePublishModal}
        isOpen={publishModal}
        message="Are you sure you want to publish this partner case?"
        onConfirm={onPublishCase}
        saving={partnerCaseSaving}
      />
      <PartnerCaseBadgeLinkModal
        toggle={toggleBadgeModal}
        isOpen={badgeModal}
        caseDoc={activeCase}
        author={author}
      />
    </div>
  );
};

PartnerCaseButtons.propTypes = {
  author: PropTypes.object,
  activeCase: PropTypes.object
};

export const CaseLanguage = (props) => {
  const { activeCase, caseLock } = props;

  const dispatch = useDispatch();
  const [language, setLanguage] = useState("");
  const locales = useSelector((state) => state.reference.sortedLocales);
  const caseUuid = get(activeCase, "caseUuid");
  const currentLanguage = get(activeCase, "language");

  useEffect(() => {
    if (activeCase) {
      setLanguage(currentLanguage);
    }
  }, [activeCase, currentLanguage]);

  const onChange = async (event) => {
    const { value } = event.target;

    setLanguage(value);
    dispatch(editCaseLanguage(caseUuid, value));
  };

  return (
    <Card className="my-2">
      <CardBody className="pt-2 pb-3 px-3">
        <div>
          <Label
            className="text-11 text-uppercase font-weight-bold mb-2"
            for="caption">
            case language
          </Label>

          {map(locales, (v, idx) => {
            return (
              <FormGroup key={`lang-${idx}`} className="pl-0 mb-0">
                <CustomInput
                  type="radio"
                  name="language"
                  id={`lang-${v.code}`}
                  value={v.code}
                  checked={isEqual(language, v.code)}
                  onChange={onChange}
                  label={v.languageShortName}
                  disabled={!isNil(caseLock)}
                />
              </FormGroup>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

CaseLanguage.propTypes = {
  activeCase: PropTypes.object
};

export const CaseAuthorDetailShort = (props) => {
  const { author, classes, button } = props;

  const firstName = get(author, "firstName");
  const profession = get(author, "tree.profession.professionName");
  const specialty = get(author, "tree.specialty.specialtyName");
  const username = get(author, "username");
  const isPartner = get(author, "isPartner");
  const avatar = get(author, "avatar");
  const tempVerified = Boolean(get(author, "flagForReview", false));

  return (
    <Card className={`case-author-detail my-2 ${classes}`}>
      {isPartner && <div className="partner-case-header">Special Account</div>}
      <CardBody className="py-2 px-0 d-inline-flex align-items-start">
        {isPartner && avatar && (
          <div className="p-2">
            <img alt={`${firstName}'s avatar'`} src={getAvatarUrl(avatar)} />
          </div>
        )}
        {!isPartner && (
          <div className="p-2">
            <ProfileImage username={firstName} tempVerified={tempVerified} />
          </div>
        )}
        <div>
          <CardTitle className="my-0 px-2">{getDisplayName(author)}</CardTitle>
          <CardSubtitle className="px-2 pt-1 text-secondary">
            @{username}
          </CardSubtitle>
          <CardTitle className="my-0 px-2">
            <div className="text-secondary">{profession}</div>
            <div className="text-secondary">{specialty}</div>
          </CardTitle>
        </div>
        {button}
      </CardBody>
    </Card>
  );
};

export const CaseAuthorDetail = (props) => {
  const { author } = props;

  const countries = useSelector((state) => state.reference.countries);
  const states = useSelector((state) => state.reference.states);

  const isPartner = get(author, "isPartner");
  const firstName = get(author, "firstName");
  const username = get(author, "username");
  const practiceHospital = get(author, "practiceHospital");
  const profession = get(author, "tree.profession.professionName");
  const specialty = get(author, "tree.specialty.specialtyName");
  const subSpecialty = get(author, "tree.subspecialty.specialtyName");
  const avatar = get(author, "avatar");
  const countryUuid = get(author, "countryUuid");
  const country = find(countries, (c) => isEqual(c.uuid, countryUuid));
  const stateUuid = get(author, "stateUuid");
  const state = find(states, (s) => isEqual(s.uuid, stateUuid));

  if (!author) {
    return null;
  }

  const specialties = compact(concat(specialty, subSpecialty));
  const locations = compact(concat(get(state, "name"), get(country, "name")));

  return (
    <Card className="case-author-detail my-2">
      {isPartner && <div className="partner-case-header">Special Account</div>}
      <CardBody className="py-2 px-0">
        {!isPartner && (
          <div className="p-2">
            <ProfileImage username={firstName} />
          </div>
        )}
        {isPartner && avatar && (
          <div className="p-2">
            <img alt={`${firstName}'s avatar'`} src={getAvatarUrl(avatar)} />
          </div>
        )}

        <div className="px-2">
          <div className={`mb-2 border-bottom`}>
            <div className="my-0 text-14" style={{ lineHeight: "1.1rem" }}>
              {getDisplayName(author)}
            </div>
            {username && (
              <div className={`text-secondary text-13 pb-2`}>@{username}</div>
            )}
          </div>

          <div className={`text-secondary text-13`}>{profession}</div>
          {!isEmpty(specialties) && (
            <div className="text-13">{join(specialties, " | ")}</div>
          )}

          {!isEmpty(practiceHospital) && (
            <div className="pt-2 text-13 text-secondary">
              {practiceHospital}
            </div>
          )}
          {!isEmpty(locations) && (
            <div className="pt-2 text-13">{join(locations, ", ")}</div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

CaseAuthorDetail.propTypes = {
  author: PropTypes.object
};

export const CaseDetailBody = (props) => {
  const { activeCase, refreshCases, caseLock, author } = props;

  const dispatch = useDispatch();
  const moderatorUid = useSelector((state) => state.user.uid);
  const reviewEditSaving = useSelector(
    (state) => state.moderation.reviewEditSaving
  );
  const [tineyeModal, setTineyeModal] = useState(false);
  const [mediaModal, setMediaModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [captionModal, setCaptionModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [imageDisplayModal, setImageDisplayModal] = useState(false);
  const [currentImageDisplayUrl, setCurrentImageDisplayUrl] = useState(null);
  const confirmEditUuid = useRef();
  const confirmMedia = useRef();

  const toggleTineyeModal = () => setTineyeModal(!tineyeModal);
  const toggleMediaModal = () => setMediaModal(!mediaModal);
  const toggleNotesModal = () => setNotesModal(!notesModal);
  const toggleCaptionModal = () => setCaptionModal(!captionModal);
  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleCommentsModal = () => setCommentsModal(!commentsModal);
  const toggleImageDisplayModal = () =>
    setImageDisplayModal(!imageDisplayModal);

  const caseUuid = get(activeCase, "caseUuid");
  const title = get(activeCase, "title");
  const caption = get(activeCase, "caption");
  const caseState = get(activeCase, "caseState");
  const originalMedia = sortBy(get(activeCase, "media", []), "displayOrder");
  const isPartner = get(author, "isPartner");
  const partnerLinkText = get(activeCase, "contentItems[0].externalLinkText");
  const partnerLinkUrl = get(activeCase, "contentItems[0].externalLinkUrl");
  const groupCase = !!get(activeCase, "groupUuid");

  const suggestedEdits = get(activeCase, "moderationEdit", []);
  const suggestedEditContent = find(
    suggestedEdits,
    (edit) =>
      isEqual(edit.editType, SUGGESTED_EDIT_TYPES.CONTENT) &&
      !isNil(edit.editUuid)
  );
  const suggestedEditMedia = filter(suggestedEdits, (edit) =>
    isEqual(edit.editType, SUGGESTED_EDIT_TYPES.MEDIA)
  );

  const suggestedEditMediaObject = formatSuggestedEditMedia(suggestedEditMedia);

  const media = updateMediaUrlsFromSuggestion(
    originalMedia,
    suggestedEditMediaObject,
    moderatorUid
  );
  const isImageSeries = size(media) > 1;
  const firstMedia = head(media);
  const imageSize = 525;
  const imageUrl = getImageUrl(firstMedia, imageSize, imageSize);
  const rawImageUrl = get(firstMedia, "url");
  const notesCount = size(get(activeCase, "moderationNotes", []));

  const suggestedTitle = get(suggestedEditContent, "title");
  const suggestedCaption = get(suggestedEditContent, "caption");
  const commentsCount = size(get(activeCase, "comments", []));
  const showContentSuggestionApprovalSection =
    isSuggestionApprovalAllowed(caseState) && suggestedEditContent;

  const filteredSuggestedEditMedia = filter(
    suggestedEditMedia,
    (editedMedia) => {
      const mediaUuid = editedMedia.mediaUuid;
      const currentMedia = find(originalMedia, (m) =>
        isEqual(m.mediaUuid, mediaUuid)
      );

      return !isNil(currentMedia);
    }
  );
  const showImageApprovalSection =
    isSuggestionApprovalAllowed(caseState) &&
    size(filteredSuggestedEditMedia) > 0;
  const titleToDisplay = isModeratorEdit(suggestedEditContent, moderatorUid)
    ? suggestedTitle
    : title;
  const captionToDisplay = isModeratorEdit(suggestedEditContent, moderatorUid)
    ? suggestedCaption
    : caption;
  const badgeCssClass = getBadgeCssClass(caseState);
  const showSuggestionModerationComplete =
    isSuggestionApprovalAllowed(caseState) &&
    size(filteredSuggestedEditMedia) === 0 &&
    !suggestedEditContent;

  const confirmApprove = (editUuid, isMedia) => {
    confirmEditUuid.current = editUuid;
    confirmMedia.current = isMedia;
    toggleApproveModal();
  };

  const onApprove = async () => {
    const action = confirmMedia.current ? "approve/media" : "approve";
    await dispatch(
      reviewEditCase(caseUuid, confirmEditUuid.current, action, refreshCases)
    );
    toggleApproveModal();
  };

  const confirmReject = (editUuid, isMedia) => {
    confirmEditUuid.current = editUuid;
    confirmMedia.current = isMedia;
    toggleRejectModal();
  };

  const onReject = async () => {
    const action = confirmMedia.current ? "reject/media" : "reject";
    await dispatch(
      reviewEditCase(caseUuid, confirmEditUuid.current, action, refreshCases)
    );
    toggleRejectModal();
  };

  const renderLockDetail = () => {
    return (
      <>
        <p className="mt-2 mb-0">
          <strong>View mode</strong>
        </p>
        <p>
          This case is in use by{" "}
          {defaultTo(get(caseLock, "displayName"), get(caseLock, "firstName"))}
        </p>
        <p className="text-12">
          Locked at{" "}
          {moment(caseLock.lockTime.seconds * 1000).format(
            "h:mm A [on] MMMM Do"
          )}
        </p>
      </>
    );
  };

  return (
    <>
      <CasePagingBanner activeCase={activeCase} />

      {groupCase && (
        <Alert className="bg-green my-2 text-16 text-darkest-gray">
          This case was uploaded in a group
        </Alert>
      )}

      <Card className="case-detail-body">
        {imageUrl && (
          <div
            className="position-relative d-flex justify-content-center"
            key={get(firstMedia, "mediaUuid")}
            onClick={() => toggleMediaModal(media)}>
            <CardImg top width="100%" src={imageUrl} alt="Case image" />

            {badgeCssClass && (
              <span className={`btn-round dot ${badgeCssClass}`}></span>
            )}
            {isImageSeries && (
              <Button className="btn-detail-body images">
                <FontAwesomeIcon icon={faImages} color="white" />
              </Button>
            )}
            {caseLock && (
              <>
                <Button className="btn-detail-body lock">
                  <FontAwesomeIcon icon={faLock} color="white" />
                </Button>
                <div className="image-overlay text-center text-white flex-column">
                  {renderLockDetail()}
                </div>
              </>
            )}
          </div>
        )}
        {!imageUrl && caseLock && (
          <div className="d-flex text-white bg-primary flex-column align-items-center justify-content-center">
            {renderLockDetail()}
          </div>
        )}
        <CardBody className="pt-0">
          <div className="case-links pb-3 d-xl-flex justify-content-between">
            <Button
              className="px-0 text-center"
              color="link"
              disabled={!isNil(caseLock) || !isEditActive(caseState)}
              onClick={toggleCaptionModal}>
              Edit Caption
            </Button>
            {false && (
              <Button
                className="text-center"
                color="link"
                onClick={toggleTineyeModal}>
                Tineye Results
              </Button>
            )}
            <Button
              className="px-0 text-center"
              color="link"
              onClick={toggleNotesModal}>
              Notes ({notesCount})
            </Button>
          </div>

          {showContentSuggestionApprovalSection && (
            <>
              <div className="pb-3">
                <Button
                  className="mr-3 font-weight-bold btn-sm"
                  color="approved"
                  disabled={!isNil(caseLock)}
                  onClick={() =>
                    confirmApprove(suggestedEditContent.editUuid, false)
                  }>
                  <FontAwesomeIcon
                    icon={faCheck}
                    color="white"
                    className="mr-2 text-12"
                  />
                  <span className="text-14">Approve</span>
                </Button>
                <Button
                  className="font-weight-bold btn-sm"
                  color="removed"
                  disabled={!isNil(caseLock)}
                  onClick={() =>
                    confirmReject(suggestedEditContent.editUuid, false)
                  }>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="white"
                    className="mr-2 text-12"
                  />
                  <span className="text-14">Revert</span>
                </Button>
              </div>
              <div>
                <div className="mb-3">
                  <div className="mb-2">
                    <div className="text-11 font-weight-bold text-uppercase text-secondary">
                      Current Title
                    </div>
                    <div>{isEmpty(title) ? <i>None</i> : title}</div>
                  </div>
                  <div>
                    <div className="text-11 font-weight-bold text-uppercase text-secondary">
                      Suggested Title
                    </div>
                    <div>
                      {isEmpty(suggestedTitle) ? <i>None</i> : suggestedTitle}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="mb-2">
                    <div className="text-11 font-weight-bold text-uppercase text-secondary">
                      Current Caption
                    </div>
                    <div>{isEmpty(caption) ? <i>None</i> : caption}</div>
                  </div>
                  <div>
                    <div className="text-11 font-weight-bold text-uppercase text-secondary">
                      Suggested Caption
                    </div>
                    <div>
                      {isEmpty(suggestedCaption) ? (
                        <i>None</i>
                      ) : (
                        suggestedCaption
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!showContentSuggestionApprovalSection && (
            <div className="mb-3">
              {titleToDisplay && (
                <CardText className="font-weight-bold">
                  {titleToDisplay}
                </CardText>
              )}
              <CardText>{captionToDisplay}</CardText>
            </div>
          )}

          {showImageApprovalSection && (
            <div>
              <div className="mb-2">
                {isEmpty(filteredSuggestedEditMedia) ? null : (
                  <div className="text-11 font-weight-bold text-uppercase text-secondary">
                    Edited Media
                  </div>
                )}

                {map(filteredSuggestedEditMedia, (editedMedia) => {
                  const mediaUuid = editedMedia.mediaUuid;
                  const THUMBNAIL_SIZE = 120;
                  const currentMedia = find(originalMedia, (m) =>
                    isEqual(m.mediaUuid, mediaUuid)
                  );

                  if (!currentMedia) {
                    return null;
                  }

                  const editedMediaUrl = getImageUrl(
                    {
                      url: editedMedia.url,
                      type: "image"
                    },
                    THUMBNAIL_SIZE,
                    THUMBNAIL_SIZE,
                    "crop"
                  );

                  const currentMediaUrl = getOriginalUrl(currentMedia);
                  const croppedCurrentMediaUrl = currentMediaUrl
                    ? getImageUrl(
                        {
                          url: currentMediaUrl,
                          type: "image"
                        },
                        THUMBNAIL_SIZE,
                        THUMBNAIL_SIZE,
                        "crop"
                      )
                    : null;

                  return (
                    <div
                      className="d-flex case-previewer border-bottom py-3"
                      key={mediaUuid}>
                      {currentMediaUrl && (
                        <div
                          className="thumbnail my-2 mr-3"
                          onClick={() => {
                            setCurrentImageDisplayUrl(currentMediaUrl);
                            toggleImageDisplayModal();
                          }}>
                          <img
                            className="img-thumbnail"
                            src={croppedCurrentMediaUrl}
                            alt={"current image " + mediaUuid}
                          />
                          <div>current</div>
                        </div>
                      )}
                      <div
                        className="thumbnail my-2 mr-3"
                        onClick={() => {
                          setCurrentImageDisplayUrl(editedMedia.url);
                          toggleImageDisplayModal();
                        }}>
                        <img
                          className="img-thumbnail"
                          src={editedMediaUrl}
                          alt={"edited image " + mediaUuid}
                        />
                        <div>suggested</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          className="mr-3 font-weight-bold btn-sm"
                          color="approved"
                          disabled={!isNil(caseLock)}
                          onClick={() =>
                            confirmApprove(editedMedia.editUuid, true)
                          }>
                          <FontAwesomeIcon
                            icon={faCheck}
                            color="white"
                            className="mr-2 text-12"
                          />
                          <span className="text-14">Approve</span>
                        </Button>
                        <Button
                          className="font-weight-bold btn-sm"
                          color="removed"
                          disabled={!isNil(caseLock)}
                          onClick={() =>
                            confirmReject(editedMedia.editUuid, true)
                          }>
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="white"
                            className="mr-2 text-12"
                          />
                          <span className="text-14">Revert</span>
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="mt-3">
            <Button
              disabled={commentsCount === 0}
              className="px-0 text-center"
              color="link"
              onClick={toggleCommentsModal}>
              See comments ({commentsCount})
            </Button>
          </div>

          {showSuggestionModerationComplete && (
            <div className="suggested-edits-completed">
              All edits have been approved or rejected. You must now click
              Approve, Flag, or Reject button to transition the case to the next
              step.
            </div>
          )}

          {isPartner && partnerLinkUrl && (
            <div className="my-3">
              <a
                href={partnerLinkUrl}
                className="partner-case-link"
                rel="noopener noreferrer"
                target="_blank">
                {partnerLinkText ? partnerLinkText : partnerLinkUrl}
                <span className="rounded-circle">
                  <FontAwesomeIcon icon={faArrowRight} size={"sm"} />
                </span>
              </a>
            </div>
          )}
        </CardBody>
        <ImageDisplayModal
          imageUrl={currentImageDisplayUrl}
          toggle={toggleImageDisplayModal}
          isOpen={imageDisplayModal}
        />
        <TineyeModal
          toggle={toggleTineyeModal}
          isOpen={tineyeModal}
          url={rawImageUrl}
        />
        <MediaViewerModal
          toggle={toggleMediaModal}
          isOpen={mediaModal}
          media={media}
          caseUuid={activeCase.caseUuid}
          caseLock={caseLock}
          refreshCases={refreshCases}
        />
        <NotesViewerModal
          toggle={toggleNotesModal}
          isOpen={notesModal}
          caseDoc={activeCase}
        />
        <CaptionEditModal
          toggle={toggleCaptionModal}
          isOpen={captionModal}
          caseDoc={activeCase}
          refreshCases={refreshCases}
          titleToDisplay={titleToDisplay}
          captionToDisplay={captionToDisplay}
        />
        <ConfirmModal
          toggle={toggleApproveModal}
          isOpen={approveModal}
          message="Are you sure you want to approve this edit suggestion?"
          onConfirm={onApprove}
          saving={reviewEditSaving}
        />
        <ConfirmModal
          toggle={toggleRejectModal}
          isOpen={rejectModal}
          message="This edit suggestion will be removed and the original content will remain. Proceed?"
          onConfirm={onReject}
          saving={reviewEditSaving}
        />
        <CaseCommentsViewerModal
          toggle={toggleCommentsModal}
          isOpen={commentsModal}
          caseDoc={activeCase}
        />
      </Card>
    </>
  );
};

CaseDetailBody.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  refreshCases: PropTypes.func
};

export const CaseFilters = (props) => {
  const dispatch = useDispatch();
  const { filters, applyFilters } = props;
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);

  const clearFilters = () => {
    dispatch(applyFilters({}));
  };

  return (
    <>
      <div
        className="d-flex justify-content-end align-items-end flex-grow-1 cases-filters"
        style={{ position: "relative", bottom: 0 }}>
        <Button color="link" onClick={toggleFilterModal}>
          <FontAwesomeIcon icon={faPlusCircle} size={"sm"} className="mr-1" />
          Filters {!isEmpty(filters) && <Badge>{size(filters)}</Badge>}
        </Button>
        {!isEmpty(filters) && (
          <Button className="ml-2" color="link" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </div>

      <TaggingFilterModal
        toggle={toggleFilterModal}
        isOpen={filterModal}
        applyFilters={applyFilters}
        filters={filters}
      />
    </>
  );
};

CaseFilters.propTypes = {
  filters: PropTypes.object,
  applyFilters: PropTypes.func.isRequired
};
