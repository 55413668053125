import { Toast, ToastBody, ToastHeader } from "reactstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { hideGlobalMessage } from "../../actions/global.actions";

const GlobalMessageToast = (props) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.global.message);
  const title = useSelector((state) => state.global.title);
  const isError = useSelector((state) => state.global.isError);
  const fixToTop = useSelector((state) => state.global.fixToTop);
  const show = message && !isEmpty(message);

  const hideMessage = () => {
    dispatch(hideGlobalMessage());
  };

  if (show && !isError) {
    setTimeout(hideMessage, 6000);
  }

  if (!show) {
    return null;
  }

  return (
    <div className={fixToTop ? "sticky-top" : ""}>
      <div className="toast-container">
        <Toast className={`${!isError && "success"}`}>
          <ToastHeader toggle={hideMessage}>
            {title || "General Error"}
          </ToastHeader>
          <ToastBody>{message}</ToastBody>
        </Toast>
      </div>
    </div>
  );
};

GlobalMessageToast.propTypes = {};

export default GlobalMessageToast;
