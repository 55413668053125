import { compact, map, trim } from "lodash";
import {
  fetchUsersByUuids,
  getAdminUsers,
  loadProfileForUid,
  saveUserRolesToDB
} from "../db/users-db";

const actionsPrefix = "users";

export const SAVE_USER_ROLES_START = `${actionsPrefix}/SAVE_USER_ROLES_START`;
export const SAVE_USER_ROLES_COMPLETE = `${actionsPrefix}/SAVE_USER_ROLES_COMPLETE`;
export const ADMIN_USERS_LOAD = `${actionsPrefix}/ADMIN_USERS_LOAD`;
export const ADMIN_USERS_LOADED = `${actionsPrefix}/ADMIN_USERS_LOADED`;

export const saveUserRoles = (uid, userRoles) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_USER_ROLES_START
    });

    const result = await saveUserRolesToDB(uid, userRoles);

    dispatch({
      type: SAVE_USER_ROLES_COMPLETE,
      uid: uid,
      userRoles: userRoles,
      result: result
    });
  };
};

export const loadAdminUsers = () => {
  return async function(dispatch) {
    dispatch({
      type: ADMIN_USERS_LOAD
    });

    try {
      const allUsers = await getAdminUsers();
      const promises = map(allUsers, (u) => {
        return loadProfileForUid(u.uid).then((r) => {
          if (!r) {
            return null;
          }

          return {
            ...u,
            firstName: r.firstName,
            lastName: r.lastName,
            name: trim(`${r.firstName || ""} ${r.lastName || ""}`),
            userUuid: r.userUuid
          };
        });
      });

      const users = await Promise.all(promises);
      dispatch({
        type: ADMIN_USERS_LOADED,
        users: compact(users),
        isError: false
      });
    } catch (error) {
      dispatch({
        type: ADMIN_USERS_LOADED,
        users: [],
        isError: true,
        message: error.message
      });
    }
  };
};

export const getUsersByUuids = async (userUuids) => {
  return await fetchUsersByUuids(userUuids);
};
