import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { CAMPAIGN_SECONDARY_NAVIGATION } from "../../constants/route-constants";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignPageConfig } from "../../utils/campaign-utils";
import {
  CampaignListPageHeader,
  CampaignListTable
} from "./components/CampaignSections";
import {
  getCampaigns,
  updateCampaignSearchFilter
} from "../../actions/campaigns.actions";
import "./components/style.scss";
import Loading from "../../components/Loading";

const CampaignListPage = (props) => {
  const { filterType = "" } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const pageConfig = getCampaignPageConfig(filterType);
  const processing = useSelector((state) => state.campaigns.processing);
  const campaigns = useSelector(
    (state) => state.campaigns[pageConfig.pageKey].campaigns
  );

  useEffect(() => {
    dispatch(getCampaigns(pageConfig.pageKey, 0));
  }, [pageConfig, location, dispatch]);

  const onSearch = async (searchFilter) => {
    await dispatch(
      updateCampaignSearchFilter(pageConfig.pageKey, searchFilter)
    );

    dispatch(getCampaigns(pageConfig.pageKey, 0));
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <>
        <CampaignListPageHeader pageConfig={pageConfig} onSearch={onSearch} />{" "}
        <CampaignListTable pageConfig={pageConfig} campaigns={campaigns} />
      </>
    );
  };

  return (
    <div className="campaign-page">
      <SecondaryNavigation
        links={CAMPAIGN_SECONDARY_NAVIGATION}
        active={pageConfig.pageKey}
        centerAlign={false}
      />
      <Container>{renderBody()}</Container>
    </div>
  );
};

export default CampaignListPage;
