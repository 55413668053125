import React from "react";
import { Alert, Button, Form, FormGroup } from "reactstrap";
import "./authentication-forms.scss";
import { useDispatch, useSelector } from "react-redux";
import { signInWithGoogle } from "../../actions/anon-user.actions";
import { isEmpty } from "lodash";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";

const GoogleSignInForm = (props) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.anonUser.googleMessage);

  const onSignInGoogle = () => {
    dispatch(signInWithGoogle());
  };

  return (
    <Form className="pt-3 px-5">
      {!isEmpty(error) && <Alert color={"danger"}>{error}</Alert>}

      <FormGroup className="text-center">
        <div>
          <h4 className="text-dark-gray pb-3">Sign in to your Admin account</h4>
          <p className="text-dark-gray text-15 pb-4">
            Access to the Figure 1 Admin Tool is restricted to employees and
            associates with a valid @figure1.com email account. Please contact
            IT Support if you encounter issues logging in to the tool.
          </p>
        </div>
        <Button
          onClick={onSignInGoogle}
          className="px-5 rounded-pill"
          size="lg"
          color="main-red">
          Sign In With Google
        </Button>
        <div className="pt-5">
          <Link to={ROUTES.TAGGER_SIGN_IN}>
            Sign In with Email and Password?
          </Link>
        </div>
      </FormGroup>
    </Form>
  );
};

GoogleSignInForm.propTypes = {};

export default GoogleSignInForm;
