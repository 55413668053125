import React from "react";
import F1Banner from "../../../components/common/F1Banner";

const VerificationCustomFieldsBanner = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { userData } = props;

  const hasCustomFields =
    !!userData.userCustomSchool || !!userData.userCustomSpecialty;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  if (hasCustomFields) {
    return (
      <div className="mb-2">
        <F1Banner color="red">Custom fields were added</F1Banner>
      </div>
    );
  }
  return null;
};

export default VerificationCustomFieldsBanner;
