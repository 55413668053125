import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import { compact, get, keyBy, map, orderBy } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { toolsUserSearchCall } from "../../api/cloud-functions";
import Loading from "../Loading";

const CaseCommentsViewerModal = (props) => {
  const { caseDoc, isOpen, toggle } = props;
  const [loading, setLoading] = useState(true);
  const [commenters, setCommenters] = useState({});
  const comments = get(caseDoc, "comments", []);

  const onOpened = async () => {
    setLoading(true);

    const authorUuids = compact(map(comments, (n) => get(n, "authorUuid")));
    const authorsData = await toolsUserSearchCall(
      {},
      0,
      "desc",
      100,
      authorUuids
    );

    const authors = keyBy(
      map(get(authorsData, "pageData.hits", []), (c) => get(c, "_source")),
      "userUuid"
    );

    setCommenters(authors);

    setLoading(false);
  };

  const commentsTable = compact(
    map(orderBy(comments, ["createdAt"], ["desc"]), (n, index) => {
      const createdAt = get(n, "createdAt");
      const authorUuid = get(n, "authorUuid");
      const commenter = get(commenters, authorUuid);
      return (
        <tr key={`comment-${index}`}>
          <td className="font-weight-bold align-top" style={{ width: "25%" }}>
            {get(commenter, "username", "User Not Found")}
          </td>
          <td className="date-column align-top" style={{ width: "25%" }}>
            {createdAt ? moment(createdAt).format("MMM DD, YYYY h:mm A") : ""}
          </td>
          <td className="align-top">{get(n, "text")}</td>
        </tr>
      );
    })
  );

  const renderBody = () => {
    if (loading) {
      return (
        <div style={{ minHeight: "400px" }}>
          <Loading />
        </div>
      );
    }

    return (
      <div className="pb-3">
        <div className="overflow-auto" style={{ maxHeight: "400px" }}>
          <Table bordered>
            <tbody>{commentsTable}</tbody>
          </Table>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      size="lg"
      className="notes-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Comments
      </ModalHeader>
      <ModalBody className="pt-0">{renderBody()}</ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-end">
        <Button color="primary" outline onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CaseCommentsViewerModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object
};

export default CaseCommentsViewerModal;
