import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { clone, find, sortBy, isEmpty, isEqual, isNil, map, set } from "lodash";
import { CASE_STATES_SEARCH_FILTERS } from "../../utils/case-utils";
import { filterCases } from "../../actions/case-explorer.actions";
import { findSpecialty, generateDropdown } from "../../utils/reference-utils";
import { Typeahead } from "react-bootstrap-typeahead";

const CaseExplorerFilterModal = (props) => {
  const { isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.caseExplorer.filters);
  const professions = useSelector((state) => state.reference.professions);
  const countries = useSelector((state) => state.reference.countries);
  const allLabels = useSelector((state) => state.reference.labels);
  const allLabelsArray = sortBy(
    map(allLabels, (v, k) => {
      return { name: v, value: k };
    }),
    ["name"]
  );
  const promotionChannels = useSelector(
    (state) => state.promotions.allChannels
  );
  const profession = find(professions, (p) => {
    return p.typeUuid === formData.authorProfessionUuid;
  });
  const currentSpecialty = findSpecialty(
    profession,
    formData.authorSpecialtyUuid,
    "specialties"
  );
  const currentSubSpecialty = findSpecialty(
    currentSpecialty,
    formData.authorSubSpecialtyUuid,
    "subSpecialties"
  );

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  const onSubmit = async (event) => {
    event.preventDefault();

    toggle();

    await dispatch(filterCases(formData));
  };

  const clearFilters = () => {
    toggle();

    dispatch(filterCases({}));
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onChangeProfession = (value) => {
    const newData = clone(formData);
    newData.authorProfessionUuid = value.typeUuid;
    newData.authorSpecialtyUuid = null;
    newData.authorSubSpecialtyUuid = null;

    setFormData(newData);
  };

  const onChangeSpecialty = (value) => {
    const newData = clone(formData);
    newData.authorSpecialtyUuid = value.typeUuid;
    newData.authorSubSpecialtyUuid = null;
    setFormData(newData);
  };

  const onChangeSubSpecialty = (value) => {
    setFormData(clone(set(formData, "authorSubSpecialtyUuid", value.typeUuid)));
  };

  const onChangeCountry = (value) => {
    const newData = clone(formData);
    newData.countryUuid = value.uuid;
    newData.stateUuid = null;
    setFormData(newData);
  };

  const onChangeLabel = (values) => {
    console.log(values);
    setFormData({ ...formData, labels: values });
  };

  const professionSegment = generateDropdown(
    "profession",
    "Uploader Profession",
    professions,
    "Select a profession",
    onChangeProfession,
    profession,
    "typeUuid"
  );
  const specialtySegement =
    isNil(profession?.specialties) || isEmpty(profession?.specialties)
      ? null
      : generateDropdown(
          "specialty",
          "Uploader Speciality",
          profession?.specialties,
          "Select a specialty",
          onChangeSpecialty,
          currentSpecialty,
          "typeUuid"
        );

  const subSpecialtySegment =
    isNil(currentSpecialty?.subSpecialties) ||
    isEmpty(currentSpecialty?.subSpecialties)
      ? null
      : generateDropdown(
          "subSpecialty",
          "Uploader Subspeciality",
          currentSpecialty?.subSpecialties,
          "Select a subspecialty",
          onChangeSubSpecialty,
          currentSubSpecialty
        );

  const countrySegment = generateDropdown(
    "countryUuid",
    "Uploader Country",
    countries,
    "Select a country",
    onChangeCountry,
    find(countries, (c) => isEqual(c.uuid, formData.countryUuid)),
    "uuid"
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Select filters
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="caseTerm">
                term search
              </Label>
              <Input
                id="caseTerm"
                name="caseTerm"
                placeholder="Term, case ID, username..."
                value={formData.caseTerm || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                case state
              </Label>
              <Input
                type="select"
                name="stateFilter"
                value={formData.stateFilter || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(CASE_STATES_SEARCH_FILTERS, (value, key) => {
                  return (
                    <option key={`case-state-${key}`} value={key}>
                      {value}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          {professionSegment}
          {specialtySegement}
          {subSpecialtySegment}
          {countrySegment}

          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                promotion publish date
              </Label>
              <Input
                type="date"
                name="promotion_publish_date"
                id="promotion_publish_date"
                value={formData.promotion_publish_date || ""}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                promotion channel
              </Label>
              <Input
                type="select"
                name="channel_uuid"
                id="channel_uuid"
                value={formData.channel_uuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(promotionChannels, (c) => {
                  return (
                    <option
                      key={`promotion-chan-${c.channel_uuid}`}
                      value={c.channel_uuid}>
                      {c.channel_name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                has promotion
              </Label>
              <Input
                type="select"
                name="hasPromotion"
                value={formData.hasPromotion || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                legacy case
              </Label>
              <Input
                type="select"
                name="hasLegacyUid"
                value={formData.hasLegacyUid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                uploader verified
              </Label>
              <Input
                type="select"
                name="isVerified"
                value={formData.isVerified || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="commentCount">
                comment count (contains at least)
              </Label>
              <Input
                id="commentCount"
                name="commentCount"
                value={formData.commentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-12">
              <Label className="text-11 text-uppercase font-weight-normal">
                case labels
              </Label>
              <Typeahead
                id="tagsTypeahead"
                multiple={true}
                labelKey="name"
                onChange={onChangeLabel}
                options={allLabelsArray}
                placeholder="Select a label..."
                selected={formData.labels || []}
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={clearFilters}>
            Clear filters
          </Button>

          <LoadingButton outline color="primary" type="submit">
            Apply filters
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

CaseExplorerFilterModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

CaseExplorerFilterModal.defaultProps = {};

export default CaseExplorerFilterModal;
