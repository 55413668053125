import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { get, isEmpty, map } from "lodash";
import { useSelector } from "react-redux";
import { getModuleList } from "../../utils/permission-utils";
import CardText from "reactstrap/es/CardText";
import { Link } from "react-router-dom";
import "../pages.scss";
import EmptyContainer from "../../components/common/EmptyContainer";
import Loading from "../../components/Loading";

const HomePage = () => {
  const user = useSelector((state) => state.user.user);
  const userRoles = useSelector((state) => state.user.userRoles);
  const accessibleModules = getModuleList(userRoles);
  const firstName = get(user, "firstName");
  const emailAddress = get(user, "email");

  if (!user) {
    return <Loading />;
  }

  const renderNoRoles = () => {
    return (
      <EmptyContainer
        title={`No Roles Were Found`}
        description="Welcome! You have successfully accessed the Figure 1 Admin Tool but your account has
        not been granted any roles. Please reach out to an Administrator for further access."
      />
    );
  };

  const renderBody = () => {
    return (
      <>
        <p className={"text-muted"}>
          Welcome! You have successfully accessed the Figure 1 Admin Tool and
          have access to the following modules. Please reach out to an
          Administrator for further access.
        </p>
        <Row>
          {map(accessibleModules, (value, index) => {
            return (
              <Col key={`home-card=${index}`} sm="12" md="6" lg="4">
                <Card
                  key={`${value.displayName}`}
                  className={"text-white bg-primary mb-4"}>
                  <CardBody>
                    <div className={"module-icon"}>
                      <div className={value.icon} />
                    </div>
                    <h4 className={"text-center"}>{value.displayName}</h4>
                    <CardText className={"text-center"}>
                      {value.description}
                    </CardText>
                    <Link
                      to={value.link}
                      className={
                        "stretched-link text-white text-center"
                      }></Link>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  return (
    <div className="home">
      <Container>
        <div className="py-5">
          <h2 className={"pb-3 mb-4 border-bottom"}>
            {firstName ? `Welcome ${firstName}` : "Welcome"}!
            {emailAddress && (
              <div className={"p1-1 text-12 text-secondary"}>
                {emailAddress}
              </div>
            )}
          </h2>
          {isEmpty(accessibleModules) && renderNoRoles()}
          {!isEmpty(accessibleModules) && renderBody()}
        </div>
      </Container>
    </div>
  );
};

export default HomePage;
