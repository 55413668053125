import React, { useState } from "react";
import { Badge, Button, NavItem, NavLink, Pagination } from "reactstrap";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { filterUsers, getUsers } from "../../../actions/tools.actions";
import ToolsUsersFilterModal from "../../../components/modals/ToolsUsersFilterModal";
import { formatNumber } from "../../../utils/general-utils";

export const ToolsUserPagination = () => {
  const dispatch = useDispatch();

  const currentPage = useSelector((state) => state.tools.currentPage);
  const totalUsers = useSelector((state) => state.tools.totalUsers);
  const fromPage = useSelector((state) => state.tools.fromPage);
  const toPage = useSelector((state) => state.tools.toPage);
  const hasPrevious = useSelector((state) => state.tools.hasPrevious);
  const hasNext = useSelector((state) => state.tools.hasNext);
  const filters = useSelector((state) => state.tools.filters);
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const clearFilters = () => {
    dispatch(filterUsers({}));
  };

  const onPreviousPage = () => {
    dispatch(getUsers(currentPage - 1));
    return false;
  };

  const onNextPage = () => {
    dispatch(getUsers(currentPage + 1));
    return false;
  };

  return (
    <div className="d-flex text-secondary mb-3 justify-content-between">
      <div className="case-explorer-pagination d-flex ">
        <span>
          {totalUsers === 0 ? 0 : fromPage}-{toPage} of{" "}
          {formatNumber(totalUsers)}
        </span>
        <Pagination>
          <NavItem>
            <NavLink href="#" disabled={!hasPrevious} onClick={onPreviousPage}>
              <div className="ml-3 mr-2">&#8249;</div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" disabled={!hasNext} onClick={onNextPage}>
              <div className="ml-3 mr-2">&#8250;</div>
            </NavLink>
          </NavItem>
        </Pagination>
      </div>
      <div>
        <Button color="link" onClick={toggleFilterModal}>
          <FontAwesomeIcon icon={faPlusCircle} size={"sm"} className="mr-1" />
          Filters{" "}
          {!isEmpty(filters) && <Badge color="primary">{size(filters)}</Badge>}
        </Button>
        {!isEmpty(filters) && (
          <Button className="ml-2" color="link" onClick={clearFilters}>
            Clear
          </Button>
        )}
      </div>

      <ToolsUsersFilterModal toggle={toggleFilterModal} isOpen={filterModal} />
    </div>
  );
};

ToolsUserPagination.propTypes = {};
