import {
  verificationAddNoteCall,
  verificationEditCall,
  verificationGeneralNavigationCall,
  verificationStateCall,
  verificationTagCall,
  verificationTagManageCall
} from "../api/cloud-functions";
import {
  compact,
  find,
  forEach,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
  union,
  merge
} from "lodash";
import { getUsersRefreshPage } from "../utils/verification-utils";
import { showGlobalMessage } from "./global.actions";
import * as ROUTES from "../constants/routes";
import {
  saveAdminVerificationPriorityConfig,
  subscribeToAdminVerificationPriorityConfig
} from "../db/users-db";

const actionsPrefix = "verification";

export const GET_USERS_START = `${actionsPrefix}/GET_USERS_START`;
export const GET_USERS_COMPLETE = `${actionsPrefix}/GET_USERS_COMPLETE`;
export const SET_ACTIVE_USER = `${actionsPrefix}/SET_ACTIVE_USER`;
export const SET_BULK_USER = `${actionsPrefix}/SET_BULK_USER`;
export const SET_ALL_BULK_USERS = `${actionsPrefix}/SET_ALL_BULK_USERS`;
export const RESET_BULK_USERS = `${actionsPrefix}/RESET_BULK_USERS`;
export const ADD_NOTE_START = `${actionsPrefix}/ADD_NOTE_START`;
export const ADD_NOTE_COMPLETE = `${actionsPrefix}/ADD_NOTE_COMPLETE`;
export const CHANGE_STATE_START = `${actionsPrefix}/CHANGE_STATE_START`;
export const CHANGE_STATE_COMPLETE = `${actionsPrefix}/CHANGE_STATE_COMPLETE`;
export const TAG_CHANGE_START = `${actionsPrefix}/TAG_CHANGE_START`;
export const TAG_CHANGE_COMPLETE = `${actionsPrefix}/TAG_CHANGE_COMPLETE`;
export const TAG_MANAGE_START = `${actionsPrefix}/TAG_MANAGE_START`;
export const TAG_MANAGE_COMPLETE = `${actionsPrefix}/TAG_MANAGE_COMPLETE`;
export const EDIT_USER_START = `${actionsPrefix}/EDIT_USER_START`;
export const EDIT_USER_COMPLETE = `${actionsPrefix}/EDIT_USER_COMPLETE`;
export const VERIFICATION_USER_REFRESH = `${actionsPrefix}/VERIFICATION_USER_REFRESH`;
export const ENABLE_PRIORITY_FILTER = `${actionsPrefix}/ENABLE_PRIORITY_FILTER`;
export const DISABLE_PRIORITY_FILTER = `${actionsPrefix}/DISABLE_PRIORITY_FILTER`;
export const SAVE_PRIORITY_FILTERS_COMPLETE = `${actionsPrefix}/SAVE_PRIORITY_FILTERS_COMPLETE`;
export const SAVE_PRIORITY_FILTERS_LISTENER = `${actionsPrefix}/SAVE_PRIORITY_FILTERS_LISTENER`;
export const SAVE_FILTERS = `${actionsPrefix}/SAVE_FILTERS`;

const STATE_CHANGE_ERROR_TITLE = "State Change Error";
const STATE_CHANGE_ERROR_MESSAGE =
  "The attempt to transition the user state failed.  Please try again.";

export const getVerificationUsers = (
  verificationTypeParam,
  verificationStatusParam,
  page,
  history
) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_USERS_START
    });

    const priorityEnabled = getState().verification.priorityEnabled;
    const priorityFilters = getState().verification.priorityFilters;

    const filters = getState().verification.filters;
    const verificationType = !isNil(verificationTypeParam)
      ? verificationTypeParam
      : getState().verification.activeVerificationType;
    const verificationStatus = !isNil(verificationStatusParam)
      ? verificationStatusParam
      : getState().verification.activeVerificationStatus;
    const currentPage = !isNil(page)
      ? page
      : getUsersRefreshPage(
          getState().verification.currentPage,
          getState().verification.users
        );

    const filtersToUse = priorityEnabled
      ? merge({}, priorityFilters, filters)
      : filters;

    try {
      const results = await verificationGeneralNavigationCall(
        verificationType,
        verificationStatus,
        filtersToUse,
        currentPage
      );

      dispatch({
        type: GET_USERS_COMPLETE,
        results,
        verificationType,
        verificationStatus,
        filters,
        currentPage
      });

      if (history) {
        const totalResults = get(results, "allStats.allStatusStats.all");
        if (totalResults === 1 && history) {
          history.replace(`${ROUTES.VERIFICATION_ALL}/all`);
        }
      }
    } catch (error) {
      console.log("Error retrieving users", error);
      dispatch({
        type: GET_USERS_COMPLETE,
        error: error
      });
    }
  };
};

export const getBulkVerificationUsers = async (
  filters,
  verificationType,
  verificationStatus
) => {
  const results = await verificationGeneralNavigationCall(
    verificationType,
    verificationStatus,
    filters,
    0,
    250
  );

  const userUids = compact(
    map(get(results, "pageData.hits", []), (c) => get(c, "_source.userUid"))
  );

  const userUuids = compact(
    map(get(results, "pageData.hits", []), (c) => get(c, "_source.userUuid"))
  );

  return { userUids, userUuids };
};

export const filterVerificationUsers = (filters, history) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_USERS_START
    });

    await dispatch({
      type: SAVE_FILTERS,
      filters
    });

    dispatch(getVerificationUsers(null, null, 0, history));
  };
};

export const addVerificationNote = (userUid, userUuid, text) => {
  return async function(dispatch) {
    dispatch({
      type: ADD_NOTE_START
    });

    try {
      const response = await verificationAddNoteCall(userUid, userUuid, text);

      dispatch({
        type: VERIFICATION_USER_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the note.  Please try again.",
          "Save Note Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: ADD_NOTE_COMPLETE
    });
  };
};

export const changeVerificationState = (userUids, userUuid, state) => {
  return async function(dispatch) {
    dispatch({
      type: CHANGE_STATE_START
    });

    try {
      await verificationStateCall(userUids, userUuid, state);

      dispatch(getVerificationUsers());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: CHANGE_STATE_COMPLETE
    });
  };
};

export const saveVerificationTags = (
  userUids,
  userUuids,
  tagUuids,
  allUsers
) => {
  return async function(dispatch) {
    dispatch({
      type: TAG_CHANGE_START
    });

    try {
      if (size(userUids) > 1) {
        const promises = [];

        forEach(userUids, (userUid) => {
          const user = find(allUsers, (u) => isEqual(u.userUid, userUid));
          if (user) {
            const userTags = union(
              map(get(user, "verificationTags", []), (t) => t.tagUuid),
              tagUuids
            );
            promises.push(
              verificationTagCall([userUid], [user.userUuid], userTags)
            );
          }
        });

        await Promise.all(promises);

        dispatch(getVerificationUsers());
      } else {
        const response = await verificationTagCall(
          userUids,
          userUuids,
          tagUuids
        );

        dispatch({
          type: VERIFICATION_USER_REFRESH,
          response
        });
      }
    } catch (error) {
      console.log("Error saving verification tags", error);
    }

    dispatch({
      type: TAG_CHANGE_COMPLETE
    });
  };
};

export const addVerificationGlobalTag = (tagName) => {
  return async function(dispatch) {
    dispatch({
      type: TAG_MANAGE_START
    });

    try {
      await verificationTagManageCall([
        {
          action: "create",
          name: tagName
        }
      ]);
    } catch (error) {
      console.log("Error saving global verification tag", error);
    }

    dispatch({
      type: TAG_MANAGE_COMPLETE
    });
  };
};

export const removeVerificationGlobalTags = (tagUuids) => {
  return async function(dispatch) {
    const tagsToRemoves = map(tagUuids, (t) => {
      return {
        action: "delete",
        uuid: t
      };
    });

    if (isEmpty(tagsToRemoves)) {
      return;
    }

    dispatch({
      type: TAG_MANAGE_START
    });

    try {
      await verificationTagManageCall(tagsToRemoves);
    } catch (error) {
      console.log("Error saving global verification tag", error);
    }

    dispatch({
      type: TAG_MANAGE_COMPLETE
    });
  };
};

export const setActiveVerificationUser = (user) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ACTIVE_USER,
      user
    });
  };
};

export const setBulkUser = (userUid, active) => {
  return async function(dispatch) {
    dispatch({
      type: SET_BULK_USER,
      userUid,
      active
    });
  };
};

export const setAllBulkUsers = (userUids) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ALL_BULK_USERS,
      userUids
    });
  };
};

export const resetBulkUsers = () => {
  return async function(dispatch) {
    dispatch({
      type: RESET_BULK_USERS
    });
  };
};

export const editVerificationUser = (userUid, formData) => {
  return async function(dispatch) {
    dispatch({
      type: EDIT_USER_START
    });

    try {
      const response = await verificationEditCall(userUid, formData);

      dispatch({
        type: VERIFICATION_USER_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while editing the user.  Please try again.",
          "Save Edit Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: EDIT_USER_COMPLETE
    });
  };
};

export const enablePriorityFilter = () => {
  return async function(dispatch) {
    await dispatch({
      type: ENABLE_PRIORITY_FILTER
    });

    dispatch(getVerificationUsers());
  };
};

export const disablePriorityFilter = () => {
  return async function(dispatch) {
    await dispatch({
      type: DISABLE_PRIORITY_FILTER
    });

    dispatch(getVerificationUsers());
  };
};

export const subscribePriorityFiltersListener = () => {
  return async function(dispatch, getState) {
    const existing = getState().verification.priorityFiltersListener;
    if (typeof existing === "function") {
      return;
    }

    const listener = await subscribeToAdminVerificationPriorityConfig(
      (data) => {
        dispatch({
          type: SAVE_PRIORITY_FILTERS_COMPLETE,
          priorityFilters: data
        });
      }
    );

    dispatch({
      type: SAVE_PRIORITY_FILTERS_LISTENER,
      listener: listener
    });
  };
};

export const savePriorityFilters = (priorityFilters) => {
  return async function(dispatch) {
    await dispatch({
      type: SAVE_PRIORITY_FILTERS_COMPLETE,
      priorityFilters
    });

    await saveAdminVerificationPriorityConfig(priorityFilters);
  };
};
