import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getTineyeResults } from "../../actions/moderation.actions";
import { isEmpty, map } from "lodash";

const TineyeModal = (props) => {
  const { url, isOpen, toggle } = props;

  const dispatch = useDispatch();
  const processing = useSelector((state) => state.moderation.tineyeProcessing);
  const results = useSelector((state) => state.moderation.tineyeResults);
  const error = useSelector((state) => state.moderation.tineyeError);

  useEffect(() => {
    if (isOpen && !isEmpty(url)) {
      dispatch(getTineyeResults(url));
    }
  }, [isOpen, url, dispatch]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Tineye Results</ModalHeader>
      <ModalBody>
        {processing && (
          <div className="d-flex justify-content-center p-4">
            <Spinner style={{ width: "3rem", height: "3rem" }} />
          </div>
        )}
        {error && (
          <Alert color={"danger"}>
            We were unable to retrieve any results from Tineye for the image in
            the Case.
          </Alert>
        )}
        {map(results, (m) => {
          return <div>{m}</div>;
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

TineyeModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  url: PropTypes.string
};

export default TineyeModal;
