import React, { useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { get } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { editCase } from "../../actions/moderation.actions";
import { isModeratorManager } from "../../utils/permission-utils";
import LoadingButton from "../common/LoadingButton";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "easymde/dist/easymde.min.css";
import { onInvalidSubmit } from "../../utils/form-utils";

const CaptionEditModal = (props) => {
  const {
    caseDoc,
    isOpen,
    toggle,
    message,
    isError,
    titleToDisplay = "",
    captionToDisplay = ""
  } = props;
  const initialState = {
    caption: "",
    title: ""
  };
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.user.userRoles);
  const saving = useSelector((state) => state.moderation.editSaving);

  const onOpened = () => {
    setFormData({
      title: titleToDisplay,
      caption: captionToDisplay
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const caseUuid = get(caseDoc, "caseUuid");
    const moderationManager = isModeratorManager(userRoles);
    await dispatch(
      editCase(caseUuid, formData.title, formData.caption, !moderationManager)
    );
    toggle();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Edit Case
      </ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <AvField
            id="title"
            name="title"
            label="title"
            placeholder="Title here"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.title || ""}
            onChange={onChange}
            required
          />

          <AvField
            id="caption"
            name="caption"
            label="caption"
            placeholder="Caption here"
            type="textarea"
            rows="8"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.caption || ""}
            onChange={onChange}
            required
          />
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-5 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={saving}>
            Save Edit
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

CaptionEditModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object,
  refreshCases: PropTypes.func,
  titleToDisplay: PropTypes.string,
  captionToDisplay: PropTypes.string
};

CaptionEditModal.defaultProps = {};

export default CaptionEditModal;
