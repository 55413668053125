import { get, isEmpty, isNil, isString, map, omitBy } from "lodash";
import { caseExplorerSearchCall } from "../api/cloud-functions";

const actionsPrefix = "case-explorer";

export const GET_CASES_START = `${actionsPrefix}/GET_CASES_START`;
export const GET_CASES_COMPLETE = `${actionsPrefix}/GET_CASES_COMPLETE`;
export const UPDATE_CASE_FILTERS = `${actionsPrefix}/UPDATE_CASE_FILTERS`;
export const UPDATE_CASE_SORT = `${actionsPrefix}/UPDATE_CASE_SORT`;
export const CASE_REFRESH = `${actionsPrefix}/CASE_REFRESH`;

export const getCases = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_CASES_START
    });

    const filters = omitBy(
      {
        ...getState().caseExplorer.filters
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const sortColumn = getState().caseExplorer.sortColumn;
    const sortDirection = getState().caseExplorer.sortDirection;
    const pageSize = getState().caseExplorer.pageSize;

    try {
      const results = await caseExplorerSearchCall(
        filters,
        page,
        sortDirection,
        pageSize,
        sortColumn
      );

      dispatch({
        type: GET_CASES_COMPLETE,
        results,
        page
      });
    } catch (error) {
      console.log("Error retrieving cases", error);
      dispatch({
        type: GET_CASES_COMPLETE,
        error: error
      });
    }
  };
};

export const getCasesForDownload = async (
  pageSize,
  currentFilters,
  sortColumn,
  sortDirection
) => {
  const filters = omitBy(
    {
      ...currentFilters
    },
    (v) => isNil(v) || (isString(v) && isEmpty(v))
  );

  const results = await caseExplorerSearchCall(
    filters,
    0,
    sortDirection,
    pageSize,
    sortColumn
  );

  return map(get(results, "pageData.hits", []), (c) => get(c, "_source"));
};

export const filterCases = (filters, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_CASE_FILTERS,
      filters
    });

    dispatch(getCases(page));
  };
};

export const sortCases = (sortColumn, sortDirection) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_CASE_SORT,
      sortColumn,
      sortDirection
    });

    dispatch(getCases(0));
  };
};
