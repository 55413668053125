import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Label, Row } from "reactstrap";
import "./style.scss";
import {
  faArrowLeft,
  faArrowUp,
  faCheck,
  faEnvelopeSquare,
  faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBulkUsers,
  setAllBulkUsers
} from "../../actions/verification.actions";
import VerificationStatusModal from "../modals/VerificationStatusModal";
import { filter, get, includes, map } from "lodash";
import VerificationTagModal from "../modals/VerificationTagModal";
import { VerificationCopyEmails } from "../../pages/verification/components/VerificationSections";

const VerificationAllNav = (props) => {
  const { isOpen } = props;
  const dispatch = useDispatch();

  const allUsers = useSelector((state) => state.verification.users);
  const userUids = useSelector((state) => state.verification.bulkUsers);
  const totalUsers = useSelector((state) => state.verification.totalUsers);
  const allUserUids = map(allUsers, (u) => get(u, "userUid"));
  const activeUsers = filter(allUsers, (u) => {
    return includes(userUids, get(u, "userUid"));
  });
  const [bulkAll, setBulkAll] = useState(false);

  const [statusModalData, setStatusModalData] = useState({
    open: false,
    verificationStatus: ""
  });

  const toggleStatusModal = (verificationStatus = "") => {
    setStatusModalData({
      open: !statusModalData.open,
      verificationStatus: verificationStatus
    });
  };
  const [tagModal, setTagModal] = useState(false);
  const toggleTagModal = () => setTagModal(!tagModal);

  const onResetBulkUsers = () => {
    dispatch(resetBulkUsers());
    setBulkAll(false);
  };

  const onSelectAll = () => {
    if (bulkAll) {
      dispatch(resetBulkUsers());
      setBulkAll(false);
    } else {
      dispatch(setAllBulkUsers(allUserUids));
      setBulkAll(true);
    }
  };

  const onCloseTagModal = () => {
    dispatch(setAllBulkUsers(userUids));

    toggleTagModal();
  };

  return (
    <>
      <Row
        className={
          isOpen
            ? "show collapse verification-all-tabs mx-0 p-2"
            : "collapse verification-all-tabs mx-0 p-2"
        }>
        <Button
          color="link"
          className="text-white col-xl-2 text-left"
          onClick={onResetBulkUsers}>
          <FontAwesomeIcon icon={faArrowLeft} className="text-white mr-2" />
          Back
        </Button>
        <FormGroup check className="align-self-center ml-1">
          <Label check className="text-white">
            <Input
              type="checkbox"
              checked={bulkAll}
              onClick={onSelectAll}
              onChange={() => {}}
            />{" "}
            Select all
            <span className="text-11 d-block">
              ({totalUsers > 250 ? `250 of ${totalUsers}` : `All ${totalUsers}`}
              )
            </span>
          </Label>
        </FormGroup>
        <div className="d-flex justify-content-end align-items-center flex-grow-1">
          <Button size="sm" className="nav-btn" onClick={toggleTagModal}>
            Add tag
            <FontAwesomeIcon icon={faPlusCircle} size={"sm"} className="ml-1" />
          </Button>
          <Button id="send-email-btn" size="sm" className="nav-btn ml-2">
            Send email
            <FontAwesomeIcon
              icon={faEnvelopeSquare}
              size={"sm"}
              className="ml-1"
            />
          </Button>
          <Button
            size="sm"
            className="nav-btn ml-3"
            onClick={() => toggleStatusModal("verified")}>
            Verify all
            <FontAwesomeIcon icon={faCheck} size={"sm"} className="ml-1" />
          </Button>
          <Button
            size="sm"
            className="nav-btn ml-3 mr-1"
            onClick={() => toggleStatusModal("")}>
            Update processing state
            <FontAwesomeIcon icon={faArrowUp} size={"sm"} className="ml-1" />
          </Button>
        </div>
      </Row>

      <VerificationStatusModal
        toggle={toggleStatusModal}
        isOpen={statusModalData.open}
        userUids={userUids}
        verificationStatus={statusModalData.verificationStatus}
        bulkBatchUpdate={bulkAll}
      />
      <VerificationTagModal
        toggle={onCloseTagModal}
        isOpen={tagModal}
        userUids={userUids}
      />
      <VerificationCopyEmails users={activeUsers} />
    </>
  );
};

VerificationAllNav.propTypes = {
  active: PropTypes.string
};

export default VerificationAllNav;
