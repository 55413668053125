import { get, head, isEmpty, isNil, map } from "lodash";
import { adminUserSaveCall, adminUserSearchCall } from "../api/cloud-functions";
import { showGlobalMessage } from "./global.actions";
import * as ROUTES from "../constants/routes";

const actionsPrefix = "profile";

export const GET_USER_START = `${actionsPrefix}/GET_USER_START`;
export const GET_USER_COMPLETE = `${actionsPrefix}/GET_USER_COMPLETE`;
export const SAVE_PROFILE_START = `${actionsPrefix}/SAVE_PROFILE_START`;
export const SAVE_PROFILE_COMPLETE = `${actionsPrefix}/SAVE_PROFILE_COMPLETE`;
export const SET_ACTIVE_USER = `${actionsPrefix}/SET_ACTIVE_USER`;

const SAVE_UPDATE_SUCCESS = "The profile was saved successfully.";
const SAVE_ERROR = "There was an error saving the profile.  Please try again.";
const SAVE_ERROR_CONFLICT =
  "The username or email address is already used.  Please try again.";

export const findUser = (searchTerm, history) => {
  return async function(dispatch) {
    console.log(searchTerm);
    dispatch({
      type: GET_USER_START,
      searchTerm
    });

    try {
      const results = await adminUserSearchCall({ userTerm: searchTerm });
      const users = map(get(results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      console.log(users);

      if (!isEmpty(users)) {
        const user = head(users);

        if (history && user.userUid) {
          history.push(`${ROUTES.CAMPAIGN_PROFILE}/${user.userUid}`);
        } else {
          dispatch({
            type: GET_USER_COMPLETE,
            user: user,
            searchNoResults: false
          });
        }
      } else {
        dispatch({
          type: GET_USER_COMPLETE,
          user: null,
          searchNoResults: true
        });
      }
    } catch (error) {
      console.log(`Error fetching user: ${error}`);
      dispatch({
        type: GET_USER_COMPLETE,
        user: null,
        searchNoResults: true
      });
    }
  };
};

export const setActiveUser = (user) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ACTIVE_USER,
      user
    });
  };
};

export const saveProfile = (formData, history) => {
  return async function(dispatch) {
    dispatch({
      type: SAVE_PROFILE_START
    });

    try {
      const isNew = isNil(formData.userUid);
      const response = await adminUserSaveCall(formData);
      const success = !isNil(response) && !isNil(response.doc);
      dispatch({
        type: SAVE_PROFILE_COMPLETE,
        userSaveError: !success
      });

      if (success) {
        dispatch(
          showGlobalMessage(SAVE_UPDATE_SUCCESS, "Saved Successful", false)
        );

        if (isNew) {
          history.push(`${ROUTES.CAMPAIGN_PROFILE}/${response.doc.userUid}`);
        } else {
          dispatch(setActiveUser(response.doc));
        }
      }

      return success;
    } catch (error) {
      console.log("Error updating user data", error);
      const errorBody = JSON.parse(error.message);

      dispatch(
        showGlobalMessage(
          errorBody.status === 409 ? SAVE_ERROR_CONFLICT : SAVE_ERROR,
          "Save Profile Error",
          true,
          error
        )
      );

      dispatch({
        type: SAVE_PROFILE_COMPLETE,
        userSaveError: true
      });

      return false;
    }
  };
};
