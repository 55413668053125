import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Pagination
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../utils/general-utils";
import { isEqual } from "lodash";
import { getCasesSortDirection } from "../../../utils/case-utils";

const CasesPaginationRow = (props) => {
  const { stateLocation, getPage, sortCases } = props;

  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state[stateLocation].currentPage);
  const totalCases = useSelector((state) => state[stateLocation].totalCases);
  const fromPage = useSelector((state) => state[stateLocation].fromPage);
  const toPage = useSelector((state) => state[stateLocation].toPage);
  const hasPrevious = useSelector((state) => state[stateLocation].hasPrevious);
  const hasNext = useSelector((state) => state[stateLocation].hasNext);
  const stateSortDirections = useSelector(
    (state) => state[stateLocation].stateSortDirections
  );
  const stateFilter = useSelector((state) => state[stateLocation].stateFilter);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const sortFilter = getCasesSortDirection(stateFilter, stateSortDirections);

  const changeSortFilter = (selectedFilter) => {
    dispatch(sortCases(selectedFilter));
    toggle();
  };

  const onPreviousPage = () => {
    dispatch(getPage(currentPage - 1));
    return false;
  };

  const onNextPage = () => {
    dispatch(getPage(currentPage + 1));
    return false;
  };

  if (totalCases === 0) {
    return null;
  }

  return (
    <div className={"cases-pagination d-flex justify-content-between"}>
      <div>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="nav-link text-secondary p-0 text-14"
            color="text-secondary"
            caret>
            {isEqual(sortFilter, "asc")
              ? "Oldest to Newest"
              : "Newest to Oldest"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => changeSortFilter("asc")}
              active={isEqual(sortFilter, "asc")}>
              Oldest to Newest
            </DropdownItem>
            <DropdownItem
              onClick={() => changeSortFilter("desc")}
              active={isEqual(sortFilter, "desc")}>
              Newest to Oldest
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div>
        <Pagination listClassName="justify-content-center">
          <NavItem>
            <NavLink href="#" disabled={!hasPrevious} onClick={onPreviousPage}>
              <div>
                <span className={"nav-link-icon"}>&#8249;</span> Back
              </div>
            </NavLink>
          </NavItem>
          <span className="cases-pagination-to-from flex-lg-grow-1 align-self-center text-center text-secondary mx-3">
            {formatNumber(fromPage)}-{formatNumber(toPage)} of{" "}
            {formatNumber(totalCases)}
          </span>
          <NavItem>
            <NavLink href="#" disabled={!hasNext} onClick={onNextPage}>
              <div>
                Next <span className={"nav-link-icon"}>&#8250;</span>
              </div>
            </NavLink>
          </NavItem>
        </Pagination>
      </div>
    </div>
  );
};

CasesPaginationRow.propTypes = {
  stateLocation: PropTypes.string.isRequired,
  getPage: PropTypes.func.isRequired,
  sortCases: PropTypes.func.isRequired
};

export default CasesPaginationRow;
