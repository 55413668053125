import {
  CHECK_SIGN_IN_RESULT,
  CHECK_SIGN_IN_RESULT_COMPLETE,
  RESET_MESSAGE,
  SIGN_IN,
  SIGN_IN_RESULT,
  SIGN_UP,
  SIGNING_UP,
  VALIDATING_USER
} from "../actions/anon-user.actions";

const INITIAL_STATE = {
  email: "",
  manualSignup: false,
  isProcessing: true,
  isError: false,
  success: false,
  message: "",
  googleMessage: ""
};

const anonUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNING_UP:
      return {
        ...state,
        isProcessing: true,
        manualSignup: true
      };
    case SIGN_IN:
    case SIGN_UP:
    case SIGN_IN_RESULT:
      return {
        ...state,
        email: action.email,
        message: action.message,
        isError: action.isError,
        isProcessing: false,
        manualSignup: false
      };
    case RESET_MESSAGE:
      return {
        ...state,
        isProcessing: false,
        isError: false,
        message: ""
      };
    case CHECK_SIGN_IN_RESULT:
      return {
        ...state,
        isProcessing: true,
        googleMessage: ""
      };
    case CHECK_SIGN_IN_RESULT_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        googleMessage: action.message
      };
    case VALIDATING_USER:
      return {
        ...state,
        isProcessing: true
      };
    default:
      return state;
  }
};

export default anonUserReducer;
