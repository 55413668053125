import React from "react";
import { Container } from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { signOut } from "../../actions/user.actions";
import * as ROUTES from "../../constants/routes";
import { userSignedIn } from "../../utils/route-utils";
import Loading from "../../components/Loading";

class LogoutPage extends React.Component {
  state = {
    logoutComplete: false
  };

  componentDidMount = () => {
    this.props.signOut();
  };

  render() {
    const { auth } = this.props;

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    return (
      <Container>
        <Loading />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LogoutPage);
