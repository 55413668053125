import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Nav, Navbar, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { isEqual, map } from "lodash";
import "./style.scss";
import { useSelector } from "react-redux";

const SecondaryNavigation = (props) => {
  const { links, active = "", centerAlign = true } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleModerationItem = () => setIsOpen(!isOpen);
  const userRoles = useSelector((state) => state.user.userRoles);

  return (
    <Container fluid={true} className="secondary-nav">
      <Container>
        <Navbar
          dark
          expand="xl"
          className={`px-0 d-none d-xs-none d-sm-none d-md-flex d-lg-flex ${
            centerAlign ? "justify-content-center" : ""
          }`}
          style={{ minHeight: "50px" }}>
          <Nav navbar>
            {map(links, (link, index) => {
              if (!link.hasAccess(userRoles)) {
                return (
                  <div className="d-flex flex-column" key={`link-${index}`}>
                    <NavItem
                      className={
                        "d-inline-flex justify-content-between disabled"
                      }>
                      <Link
                        disabled={true}
                        className={"nav-link disabled"}
                        to={link.to}>
                        {link.displayName}
                      </Link>
                    </NavItem>
                  </div>
                );
              }

              return (
                <NavItem
                  key={`link-${index}`}
                  onClick={
                    link.displayName === "Moderation"
                      ? toggleModerationItem
                      : null
                  }>
                  <Link
                    className={
                      isEqual(active, link.activeName)
                        ? "active nav-link"
                        : "nav-link"
                    }
                    to={link.to}>
                    {link.displayName}
                  </Link>
                </NavItem>
              );
            })}
          </Nav>
        </Navbar>
      </Container>
    </Container>
  );
};

SecondaryNavigation.propTypes = {
  links: PropTypes.array,
  active: PropTypes.string,
  mobile: PropTypes.bool,
  centerAlign: PropTypes.bool
};

export default SecondaryNavigation;
