import {
  CASE_REFRESH,
  GET_CASES_COMPLETE,
  GET_CASES_START,
  UPDATE_CASE_FILTERS,
  UPDATE_CASE_SORT
} from "../actions/case-explorer.actions";
import { get, isEmpty, isEqual, isNil, map, omitBy, size } from "lodash";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  message: null,
  sortDirection: "desc",
  sortColumn: "publishedAt",
  pageSize: 25,
  currentPage: 0,
  cases: [],
  filters: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CASES_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        cases: []
      };
    }
    case GET_CASES_COMPLETE: {
      const casesPage = get(action.results, "pageData", []);
      const cases = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(casesPage, "pageSize", 10);
      const totalCases = get(casesPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(cases);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalCases;

      return {
        ...state,
        processing: false,
        cases,
        pageSize: pageSize,
        totalCases,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage
      };
    }
    case UPDATE_CASE_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case UPDATE_CASE_SORT: {
      return {
        ...state,
        sortDirection: action.sortDirection,
        sortColumn: action.sortColumn
      };
    }
    case CASE_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const caseUuid = get(action.response, "caseUuid");
      return {
        ...state,
        activeCase: isEqual(get(state.activeCase, "caseUuid"), caseUuid)
          ? action.response
          : state.activeCase,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            return action.response;
          } else {
            return c;
          }
        })
      };
    }
    default:
      return state;
  }
};

export default reducer;
