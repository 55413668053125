import React, { useCallback, useState } from "react";
import { Button, FormGroup, Label, Spinner } from "reactstrap";
import { get, head, isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { campaignImageUploadCall } from "../../../api/cloud-functions";
import "easymde/dist/easymde.min.css";
import { getFieldValue } from "../../../utils/form-utils";
import { STATIC_MEDIA_TYPE } from "../../../utils/campaign-utils";

export const CampaignCaseSingleImageSection = (props) => {
  const {
    formData,
    onChange,
    fieldName = "feed_card.feed_card_type",
    prefix = ""
  } = props;
  const existingMedia = getFieldValue(formData, fieldName, prefix);
  const media = !isEqual(get(existingMedia, "type"), STATIC_MEDIA_TYPE.VIDEO)
    ? existingMedia
    : null;

  const setMedia = (fileInfo) => {
    onChange({
      target: {
        name: fieldName,
        value: fileInfo
      }
    });
  };

  return (
    <>
      <FormGroup>
        <Label
          className="text-11 text-uppercase font-weight-normal"
          for="imageSelected">
          <FontAwesomeIcon icon={faPlusCircle} className="link-color mr-2" />
          choose image
        </Label>
        <div className="sortable-image-container" style={{ height: "500px" }}>
          <CampaignFormImage media={media} setMedia={setMedia} />
        </div>
      </FormGroup>
    </>
  );
};

CampaignCaseSingleImageSection.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  formData: PropTypes.object
};

const CampaignFormImage = (props) => {
  const { media, setMedia } = props;
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = head(acceptedFiles);
      if (!file) {
        return;
      }

      setUploading(true);
      const response = await campaignImageUploadCall(file);
      const uploadedMedia = get(response, "media");
      if (uploadedMedia) {
        setMedia(uploadedMedia);
      }
      setUploading(false);
    },
    [setMedia]
  );
  const onRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMedia(null);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false
  });

  return (
    <div {...getRootProps()} className="thumbnail mb-2 mr-2">
      <input {...getInputProps()} />

      {uploading && (
        <div className="centered-container">
          <Spinner color="primary" />
        </div>
      )}

      {!uploading && !media && (
        <div className="centered-container">
          <FontAwesomeIcon
            icon={faPlusCircle}
            size="lg"
            className="text-primary"
          />
        </div>
      )}

      {!uploading && media && (
        <>
          <img
            src={`${media.url}?w=800&h=800&fit=crop`}
            className="img-thumbnail"
            alt=""
          />
          <Button className="btn-round" color="danger" onClick={onRemove}>
            <FontAwesomeIcon icon={faTrash} color="white" />
          </Button>
        </>
      )}
    </div>
  );
};

CampaignFormImage.propTypes = {
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  position: PropTypes.number
};
