import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem
} from "reactstrap";
import {
  MODERATION_CASES,
  MODERATION_COMMENTS,
  MODERATION_COMMENTS_HISTORY,
  MODERATION_PARTNER_CASES
} from "../../constants/routes";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import "./style.scss";

const ModerationTabs = (props) => {
  const { active, activeTab = "cases", platform } = props;

  const [isCasesOpen, setIsCasesOpen] = useState(false);
  const [isPartnerCasesOpen, setIsParterCasesOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isCommentsHistoryOpen, setIsCommentsHistoryOpen] = useState(false);
  const toggleCasesItem = () => setIsCasesOpen(!isCasesOpen);
  const togglePartnerCasesItem = () =>
    setIsParterCasesOpen(!isPartnerCasesOpen);
  const toggleCommentsItem = () => setIsCommentsOpen(!isCommentsOpen);
  const toggleCommentsHistoryItem = () =>
    setIsCommentsHistoryOpen(!isCommentsHistoryOpen);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleModerationDropdown = () =>
    setDropdownOpen((prevState) => !prevState);

  const desktop = (
    <Nav className="tertiaryNav d-none d-sm-none d-md-flex" tabs>
      <NavItem>
        <Link
          onClick={toggleCasesItem}
          className={isEqual(active, "cases") ? "active nav-link" : "nav-link"}
          to={MODERATION_CASES}>
          <span>Cases</span>
        </Link>
      </NavItem>
      <NavItem>
        <Link
          onClick={togglePartnerCasesItem}
          className={
            isEqual(active, "partnerCases") ? "active nav-link" : "nav-link"
          }
          to={MODERATION_PARTNER_CASES}>
          <span>Partner Cases</span>
        </Link>
      </NavItem>
      <NavItem>
        <Link
          onClick={toggleCommentsItem}
          className={
            isEqual(active, "comments") ? "active nav-link" : "nav-link"
          }
          to={MODERATION_COMMENTS}>
          <span>Comments</span>
        </Link>
      </NavItem>
      <NavItem>
        <Link
          onClick={toggleCommentsHistoryItem}
          className={
            isEqual(active, "commentsHistory") ? "active nav-link" : "nav-link"
          }
          to={MODERATION_COMMENTS_HISTORY}>
          <span>Comment History</span>
        </Link>
      </NavItem>
    </Nav>
  );

  const mobile = (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleModerationDropdown}
      className="tertiaryNav mobile d-sm-flex d-md-none w-50">
      <DropdownToggle caret className="w-100 rounded-0 mr-1 text-capitalize">
        {activeTab ? activeTab : `Select Page`}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <Link
            onClick={toggleCasesItem}
            className={
              isEqual(active, "cases") ? "active nav-link" : "nav-link"
            }
            to={MODERATION_CASES}>
            <span>Cases</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link
            onClick={togglePartnerCasesItem}
            className={
              isEqual(active, "partnerCases") ? "active nav-link" : "nav-link"
            }
            to={MODERATION_PARTNER_CASES}>
            <span>Partner Cases</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link
            onClick={toggleCommentsItem}
            className={
              isEqual(active, "comments") ? "active nav-link" : "nav-link"
            }
            to={MODERATION_COMMENTS}>
            <span>Comments</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link
            onClick={toggleCommentsHistoryItem}
            className={
              isEqual(active, "commentsHistory")
                ? "active nav-link"
                : "nav-link"
            }
            to={MODERATION_COMMENTS_HISTORY}>
            <span>Comment History</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  return platform === "mobile" ? mobile : desktop;
};

ModerationTabs.propTypes = {
  active: PropTypes.string
};

export default ModerationTabs;
