import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, FormGroup, FormText, Label } from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import {
  clone,
  filter,
  get,
  isEmpty,
  isNil,
  map,
  pullAt,
  set,
  size
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  deleteCampaignCase,
  saveCampaignClinicalMomentsCase
} from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  CLINICAL_MOMENTS_SLIDE_TYPES,
  getCampaignPageConfigFromTacticState,
  getClinicalMomentContentType,
  getTacticClinicalMomentFormData,
  getTacticState,
  handleSwitchChange,
  isJobCodeActive
} from "../../../utils/campaign-utils";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit
} from "../../../utils/form-utils";
import "easymde/dist/easymde.min.css";
import { CampaignTacticSettings } from "./CampaignTacticSettings";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import RowToggler from "../../../components/common/RowToggler";
import SimpleMDEEditor from "react-simplemde-editor";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import {
  CampaignFormCommonElements,
  CampaignQuizOrPollInputs
} from "./CampaignForms";
import { arrayMove } from "../../../utils/general-utils";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import { CampaignCaseSingleImageSection } from "./CampaignCaseSingleImageSection";
import { CampaignCaseImageSection } from "./CampaignCaseImageSection";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  TacticActionButtons,
  TacticExpandCollapseAll
} from "./CampaignSections";

const EMPTY_SLIDE = {
  slideType: "",
  content_type: ""
};

export const CampaignClinicalMomentForm = (props) => {
  const { caseData, activeSection } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [formData, setFormData] = useState({});

  const campaignUuid = get(activeCampaign, "campaignUuid");
  const slides = get(formData, "slides", []);
  const slidesBottom = useRef(null);
  const slideTypes = get(activeSection, "slideTypes");

  const [reorderActive, setReorderActive] = useState(false);
  const toggleReorder = () => setReorderActive(!reorderActive);

  useEffect(() => {
    setFormData(
      getTacticClinicalMomentFormData(
        campaignUuid,
        caseData,
        activeSection,
        user
      )
    );
  }, [campaignUuid, caseData, activeSection, user]);

  const onSubmit = async () => {
    const submitData = {
      ...formData,
      slides: filter(
        map(formData.slides, (slide) => {
          return {
            ...slide,
            content_type: getClinicalMomentContentType(slide.slideType)
          };
        }),
        (s) => !isNil(s.content_type)
      )
    };

    await dispatch(saveCampaignClinicalMomentsCase(submitData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const addSlide = () => {
    slides.push(clone(EMPTY_SLIDE));

    setFormData(clone(formData));

    slidesBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    setFormData(
      clone(set(formData, "slides", arrayMove(slides, oldIndex, newIndex)))
    );
  };

  const renderSlide = (slide, index) => {
    return (
      <CampaignSlideContainer
        key={index}
        slideIndex={index}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  };

  const SortableSlide = SortableElement(({ position, slide }) => {
    return (
      <CampaignSlideContainer
        key={position}
        slideIndex={position}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  });

  const SortableSlidesContainer = SortableContainer(({ items }) => {
    return (
      <div>
        {map(items, (slide, index) => {
          return (
            <SortableSlide
              key={`slide-${index}`}
              index={index}
              position={index}
              slide={slide}
            />
          );
        })}
      </div>
    );
  });

  const renderSlides = () => {
    if (reorderActive) {
      return (
        <SortableSlidesContainer
          items={slides}
          onSortEnd={onSortEnd}
          axis="xy"
          useDragHandle
        />
      );
    }

    return map(slides, (slide, index) => renderSlide(slide, index));
  };

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
      <TacticExpandCollapseAll />

      <CampaignTacticSettings
        formData={formData}
        onChange={onChange}
        includeIsiField={false}
      />

      {renderSlides()}

      <div className="add-slide" onClick={addSlide}>
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        ADD SLIDE
      </div>

      {size(slides) > 1 && (
        <div className="d-flex justify-content-end">
          <Button color="link" onClick={toggleReorder}>
            {reorderActive
              ? "Disable Slide Reordering"
              : "Enable Slide Reordering"}
          </Button>
        </div>
      )}

      <CampaignFormButtons saving={saving} caseData={caseData} />
      <div ref={slidesBottom} />
    </AvForm>
  );
};

CampaignClinicalMomentForm.propTypes = {
  activeSection: PropTypes.object,
  caseData: PropTypes.object
};

export const CampaignFormButtons = (props) => {
  const { saving = false, caseData } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const deleting = useSelector((state) => state.campaigns.deleting);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const caseUuid = get(caseData, "caseUuid");
  const caseState = get(caseData, "caseState");
  const tacticState = getTacticState(caseState);

  const removeTactic = async () => {
    dispatch(deleteCampaignCase(caseUuid, campaignUuid, history));
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4 p-3 ">
        <div>
          {caseUuid && (
            <>
              <Button
                color="red"
                className="mr-3"
                size="lg"
                onClick={toggleDeleteModal}>
                Delete Tactic
              </Button>
              <TacticActionButtons
                showEdit={false}
                campaign={activeCampaign}
                tacticUuid={caseUuid}
                tacticState={tacticState}
                pageConfig={getCampaignPageConfigFromTacticState(tacticState)}
              />
            </>
          )}
        </div>
        <div className="d-inline-flex justify-content-end">
          <Button
            size="lg"
            color="secondary"
            onClick={() => {
              history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
            }}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Save
          </LoadingButton>
        </div>
      </div>
      <ConfirmModal
        toggle={toggleDeleteModal}
        isOpen={deleteModal}
        message="Are you sure you want to delete this tactic?"
        onConfirm={removeTactic}
        saving={deleting}
      />
    </>
  );
};

CampaignFormButtons.propTypes = {
  saving: PropTypes.bool,
  caseData: PropTypes.object
};

const CampaignSlideContainer = (props) => {
  const { formData, setFormData, slideIndex, slideTypes, reorderActive } =
    props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);
  const [removeSlideModal, setRemoveSlideModal] = useState(false);
  const toggleRemoveSlideModal = () => setRemoveSlideModal(!removeSlideModal);

  const prefix = `slides[${slideIndex}]`;
  const slideType = get(formData, `${prefix}.slideType`);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const removeSlide = () => {
    const slides = getFieldValue(formData, "slides");
    pullAt(slides, [slideIndex]);
    setFormData(clone(formData));
  };

  const renderSlideTypeData = () => {
    switch (slideType) {
      case CLINICAL_MOMENTS_SLIDE_TYPES.FEEDCARD:
        return (
          <GeneralFeedcard
            formData={formData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE:
        return (
          <SlideCover
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.BASIC:
        return (
          <SlideBasic
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.QUIZ:
        return (
          <SlideQuiz
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.POLL:
        return (
          <SlidePoll
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.CONCLUSION:
        return (
          <SlideConclusion
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      default:
        return null;
    }
  };

  const renderSlideName = () => {
    if (isEmpty(slideType)) {
      return `Slide ${slideIndex + 1}`;
    }

    return `Slide ${slideIndex + 1}: ${slideType}`;
  };

  const DragHandle = SortableHandle(() => (
    <span className="drag-handle mr-2 text-secondary cursor-grab">
      <FontAwesomeIcon icon={faArrowsAlt} />
    </span>
  ));

  return (
    <div className="bg-white p-3 mt-4">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">
          {reorderActive && <DragHandle />}
          {renderSlideName()}
        </div>
        <RowToggler
          toggle={toggleCollapse}
          collapse={collapse}
          large={true}
          disabled={reorderActive}
        />
      </div>
      {!reorderActive && (
        <Collapse isOpen={collapse} className="pt-2 slide-body">
          <AvField
            type="select"
            name={getFieldName("slideType", prefix)}
            labelClass="text-11 text-uppercase font-weight-normal"
            label="slide type"
            value={slideType || ""}
            onChange={onChange}>
            <option value="">Select...</option>
            {map(slideTypes, (v, key) => {
              return (
                <option key={`slide-type-${key}`} value={v}>
                  {v}
                </option>
              );
            })}
          </AvField>

          {renderSlideTypeData()}

          <div className="d-flex justify-content-start pt-3">
            <Button size="md" color="red" onClick={toggleRemoveSlideModal}>
              Delete Slide
            </Button>
          </div>
        </Collapse>
      )}

      <ConfirmModal
        toggle={toggleRemoveSlideModal}
        isOpen={removeSlideModal}
        message="Are you sure you want to delete slide?"
        onConfirm={removeSlide}
      />
    </div>
  );
};

CampaignSlideContainer.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  slideIndex: PropTypes.number,
  slideTypes: PropTypes.object,
  reorderActive: PropTypes.bool
};

const GeneralFeedcard = (props) => {
  const { formData, onChange, prefix } = props;

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() => handleSwitchChange(onChange, fieldName, !value)}
      />
    );
  };

  return (
    <>
      <FormGroup className="w-25">
        <Label className="text-11 text-uppercase font-weight-normal">
          job code
        </Label>
        {renderToggleSwitch("features.job_code_enabled")}
      </FormGroup>
      <AvField
        name={getFieldName("sponsored_content.job_code")}
        value={getFieldValue(formData, "sponsored_content.job_code")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "sponsored_content.job_code"),
          40
        )}
        label="Job code"
        placeholder="Enter code here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        disabled={!isJobCodeActive(formData)}
        onChange={onChange}
      />
      <AvField
        name={getFieldName("feed_card_label", prefix)}
        value={getFieldValue(formData, "feed_card_label", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card_label", prefix),
          23
        )}
        label="Content label"
        placeholder="Enter label here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("feed_card_title", prefix)}
        value={getFieldValue(formData, "feed_card_title", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card_title", prefix),
          84
        )}
        label="banner title"
        placeholder="Enter banner here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <CampaignCaseSingleImageSection
        formData={formData}
        onChange={onChange}
        fieldName={getFieldName("feed_card_media", prefix)}
      />
      <AvField
        name={getFieldName("colour", prefix)}
        value={getFieldValue(formData, "colour", prefix)}
        label="banner colour code"
        placeholder="Hex value here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("button_text", prefix)}
        value={getFieldValue(formData, "button_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "button_text", prefix),
          21
        )}
        label="CTA text"
        placeholder="Enter cta text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
    </>
  );
};

const SlideQuiz = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={true}
        prefix={prefix}
        isiActive={true}
        includeIsiToggle={true}
        singleImage={true}
      />
    </>
  );
};

const SlidePoll = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={false}
        prefix={prefix}
        isiActive={true}
        includeIsiToggle={true}
        singleImage={true}
      />
    </>
  );
};

const SlideCover = (props) => {
  const { formData, onChange, setFormData, prefix } = props;

  const handleChange = (value) => {
    onChange({
      target: { name: getFieldName("caption", prefix), value }
    });
  };

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() => handleSwitchChange(onChange, fieldName, !value)}
      />
    );
  };

  return (
    <>
      <FormGroup className="w-25">
        <Label className="text-11 text-uppercase font-weight-normal">
          job code
        </Label>
        {renderToggleSwitch("features.job_code_enabled")}
      </FormGroup>
      <AvField
        name={getFieldName("sponsored_content.job_code")}
        value={getFieldValue(formData, "sponsored_content.job_code")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "sponsored_content.job_code"),
          40
        )}
        label="Job code"
        placeholder="Enter code here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        disabled={!isJobCodeActive(formData)}
        onChange={onChange}
      />
      <AvField
        name={getFieldName("feed_card_label", prefix)}
        value={getFieldValue(formData, "feed_card_label", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card_label", prefix),
          20
        )}
        label="Content label"
        placeholder="Enter label here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("title", prefix)}
        value={getFieldValue(formData, "title", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "title", prefix),
          93
        )}
        label="intro slide card title"
        placeholder="Enter intro slide card title here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"]
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <AvField
        name={getFieldName("references", prefix)}
        value={getFieldValue(formData, "references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />

      <CampaignCaseImageSection
        formData={formData}
        onChange={onChange}
        setFormData={setFormData}
        prefix={prefix}
        includeVideo={false}
        singleImage={true}
      />

      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={prefix}
        isiActive={false}
        includeIsiToggle={false}
      />

      <AvField
        name={getFieldName("button_text", prefix)}
        value={getFieldValue(formData, "button_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "button_text", prefix),
          21
        )}
        label="button text"
        placeholder="Enter cta text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
    </>
  );
};

export const SlideBasic = (props) => {
  const {
    formData,
    onChange,
    setFormData,
    prefix,
    includeIsiField = true
  } = props;

  const handleChange = (value) => {
    onChange({
      target: { name: getFieldName("caption", prefix), value }
    });
  };

  return (
    <>
      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"]
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <AvField
        name={getFieldName("references", prefix)}
        value={getFieldValue(formData, "references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />

      <CampaignCaseImageSection
        formData={formData}
        onChange={onChange}
        setFormData={setFormData}
        prefix={prefix}
        singleImage={true}
      />

      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={prefix}
        isiActive={includeIsiField}
        includeIsiToggle={includeIsiField}
      />
    </>
  );
};

export const SlideConclusion = (props) => {
  const {
    formData,
    onChange,
    setFormData,
    prefix,
    includeIsiField = true
  } = props;

  const handleChange = (value) => {
    onChange({
      target: { name: getFieldName("caption", prefix), value }
    });
  };

  return (
    <>
      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"]
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <AvField
        name={getFieldName("references", prefix)}
        value={getFieldValue(formData, "references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />

      <CampaignCaseImageSection
        formData={formData}
        onChange={onChange}
        setFormData={setFormData}
        prefix={prefix}
        includeVideo={false}
        singleImage={true}
      />

      <AvField
        name={getFieldName("button_text", prefix)}
        value={getFieldValue(formData, "button_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "button_text", prefix),
          23
        )}
        label="button text"
        placeholder="Enter button text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />

      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={prefix}
        isiActive={includeIsiField}
        includeIsiToggle={includeIsiField}
      />
    </>
  );
};
