import React, { useCallback, useState } from "react";
import { Button, CustomInput, FormGroup, Label, Spinner } from "reactstrap";
import {
  clone,
  compact,
  fill,
  get,
  head,
  isEqual,
  map,
  set,
  size,
  filter
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAlt,
  faPlusCircle,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { STATIC_MEDIA_TYPE } from "../../../utils/campaign-utils";
import { useDropzone } from "react-dropzone";
import { campaignImageUploadCall } from "../../../api/cloud-functions";
import "easymde/dist/easymde.min.css";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { arrayMove } from "../../../utils/general-utils";
import { getFieldName, getFieldValue } from "../../../utils/form-utils";
import { AvField } from "availity-reactstrap-validation";
import { CampaignCaseSingleImageSection } from "./CampaignCaseSingleImageSection";

export const CampaignCaseImageSection = (props) => {
  const {
    formData,
    onChange,
    setFormData,
    prefix = "",
    includeVideo = true,
    singleImage = false
  } = props;
  const fieldName = getFieldName("media", prefix);
  const populatedMedia = filter(
    getFieldValue(formData, "media", prefix, []),
    (o) => !isEqual(get(o, "type"), STATIC_MEDIA_TYPE.VIDEO)
  );
  const mediaType = getFieldValue(formData, "mediaType", prefix);
  const currentSize = size(populatedMedia);
  if (currentSize < 10) {
    populatedMedia[9] = null;
  }
  const images = fill(populatedMedia, null, currentSize);
  const totalImages = size(compact(images));

  const setMedia = (fileInfo, position) => {
    images[position] = fileInfo;

    setFormData(clone(set(formData, fieldName, [...images])));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    setFormData(
      clone(set(formData, fieldName, arrayMove(images, oldIndex, newIndex)))
    );
  };

  const SortableImage = SortableElement(({ position, media }) => {
    return (
      <div className="mb-3 mr-3 sortable-image-container">
        <CampaignFormImage
          position={position}
          media={media}
          setMedia={setMedia}
          showHandle={true}
        />
      </div>
    );
  });

  const SortableImageContainer = SortableContainer(({ items }) => {
    return (
      <div className="d-flex flex-wrap justify-content-center image-selection">
        {map(items, (media, index) => {
          return (
            <SortableImage
              key={`item-${index}`}
              index={index}
              position={index}
              media={media}
            />
          );
        })}
      </div>
    );
  });

  const renderImages = () => {
    if (totalImages > 1) {
      return (
        <SortableImageContainer
          items={images}
          onSortEnd={onSortEnd}
          axis="xy"
          useDragHandle
        />
      );
    }

    return (
      <div className="d-flex flex-wrap justify-content-center image-selection">
        {map(images, (media, index) => {
          return (
            <div
              key={`item-${index}`}
              className="mb-3 mr-3 sortable-image-container">
              <CampaignFormImage
                position={index}
                media={media}
                setMedia={setMedia}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <FormGroup>
        <div className="d-flex justify-content-between">
          <Label
            className="text-11 text-uppercase font-weight-normal"
            for="externalLinkUrl">
            media type
          </Label>
          <span className="text-11 text-secondary"></span>
        </div>
        <div>
          <CustomInput
            id={getFieldName("imageMediaType", prefix)}
            type="radio"
            label="Image"
            name={getFieldName("mediaType", prefix)}
            value={STATIC_MEDIA_TYPE.IMAGE}
            checked={isEqual(mediaType, STATIC_MEDIA_TYPE.IMAGE)}
            onChange={onChange}
            inline
          />
          {includeVideo && (
            <CustomInput
              id={getFieldName("videoMediaType", prefix)}
              type="radio"
              label="Vimeo video"
              name={getFieldName("mediaType", prefix)}
              value={STATIC_MEDIA_TYPE.VIDEO}
              checked={isEqual(mediaType, STATIC_MEDIA_TYPE.VIDEO)}
              onChange={onChange}
              inline
            />
          )}
          <CustomInput
            id={getFieldName("noMediaType", prefix)}
            type="radio"
            label="No media"
            name={getFieldName("mediaType", prefix)}
            value={STATIC_MEDIA_TYPE.NO_MEDIA}
            checked={isEqual(mediaType, STATIC_MEDIA_TYPE.NO_MEDIA)}
            onChange={onChange}
            inline
          />
        </div>
      </FormGroup>
      {isEqual(mediaType, STATIC_MEDIA_TYPE.IMAGE) && (
        <>
          {singleImage && (
            <CampaignCaseSingleImageSection
              formData={formData}
              onChange={onChange}
              fieldName={getFieldName("media[0]", prefix)}
            />
          )}
          {!singleImage && (
            <FormGroup>
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="imageSelected">
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="link-color mr-2"
                />
                choose image(s) (drag to re-order)
              </Label>
              {renderImages()}
            </FormGroup>
          )}
        </>
      )}
      {isEqual(mediaType, STATIC_MEDIA_TYPE.VIDEO) && (
        <AvField
          name={getFieldName("vimeoFileName", prefix)}
          value={getFieldValue(formData, "vimeoFileName", prefix)}
          label="Vimeo Filename"
          placeholder="Enter Vimeo filename here (i.e. 399980347)"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          groupAttrs={{ className: "flex-fill mr-3" }}
          onChange={onChange}
          required
        />
      )}
    </>
  );
};

CampaignCaseImageSection.propTypes = {
  onChange: PropTypes.func,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  includeVideo: PropTypes.bool,
  singleImage: PropTypes.bool
};

const CampaignFormImage = (props) => {
  const { media, setMedia, position, showHandle } = props;
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = head(acceptedFiles);
      if (!file) {
        return;
      }

      setUploading(true);
      const response = await campaignImageUploadCall(file);
      const uploadedMedia = get(response, "media");
      if (uploadedMedia) {
        setMedia(uploadedMedia, position);
      }
      setUploading(false);
    },
    [setMedia, position]
  );
  const onRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setMedia(null, position);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false
  });

  const DragHandle = SortableHandle(() => (
    <Button className="drag-handle btn-round" color="danger">
      <FontAwesomeIcon icon={faArrowsAlt} color="white" />
    </Button>
  ));

  return (
    <div {...getRootProps()} className="thumbnail mb-2 mr-2">
      <input {...getInputProps()} />

      {uploading && (
        <div className="centered-container">
          <Spinner color="primary" />
        </div>
      )}

      {!uploading && !media && (
        <div className="centered-container">
          <FontAwesomeIcon
            icon={faPlusCircle}
            size="lg"
            className="text-primary"
          />
        </div>
      )}

      {!uploading && media && (
        <>
          {showHandle && <DragHandle />}
          <img
            src={`${media.url}?w=300&h=300&fit=crop`}
            className="img-thumbnail"
            alt=""
          />
          <Button className="btn-round" color="danger" onClick={onRemove}>
            <FontAwesomeIcon icon={faTrash} color="white" />
          </Button>
        </>
      )}
    </div>
  );
};

CampaignFormImage.propTypes = {
  onImageUpload: PropTypes.func,
  onImageRemove: PropTypes.func,
  position: PropTypes.number,
  showHandle: PropTypes.bool
};
