import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import VerificationLandingPage from "./verification/VerificationLandingPage";
import { VERIFICATION_MODULE_NAME } from "../constants/route-constants";
import GlobalHeader from "../components/GlobalHeader";
import { userSignedIn } from "../utils/route-utils";
import LoadingPage from "./home/LoadingPage";
import { isVerifier } from "../utils/permission-utils";

class VerificationApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth, userRoles } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    if (!isVerifier(userRoles)) {
      return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={VERIFICATION_MODULE_NAME} />
        <Switch>
          <Route
            exact
            path={`${ROUTES.VERIFICATION_ROOT}/:verificationType/:verificationStatus`}>
            {this.conditionallyRenderDefaultOrPage(<VerificationLandingPage />)}
          </Route>
          <Route exact path={`${ROUTES.VERIFICATION_ROOT}/:verificationType`}>
            {this.conditionallyRenderDefaultOrPage(<VerificationLandingPage />)}
          </Route>
          <Route exact path={ROUTES.VERIFICATION_ROOT}>
            {this.conditionallyRenderDefaultOrPage(<VerificationLandingPage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.user.userRoles
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(VerificationApp);
