import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row
} from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvInput
} from "availity-reactstrap-validation";
import {
  clone,
  defaultTo,
  filter,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  pullAt,
  range,
  set,
  toNumber
} from "lodash";
import LoadingButton from "../../../components/common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit
} from "../../../utils/form-utils";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import {
  PROFILE_TYPE,
  USER_TYPE_MAPPING
} from "../../../utils/campaign-profile-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  findUser,
  saveProfile,
  setActiveUser
} from "../../../actions/profile.actions";
import Loading from "../../../components/Loading";
import EmptyContainer from "../../../components/common/EmptyContainer";
import { ProfileImage } from "./ProfileImage";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import F1SubLabel from "../../../components/common/F1SubLabel";

export const ProfileMainForm = (props) => {
  const { existing = false } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUser = useSelector((state) => state.profile.activeUser);
  const saving = useSelector((state) => state.profile.userSaving);
  const savedSearchTerm = useSelector((state) => state.profile.savedSearchTerm);
  const searching = useSelector((state) => state.profile.searching);
  const searchNoResults = useSelector((state) => state.profile.searchNoResults);
  const professions = useSelector((state) => state.reference.professions);
  const filteredProfessions = professions?.filter((p) => !!p.uuid);

  const [formData, setFormData] = useState({});
  const selectedProfession = find(professions, (s) =>
    isEqual(s.uuid, formData.professionUuid)
  );
  const specialties = get(selectedProfession, "specialties", []);

  const [searchTerm, setSearchTerm] = useState(null);

  const isInstitutional = isEqual(
    getFieldValue(formData, "userType"),
    USER_TYPE_MAPPING.InstitutionalUser
  );
  const isIndividual = !isInstitutional;

  const getProfession = useCallback(() => {
    const professionUuid = get(
      activeUser,
      "primarySpecialty.tree.profession.professionUuid"
    );

    return find(professions, (p) => isEqual(p.typeUuid, professionUuid));
  }, [activeUser, professions]);

  const getSpecialty = useCallback(
    (profession) => {
      if (!profession) {
        return null;
      }

      const specialtyUuid = get(
        activeUser,
        "primarySpecialty.tree.specialty.specialtyUuid"
      );

      return find(get(profession, "specialties"), (s) =>
        isEqual(s.typeUuid, specialtyUuid)
      );
    },
    [activeUser]
  );

  useEffect(() => {
    const profession = getProfession();
    const specialty = getSpecialty(profession);
    const professionTreeUuid = get(profession, "uuid");
    const specialtyTreeUuid = get(specialty, "uuid");

    setFormData({
      userType:
        USER_TYPE_MAPPING[get(activeUser, "userType")] ||
        USER_TYPE_MAPPING.Figure1Member,
      userUid: get(activeUser, "userUid"),
      userUuid: get(activeUser, "userUuid"),
      email: defaultTo(get(activeUser, "email"), ""),
      username: defaultTo(get(activeUser, "username"), ""),
      userDisplayName: defaultTo(get(activeUser, "displayName"), ""),
      userPracticeLocation: defaultTo(get(activeUser, "practiceLocation"), ""),
      userPracticeHospital: defaultTo(get(activeUser, "practiceHospital"), ""),
      userBio: defaultTo(get(activeUser, "userBio"), ""),
      profileDisplayName: defaultTo(get(activeUser, "profileDisplayName"), ""),
      caseCommentDisplayName: defaultTo(
        get(activeUser, "caseCommentDisplayName"),
        ""
      ),
      profileLink: defaultTo(get(activeUser, "profileLink"), ""),
      profileLinkText: defaultTo(get(activeUser, "profileLinkText"), ""),
      disclosureText: defaultTo(get(activeUser, "disclosureText"), ""),
      caseFeedEnabled: defaultTo(get(activeUser, "caseFeedEnabled"), false),
      caseFeedTitle: defaultTo(get(activeUser, "caseFeedTitle"), ""),
      professionUuid: defaultTo(professionTreeUuid, ""),
      specialtyUuid: defaultTo(specialtyTreeUuid, ""),
      experience: get(activeUser, "experience", []),
      education: get(activeUser, "education", []),
      affiliations: get(activeUser, "affiliations", []),
      avatar: get(activeUser, "avatar"),
      backgroundImage: get(activeUser, "backgroundImage")
    });
  }, [activeUser, getProfession, getSpecialty]);

  useEffect(() => {
    setSearchTerm(isNil(savedSearchTerm) ? "" : savedSearchTerm);
  }, [savedSearchTerm]);

  const onSubmit = () => {
    const cleanDateFields = (col) => {
      return map(
        filter(col, (a) => !isEmpty(a.startYear)),
        (a) => {
          return {
            ...a,
            startYear: toNumber(a.startYear),
            endYear: isEmpty(a.endYear) ? null : toNumber(a.endYear)
          };
        }
      );
    };

    // institutional can only be true, individuals can be enabled or not
    const caseFeedEnabled = isIndividual ? formData.caseFeedEnabled : true;

    const data = {
      ...formData,
      caseFeedEnabled,
      profileLink: isEmpty(formData.profileLink)
        ? undefined
        : formData.profileLink,
      experience: cleanDateFields(formData.experience),
      education: cleanDateFields(formData.education),
      affiliations: cleanDateFields(formData.affiliations)
    };

    data.primarySpecialty = isEmpty(formData.specialtyUuid)
      ? formData.professionUuid
      : formData.specialtyUuid;

    dispatch(saveProfile(data, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    if (isEqual(name, "professionUuid")) {
      set(formData, name, value);
      set(formData, "specialtyUuid", "");
      setFormData(clone(formData));
    } else if (isEqual(name, "caseFeedEnabled")) {
      setFormData(clone(set(formData, name, value === "true")));
    } else {
      setFormData(clone(set(formData, name, value)));
    }
  };

  const onCancel = () => {
    dispatch(setActiveUser(null));

    history.push(`${ROUTES.CAMPAIGN_PROFILE}/existing`);
  };

  const onSearchSubmit = async (event) => {
    event.preventDefault();

    dispatch(findUser(searchTerm, history));
  };

  const onChangeSearchText = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const renderIndividualFields = () => {
    if (!isIndividual) {
      return null;
    }

    return (
      <>
        <FormGroup>
          <div className="d-flex justify-content-between">
            <Label className="text-15 font-weight-bold">
              Select type of account
            </Label>
            <span className="text-11 text-secondary"></span>
          </div>
          <div>
            <CustomInput
              id={getFieldName("individualToggleAbout")}
              type="radio"
              label="About"
              name={getFieldName("caseFeedEnabled")}
              value={"false"}
              checked={!getFieldValue(formData, "caseFeedEnabled")}
              onChange={onChange}
            />
            <CustomInput
              id={getFieldName("individualToggleCase")}
              type="radio"
              label="Case feed"
              name={getFieldName("caseFeedEnabled")}
              value={"true"}
              checked={getFieldValue(formData, "caseFeedEnabled")}
              onChange={onChange}
            />
          </div>
        </FormGroup>

        {renderIndividualAboutFields()}
        {renderIndividualCaseFeedFields()}
      </>
    );
  };

  const renderIndividualAboutFields = () => {
    if (formData.caseFeedEnabled) {
      return null;
    }

    const experience = getFieldValue(formData, "experience");
    const education = getFieldValue(formData, "education");
    const memberships = getFieldValue(formData, "affiliations");

    const addExperience = () => {
      onAddSection(experience);
    };

    const addEducation = () => {
      onAddSection(education);
    };

    const addMembership = () => {
      onAddSection(memberships);
    };

    const onAddSection = (typeArray) => {
      typeArray.push({});
      setFormData(clone(formData));
    };

    const onRemoveSection = (typeArray, index) => {
      pullAt(typeArray, [index]);
      setFormData(clone(formData));
    };

    return (
      <>
        <Row>
          <div className="col ">
            <div className="profile-about-section-header">
              {isEmpty(experience) && (
                <div className="text-15 font-weight-bold">Experience</div>
              )}

              {map(experience, (e, idx) => {
                return (
                  <div
                    key={`experience-${idx}`}
                    className="profile-group-section">
                    <div className="d-flex justify-content-between">
                      <div className="text-15 font-weight-bold">
                        Experience {idx > 0 ? ` ${idx + 1}` : ""}
                      </div>
                      <Button
                        size="md"
                        color="red"
                        onClick={() => onRemoveSection(experience, idx)}>
                        Delete
                      </Button>
                    </div>

                    <Row>
                      <AvField
                        name={getFieldName(`experience[${idx}].description`)}
                        value={getFieldValue(
                          formData,
                          `experience[${idx}].description`
                        )}
                        label="position"
                        placeholder="Type in position"
                        type="text"
                        labelClass="text-11 text-uppercase font-weight-normal"
                        groupAttrs={{ className: "col" }}
                        onChange={onChange}
                        required
                      />

                      <AvField
                        name={getFieldName(`experience[${idx}].location`)}
                        value={getFieldValue(
                          formData,
                          `experience[${idx}].location`
                        )}
                        label="institution/employer"
                        placeholder="Type in institution"
                        labelClass="text-11 text-uppercase font-weight-normal"
                        groupAttrs={{ className: "col" }}
                        onChange={onChange}
                        required
                      />
                    </Row>
                    <Row>
                      <div className="col">
                        <Row>
                          <AvField
                            name={getFieldName(`experience[${idx}].startYear`)}
                            value={getFieldValue(
                              formData,
                              `experience[${idx}].startYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="start date"
                            onChange={onChange}
                            required>
                            <option value="">Select...</option>
                            {map(range(1940, 2050), (c) => {
                              return (
                                <option key={`exp-start-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>

                          <AvField
                            name={getFieldName(`experience[${idx}].endYear`)}
                            value={getFieldValue(
                              formData,
                              `experience[${idx}].endYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="end date"
                            onChange={onChange}>
                            <option value="">Select...</option>
                            {map(range(1940, 2050), (c) => {
                              return (
                                <option key={`exp-end-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>
                        </Row>
                      </div>
                      <div className="col"></div>
                    </Row>
                  </div>
                );
              })}

              <div className="add-new-section" onClick={addExperience}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                ADD MORE EXPERIENCE
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col">
            <div className="profile-about-section-header">
              {isEmpty(education) && (
                <div className="text-15 font-weight-bold">Education</div>
              )}

              {map(education, (e, idx) => {
                return (
                  <div
                    key={`education-${idx}`}
                    className="profile-group-section">
                    <div className="d-flex justify-content-between">
                      <div className="text-15 font-weight-bold">
                        Education {idx > 0 ? ` ${idx + 1}` : ""}
                      </div>
                      <Button
                        size="md"
                        color="red"
                        onClick={() => onRemoveSection(education, idx)}>
                        Delete
                      </Button>
                    </div>

                    <Row>
                      <AvField
                        name={getFieldName(`education[${idx}].location`)}
                        value={getFieldValue(
                          formData,
                          `education[${idx}].location`
                        )}
                        label="school name"
                        placeholder="Type in school name"
                        type="text"
                        labelClass="text-11 text-uppercase font-weight-normal"
                        groupAttrs={{ className: "col" }}
                        onChange={onChange}
                        required
                      />

                      <AvField
                        name={getFieldName(`education[${idx}].description`)}
                        value={getFieldValue(
                          formData,
                          `education[${idx}].description`
                        )}
                        label="degree and program"
                        placeholder="Type in degree"
                        labelClass="text-11 text-uppercase font-weight-normal"
                        groupAttrs={{ className: "col" }}
                        onChange={onChange}
                        required
                      />
                    </Row>
                    <Row>
                      <div className="col">
                        <Row>
                          <AvField
                            name={getFieldName(`education[${idx}].startYear`)}
                            value={getFieldValue(
                              formData,
                              `education[${idx}].startYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="start date"
                            onChange={onChange}
                            required>
                            <option value="">Select...</option>
                            {map(range(1940, 2050), (c) => {
                              return (
                                <option key={`edu-start-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>

                          <AvField
                            name={getFieldName(`education[${idx}].endYear`)}
                            value={getFieldValue(
                              formData,
                              `education[${idx}].endYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="end date"
                            onChange={onChange}>
                            <option value="">Select...</option>
                            {map(range(1940, 2050), (c) => {
                              return (
                                <option key={`edu-end-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>
                        </Row>
                      </div>
                      <div className="col"></div>
                    </Row>
                  </div>
                );
              })}

              <div className="add-new-section" onClick={addEducation}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                ADD MORE EDUCATION
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col">
            <div className="profile-about-section-header">
              {isEmpty(memberships) && (
                <div className="text-15 font-weight-bold">
                  Affiliations and Memberships
                </div>
              )}

              {map(memberships, (e, idx) => {
                return (
                  <div
                    key={`membership-${idx}`}
                    className="profile-group-section">
                    <div className="d-flex justify-content-between">
                      <div className="text-15 font-weight-bold">
                        Affiliations and Memberships{" "}
                        {idx > 0 ? ` ${idx + 1}` : ""}
                      </div>
                      <Button
                        size="md"
                        color="red"
                        onClick={() => onRemoveSection(memberships, idx)}>
                        Delete
                      </Button>
                    </div>

                    <Row>
                      <AvField
                        name={getFieldName(`affiliations[${idx}].description`)}
                        value={getFieldValue(
                          formData,
                          `affiliations[${idx}].description`
                        )}
                        label="affiliation/membership name"
                        placeholder="Type in affiliation/membership name"
                        type="text"
                        labelClass="text-11 text-uppercase font-weight-normal"
                        groupAttrs={{ className: "col" }}
                        onChange={onChange}
                        required
                      />

                      <div className="col">
                        <Row>
                          <AvField
                            name={getFieldName(
                              `affiliations[${idx}].startYear`
                            )}
                            value={getFieldValue(
                              formData,
                              `affiliations[${idx}].startYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="start date"
                            onChange={onChange}
                            required>
                            <option value="">Select...</option>
                            {map(range(1980, 2050), (c) => {
                              return (
                                <option key={`aff-start-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>

                          <AvField
                            name={getFieldName(`affiliations[${idx}].endYear`)}
                            value={getFieldValue(
                              formData,
                              `affiliations[${idx}].endYear`
                            )}
                            type="select"
                            labelClass="text-11 text-uppercase font-weight-normal"
                            groupAttrs={{ className: "col" }}
                            label="end date"
                            onChange={onChange}>
                            <option value="">Select...</option>
                            {map(range(1980, 2050), (c) => {
                              return (
                                <option key={`aff-end-${c}`} value={c}>
                                  {c}
                                </option>
                              );
                            })}
                          </AvField>
                        </Row>
                      </div>
                    </Row>
                  </div>
                );
              })}

              <div className="add-new-section" onClick={addMembership}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                ADD MORE AFFILIATIONS AND MEMBERSHIPS
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  };

  const renderIndividualCaseFeedFields = () => {
    if (!formData.caseFeedEnabled) {
      return null;
    }

    return (
      <Row>
        <AvField
          name={getFieldName("caseFeedTitle")}
          value={getFieldValue(formData, "caseFeedTitle")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "caseFeedTitle"),
            66
          )}
          label={
            <>
              <span className="text-uppercase">case feed title</span>{" "}
              <span>(Optional)</span>
            </>
          }
          placeholder="Type in case feed title"
          type="text"
          labelClass="text-11 font-weight-normal"
          groupAttrs={{ className: "col" }}
          onChange={onChange}
        />
      </Row>
    );
  };

  const renderInstitutionalFields = () => {
    if (!isInstitutional) {
      return null;
    }

    return (
      <Row>
        <AvField
          name={getFieldName("caseFeedTitle")}
          value={getFieldValue(formData, "caseFeedTitle")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "caseFeedTitle"),
            66
          )}
          label={
            <>
              <span className="text-uppercase">case feed title</span>{" "}
              <span>(Optional)</span>
            </>
          }
          placeholder="Type in case feed title"
          type="text"
          labelClass="text-11 font-weight-normal"
          groupAttrs={{ className: "col" }}
          onChange={onChange}
        />
      </Row>
    );
  };

  const renderUserBody = () => {
    if (existing && searching) {
      return <Loading />;
    }

    if (existing && searchNoResults) {
      return (
        <EmptyContainer
          title="No Profile Found"
          description="No profile was found for the search term."
        />
      );
    }

    if (existing && !activeUser) {
      return null;
    }

    return (
      <AvForm
        className="px-2"
        onValidSubmit={onSubmit}
        onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
        <Row>
          <div className="col">
            <FormGroup>
              <div className="d-flex justify-content-between">
                <Label className="text-15 font-weight-bold">
                  Select type of account
                </Label>
                <span className="text-11 text-secondary"></span>
              </div>
              <div>
                <CustomInput
                  id={getFieldName("individualType")}
                  type="radio"
                  label={PROFILE_TYPE.USER}
                  name={getFieldName("userType")}
                  value={USER_TYPE_MAPPING.Figure1Member}
                  checked={isIndividual}
                  onChange={onChange}
                />
                <CustomInput
                  id={getFieldName("institutionalType")}
                  type="radio"
                  label={PROFILE_TYPE.FIGURE1_INSTITUTIONAL}
                  name={getFieldName("userType")}
                  value={USER_TYPE_MAPPING.InstitutionalUser}
                  checked={isInstitutional}
                  onChange={onChange}
                />
              </div>
            </FormGroup>
          </div>
        </Row>
        {formData.userUid && (
          <Row>
            <div className="col">
              <div className="d-flex">
                <ProfileImage
                  formData={formData}
                  onChange={onChange}
                  userUid={formData.userUid}
                />

                {isInstitutional && (
                  <ProfileImage
                    formData={formData}
                    onChange={onChange}
                    userUid={formData.userUid}
                    fieldName="backgroundImage"
                    fieldLabel="upload background image"
                  />
                )}
              </div>
            </div>
          </Row>
        )}
        <Row>
          <AvField
            name={getFieldName("userDisplayName")}
            value={getFieldValue(formData, "userDisplayName")}
            label="display name"
            placeholder="Enter display name..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
            required
          />{" "}
          <AvField
            name={getFieldName("username")}
            value={getFieldValue(formData, "username")}
            label="username"
            placeholder="Type in username"
            labelClass="text-11 text-uppercase font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
            required
          />
        </Row>

        <Row>
          <AvField
            name={getFieldName("email")}
            value={getFieldValue(formData, "email")}
            label={
              <>
                <span className="text-uppercase">email</span>{" "}
                <span>(not shown in profile)</span>
              </>
            }
            placeholder="Type in email"
            labelClass="text-11  font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
            required
          />
        </Row>

        <Row>
          <FormGroup className="col">
            <AvField
              type="select"
              name={getFieldName("professionUuid")}
              labelClass="text-11 font-weight-normal"
              label={
                <>
                  <span className="text-uppercase">profession</span>{" "}
                  {isInstitutional && <span>(not shown in profile)</span>}
                </>
              }
              value={getFieldValue(formData, "professionUuid")}
              onChange={onChange}
              required>
              <option value="">Select...</option>
              {map(filteredProfessions, (c) => (
                <option key={`profession-${c.uuid}`} value={c.uuid}>
                  {c.name}
                </option>
              ))}
            </AvField>
          </FormGroup>

          <FormGroup className="col">
            <Label className="text-11 font-weight-normal">
              <>
                <span className="text-uppercase">specialty</span>{" "}
                {isInstitutional && <span>(not shown in profile)</span>}
              </>
            </Label>
            <Input
              type="select"
              name={getFieldName("specialtyUuid")}
              value={getFieldValue(formData, "specialtyUuid")}
              onChange={onChange}>
              <option value="">Select...</option>
              {map(specialties, (c) => {
                return (
                  <option key={`specialty-${c.uuid}`} value={c.uuid}>
                    {c.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Row>

        {isInstitutional && (
          <>
            <Row>
              <AvGroup className="col">
                <Label
                  className="text-11 font-weight-normal"
                  for={getFieldName("caseCommentDisplayName")}>
                  <span className="text-uppercase">Case/ comment label</span>
                </Label>
                <F1SubLabel>
                  This will display below username on case posts/tactics and
                  beside username on comments
                </F1SubLabel>
                <AvInput
                  name={getFieldName("caseCommentDisplayName")}
                  value={getFieldValue(formData, "caseCommentDisplayName")}
                  placeholder="Type in institution"
                  onChange={onChange}
                  type="text"
                  required
                />
                <AvFeedback>This field is invalid</AvFeedback>
              </AvGroup>
            </Row>
            <Row>
              <AvField
                name={getFieldName("profileDisplayName")}
                value={getFieldValue(formData, "profileDisplayName")}
                label={
                  <span className="text-uppercase">Institution or focus</span>
                }
                placeholder="Type in profile display name"
                type="text"
                labelClass="text-11 font-weight-normal"
                groupAttrs={{ className: "col" }}
                onChange={onChange}
                required
              />
            </Row>

            <Row>
              <AvField
                name={getFieldName("disclosureText")}
                value={getFieldValue(formData, "disclosureText")}
                label={
                  <>
                    <span className="text-uppercase">disclosure text</span>{" "}
                    <span>(Optional)</span>
                  </>
                }
                placeholder="Type in disclosure text"
                type="text"
                labelClass="text-11 font-weight-normal"
                groupAttrs={{ className: "col" }}
                onChange={onChange}
              />
            </Row>
          </>
        )}

        <Row>
          <AvField
            name={getFieldName("userPracticeLocation")}
            value={getFieldValue(formData, "userPracticeLocation")}
            label={
              <>
                <span className="text-uppercase">location</span>{" "}
                <span>(Optional)</span>
              </>
            }
            placeholder="Type in location"
            type="text"
            labelClass="text-11 font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
          />

          <AvField
            name={getFieldName("userPracticeHospital")}
            value={getFieldValue(formData, "userPracticeHospital")}
            label={
              <>
                <span className="text-uppercase">hospital</span>{" "}
                <span>(Optional)</span>
              </>
            }
            placeholder="Type in hospital"
            labelClass="text-11 font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
          />
        </Row>

        <Row>
          <AvField
            name={getFieldName("userBio")}
            value={getFieldValue(formData, "userBio")}
            helpMessage={getFieldValueCharacters(
              getFieldValue(formData, "userBio"),
              520
            )}
            label={
              <>
                <span className="text-uppercase">bio</span>{" "}
                <span>(Optional)</span>
              </>
            }
            placeholder="Type in bio"
            type="textarea"
            labelClass="text-11 font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
          />
        </Row>

        <Row>
          <AvField
            name={getFieldName("profileLinkText")}
            value={getFieldValue(formData, "profileLinkText")}
            helpMessage={getFieldValueCharacters(
              getFieldValue(formData, "profileLinkText"),
              56
            )}
            label={
              <>
                <span className="text-uppercase">link text</span>{" "}
                <span>(Optional)</span>
              </>
            }
            placeholder="Type in link text"
            type="text"
            labelClass="text-11 font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
          />
        </Row>

        <Row>
          <AvField
            name={getFieldName("profileLink")}
            value={getFieldValue(formData, "profileLink")}
            label={
              <>
                <span className="text-uppercase">link url</span>{" "}
                <span>(Optional)</span>
              </>
            }
            placeholder="Type in url"
            type="text"
            labelClass="text-11 font-weight-normal"
            groupAttrs={{ className: "col" }}
            onChange={onChange}
            errorMessage="A valid URL is required"
            validate={{ url: true }}
          />
        </Row>

        {renderIndividualFields()}
        {renderInstitutionalFields()}

        <div className="d-flex justify-content-end mt-4 pt-4 border-top">
          <Button size="lg" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Save
          </LoadingButton>
        </div>
      </AvForm>
    );
  };

  return (
    <Card className="border-0">
      <CardBody>
        {existing && (
          <Form onSubmit={onSearchSubmit}>
            <FormGroup>
              <Label className="text-11 text-uppercase font-weight-normal">
                Search
              </Label>
              <InputGroup className="border-secondary border">
                <Input
                  id="searchTerm"
                  name="searchTerm"
                  type="text"
                  className="border-0"
                  value={searchTerm || ""}
                  onChange={onChangeSearchText}
                  placeholder="Search by UID, UUID, username, or email"
                />
                <InputGroupAddon className="border-0" addonType="append">
                  <Button
                    className="rounded-0 border-0"
                    type="submit"
                    color="white">
                    <FontAwesomeIcon icon={faSearch} color="secondary" />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        )}

        <div style={{ minHeight: "400px" }}>{renderUserBody()}</div>
      </CardBody>
    </Card>
  );
};
