import React, { useState } from "react";
import {
  Button,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Jumbotron,
  Modal,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import ModalBody from "reactstrap/es/ModalBody";
import { get, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../../../components/common/LoadingButton";
import Loading from "../../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getDisplayName } from "../../../utils/verification-utils";
import { findAuthors } from "../../../actions/campaigns.actions";

const CampaignAuthorSearchModal = (props) => {
  const { isOpen, toggle, selectUser, title = "Find Author" } = props;
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchExecuted, setSearchExecuted] = useState(false);

  const processing = useSelector((state) => state.campaigns.authorsSearching);
  const searchResults = useSelector((state) => state.campaigns.authors);

  const onSelect = (user) => {
    selectUser(user);
    toggle();
  };

  const onSearchSubmit = async (event) => {
    event.preventDefault();

    dispatch(findAuthors(searchTerm));

    setSearchExecuted(true);
  };

  const onChangeSearchText = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const onOpen = () => {
    setSearchExecuted(false);
    setSearchTerm("");
  };

  const renderDefaultBody = () => {
    return (
      <Jumbotron className={"mt-3 border"}>
        <Container fluid>
          <h3>Enter a search term</h3>
          <p className="lead">
            Once entered, click the search icon to find an author.
          </p>
        </Container>
      </Jumbotron>
    );
  };

  const renderNoResults = () => {
    return (
      <Jumbotron className={"mt-3 border"}>
        <Container fluid>
          <h1 className="display-4">No results found</h1>
          <p className="lead">
            Modify your search query to find your profiles.
          </p>
        </Container>
      </Jumbotron>
    );
  };

  const renderResults = () => {
    return (
      <div className="mb-2">
        <table className="table">
          <thead>
            <tr>
              <th
                scope="col"
                className="text-11 font-weight-bold text-uppercase pl-0">
                UID
              </th>
              <th
                scope="col"
                className="text-11 font-weight-bold text-uppercase">
                Username
              </th>
              <th
                scope="col"
                className="text-11 font-weight-bold text-uppercase">
                Name
              </th>
              <th
                scope="col"
                className="text-11 font-weight-bold text-uppercase">
                Email
              </th>
              <th
                scope="col"
                className="text-11 font-weight-bold text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {map(searchResults, (c) => {
              const uuid = get(c, "userUid");
              return (
                <tr key={`user-${uuid}`}>
                  <th
                    scope="row"
                    className="text-secondary text-13 pl-0 align-middle font-weight-normal">
                    {uuid}
                  </th>
                  <td className="text-secondary text-13 align-middle">
                    {get(c, "username")}
                  </td>
                  <td className="text-secondary text-13 align-middle">
                    {`${getDisplayName(c)}`}
                  </td>
                  <td className="text-secondary text-13 align-middle">
                    {get(c, "email")}
                  </td>
                  <td className="text-secondary text-13 align-middle">
                    <LoadingButton
                      color="primary-dark"
                      className="btn-md ml-2"
                      onClick={() => {
                        onSelect(c);
                      }}
                      type="button">
                      Select
                    </LoadingButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderInnerBody = () => {
    if (processing) {
      return <Loading />;
    }

    if (!searchExecuted) {
      return renderDefaultBody();
    }

    if (isEmpty(searchResults)) {
      return renderNoResults();
    }

    if (!isEmpty(searchResults)) {
      return renderResults();
    }
  };

  const renderBody = () => {
    return (
      <div>
        <FormGroup>
          <InputGroup className="border-secondary border">
            <Input
              id="searchTerm"
              name="searchTerm"
              type="text"
              className="border-0"
              value={searchTerm || ""}
              onChange={onChangeSearchText}
              placeholder="Search by UID, UUID, username, or email"
            />
            <InputGroupAddon className="border-0" addonType="append">
              <Button
                className="rounded-0 border-0"
                type="button"
                onClick={onSearchSubmit}
                color="white">
                <FontAwesomeIcon icon={faSearch} color="secondary" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        {renderInnerBody()}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onOpened={onOpen}
      size="lg"
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody style={{ minHeight: "400px" }}>{renderBody()}</ModalBody>
    </Modal>
  );
};

CampaignAuthorSearchModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectUser: PropTypes.func.isRequired
};

export default CampaignAuthorSearchModal;
