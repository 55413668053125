import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { ADMIN_MODULE_NAME } from "../constants/route-constants";
import GlobalHeader from "../components/GlobalHeader";
import AdminLandingPage from "./admin/AdminLandingPage";
import { userSignedIn } from "../utils/route-utils";
import LoadingPage from "./home/LoadingPage";
import { isAdmin } from "../utils/permission-utils";

class AdminApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth, userRoles } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    if (!isAdmin(userRoles)) {
      return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={ADMIN_MODULE_NAME} />
        <Switch>
          <Route exact path={ROUTES.ADMIN_ROOT}>
            {this.conditionallyRenderDefaultOrPage(<AdminLandingPage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.user.userRoles
});

export default compose(withRouter, connect(mapStateToProps, null))(AdminApp);
