import { find, get, isEqual, isNil, filter } from "lodash";

export const isPromotionActive = (promotion, caseUuid) => {
  return !isNil(
    find(get(promotion, "cases", []), (c) => isEqual(c.case_id, caseUuid))
  );
};

export const isPromotionChannelActive = (allPromotions, channelUuid) => {
  return !isNil(
    find(allPromotions, (p) => isEqual(p.channel_uuid, channelUuid))
  );
};

export const getActivePromotions = (allPromotions, caseUuid) => {
  return filter(allPromotions, (p) => isPromotionActive(p, caseUuid));
};
