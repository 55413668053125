import React, { useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { defaultTo, get } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { editPartnerCaseSettings } from "../../actions/moderation.actions";
import LoadingButton from "../common/LoadingButton";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "easymde/dist/easymde.min.css";
import { getFieldValue, onInvalidSubmit } from "../../utils/form-utils";

const PartnerCaseBadgeLinkModal = (props) => {
  const { isOpen, toggle, caseDoc } = props;

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.moderation.editSaving);
  const message = null;
  const isError = null;

  const onOpened = () => {
    setFormData({
      sponsored_text: defaultTo(
        get(caseDoc, "contentItems[0].sponsoredContent.sponsoredText"),
        ""
      ),
      disclosure_text: defaultTo(
        get(caseDoc, "contentItems[0].sponsoredContent.disclosureText"),
        ""
      ),
      external_link_text: defaultTo(
        get(caseDoc, "contentItems[0].externalLinkText"),
        ""
      ),
      external_link_url: defaultTo(
        get(caseDoc, "contentItems[0].externalLinkUrl"),
        ""
      )
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const caseUuid = get(caseDoc, "caseUuid");
    await dispatch(editPartnerCaseSettings(caseUuid, formData));
    toggle();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Add badge and/or link
      </ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <AvField
            name="sponsored_text"
            value={getFieldValue(formData, "sponsored_text")}
            helpMessage="25 max characters"
            label="sponsored or promoted text"
            placeholder="Enter text here..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
          />
          <AvField
            name="disclosure_text"
            value={getFieldValue(formData, "disclosure_text")}
            helpMessage="98 max characters"
            label="tooltip disclosure text"
            placeholder="Enter text here..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
          />
          <AvField
            name="external_link_text"
            value={getFieldValue(formData, "external_link_text")}
            helpMessage="54 max characters"
            label="external link display text"
            placeholder="Add external link display text here..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
          />
          <AvField
            name="external_link_url"
            value={getFieldValue(formData, "external_link_url")}
            label="external link"
            placeholder="Enter URL here..."
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            onChange={onChange}
            errorMessage="A valid URL is required"
            validate={{ url: true }}
          />
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-5 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

PartnerCaseBadgeLinkModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object,
  author: PropTypes.object
};

PartnerCaseBadgeLinkModal.defaultProps = {};

export default PartnerCaseBadgeLinkModal;
