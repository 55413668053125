import React, { useState } from "react";
import {
  Button,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { compact, get, isEmpty, map, size } from "lodash";
import PropTypes from "prop-types";
import { isModeratorManager } from "../../utils/permission-utils";
import { editCaseMedia } from "../../actions/moderation.actions";
import "./modal.scss";
import { UIEvent, PhotoEditorSDKUI } from "photoeditorsdk";
import { html5License } from "../../../pesdk-license";
import { getImageUrl, startMediaDownloadZip } from "../../utils/media-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPen } from "@fortawesome/free-solid-svg-icons";

const pesdkConfiguration = {
  container: "#photoEditor",
  image: null, // Image url or Image path relative to assets folder
  license: JSON.stringify(html5License),
  theme: "light",
  layout: "advanced",
  assetBaseUrl: "/pesdk-assets",
  tools: ["transform", "adjustment", "text", "sticker", "brush"],
  defaultTool: "sticker",
  displayCloseWarning: false,
  export: {
    image: {
      format: "image/png", // this is the default
      enableDownload: false,
      exportType: "data-url" // try data-url
    }
  },
  sticker: {
    flattenCategories: true,
    enableCustomUpload: false, // true is the default
    categories: [
      {
        identifier: "imgly_sticker_shapes"
      }
    ]
  }
};

const MediaViewerModal = (props) => {
  const { media, caseUuid, caseLock, isOpen, toggle, refreshCases } = props;
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.user.userRoles);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const mediaSize = size(media);

  const onOpened = () => {
    if (activeIndex !== 0) {
      setActiveIndex(0);
    }

    if (animating) {
      setAnimating(false);
    }

    if (isEditorOpen) {
      setIsEditorOpen(false);
    }
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === mediaSize - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? mediaSize - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const downloadZip = async () => {
    await startMediaDownloadZip(media, setDownloading);
  };

  const openEditor = async () => {
    const imageUrl = getImageUrl(media[activeIndex], 800);
    pesdkConfiguration.image = imageUrl;
    const editor = await PhotoEditorSDKUI.init(pesdkConfiguration);
    editor.on(UIEvent.EDITOR_READY, () => {
      setIsEditorOpen(true);
    });
    editor.on(UIEvent.EXPORT, async (base64ImageUrl) => {
      const moderationManager = isModeratorManager(userRoles);
      const mediaUuid = media[activeIndex].mediaUuid;
      await dispatch(
        editCaseMedia(
          caseUuid,
          mediaUuid,
          !moderationManager,
          base64ImageUrl,
          refreshCases
        )
      );
      editor.close();
      setIsEditorOpen(false);
    });
    editor.on(UIEvent.CLOSE, (imageSrc) => {
      setIsEditorOpen(false);
    });
  };

  const slides = compact(
    map(media, (m) => {
      const imageUrl = getImageUrl(m, 800, 600, "max");
      if (!imageUrl) {
        return null;
      }

      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={get(m, "mediaUuid")}>
          <div className="case-detail-body">
            <div className="position-relative">
              {!isEditorOpen && !caseLock && (
                <Button className="btn-detail-body pen" onClick={openEditor}>
                  <FontAwesomeIcon icon={faPen} color="white" />
                </Button>
              )}
              <div style={{ textAlign: "center" }}>
                <img src={imageUrl} alt={``} />
              </div>
            </div>
          </div>
          <CarouselCaption captionText={`${activeIndex + 1} of ${mediaSize}`} />
        </CarouselItem>
      );
    })
  );

  return (
    <Modal
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      className={"media-viewer-modal"}
      size="lg">
      <ModalHeader toggle={toggle} className="border-0"></ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between pb-3">
          <div className="text-12 text-uppercase text-secondary font-weight-bold ">
            Total Images: {size(media)}
          </div>
          {downloading && <Spinner size="sm" color="primary" />}
          {!downloading && (
            <Button
              className="text-12 p-0 font-weight-bold text-uppercase"
              color="link"
              onClick={downloadZip}>
              <FontAwesomeIcon icon={faDownload} size={"sm"} className="mr-1" />
              Download All
            </Button>
          )}
        </div>

        {isEmpty(media) && "No media was found."}
        <div id="photoEditor" style={{ minHeight: isEditorOpen ? 700 : 0 }} />
        {!isEmpty(media) && !isEditorOpen && (
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}>
            {slides}
            {mediaSize > 1 && (
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
            )}
            {mediaSize > 1 && (
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            )}
          </Carousel>
        )}
      </ModalBody>
    </Modal>
  );
};

MediaViewerModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  media: PropTypes.array,
  caseLock: PropTypes.object
};

MediaViewerModal.defaultProps = {
  media: []
};

export default MediaViewerModal;
