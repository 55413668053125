import React, { useState } from "react";
import { Collapse, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import "easymde/dist/easymde.min.css";
import RowToggler from "../../../components/common/RowToggler";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters
} from "../../../utils/form-utils";
import {
  handleSwitchChange,
  isIsiActive,
  isJobCodeActive
} from "../../../utils/campaign-utils";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import { CampaignCaseSingleImageSection } from "./CampaignCaseSingleImageSection";

export const CampaignIsiSettings = (props) => {
  const { formData, onChange } = props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);

  if (!isIsiActive(formData)) {
    return null;
  }

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() => handleSwitchChange(onChange, fieldName, !value)}
      />
    );
  };

  return (
    <div className="bg-white p-3 mt-4">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">ISI Feedcard</div>
        <RowToggler toggle={toggleCollapse} collapse={collapse} large={true} />
      </div>
      <Collapse isOpen={collapse} className="pt-2">
        <FormGroup className="w-25">
          <Label className="text-11 text-uppercase font-weight-normal">
            job code
          </Label>
          {renderToggleSwitch("features.job_code_enabled")}
        </FormGroup>
        <AvField
          name={getFieldName("sponsored_content.job_code")}
          value={getFieldValue(formData, "sponsored_content.job_code")}
          label="Job code"
          placeholder="Enter code here..."
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          disabled={!isJobCodeActive(formData)}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "sponsored_content.job_code"),
            40
          )}
          onChange={onChange}
        />
        <AvField
          name={getFieldName("feed_card.feed_card_label")}
          value={getFieldValue(formData, "feed_card.feed_card_label")}
          label="Content label"
          placeholder="Enter label here…"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "feed_card.feed_card_label"),
            23
          )}
          onChange={onChange}
          required
        />
        <AvField
          name={getFieldName("feed_card.feed_card_title")}
          value={getFieldValue(formData, "feed_card.feed_card_title")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "feed_card.feed_card_title"),
            84
          )}
          label="banner title"
          placeholder="Enter banner here…"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          onChange={onChange}
          required
        />
        <CampaignCaseSingleImageSection
          formData={formData}
          onChange={onChange}
          fieldName={getFieldName("feed_card.feed_card_media")}
        />
        <AvField
          name={getFieldName("feed_card.colour")}
          value={getFieldValue(formData, "feed_card.colour")}
          label="banner colour code"
          placeholder="Hex value here…"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          onChange={onChange}
          required
        />
        <AvField
          name={getFieldName("feed_card.button_text")}
          value={getFieldValue(formData, "feed_card.button_text")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "feed_card.button_text"),
            21
          )}
          label="CTA text"
          placeholder="Enter cta text here…"
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          onChange={onChange}
          required
        />
      </Collapse>
    </div>
  );
};

CampaignIsiSettings.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object
};
