import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SignInForm from "../../components/forms/SignInForm";
import { Col, Container, Row } from "reactstrap";
import Figure1Image from "../../assets/images/default.png";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { signIn } from "../../actions/anon-user.actions";
import "./pages.scss";
import Loading from "../../components/Loading";

const SignInPage = () => {
  const dispatch = useDispatch();

  const processing = useSelector((state) => state.anonUser.isProcessing);
  const message = useSelector((state) => state.anonUser.message);
  const isError = useSelector((state) => state.anonUser.isError);

  const signInUser = (email, password) => dispatch(signIn(email, password));

  if (processing) {
    return <Loading />;
  }

  return (
    <Container>
      <Row>
        <Col className="p-5 d-flex justify-content-center">
          <Link to={"/"}>
            <img className="header-icon" src={Figure1Image} alt="Figure1" />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <SignInForm
            processing={processing}
            signIn={signInUser}
            message={message}
            isError={isError}
          />
        </Col>
      </Row>
      <Row>
        <Col className="p-3 d-flex justify-content-center">
          Don't have an account?&nbsp;
          <Link to={ROUTES.TAGGER_SIGN_UP}>Sign Up.</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPage;
