import React from "react";
import ToggleButton from "react-toggle-button";

const styles = {
  containerStyle: {
    width: 75
  },
  trackStyle: {
    height: 35,
    width: 75,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ccd3d8"
  },
  activeLabelStyle: {
    fontSize: 12,
    left: 0
  },
  inactiveLabelStyle: {
    fontSize: 12,
    color: "000",
    padding: 0
  },
  thumbStyle: {
    height: 29,
    width: 29,
    marginLeft: 2
  },
  colors: {
    activeThumb: {
      base: "#fff",
      hover: "#fff"
    },
    inactiveThumb: {
      base: "#CCD3D8",
      hover: "#fff"
    },
    active: {
      base: "#3BAFA8",
      hover: "#B1BFD7"
    },
    inactive: {
      base: "#fff",
      hover: "#3BAFA8"
    }
  }
};

const F1ToggleButton = (props) => {
  const { value, onClick } = props;

  return (
    <ToggleButton
      value={value}
      onClick={onClick}
      colors={styles.colors}
      containerStyle={styles.containerStyle}
      activeLabelStyle={styles.activeLabelStyle}
      inactiveLabelStyle={styles.inactiveLabelStyle}
      trackStyle={styles.trackStyle}
      thumbStyle={styles.thumbStyle}
      thumbAnimateRange={[1, 41]}
    />
  );
};

export default F1ToggleButton;
