import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import {
  MODERATION_SECONDARY_NAVIGATION,
  TAGGING_MODULE_NAME
} from "../../constants/route-constants";
import { useLocation, useParams } from "react-router-dom";
import { TAGGING_LANDING } from "../../constants/routes";
import CaseLeftNavigation from "./components/CaseLeftNavigation";
import { useDispatch, useSelector } from "react-redux";
import { find, get, head, isEmpty } from "lodash";
import CasePreviewerColumn from "./components/CasePreviewerColumn";
import {
  filterCases,
  getCases,
  getCasesByState,
  searchCasesByString,
  setActiveCase,
  setCasesSortDirection
} from "../../actions/tagging.actions";
import { TAGGING_FILTERS } from "../../utils/tagging-utils";
import {
  TaggingCaseDetailBody,
  TaggingLabelsFilters,
  TaggingMeshFilters,
  TaggingSpecialtiesFilters
} from "./components/TaggingSections";
import CasesPaginationRow from "./components/CasesPaginationRow";
import { TaggingActionButtons } from "./components/TaggingActionButtons";
import {
  CaseAuthorDetailShort,
  CaseFilters,
  CaseMetaDataSection
} from "./components/CaseSections";
import {
  getAllCountriesAndStates,
  getAllLabels,
  getAllTaggingSpecialties
} from "../../actions/reference.actions";
import EmptyContainer from "../../components/common/EmptyContainer";
import { lockCase, removeUserLock } from "../../actions/moderation.actions";
import { findCaseLock } from "../../utils/case-utils";
import Loading from "../../components/Loading";

const TaggingLandingPage = (props) => {
  const { filterType = TAGGING_FILTERS.UNTAGGED.statsKey } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const processing = useSelector((state) => state.tagging.processing);
  const allLocks = useSelector((state) => state.moderation.allLocks);
  const user = useSelector((state) => state.user.user);
  const cases = useSelector((state) => state.tagging.cases);
  const activeCase = useSelector((state) => state.tagging.activeCase);
  const stats = useSelector((state) => state.tagging.stats);
  const filters = useSelector((state) => state.tagging.filters);
  const searchTextFilter = useSelector(
    (state) => state.tagging.searchTextFilter
  );
  const filter =
    find(TAGGING_FILTERS, (f) => f.statsKey === filterType) ||
    TAGGING_FILTERS.UNTAGGED;
  const activeCaseUuid = get(activeCase, "caseUuid");
  const caseLock = findCaseLock(activeCaseUuid, allLocks, user);

  const [addedSpecialties, setAddedSpecialties] = useState([]);
  const [removedSpecialties, setRemovedSpecialties] = useState([]);
  const [addedTerms, setAddedTerms] = useState([]);
  const [removedTerms, setRemovedTerms] = useState([]);
  const [caseLabels, setCaseLabels] = useState([]);

  useEffect(() => {
    dispatch(getAllTaggingSpecialties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllLabels());
    dispatch(getAllCountriesAndStates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCasesByState(filterType, 0));
  }, [location, filterType, dispatch]);

  useEffect(() => {
    setSearchText(searchTextFilter);
  }, [searchTextFilter]);

  useEffect(() => {
    if (!caseLock) {
      dispatch(lockCase(user, activeCaseUuid));
    }

    return () => {
      dispatch(removeUserLock(user));
    };
  }, [activeCaseUuid, dispatch, caseLock, user]);

  const onSetActiveCase = (activeCase) => {
    dispatch(setActiveCase(activeCase));
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    dispatch(searchCasesByString(searchText));
  };

  const onSearchChange = (event) => {
    const { value } = event.target;

    setSearchText(value);
    if (isEmpty(value) && !isEmpty(searchTextFilter)) {
      dispatch(searchCasesByString(value));
    }
  };

  const author = head(get(activeCase, "authors"));

  const renderSearchForm = () => {
    return (
      <div className="tagging-search-form">
        <Form
          onSubmit={onSubmit}
          className="w-100 mx-0 my-3 d-inline-flex align-items-center justify-content-between">
          <Input
            type="search"
            className="w-25 mr-2"
            placeholder="Search Username..."
            onChange={onSearchChange}
            value={searchText || ""}
          />
          <Button type="submit" color="primary" outline>
            Search
          </Button>
          <CaseFilters filters={filters} applyFilters={filterCases} />
        </Form>
      </div>
    );
  };

  const renderInnerBody = () => {
    if (isEmpty(cases)) {
      return (
        <EmptyContainer
          title={`No Cases Found Under "${filter.displayName}"`}
          description="All the cases have have been processed associated with this filter."
        />
      );
    }

    return (
      <div>
        <Row>
          <Col
            className="case-previewer-column"
            xl="2"
            lg="12"
            md="12"
            style={{ marginTop: 36 }}>
            <CasePreviewerColumn
              cases={cases}
              activeCaseId={get(activeCase, "caseUuid")}
              onSetActiveCase={onSetActiveCase}
            />
          </Col>
          <Col fluid="lg1" className="moderation-column-middle">
            <CasesPaginationRow
              getPage={getCases}
              sortCases={setCasesSortDirection}
              stateLocation={"tagging"}
            />
            <TaggingCaseDetailBody
              activeCase={activeCase}
              caseLock={caseLock}
            />
          </Col>
          <Col lg="4" style={{ marginTop: 26, paddingBottom: 80 }}>
            <TaggingActionButtons
              activeCase={activeCase}
              refreshCases={getCases}
              caseLock={caseLock}
              addedTerms={addedTerms}
              removedTerms={removedTerms}
              addedSpecialties={addedSpecialties}
              removedSpecialties={removedSpecialties}
              caseLabels={caseLabels}
            />
            <CaseAuthorDetailShort author={author} />
            <TaggingSpecialtiesFilters
              activeCase={activeCase}
              caseLock={caseLock}
              addedSpecialties={addedSpecialties}
              setAddedSpecialties={setAddedSpecialties}
              removedSpecialties={removedSpecialties}
              setRemovedSpecialties={setRemovedSpecialties}
            />
            <TaggingMeshFilters
              activeCase={activeCase}
              caseLock={caseLock}
              addedTerms={addedTerms}
              setAddedTerms={setAddedTerms}
              removedTerms={removedTerms}
              setRemovedTerms={setRemovedTerms}
            />
            <TaggingLabelsFilters
              activeCase={activeCase}
              caseLock={caseLock}
              caseLabels={caseLabels}
              setCaseLabels={setCaseLabels}
            />
            <CaseMetaDataSection activeCase={activeCase} />
          </Col>
        </Row>
      </div>
    );
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0 mb-4 bg-white">
        <Col
          className="tagging-left-nav col-border-right py-4 px-0 min-vh-100"
          xl="2"
          lg="2"
          md="2">
          <CaseLeftNavigation
            active={filterType}
            baseUrl={TAGGING_LANDING}
            stats={stats}
            collection={TAGGING_FILTERS}
          />
        </Col>
        <Col lx="10" lg="10" md="10" className="my-0">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="tagging-page">
      <SecondaryNavigation
        active={TAGGING_MODULE_NAME}
        links={MODERATION_SECONDARY_NAVIGATION}
      />

      <Container className="tagging-page-container">
        {renderSearchForm()}
        {renderBody()}
      </Container>
    </div>
  );
};

export default TaggingLandingPage;
