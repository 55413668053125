import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import "easymde/dist/easymde.min.css";
import { getFieldName, getFieldValue } from "../../utils/form-utils";
import { saveCampaignCaseDates } from "../../actions/campaigns.actions";

const CampaignTacticDateModal = (props) => {
  const { isOpen, toggle, onChangeTacticSettingsFormData, caseUuid = "", startDate = "", endDate = "" } = props;
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: ""
  });
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.campaigns.saving);

  const onOpened = () => {
    setFormData({
      caseUuid: caseUuid,
      start_date: startDate,
      end_date: endDate
    });
  };

  const onSubmit = async () => {
    if (!!caseUuid)
      await dispatch(saveCampaignCaseDates(formData));
    else {
      onChangeTacticSettingsFormData({ target: { name: getFieldName("settings.start_date"), value: formData.start_date } });
      onChangeTacticSettingsFormData({ target: { name: getFieldName("settings.end_date"), value: formData.end_date } });
    }
    toggle();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Campaign Tactic Active Dates
      </ModalHeader>

      <ModalBody className="pt-0">
        <FormGroup>
          <Label className="text-11 text-uppercase font-weight-normal">
            Tactic start date
          </Label>
          <Input
            name="start_date"
            value={getFieldValue(formData, "start_date")}
            onChange={onChange}
            placeholder="Pick an start date"
            type="date"
          />
        </FormGroup>
        <FormGroup>
          <Label className="text-11 text-uppercase font-weight-normal">
            Tactic end date
          </Label>
          <Input
            name="end_date"
            value={getFieldValue(formData, "end_date")}
            onChange={onChange}
            placeholder="Pick an end date"
            type="date"
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pb-5 justify-content-between">
        <Button color="primary" outline onClick={toggle}>
          Cancel
        </Button>
        <LoadingButton
          outline
          color="primary"
          type="button"
          loading={saving}
          disabled={saving}
          onClick={onSubmit}>
          Save
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

CampaignTacticDateModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

CampaignTacticDateModal.defaultProps = {};

export default CampaignTacticDateModal;
