import {
  deletePromotion as deletePromotionCall,
  deletePromotionChannelCall,
  postPromotion,
  savePromotionChannelCall
} from "../api/cloud-functions";
import {
  getPromotion,
  removeCaseFromPromotion as removeCaseFromPromotionCall
} from "../api/cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "promotion";

export const PROMOTION_LOAD = `${actionsPrefix}/PROMOTION_LOAD`;
export const PROMOTION_LOADED = `${actionsPrefix}/PROMOTION_LOADED`;
export const PROMOTION_LOADED_ERROR = `${actionsPrefix}/PROMOTION_LOADED_ERROR`;
export const PROMOTION_EDIT = `${actionsPrefix}/PROMOTION_EDIT`;
export const PROMOTION_DELETE = `${actionsPrefix}/PROMOTION_DELETE`;
export const PROMOTION_DELETED = `${actionsPrefix}/PROMOTION_DELETED`;
export const PROMOTION_DELETE_CASE = `${actionsPrefix}/PROMOTION_DELETE_CASE`;
export const PROMOTION_DELETED_CASE = `${actionsPrefix}/PROMOTION_DELETED_CASE`;
export const PROMOTION_UPDATE_FIELDS = `${actionsPrefix}/PROMOTION_UPDATE_FIELDS`;
export const PROMOTION_CHANNEL_DELETE = `${actionsPrefix}/PROMOTION_CHANNEL_DELETE`;
export const PROMOTION_CHANNEL_DELETED = `${actionsPrefix}/PROMOTION_CHANNEL_DELETED`;
export const PROMOTION_CHANNEL_SAVE = `${actionsPrefix}/PROMOTION_CHANNEL_SAVE`;
export const PROMOTION_CHANNEL_SAVED = `${actionsPrefix}/PROMOTION_CHANNEL_SAVED`;

export const updatePromotionFields = (data) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_UPDATE_FIELDS,
      data: data
    });
  };
};

export const addCaseToPromotion = (promo, caseUuid) => {
  return async function(dispatch) {
    if (!promo.cases) {
      promo.cases = [];
    }

    promo.cases = [
      ...promo.cases,
      {
        case_id: caseUuid,
        promotion_uuid: promo.promotion_uuid,
        case_notes: ""
      }
    ];

    dispatch(createOrUpdatePromotion(promo));
  };
};

export const removeCaseFromPromotion = (promo, caseUuid) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_DELETE_CASE,
      isProcessing: true,
      promotionUuid: promo.promotion_uuid
    });

    try {
      await removeCaseFromPromotionCall(promo.promotion_uuid, caseUuid);
      const response = await getPromotion(promo.promotion_uuid);
      dispatch({
        type: PROMOTION_LOADED,
        data: response.store,
        isProcessing: false
      });
    } catch (error) {
      console.log("failed with error ", error.message);
      dispatch({
        type: PROMOTION_DELETED_CASE,
        isProcessing: false,
        isError: true,
        message: error.message
      });
    }
  };
};

export const createOrUpdatePromotion = (promo) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_EDIT,
      isProcessing: true,
      promotionUuid: promo.promotion_uuid
    });

    try {
      const response = await postPromotion(promo);
      console.log(response);
      if (isRequestSuccess(response)) {
        dispatch({
          type: PROMOTION_LOADED,
          data: response.store,
          isProcessing: false
        });
      } else {
        console.log("failed to create promotion ", response.store);
        dispatch({
          type: PROMOTION_LOADED_ERROR,
          isProcessing: false,
          isError: true,
          message: "failed to create promotion"
        });
      }
    } catch (error) {
      dispatch({
        type: PROMOTION_LOADED_ERROR,
        isProcessing: false,
        isError: true,
        message: error.message
      });
    }
  };
};

export const deletePromotion = (promoId) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_DELETE,
      isProcessing: true
    });

    await deletePromotionCall(promoId);
    dispatch({
      type: PROMOTION_DELETED,
      promotionUuid: promoId
    });
  };
};

export const createPromotionChannel = (channelName) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_CHANNEL_SAVE
    });

    const response = await savePromotionChannelCall(channelName);
    dispatch({
      type: PROMOTION_CHANNEL_SAVED,
      data: response.store
    });
  };
};

export const deletePromotionChannel = (channelUuid) => {
  return async function(dispatch) {
    dispatch({
      type: PROMOTION_CHANNEL_DELETE
    });

    await deletePromotionChannelCall(channelUuid);
    dispatch({
      type: PROMOTION_CHANNEL_DELETED,
      channelUuid: channelUuid
    });
  };
};
