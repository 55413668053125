import React, { useCallback, useState } from "react";
import { FormGroup, Label, Spinner } from "reactstrap";
import { head, isString } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { adminProfileImageUploadCall } from "../../../api/cloud-functions";
import "easymde/dist/easymde.min.css";
import { getFieldValue } from "../../../utils/form-utils";

export const ProfileImage = (props) => {
  const {
    formData,
    onChange,
    userUid,
    fieldName = "avatar",
    fieldLabel = "upload avatar image"
  } = props;
  const mediaUrl = getFieldValue(formData, fieldName);

  const setMedia = (fileInfo) => {
    onChange({
      target: {
        name: fieldName,
        value: fileInfo
      }
    });
  };

  return (
    <>
      <FormGroup className="mr-3">
        <Label
          className="text-11 text-uppercase font-weight-normal"
          for="imageSelected">
          <FontAwesomeIcon icon={faPlusCircle} className="link-color mr-2" />
          {fieldLabel}
        </Label>
        <div
          className="sortable-image-container"
          style={{ height: "250px", width: "250px" }}>
          <FormImage
            mediaUrl={mediaUrl}
            setMedia={setMedia}
            userUid={userUid}
            type={fieldName === "backgroundImage" ? "background" : "avatar"}
          />
        </div>
      </FormGroup>
    </>
  );
};

ProfileImage.propTypes = {
  fieldName: PropTypes.string,
  onChange: PropTypes.func,
  formData: PropTypes.object,
  userUid: PropTypes.string
};

const FormImage = (props) => {
  const { mediaUrl, setMedia, userUid, type } = props;
  const [uploading, setUploading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = head(acceptedFiles);
      if (!file) {
        return;
      }

      setUploading(true);
      try {
        const response = await adminProfileImageUploadCall(userUid, type, file);

        // Not great but we're parsing the response string to get the new url
        if (response && isString(response.success)) {
          const urlStart = response.success.indexOf("http");
          if (urlStart > 0) {
            setMedia(response.success.substring(urlStart));
          }
        }
      } catch (e) {
        console.log("Unable to upload image", e);
      }

      setUploading(false);
    },
    [setMedia, type, userUid]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false
  });

  return (
    <div {...getRootProps()} className="thumbnail mb-2 mr-2">
      <input {...getInputProps()} />

      {uploading && (
        <div className="centered-container">
          <Spinner color="primary" />
        </div>
      )}

      {!uploading && !mediaUrl && (
        <div className="centered-container">
          <FontAwesomeIcon
            icon={faPlusCircle}
            size="lg"
            className="text-primary"
          />
        </div>
      )}

      {!uploading && mediaUrl && (
        <>
          <img
            src={`${mediaUrl}?w=400&h=400&fit=crop`}
            className="img-thumbnail"
            alt=""
          />
        </>
      )}
    </div>
  );
};

FormImage.propTypes = {
  setMedia: PropTypes.func,
  userUid: PropTypes.string,
  mediaUrl: PropTypes.string,
  type: PropTypes.string
};
