import React, { useState } from "react";
import { Button, Nav, NavItem } from "reactstrap";
import PropTypes from "prop-types";
import { get, isEmpty, isEqual, map } from "lodash";
import { faAngleRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MANAGE_PAGE_CONFIG } from "../../../utils/campaign-utils";
import AddTactic from "./AddTactic";
import { useSelector } from "react-redux";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";

const CampaignLeftNavigation = (props) => {
  const { campaignSettingsActive, caseUuidActive, newCaseTypeActive } = props;

  const history = useHistory();
  const [tacticModal, setTacticModal] = useState(false);
  const toggleTacticModal = () => setTacticModal(!tacticModal);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const cases = get(activeCampaign, "cases");

  return (
    <Nav className="campaign-left-nav" vertical>
      <NavItem>
        <Button
          outline
          onClick={() =>
            history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`)
          }
          className={`nav-link action ${
            campaignSettingsActive ? "active" : ""
          }`}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          {MANAGE_PAGE_CONFIG.MAIN.displayName}
          <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
        </Button>
      </NavItem>
      <NavItem id="addTactic">
        <Button
          type="button"
          onClick={toggleTacticModal}
          outline
          className={`nav-link add-tactics`}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          ADD TACTICS
          <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
        </Button>
      </NavItem>
      {map(cases, (t, index) => {
        const caseTacticType = t.tacticType;
        const tacticName = get(t, "campaignSettings.tacticName", "");
        const caseUuid = get(t, "caseUuid", "");

        const isActive = isEqual(caseUuidActive, caseUuid);
        return (
          <NavItem key={`tactic-${index}`}>
            <Button
              outline
              onClick={() =>
                history.push(
                  ROUTES.CAMPAIGN_TACTIC_MANAGE.replace(
                    ":campaignId",
                    campaignUuid
                  ).replace(":caseId", caseUuid)
                )
              }
              className={`nav-link tactic ${isActive ? "active" : ""}`}>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
              {!isEmpty(tacticName) ? tacticName : caseTacticType.displayName}
              <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
            </Button>
          </NavItem>
        );
      })}

      {newCaseTypeActive && (
        <NavItem>
          <Button
            outline
            onClick={() => {}}
            className={`nav-link tactic active`}>
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            {newCaseTypeActive.displayName}
            <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
          </Button>
        </NavItem>
      )}

      <AddTactic
        isOpen={tacticModal}
        toggle={toggleTacticModal}
        campaignUuid={campaignUuid}
      />
    </Nav>
  );
};

CampaignLeftNavigation.propTypes = {
  campaignSettingsActive: PropTypes.bool,
  caseUuidActive: PropTypes.string,
  newCaseTypeActive: PropTypes.object
};

export default CampaignLeftNavigation;
