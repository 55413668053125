import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { CAMPAIGN_SECONDARY_NAVIGATION } from "../../constants/route-constants";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import { useDispatch, useSelector } from "react-redux";
import CampaignLeftNavigation from "./components/CampaignLeftNavigation";
import { getCampaignById } from "../../actions/campaigns.actions";
import { get } from "lodash";
import {
  getAllCountriesAndStates,
  getAllLocales,
  getAllProfessionAndSpecialties
} from "../../actions/reference.actions";
import "./components/style.scss";
import Loading from "../../components/Loading";
import { CampaignMainForm } from "./components/CampaignMainForm";

const CampaignManage = () => {
  const { campaignId = "" } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const processing = useSelector((state) => state.campaigns.processing);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid", "");
  const campaignName = get(activeCampaign, "name");

  useEffect(() => {
    dispatch(getAllCountriesAndStates());
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllLocales());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCampaignById(campaignId));
  }, [campaignId, location, dispatch]);

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0 mb-4 min-vh-100">
        <Col className="col-border-right px-4 pt-5 bg-white" xl="3">
          <div className="font-weight-500 mb-3">{campaignName}</div>
          <CampaignLeftNavigation campaignSettingsActive={true} />
        </Col>
        <Col lx="9" className="mt-5 ml-4 campaign-form">
          <CampaignMainForm campaignUuid={campaignUuid} />
        </Col>
      </Row>
    );
  };

  return (
    <div className="campaign-page">
      <SecondaryNavigation
        links={CAMPAIGN_SECONDARY_NAVIGATION}
        centerAlign={false}
      />
      <Container>{renderBody()}</Container>
    </div>
  );
};

export default CampaignManage;
