import {
  CASE_REFRESH,
  GET_CASES_COMPLETE,
  GET_CASES_START,
  PARTNER_ADD_LOCAL_CONTENT_EDIT,
  PARTNER_ADD_LOCAL_MEDIA_EDIT,
  PARTNER_UPDATE_LOCAL_MEDIA,
  SET_ACTIVE_CASE,
  UPDATE_CASE_FILTERS,
  UPDATE_SORT_DIRECTION,
  UPDATE_STATE_FILTER
} from "../actions/partner-cases.actions";
import {
  findIndex,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  omitBy,
  size
} from "lodash";
import { CASE_FILTERS } from "../utils/case-utils";
import { getNextActiveCase } from "./cases.reducer";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  message: null,
  activeCase: null,
  stats: null,
  authorMetadata: null,
  stateFilter: CASE_FILTERS.APPROVED.collectionName,
  pageSize: 6,
  currentPage: 0,
  cases: [],
  filters: {},
  stateSortDirections: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CASES_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        comments: [],
        stats: null,
        authorMetadata: null,
        activeCase: null,
        previousActiveCase: state.activeCase,
        previousActiveCaseIndex: findIndex(state.cases, (c) =>
          isEqual(get(c, "caseUuid"), get(state.activeCase, "caseUuid"))
        )
      };
    }
    case GET_CASES_COMPLETE: {
      const casesPage = get(action.results, "pageData", []);
      const cases = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(casesPage, "pageSize", 10);
      const totalCases = get(casesPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(cases);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalCases;

      return {
        ...state,
        processing: false,
        stats: get(action.results, "allStats.casesStatusStats", {}),
        authorMetadata: get(action.results, "allStats.authorMetadata", {}),
        cases,
        pageSize: pageSize,
        totalCases,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage,
        activeCase: getNextActiveCase(state, cases, action.resetCaseIndex),
        previousActiveCase: null,
        previousActiveCaseIndex: null
      };
    }
    case UPDATE_STATE_FILTER: {
      return {
        ...state,
        stateFilter: action.stateFilter
      };
    }
    case UPDATE_CASE_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case UPDATE_SORT_DIRECTION: {
      const directions = state.stateSortDirections || {};
      if (state.stateFilter) {
        directions[state.stateFilter] = action.sortDirection;
      }

      return {
        ...state,
        stateSortDirections: directions
      };
    }
    case SET_ACTIVE_CASE: {
      return {
        ...state,
        activeCase: action.activeCase
      };
    }
    case CASE_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const caseUuid = get(action.response, "caseUuid");
      return {
        ...state,
        activeCase: isEqual(get(state.activeCase, "caseUuid"), caseUuid)
          ? action.response
          : state.activeCase,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            return action.response;
          } else {
            return c;
          }
        })
      };
    }
    case PARTNER_ADD_LOCAL_MEDIA_EDIT: {
      const caseUuid = get(action.payload, "caseUuid");
      return {
        ...state,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            const newEditObject = {
              moderatorUuid: action.payload.userUuid,
              moderatorUid: action.payload.userUid,
              moderatorUsername: action.payload.username,
              filename: action.payload.base64ImageUrl,
              url: action.payload.base64ImageUrl,
              editType: "media",
              mediaUuid: action.payload.mediaUuid
            };
            if (c.moderationEdit) {
              c.moderationEdit.push(newEditObject);
            } else {
              c.moderationEdit = [newEditObject];
            }
            return { ...c };
          } else {
            return c;
          }
        })
      };
    }
    case PARTNER_UPDATE_LOCAL_MEDIA: {
      const caseUuid = get(action.payload, "caseUuid");
      return {
        ...state,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            const caseMedia = get(c, "media", []);
            const newMediaList = map(caseMedia, (m) => {
              if (m.mediaUuid === action.payload.mediaUuid) {
                m.url = action.payload.base64ImageUrl;
              }
              return m;
            });
            return { ...c, media: newMediaList };
          } else {
            return c;
          }
        })
      };
    }
    case PARTNER_ADD_LOCAL_CONTENT_EDIT: {
      const caseUuid = get(action.payload, "caseUuid");
      return {
        ...state,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            const newEditObject = {
              moderatorUuid: action.payload.userUuid,
              moderatorUid: action.payload.userUid,
              moderatorUsername: action.payload.username,
              editType: "content",
              title: action.payload.title || null,
              caption: action.payload.caption || null
            };
            if (c.moderationEdit) {
              c.moderationEdit.push(newEditObject);
            } else {
              c.moderationEdit = [newEditObject];
            }
            return { ...c };
          } else {
            return c;
          }
        })
      };
    }
    default:
      return state;
  }
};

export default reducer;
