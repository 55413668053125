import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { get, map } from "lodash";
import LoadingButton from "../common/LoadingButton";
import {
  getRolesInitialFormData,
  ROLE_ATTRIBUTES
} from "../../utils/permission-utils";
import { saveUserRoles } from "../../actions/users.actions";

const AdminRoleModal = (props) => {
  const { isOpen, toggle, activeUser } = props;
  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.users.saving);

  useEffect(() => {
    setFormData(getRolesInitialFormData(activeUser));
  }, [activeUser]);

  const onSubmit = async (event) => {
    event.preventDefault();

    const uid = get(activeUser, "uid");
    await dispatch(saveUserRoles(uid, formData));
    toggle();
  };

  const onChange = (event) => {
    const { name } = event.target;
    setFormData({ ...formData, [name]: !formData[name] });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0 px-5">
          <div className="bordered-text font-weight-bolder">
            <div className="w-75">Select the desired roles for the user:</div>
          </div>
          <div className="py-4">
            {map(ROLE_ATTRIBUTES, (value, k) => {
              return (
                <FormGroup key={k} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={value.key}
                      checked={formData[value.key]}
                      onChange={onChange}
                    />{" "}
                    {value.displayName}
                  </Label>
                </FormGroup>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-between px-5 pt-3 px-5 pb-5">
          <Button color="primary" outline onClick={toggle}>
            Go Back
          </Button>
          <LoadingButton color="primary" outline type="submit" loading={saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

AdminRoleModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  activeUser: PropTypes.object
};

export default AdminRoleModal;
