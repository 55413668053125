import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { get, includes, isEmpty, isEqual, isNil, map } from "lodash";
import * as ROUTES from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  CAMPAIGN_FILTERS,
  CAMPAIGN_TACTIC_STATES,
  getTacticState,
  isArchiveActive,
  isCampaignEditActive,
  isReactivateActive
} from "../../../utils/campaign-utils";
import {
  archiveCampaign,
  reActivateCampaign,
  saveCampaignTacticState,
  updateCampaignAuthorFilter
} from "../../../actions/campaigns.actions";
import moment from "moment";
import EmptyContainer from "../../../components/common/EmptyContainer";
import NewCampaignModal from "../../../components/modals/NewCampaignModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import AddTactic from "./AddTactic";
import LoadingButton from "../../../components/common/LoadingButton";
import CampaignInfoModal from "./CampaignInfoModal";

export const CampaignListPageHeader = (props) => {
  const { pageConfig, onSearch } = props;
  const dispatch = useDispatch();
  const searchFilter = useSelector(
    (state) => state.campaigns[pageConfig.pageKey].searchFilter
  );
  const campaignFilter = useSelector((state) => state.campaigns.campaignFilter);
  const [searchTerm, setSearchTerm] = useState(searchFilter);
  const [newModal, setNewModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleNewModal = () => setNewModal(!newModal);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const onChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const clearSearchTerm = () => {
    setSearchTerm(null);

    onSearch(null);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    onSearch(searchTerm);
  };

  const changeCampaignFilter = async (selectedFilter) => {
    await dispatch(updateCampaignAuthorFilter(selectedFilter));

    onSearch(searchTerm);

    toggleDropdown();
  };

  return (
    <>
      <div className="d-flex justify-content-between my-4">
        <div className="d-flex justify-content-start">
          <Dropdown
            className={"lg d-flex align-items-end "}
            isOpen={dropdownOpen}
            toggle={toggleDropdown}>
            <DropdownToggle className="pb-0" color="text-secondary" caret>
              {campaignFilter}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => changeCampaignFilter(CAMPAIGN_FILTERS.ALL)}
                active={isEqual(campaignFilter, CAMPAIGN_FILTERS.ALL)}>
                {CAMPAIGN_FILTERS.ALL}
              </DropdownItem>
              <DropdownItem
                onClick={() => changeCampaignFilter(CAMPAIGN_FILTERS.MINE)}
                active={isEqual(campaignFilter, CAMPAIGN_FILTERS.MINE)}>
                {CAMPAIGN_FILTERS.MINE}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            size="lg"
            color="secondary"
            onClick={toggleNewModal}
            className="mr-3">
            Add new campaign
          </Button>
          <Button
            size="lg"
            color="light"
            onClick={clearSearchTerm}
            className="mr-3">
            Clear search filter
          </Button>
          <Form onSubmit={onSubmit}>
            <InputGroup className="search-box lg">
              <Input
                id="searchTerm"
                name="searchTerm"
                type="text"
                value={searchTerm || ""}
                onChange={onChange}
                placeholder="Search..."
              />
              <InputGroupAddon addonType="append">
                <Button type="submit" color="white">
                  <FontAwesomeIcon icon={faSearch} color="secondary" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Form>
        </div>
      </div>

      <NewCampaignModal toggle={toggleNewModal} isOpen={newModal} />
    </>
  );
};
CampaignListPageHeader.propTypes = {
  pageConfig: PropTypes.object,
  onSearch: PropTypes.func
};

export const CampaignListTable = (props) => {
  const { campaigns, pageConfig } = props;

  if (isEmpty(campaigns)) {
    return (
      <EmptyContainer
        title="No Campaign Found"
        description="There are currently no campaigns found with this state."
      />
    );
  }

  return (
    <Col className="col-12 px-0">
      <Table responsive className="table-striped">
        <thead>
          <tr>
            <th style={{ width: "25%" }} className="table-heading-style">
              campaign name
            </th>
            <th className="table-heading-style">client name</th>
            <th className="table-heading-style">date created</th>
            <th className="table-heading-style">created by</th>
            <th className="table-heading-style">priority</th>
            <th style={{ width: "25%" }} className="table-heading-style"></th>
          </tr>
        </thead>
        <tbody>
          {map(campaigns, (c, index) => {
            return (
              <CampaignListRow
                key={`campaign-row-${index}`}
                campaign={c}
                pageConfig={pageConfig}
              />
            );
          })}
        </tbody>
      </Table>
    </Col>
  );
};

CampaignListTable.propTypes = {
  pageConfig: PropTypes.object,
  campaigns: PropTypes.array
};

export const CampaignListRow = (props) => {
  const { campaign, pageConfig } = props;

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const [tacticModal, setTacticModal] = useState(false);
  const toggleTacticModal = () => setTacticModal(!tacticModal);

  const campaignUuid = get(campaign, "campaignUuid");
  const campaignTactics = get(campaign, "cases");
  const hasTactics = !isEmpty(campaignTactics);

  const renderTacticRow = () => {
    return (
      <>
        <Table className="table-striped mb-0">
          <thead>
            <tr>
              <th
                style={{ width: "25%" }}
                className="inner-table-heading-style">
                tactics
              </th>
              <th className="inner-table-heading-style">start date</th>
              <th className="inner-table-heading-style">end date</th>
              <th className="inner-table-heading-style">priority</th>
              <th className="inner-table-heading-style">status</th>
              <th
                style={{ width: "25%" }}
                className="inner-table-heading-style"></th>
            </tr>
          </thead>
          <tbody>
            {map(campaignTactics, (tactic) => {
              return (
                <tr key={`tactic-row-${tactic.caseUuid}`}>
                  <td className="inner-table-body-style table-active">
                    {isNil(tactic.name) ? "N/A" : tactic.name}
                  </td>
                  <td className="inner-table-body-style">
                    {tactic.startDate
                      ? moment(tactic.startDate)
                          .utc()
                          .format("MM/DD/YY")
                      : "N/A"}
                  </td>
                  <td className="inner-table-body-style">
                    {tactic.endDate
                      ? moment(tactic.endDate)
                          .utc()
                          .format("MM/DD/YY")
                      : "N/A"}
                  </td>
                  <td className="inner-table-body-style">
                    {isNil(tactic.tacticPriority)
                      ? "N/A"
                      : tactic.tacticPriority}
                  </td>
                  <td className="inner-table-body-style">
                    {getTacticState(tactic.caseState)}
                  </td>
                  <td className="inner-table-body-style text-right">
                    <TacticActionButtons
                      campaign={campaign}
                      tacticUuid={tactic.caseUuid}
                      tacticState={getTacticState(tactic.caseState)}
                      pageConfig={pageConfig}
                    />
                  </td>
                </tr>
              );
            })}

            <tr>
              <td className="inner-table-body-style table-active">
                <Button
                  outline
                  color="primary"
                  size="sm"
                  className="px-3"
                  onClick={toggleTacticModal}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    color="primary"
                    className="mr-2"
                  />
                  Add Tactic
                </Button>
              </td>
              <td className="inner-table-body-style" colSpan="5">
                &nbsp;
              </td>
            </tr>
          </tbody>
        </Table>
        <AddTactic
          isOpen={tacticModal}
          toggle={toggleTacticModal}
          campaignUuid={campaignUuid}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <tr>
        <td className={`${collapse && "table-active"} font-weight-bolder p-4`}>
          <Button
            disabled={!hasTactics}
            onClick={toggle}
            className={"tactic-row-toggler rounded-circle"}
            color="primary-dark">
            <FontAwesomeIcon
              icon={collapse ? faAngleUp : faAngleDown}
              color="white"
            />
          </Button>

          {get(campaign, "name")}
        </td>
        <td className="text-15 p-4">{get(campaign, "clientName")}</td>
        <td className="text-15 p-4 text-nowrap">
          {moment(get(campaign, "createdAt")).format("MMMM Do, YYYY")}
        </td>
        <td className="text-15 p-4">{get(campaign, "authorName")}</td>
        <td className="text-15 p-4">{get(campaign, "campaignPriority")}</td>
        <td className="text-15 py-4 text-right">
          <CampaignActionButtons campaign={campaign} pageConfig={pageConfig} />
        </td>
      </tr>
      {hasTactics && (
        <tr>
          <td colSpan="6" className="p-0 border-0">
            <Collapse isOpen={collapse}>{renderTacticRow()}</Collapse>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

CampaignListRow.propTypes = {
  campaign: PropTypes.object
};

export const CampaignActionButtons = (props) => {
  const { campaign, pageConfig } = props;
  const campaignUuid = get(campaign, "campaignUuid");
  const dispatch = useDispatch();
  const history = useHistory();
  const saving = useSelector((state) => state.campaigns.saving);
  const [archiveModal, setArchiveModal] = useState(false);
  const toggleArchiveModal = () => setArchiveModal(!archiveModal);
  const [reActivateModal, setReActivateModal] = useState(false);
  const toggleReActivateModal = () => setReActivateModal(!reActivateModal);
  const [infoModal, setInfoModal] = useState(false);
  const toggleInfoModal = () => setInfoModal(!infoModal);

  const onArchive = async () => {
    await dispatch(archiveCampaign(campaignUuid));

    history.push(`${ROUTES.CAMPAIGN_ARCHIVED}`);
  };

  const onReActivate = async () => {
    await dispatch(reActivateCampaign(campaignUuid));

    history.push(`${ROUTES.CAMPAIGN_DRAFTS}`);
  };

  const onEdit = () => {
    history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
  };

  const renderButton = (displayText = "Active", color = "", onClick) => {
    return (
      <Button color={color} className="btn-md ml-2" onClick={onClick}>
        {displayText}
      </Button>
    );
  };

  return (
    <>
      {renderButton("Info", "purple", toggleInfoModal)}
      {isCampaignEditActive(campaign) &&
        renderButton("Edit", "primary-dark", onEdit)}
      {isArchiveActive(campaign) &&
        renderButton("Archive", "orange", toggleArchiveModal)}
      {isReactivateActive(pageConfig) &&
        renderButton("Re-Activate", "red", toggleReActivateModal)}
      <ConfirmModal
        toggle={toggleArchiveModal}
        isOpen={archiveModal}
        message="This campaign will become archived. Proceed?"
        onConfirm={onArchive}
        saving={saving}
      />
      <ConfirmModal
        toggle={toggleReActivateModal}
        isOpen={reActivateModal}
        message="This campaign will move back to a draft status. Proceed?"
        onConfirm={onReActivate}
        saving={saving}
      />
      <CampaignInfoModal
        isOpen={infoModal}
        toggle={toggleInfoModal}
        campaign={campaign}
      />
    </>
  );
};

CampaignActionButtons.propTypes = {
  campaign: PropTypes.object
};

export const TacticActionButtons = (props) => {
  const {
    campaign,
    tacticUuid,
    tacticState,
    pageConfig,
    showEdit = true
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const saving = useSelector((state) => state.campaigns.savingState);
  const campaignUuid = get(campaign, "campaignUuid");
  const [activateModal, setActivateModal] = useState(false);
  const toggleActivateModal = () => setActivateModal(!activateModal);

  const [reviewModal, setReviewModal] = useState(false);
  const toggleReviewModal = () => setReviewModal(!reviewModal);

  const [archiveModal, setArchiveModal] = useState(false);
  const toggleArchiveModal = () => setArchiveModal(!archiveModal);

  const [unarchiveModal, setUnarchiveModal] = useState(false);
  const toggleUnarchiveModal = () => setUnarchiveModal(!unarchiveModal);

  const onReview = async () => {
    await dispatch(
      saveCampaignTacticState(
        campaignUuid,
        tacticUuid,
        pageConfig.pageKey,
        "review"
      )
    );

    toggleReviewModal();
  };

  const onActivate = async () => {
    await dispatch(
      saveCampaignTacticState(
        campaignUuid,
        tacticUuid,
        pageConfig.pageKey,
        "publish"
      )
    );

    toggleActivateModal();
  };

  const onArchive = async () => {
    await dispatch(
      saveCampaignTacticState(
        campaignUuid,
        tacticUuid,
        pageConfig.pageKey,
        "archive"
      )
    );

    toggleArchiveModal();
  };

  const onUnarchive = async () => {
    await dispatch(
      saveCampaignTacticState(
        campaignUuid,
        tacticUuid,
        pageConfig.pageKey,
        "unarchive"
      )
    );

    toggleUnarchiveModal();
  };

  const onEdit = () => {
    history.push(
      ROUTES.CAMPAIGN_TACTIC_MANAGE.replace(
        ":campaignId",
        campaignUuid
      ).replace(":caseId", tacticUuid)
    );
  };

  const renderButton = (displayText = "Active", color = "", onClick) => {
    return (
      <LoadingButton
        color={color}
        className={showEdit ? "btn-md ml-2" : "btn-lg"}
        onClick={onClick}
        type="button">
        {displayText}
      </LoadingButton>
    );
  };

  return (
    <>
      {showEdit && renderButton("Edit", "primary-dark", onEdit)}
      {isEqual(tacticState, CAMPAIGN_TACTIC_STATES.SC_DRAFT) &&
        renderButton("Review", "purple", toggleReviewModal)}
      {isEqual(tacticState, CAMPAIGN_TACTIC_STATES.SC_REVIEW) &&
        renderButton("Publish", "green", toggleActivateModal)}
      {isEqual(tacticState, CAMPAIGN_TACTIC_STATES.SC_APPROVED) &&
        renderButton("Archive", "orange", toggleArchiveModal)}
      {isEqual(tacticState, CAMPAIGN_TACTIC_STATES.SC_ARCHIVED) &&
        renderButton("Re-Activate", "red", toggleUnarchiveModal)}

      <ConfirmModal
        toggle={toggleReviewModal}
        isOpen={reviewModal}
        message="This tactic will be moved to the Review state. Proceed?"
        onConfirm={onReview}
        saving={saving}
      />
      <ConfirmModal
        toggle={toggleActivateModal}
        isOpen={activateModal}
        message="This tactic will be moved to the Active state. Proceed?"
        onConfirm={onActivate}
        saving={saving}
      />
      <ConfirmModal
        toggle={toggleArchiveModal}
        isOpen={archiveModal}
        message="This tactic will be moved to the Archived state. Proceed?"
        onConfirm={onArchive}
        saving={saving}
      />
      <ConfirmModal
        toggle={toggleUnarchiveModal}
        isOpen={unarchiveModal}
        message="This tactic will be moved to the Draft state. Proceed?"
        onConfirm={onUnarchive}
        saving={saving}
      />
    </>
  );
};

CampaignActionButtons.propTypes = {
  campaign: PropTypes.object
};

export const TacticExpandCollapseAll = (props) => {
  const expandAll = () => {
    let togglers = document.querySelectorAll(".row-toggler");
    let containers = document.querySelectorAll(".collapse.slide-body");
    if (containers.length === togglers.length) {
      containers.forEach((node, index) => {
        if (!includes(node.classList, "show")) {
          togglers[index].click();
        }
      });
    }
  };

  const collapseAll = () => {
    let togglers = document.querySelectorAll(".row-toggler");
    let containers = document.querySelectorAll(".collapse.slide-body");
    if (containers.length === togglers.length) {
      containers.forEach((node, index) => {
        if (includes(node.classList, "show")) {
          togglers[index].click();
        }
      });
    }
  };

  return (
    <div style={{ marginTop: -15 }} className="d-flex justify-content-end mb-3">
      <div
        className="font-weight-500 mr-2 btn-link cursor-pointer"
        onClick={expandAll}>
        Expand All
      </div>{" "}
      |
      <div
        className="font-weight-500 ml-2 btn-link cursor-pointer"
        onClick={collapseAll}>
        Collapse All
      </div>
    </div>
  );
};
