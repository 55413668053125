import { subscribeToUser, subscribeToUserRoles } from "../db/users-db";
import { doSignOut } from "../api/auth";

const actionsPrefix = "user";

export const USER_LOAD = `${actionsPrefix}/USER_LOAD`;
export const USER_LOADED = `${actionsPrefix}/USER_LOADED`;
export const USER_LISTEN = `${actionsPrefix}/USER_LISTEN`;
export const USER_ROLES_LOADED = `${actionsPrefix}/USER_ROLES_LOADED`;
export const USER_ROLES_LISTEN = `${actionsPrefix}/USER_ROLES_LISTEN`;
export const USER_SIGN_OUT = `${actionsPrefix}/signout`;

export const loadUser = (uid) => {
  return async function(dispatch, getState) {
    dispatch({
      type: USER_LOAD
    });

    const userListener = await subscribeToUser(uid, (snapshot) => {
      const user = snapshot.exists ? snapshot.data() : null;
      dispatch({
        type: USER_LOADED,
        user: user
      });
    });

    const userRolesListener = await subscribeToUserRoles(uid, (snapshot) => {
      const userRoles = snapshot.exists ? snapshot.data() : null;
      dispatch({
        type: USER_ROLES_LOADED,
        userRoles
      });
    });

    dispatch({
      type: USER_LISTEN,
      userListener: userListener
    });

    dispatch({
      type: USER_ROLES_LISTEN,
      userRolesListener: userRolesListener
    });
  };
};

export const setUserAndRole = (user, userRoles) => {
  return async function(dispatch) {
    dispatch({
      type: USER_LOADED,
      user
    });

    dispatch({
      type: USER_ROLES_LOADED,
      userRoles
    });
  };
};

export const signOut = () => {
  return async function(dispatch) {
    await doSignOut();

    dispatch({
      type: USER_SIGN_OUT
    });
  };
};

// Actions that should not to be exported
