import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import GlobalHeader from "../components/GlobalHeader";
import { userSignedIn } from "../utils/route-utils";
import { USER_SEARCH_MODULE_NAME } from "../constants/route-constants";
import LoadingPage from "./home/LoadingPage";
import { isUserSearchViewable } from "../utils/permission-utils";
import ToolsLandingPage from "./tools/ToolsLandingPage";

class ToolsApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth, userRoles } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    if (!isUserSearchViewable(userRoles)) {
      return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={USER_SEARCH_MODULE_NAME} />
        <Switch>
          <Route exact path={ROUTES.TOOLS_ROOT}>
            {this.conditionallyRenderDefaultOrPage(<ToolsLandingPage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.user.userRoles
});

export default compose(withRouter, connect(mapStateToProps, null))(ToolsApp);
