import React from "react";
import { Container } from "reactstrap";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import EmptyContainer from "../../components/common/EmptyContainer";

const NotAuthorizedPage = () => {
  return (
    <div>
      <SecondaryNavigation />
      <Container>
        <div className="p-4">
          <EmptyContainer
            title={`Not Authorized`}
            description="You do not have the required permissions to access this page.
            Please reach out to your administrator if you require access."
          />
        </div>
      </Container>
    </div>
  );
};

export default NotAuthorizedPage;
