import React, { useState } from "react";
import {
  Button,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { compact, get, isEmpty, map, size } from "lodash";
import { startMediaDownloadZip } from "../../utils/media-utils";
import "./modal.scss";

const VerificationPhotoViewerModal = (props) => {
  const { toggle, isOpen, media } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const mediaSize = size(media);

  const onOpened = () => {
    if (activeIndex !== 0) {
      setActiveIndex(0);
    }

    if (animating) {
      setAnimating(false);
    }
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === mediaSize - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? mediaSize - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const downloadZip = async () => {
    await startMediaDownloadZip(
      map(media,(m) => ({ url: m, filename: m.substring(m.lastIndexOf('/')+1) })),
      setDownloading);
  };

  const slides = compact(
    map(media, (m) => {
      const imageUrl = `${m}?w=${800}&h=${600}&fit=max`
      if (!imageUrl) {
        return null;
      }

      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={m}>
          <div className="case-detail-body">
            <div className="position-relative">
              <div style={{ textAlign: "center" }}>
                <img src={imageUrl} alt={``} />
              </div>
            </div>
          </div>
          <CarouselCaption captionText={`${activeIndex + 1} of ${mediaSize}`} />
        </CarouselItem>
      );
    })
  );

  return (
    <Modal
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      className={"media-viewer-modal"}
      size="lg">
      <ModalHeader toggle={toggle} className="border-0"></ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between pb-3">
          <div className="text-12 text-uppercase text-secondary font-weight-bold ">
            Total Images: {size(media)}
          </div>
          {downloading && <Spinner size="sm" color="primary" />}
          {!downloading && (
            <Button
              className="text-12 p-0 font-weight-bold text-uppercase"
              color="link"
              onClick={downloadZip}>
              <FontAwesomeIcon icon={faDownload} size={"sm"} className="mr-1" />
              Download All
            </Button>
          )}
        </div>

        {isEmpty(media) && "No media was found."}
        {!isEmpty(media) && (
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={false}>
            {slides}
            {mediaSize > 1 && (
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
            )}
            {mediaSize > 1 && (
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            )}
          </Carousel>
        )}
      </ModalBody>
    </Modal>
  );
};

VerificationPhotoViewerModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  media: PropTypes.array
};

export default VerificationPhotoViewerModal;
