import React from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { TACTIC_TYPES } from "../../../utils/campaign-utils";
import ModalBody from "reactstrap/es/ModalBody";
import { useHistory } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";

const AddTactic = (props) => {
  const { isOpen, toggle, campaignUuid } = props;
  const history = useHistory();

  const onAddTactic = (tactic) => {
    history.push(
      ROUTES.CAMPAIGN_TACTIC_NEW.replace(":campaignId", campaignUuid).replace(
        ":type",
        tactic.key
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0"></ModalHeader>
      <ModalBody>
        <div>
          <h5>Static Post</h5>
          <div className="tactic-container d-flex flex-wrap">
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.IMAGE)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              Single image
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.IMAGE_SERIES)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              Image series
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.TEXT)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              Text
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.VIDEO)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              Video
            </Button>
          </div>
        </div>

        <div className="mt-3">
          <h5>Interactive Post</h5>

          <div className="tactic-container d-flex flex-wrap">
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.CLINICAL_MOMENT_CME)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.CLINICAL_MOMENT_CME.displayName}
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.CLINICAL_MOMENT)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.CLINICAL_MOMENT.displayName}
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.QUIZ)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.QUIZ.displayName}
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.QUIZ_SERIES)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.QUIZ_SERIES.displayName}
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.POLL)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.POLL.displayName}
            </Button>
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.POLL_SERIES)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.POLL_SERIES.displayName}
            </Button>
          </div>
        </div>

        <div>
          <h5>Promotions</h5>
          <div className="tactic-container d-flex flex-wrap">
            <Button
              outline
              className="secondary mb-3 mr-3"
              onClick={() => onAddTactic(TACTIC_TYPES.PROMOTION)}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="secondary"
                className="mr-2"
              />
              {TACTIC_TYPES.PROMOTION.displayName}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

AddTactic.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  campaignUuid: PropTypes.string
};

export default AddTactic;
