/** @format */
export var iosLicense = {
  api_token: "oygdfudAv-5TMcjKexa0dw",
  app_identifiers: ["com.figure1.pro.Figure1ProDev", "com.figure1.pro.Figure1ProQa", "com.figure1.pro.Figure1ProStaging", "com.figure1.pro.Figure1Pro", "com.movablescience.figure1"],
  available_actions: [],
  domains: ["https://api.photoeditorsdk.com"],
  enterprise_license: false,
  expires_at: null,
  features: ["camera", "library", "export", "customassets", "whitelabel", "adjustment", "brush", "sticker", "text", "transform"],
  issued_at: 1622041204,
  minimum_sdk_version: "1.0",
  owner: "Figure 1",
  platform: "iOS",
  products: ["pesdk"],
  version: "2.4",
  signature: "Ve2Bnu/N/Tew6BPDfFh92RhK9N4RRWSpyM3U3SOd+oKw1qBHfP3nTYAnYnA69k9QJxjyIPB0hiMkr/faCQ0VZUyzwaOBlTXYHeGQjFaqelZzG5ecj/AWRx5ZgA1MtNoQyP6S9qH+BwKvr3kLlEuTRaAcRa6rr5FUyKrXCaD5CIFuIAP/ZffOf9YOv4s65kbqpVS20Z0xjDLAQOsIw/Lnki7cZPZrIBdllnwc9CxwBcx+aCF/bIWQnkXkEe2bgH89A54w0aaEFyuh60Eej6t6XM0p+ppp4RaSKDKkQYzERxNgcXN3OkDUPAEE/NsC7sjbMK1o61t+0bMNoKTk4r/FU0tdZxrOe0GUPt3+c88F+2vwhKUmsYS1JV+JnQoDa7vT1XQU1oUnC7AfrwJF36J2uT4Rz2rsakJ1eTMJP1RD0Anr9Xt9i7fHC2Qiiz61tZGzPd4pDucFtgAeJvDaTcwjoJ5bzzduZ2tnyPio4pIk+2aF4qiJhLqu3rQw154yaRDBxoCOKjWVS5Nu5XsIuU23rl57pEF1/gTGogXs/HT1jsqy0fnQmliuXiKiCKiET9uLvaRkMQksIAjo44rtpBPzAz/YcD8uE/fpcTQkGa17K9a3Smx5RGJUJb9UCD2eJiJAmqDbXrP2s1nYQM07r146Q8LUKYGop9ikTmSsx1/TmBs="
};
export var androidLicense = {
  api_token: "A21TXAm8bwToH0cBo6cYSw",
  app_identifiers: ["com.figure1pro.development", "com.figure1pro.qa", "com.figure1pro.staging", "com.figure1pro", "com.figure1.android"],
  available_actions: [],
  domains: ["https://api.photoeditorsdk.com"],
  enterprise_license: false,
  expires_at: null,
  features: ["camera", "library", "export", "customassets", "whitelabel", "adjustment", "brush", "sticker", "text", "transform"],
  issued_at: 1622041207,
  minimum_sdk_version: "1.0",
  owner: "Figure 1",
  platform: "Android",
  products: ["pesdk"],
  version: "2.4",
  signature: "OfYEIyu7YSWXcXXrdt1WqY/Wv15R1br9IHCju3lrfql28PhEr+h3ARUCPjUSmdELzWSDnfF8xhO3P8iCuvdTNCh80Or984xsBUgAOy9b0S+l907EHBa3+VMHnRNWQXlMi3A+Mg5y1BpfgPFApecDj24n/zeKxhYb5LmKLbxmloHqPcekibI2bsyfi9LOlnO7ADM7SRaGG4PBcCVHuqzcUjQDVgIvs6yjNtakf8PWdmd8NpyzjUUra0ljGs3kgQPezOIgc89NYd1R5ODY1yFkY/HkqDYupp9eFX4VpuNGQJDJx3jedHLj86/PiTeuPqQxTOmwS7hYFWccabu7Jxai6yqXUG9ObwoEuZvyXLkJNO4RN2KyR/OecSCdWgs7nWrPDI1Dhw2q0jOnfSlAW2un09++z/pX1jwt7QIXH3QXwSk9j0KjDBRLfsDusybQYDJJNSk70k4Tm8nF2BIszHoFo7hNWN+cXE3tZlj+mTKoVTrcYS32qRdJR0qdejO77QyDOnUp2j3FYorrQoUozT7x41nnXYnZeEll/tOIY+5lwyjuCaywY4gN5mYyhctn/boRysk7ajRRdQ7L5DDtto0A9D3mLGvutmmb6oGNhHuIs+MgA7v949LwxVihLTfzjYniJbEeJtbQ6hg3TlVMUeyShUGs17zwxtWA6a3pXayOGVw="
};
export var html5License = {
  api_token: "pGC4o9Yf79Vy0p9CaICn3Q",
  app_identifiers: ["*.figure1.com"],
  available_actions: [],
  domains: ["https://api.photoeditorsdk.com"],
  enterprise_license: false,
  expires_at: null,
  features: ["camera", "library", "export", "customassets", "whitelabel", "adjustment", "brush", "sticker", "text", "transform"],
  issued_at: 1600366926,
  minimum_sdk_version: "1.0",
  owner: "Figure 1",
  platform: "HTML5",
  products: ["pesdk"],
  version: "2.4",
  signature: "T4rV6h3CsIwju9zCGRVgZfEmav8qA2wz+BMYjITshkdJryUnhsN2wZZcWv6JImYnnY5w2u2rUtmJTsFO4pItNrYRtfOUY8tLQzL5xkvA1lgql8bnjRGkJ/fdV5Q7gGz/vnFkangcAHvmmuvZfUFdL9+H/P25B/57myBh2QV808wP31zCNphs70TpkyVYA5uEe548Bd9TzUXDoMM3XZDaLQCi/wOa0JQWW0ZabuJm+e+fa/5twsdE1uf3pxRP0Myj/BnPwfY2WgQ+SBWEoZjmfS8+zDVbVTu8yuHjdTzpAqEHInv6NXe54IAslyP/o+FdFwS++K8K2kaVY3PTcqVYjaT9iiiEFpkKF37NeDy4TTIJgM7sIpcgBnd255pvPkvdVeEYEtLPJjxyQEuy1NYOb7Jv5NUR7YdQ4dQALwODu7/gWoh5NX81Vk0QxCXjicDe3fTTHjFW/WMTt/z1cPtx0UWQcZz1YpQB6hwygAVFvs4Z+xZ44QT2p3JcMyPgEDu+o5N4aWT/6k5keVMvtLNQSIWO0dIfkw10sbj1VqDU9lvRHfAWXZm/RtqEBMNR+cIAJF37/YpO9klgXHu2Td44Y4KGCV7BCoEgUbzHCLPJk70+DltWpWvgLB7JChQRyx14K2pxT87pBUGX9ToWZkcpwouryB+dKj0GjHOKIg2eVRM="
};