import React, { useEffect, useState } from "react";
import { Badge, Button, Container, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadAdminUsers } from "../../actions/users.actions";
import { compact, get, isEmpty, map } from "lodash";
import { getRoleList } from "../../utils/permission-utils";
import AdminRoleModal from "../../components/modals/AdminRoleModal";
import "./pages.scss";
import Loading from "../../components/Loading";
import { useLocation } from "react-router";

const AdminLandingPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const processing = useSelector((state) => state.users.processing);
  const users = useSelector((state) => state.users.users);
  const [adminModal, setAdminModal] = useState(false);
  const [activeUser, setActiveUser] = useState(null);

  const toggleAdminModal = () => setAdminModal(!adminModal);

  useEffect(() => {
    dispatch(loadAdminUsers());
  }, [dispatch, location]);

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    const userTable = compact(
      map(users, (u, index) => {
        const rolesList = getRoleList(u);
        return (
          <tr key={`user-${index}`}>
            <td className="table-row-style align-middle text-15">
              {get(u, "name")}
            </td>
            <td className="table-row-style align-middle text-15">
              {get(u, "email")}
            </td>
            <td className="table-row-style align-middle text-15">
              {isEmpty(rolesList) && (
                <Badge color="secondary" pill>
                  None
                </Badge>
              )}
              {map(rolesList, (r, bIdx) => (
                <Badge
                  key={`user-${index}-${bIdx}`}
                  className="mr-2"
                  color={r.color}
                  pill>
                  {r.displayName}
                </Badge>
              ))}
            </td>
            <td
              style={{ width: "7%" }}
              className="table-row-style align-middle text-15">
              <Button
                color="primary-dark"
                className={"btn-md"}
                onClick={() => {
                  setActiveUser(u);
                  toggleAdminModal();
                }}>
                Edit
              </Button>
            </td>
          </tr>
        );
      })
    );

    return (
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="table-heading-style w-25">name</th>
            <th className="table-heading-style w-25">email</th>
            <th className="table-heading-style w-auto">roles</th>
            <th className="table-heading-style w-auto">actions</th>
          </tr>
        </thead>
        <tbody>{userTable}</tbody>
      </Table>
    );
  };

  return (
    <div className="admin-page">
      <Container>
        <div className="pt-4 pb-2">
          <p className="font-weight-bold">User Roles</p>
          <p>
            Below are the list of users that currently have access to certain
            features of the Admin Tool.
          </p>
        </div>
        <div className="min-vh-100">{renderBody()}</div>
      </Container>

      <AdminRoleModal
        toggle={toggleAdminModal}
        isOpen={adminModal}
        activeUser={activeUser}
      />
    </div>
  );
};

export default AdminLandingPage;
