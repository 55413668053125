import React, { useState } from "react";
import PropTypes from "prop-types";
import { defaultTo, get, toUpper } from "lodash";
import "./style.scss";
import moment from "moment";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import EditUserModal from "./EditUserModal";
import ResetPasswordModal from "./ResetPasswordModal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import {
  getProfessionName,
  getSpecialtyName,
  getDisplayName
} from "../../../utils/verification-utils";

export const ToolsUserTableHeading = (props) => {
  return (
    <thead>
      <tr>
        <th className="table-heading-style">user uid</th>
        <th className="table-heading-style">user uuid</th>
        <th className="table-heading-style">username</th>
        <th className="table-heading-style">display name</th>
        <th className="table-heading-style">email</th>
        <th className="table-heading-style">partner account</th>
        <th className="table-heading-style">legacy account</th>
        <th className="table-heading-style">verified</th>
        <th className="table-heading-style">verification status</th>
        <th className="table-heading-style">verification type</th>
        <th className="table-heading-style">profession</th>
        <th className="table-heading-style">specialty</th>
        <th className="table-heading-style">last seen</th>
        <th className="table-heading-style"># of cases posted</th>
        <th className="table-heading-style"># of comments</th>
        <th style={{ width: "25%" }} className="table-heading-style"></th>
      </tr>
    </thead>
  );
};

export const ToolsUserTableRow = (props) => {
  const { data } = props;

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => setEditModal(!editModal);

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);

  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const toggleResetPasswordModal = () =>
    setResetPasswordModal(!resetPasswordModal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <tr>
        <td className="table-row-style border-left-0">
          {get(data, "userUid")}
        </td>
        <td className="table-row-style">{get(data, "userUuid")}</td>
        <td className="table-row-style">{get(data, "username")}</td>
        <td className="table-row-style">{getDisplayName(data)}</td>
        <td className="table-row-style">
          <div>{get(data, "email")}</div>
        </td>
        <td className="table-row-style">
          {get(data, "isPartner") ? "Yes" : "No"}
        </td>
        <td className="table-row-style">
          {get(data, "legacyAccount") ? "Yes" : "No"}
        </td>
        <td className="table-row-style">
          {get(data, "isVerified") ? "Yes" : "No"}
        </td>
        <td className="table-row-style">
          {toUpper(get(data, "verificationStatus"))}
        </td>
        <td className="table-row-style">{get(data, "verificationType")}</td>
        <td className="table-row-style">{getProfessionName(data)}</td>
        <td className="table-row-style">
          {getSpecialtyName(data, null, true)}
        </td>
        <td className="table-row-style">
          {get(data, "lastSeen")
            ? moment(get(data, "lastSeen")).format("MMM DD, YYYY [at] h:mma")
            : "N/A"}
        </td>
        <td className="table-row-style">
          {defaultTo(get(data, "approvedCaseCount"), 0)}
        </td>
        <td className="table-row-style">
          {defaultTo(get(data, "approvedCommentCount"), 0)}
        </td>
        <td>
          <Dropdown isOpen={dropdownOpen} toggle={toggleMenu}>
            <DropdownToggle outline caret>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
              <DropdownItem onClick={toggleResetPasswordModal}>
                Reset Password
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                className="btn-main-red"
                onClick={toggleDeleteModal}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      {editModal && (
        <EditUserModal
          isOpen={editModal}
          toggle={toggleEditModal}
          user={data}
        />
      )}
      {deleteModal && (
        <ConfirmDeleteModal
          isOpen={deleteModal}
          toggle={toggleDeleteModal}
          user={data}
        />
      )}
      {resetPasswordModal && (
        <ResetPasswordModal
          isOpen={resetPasswordModal}
          toggle={toggleResetPasswordModal}
          user={data}
        />
      )}
    </>
  );
};

ToolsUserTableRow.propTypes = {
  data: PropTypes.object
};
