import {
  doCreateUserWithEmailAndPassword,
  doSignInWithEmailAndPassword
} from "../api/auth";
import Firebase from "../firebase";
import { createUserCall } from "../api/cloud-functions";
import { createSignupUserDoc } from "../db/users-db";
import { setAuthUser } from "./auth.actions";

const auth = Firebase.auth;
const provider = Firebase.provider;

const actionsPrefix = "anon";

export const SIGN_IN = `${actionsPrefix}/SIGN_IN`;
export const SIGN_UP = `${actionsPrefix}/SIGN_UP`;
export const SIGNING_UP = `${actionsPrefix}/SIGNING_UP`;
export const SIGN_IN_RESULT = `${actionsPrefix}/SIGN_IN_RESULT`;
export const RESET_MESSAGE = `${actionsPrefix}/RESET_MESSAGE`;
export const VALIDATING_USER = `${actionsPrefix}/VALIDATING_USER`;
export const CHECK_SIGN_IN_RESULT = `${actionsPrefix}/CHECK_SIGN_IN_RESULT`;
export const CHECK_SIGN_IN_RESULT_COMPLETE = `${actionsPrefix}/CHECK_SIGN_IN_RESULT_COMPLETE`;

export const signUp = (email, password, firstName, lastName) => {
  return async function(dispatch) {
    dispatch({
      type: SIGNING_UP
    });

    try {
      const authUser = await doCreateUserWithEmailAndPassword(
        email,
        password,
        firstName,
        lastName
      );

      await createUserCall(email, firstName, lastName);
      await createSignupUserDoc(authUser.uid, email, firstName, lastName);

      dispatch({
        type: SIGN_UP,
        isError: false
      });

      dispatch(setAuthUser(authUser));
    } catch (e) {
      console.log(e);
      dispatch({
        type: SIGN_UP,
        message: e.message,
        isError: true
      });
    }
  };
};

export const signIn = (email, password, handle) => {
  return async function(dispatch) {
    dispatch({
      type: SIGN_IN,
      message: "Signing in...",
      isProcessing: true
    });

    try {
      await doSignInWithEmailAndPassword(email, password);
      handle && handle();
      dispatch(signInResult("Signed in"));
    } catch (e) {
      console.log(e);
      dispatch(signInResult(e.message, true));
    }
  };
};

export const signInWithGoogle = () => {
  return async function(dispatch) {
    await dispatch({
      type: RESET_MESSAGE
    });

    auth.signInWithRedirect(provider);
  };
};

export const signInGoogleResult = () => {
  return async function(dispatch) {
    dispatch({
      type: CHECK_SIGN_IN_RESULT
    });

    auth
      .getRedirectResult()
      .then(() => {
        dispatch({
          type: CHECK_SIGN_IN_RESULT_COMPLETE
        });
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: CHECK_SIGN_IN_RESULT_COMPLETE,
          message: e.message
        });
      });
  };
};

export const signInResult = (message, isError = false) => {
  return {
    type: SIGN_IN_RESULT,
    message,
    isError,
    isProcessing: false
  };
};
