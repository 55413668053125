import React, { useEffect } from "react";
import { Container, Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import { compact, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getCases } from "../../actions/case-explorer.actions";
import {
  CaseExplorerTableHeading,
  CaseExplorerTableRow
} from "./components/CaseExplorerSections";
import { CaseExplorerPagination } from "./components/CaseExplorerNavigation";
import {
  getAllCountriesAndStates,
  getAllLabels,
  getAllProfessionAndSpecialties
} from "../../actions/reference.actions";
import EmptyContainer from "../../components/common/EmptyContainer";
import {
  getAllPromotionChannels,
  getAllPromotions
} from "../../actions/promotions.actions";
import Loading from "../../components/Loading";

const CaseExplorerLandingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.caseExplorer.processing);
  const cases = useSelector((state) => state.caseExplorer.cases);

  useEffect(() => {
    dispatch(getAllLabels());
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllCountriesAndStates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCases(0));
    dispatch(getAllPromotions());
    dispatch(getAllPromotionChannels());
  }, [location, dispatch]);

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    const casesTable = compact(
      map(cases, (c, index) => {
        return <CaseExplorerTableRow key={`case-${index}`} caseData={c} />;
      })
    );

    return (
      <div className="px-4 pt-4 pb-1 bg-white">
        <CaseExplorerPagination />
        {isEmpty(casesTable) && (
          <EmptyContainer
            title={`No Cases Found`}
            description="Please adjust your search criteria to locate your desired cases."
          />
        )}

        {!isEmpty(casesTable) && (
          <div
            className="table-responsive"
            style={{ height: "calc(100vh - 235px)" }}>
            <Table bordered>
              <CaseExplorerTableHeading />
              <tbody>{casesTable}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container className="case-explorer force-show-scrollbars mt-4 mb-0">
      <div>{renderBody()}</div>
    </Container>
  );
};

export default CaseExplorerLandingPage;
