import {
  includes,
  isEqual,
  size,
  toUpper,
  find,
  get,
  isNil,
  filter
} from "lodash";
import moment from "moment";
import { isModeratorManager } from "./permission-utils";
import { TAGGING_FILTERS } from "./tagging-utils";

export const LOCK_EXPIRE_TIME = 60 * 60; // 1 hours

export const CASE_REACTIONS = {
  AGREE: "agree",
  CLINICALLY_USEFUL: "clinically_useful",
  INFORMATIVE: "informative"
};

export const CASE_STATES = Object.freeze({
  APPROVED: "APPROVED",
  FLAGGED: "FLAGGED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  REJECTED: "REJECTED",
  REPORTED: "REPORTED",
  FLAGGED_TAGGER: "FLAGGED_TAGGER",
  PENDING_TAGGING: "PENDING_TAGGING",
  PENDING_NLP: "PENDING_NLP",
  EDIT_SUGGESTED: "EDIT_SUGGESTED"
});

export const CASE_STATES_SEARCH_FILTERS = Object.freeze({
  APPROVED: "Approved",
  FLAGGED: "Flagged",
  PENDING_APPROVAL: "Pending Approval",
  REJECTED: "Rejected",
  REPORTED: "Reported",
  FLAGGED_TAGGER: "Flagged By Tagger",
  PENDING_TAGGING: "Pending Tagging",
  PENDING_NLP: "Pending Auto-Tagging",
  EDIT_SUGGESTED: "Edit Suggested"
});

export const CASE_FILTERS = {
  QUEUE: {
    stateName: "PENDING_APPROVAL",
    statsKey: "pending_approval",
    displayName: "Queue"
  },
  SUGGESTED_EDITS: {
    statsKey: "edit_suggested",
    displayName: "Suggested edits",
    activeAuxClassName: "suggested",
    stateName: "EDIT_SUGGESTED",
    isViewable: (userRoles) => {
      return isModeratorManager(userRoles);
    }
  },
  FLAGGED: {
    stateName: "FLAGGED",
    statsKey: "flagged",
    displayName: "Flagged",
    activeAuxClassName: "flagged"
  },
  REPORTED: {
    stateName: "REPORTED",
    statsKey: "reported",
    displayName: "Reported",
    activeAuxClassName: "reported"
  },
  REJECTED: {
    stateName: "REJECTED",
    statsKey: "rejected",
    displayName: "Rejected"
  },
  ARCHIVED: {
    stateName: "ARCHIVED",
    statsKey: "archived",
    displayName: "Archived",
    partnerOnly: true
  },
  APPROVED: {
    stateName: "APPROVED",
    statsKey: "approved",
    displayName: "Approved"
  }
};

export const getBadgeCssClass = (caseState) => {
  const upperCaseState = toUpper(caseState);

  switch (upperCaseState) {
    case CASE_FILTERS.SUGGESTED_EDITS.stateName:
      return CASE_FILTERS.SUGGESTED_EDITS.activeAuxClassName;
    case CASE_FILTERS.FLAGGED.stateName:
      return CASE_FILTERS.FLAGGED.activeAuxClassName;
    case CASE_FILTERS.REPORTED.stateName:
      return CASE_FILTERS.REPORTED.activeAuxClassName;
    default:
      return "";
  }
};

export const isModerationApprovalActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return (
    isEqual(upperCaseState, CASE_STATES.PENDING_APPROVAL) ||
    isEqual(upperCaseState, CASE_STATES.FLAGGED) ||
    isEqual(upperCaseState, CASE_STATES.REPORTED) ||
    isEqual(upperCaseState, CASE_STATES.EDIT_SUGGESTED)
  );
};

export const isCaseActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return isEqual(upperCaseState, CASE_STATES.APPROVED);
};

export const isTaggingApprovalActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return (
    isEqual(upperCaseState, CASE_STATES.PENDING_TAGGING) ||
    isEqual(upperCaseState, CASE_STATES.FLAGGED_TAGGER)
  );
};

export const isFlaggedActive = (caseState) => {
  return !isEqual(toUpper(caseState), CASE_STATES.FLAGGED);
};

export const isCaseApproved = (caseState) => {
  return isEqual(toUpper(caseState), CASE_STATES.APPROVED);
};

export const isCaseReported = (caseState) => {
  return isEqual(toUpper(caseState), CASE_STATES.REPORTED);
};

export const isTaggingFlagActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return isEqual(upperCaseState, CASE_STATES.PENDING_TAGGING);
};

export const isTaggingRejectedActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return (
    isEqual(upperCaseState, CASE_STATES.PENDING_TAGGING) ||
    isEqual(upperCaseState, CASE_STATES.FLAGGED_TAGGER)
  );
};

export const isTaggingForceToManualActive = (caseState) => {
  const upperCaseState = toUpper(caseState);

  return isEqual(upperCaseState, CASE_STATES.PENDING_NLP);
};

export const isRejectedActive = (caseState) => {
  return !isEqual(toUpper(caseState), CASE_STATES.REJECTED);
};

export const isRejectAllowed = (caseState) => {
  return (
    isEqual(toUpper(caseState), CASE_STATES.PENDING_APPROVAL) ||
    isEqual(toUpper(caseState), CASE_STATES.FLAGGED) ||
    isEqual(toUpper(caseState), CASE_STATES.EDIT_SUGGESTED)
  );
};

export const isEditActive = (caseState) => {
  return (
    isEqual(toUpper(caseState), CASE_STATES.PENDING_APPROVAL) ||
    isEqual(toUpper(caseState), CASE_STATES.FLAGGED) ||
    isEqual(toUpper(caseState), CASE_STATES.REPORTED) ||
    isEqual(toUpper(caseState), CASE_STATES.EDIT_SUGGESTED)
  );
};

export const isSuggestionApprovalAllowed = (caseState) => {
  return isEqual(toUpper(caseState), CASE_STATES.EDIT_SUGGESTED);
};

export const isModeratorEdit = (suggestedEdit, moderatorUid) => {
  return isEqual(moderatorUid, get(suggestedEdit, "moderatorUid"));
};

export const hasSuggestedEditsToSubmit = (caseData, moderatorUid) => {
  const originalMedia = get(caseData, "media", []);
  const suggestedEdits = get(caseData, "moderationEdit", []);
  const filteredEdits = filter(suggestedEdits, (edit) => {
    if (isEqual(edit.editType, SUGGESTED_EDIT_TYPES.MEDIA)) {
      const mediaUuid = edit.mediaUuid;
      const currentMedia = find(originalMedia, (m) =>
        isEqual(m.mediaUuid, mediaUuid)
      );

      return !isNil(currentMedia);
    }

    return true;
  });

  return !isNil(find(filteredEdits, (e) => isModeratorEdit(e, moderatorUid)));
};

export const isPartnerCaseActive = (caseState) => {
  return isEqual(toUpper(caseState), CASE_STATES.APPROVED);
};

export const getCasesSortDirection = (statsKey, stateSortDirections) => {
  if (get(stateSortDirections, statsKey)) {
    return get(stateSortDirections, statsKey);
  }

  return includes(
    [CASE_FILTERS.APPROVED.statsKey, TAGGING_FILTERS.TAGGED.statsKey],
    statsKey
  )
    ? "desc"
    : "asc";
};

export const getCasesRefreshPage = (currentPage, cases) => {
  if (size(cases) > 1 || currentPage === 0) {
    return currentPage;
  }

  return currentPage - 1;
};

export const findCaseLock = (caseUuid, allLocks, user) => {
  const lock = find(allLocks, (l) => isEqual(l.caseUuid, caseUuid));

  if (lock && lock.lockTime) {
    const lockUserUid = get(lock, "uid");
    const currentUserUid = get(user, "userUid");
    const expireTime = lock.lockTime.seconds + LOCK_EXPIRE_TIME;
    const currentTime = moment().unix();

    if (currentTime > expireTime) {
      console.log("Lock has expired", new Date(expireTime * 1000));
      return null;
    }

    if (!isEqual(lockUserUid, currentUserUid)) {
      return lock;
    }
  }

  return null;
};

export const SUGGESTED_EDIT_TYPES = {
  MEDIA: "media",
  CONTENT: "content"
};

export const buildSuggestedEditMediaUrl = (
  existingMediaUrl,
  suggestedEditMediaFilename
) => {
  let newUrl = "";
  if (existingMediaUrl && suggestedEditMediaFilename) {
    newUrl = existingMediaUrl.replace(
      /\/[0-9A-Za-z.]+$/,
      `/${suggestedEditMediaFilename}`
    );
  }
  return newUrl;
};

export const formatSuggestedEditMedia = (suggestedEditMediaArray) => {
  let suggestedEdits = {};
  for (
    let editIndex = 0;
    editIndex < suggestedEditMediaArray.length;
    editIndex++
  ) {
    const uuid = suggestedEditMediaArray[editIndex].mediaUuid;
    suggestedEdits[uuid] = suggestedEditMediaArray[editIndex];
  }

  return suggestedEdits;
};

export const updateMediaUrlsFromSuggestion = (
  originalMedia,
  suggestedEditMediaObject,
  moderatorUid
) => {
  return originalMedia.map((media) => {
    if (suggestedEditMediaObject[media.mediaUuid]) {
      const suggestedEdit = suggestedEditMediaObject[media.mediaUuid];
      if (isEqual(moderatorUid, get(suggestedEdit, "moderatorUid"))) {
        return { ...media, url: suggestedEditMediaObject[media.mediaUuid].url };
      }
    }

    return media;
  });
};

export const getWebEnvironmentUrl = () => {
  const adminToolUrl = window.location.origin;

  if (includes(adminToolUrl, "local") || includes(adminToolUrl, "dev")) {
    return "https://pro-web-client-dev.firebaseapp.com";
  }

  if (includes(adminToolUrl, "qa")) {
    return "https://pro-qa.figure1.com";
  }

  return "https://app.figure1.com";
};

export const getWebCaseUrl = (caseUuid) => {
  return `${getWebEnvironmentUrl()}/case-detail/${caseUuid}`;
};
