import ElasticSearchConfig from "./ElasticSearchConfig";
import { isEmpty } from "lodash";

export const fetchMeshTags = async (prefix) => {
  if (isEmpty(ElasticSearchConfig.apiToken)) {
    return {};
  }

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${ElasticSearchConfig.apiToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      size: 20,
      suggest: {
        suggestion: {
          prefix: prefix,
          completion: {
            field: "typeAhead",
            skip_duplicates: "true"
          }
        }
      }
    })
  };

  const response = await fetch(
    `${ElasticSearchConfig.endpointUrl}/public_search_terms/_search?_source_includes=text`,
    options
  );

  return response.json();
};

export const fetchSchools = async (searchTerm) => {
  if (isEmpty(ElasticSearchConfig.apiToken)) {
    return {};
  }

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${ElasticSearchConfig.apiToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      size: 20,
      query: {
        multi_match: {
          query: searchTerm,
          type: "bool_prefix",
          fields: [
            "schoolCompletions",
            "schoolCompletions._2gram",
            "schoolCompletions._3gram"
          ]
        }
      }
    })
  };

  const response = await fetch(
    `${ElasticSearchConfig.endpointUrl}/public_schools/_search`,
    options
  );

  return response.json();
};
