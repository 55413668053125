import {
  PROMOTION_CHANNELS_LOADED,
  PROMOTIONS_LOAD,
  PROMOTIONS_LOADED
} from "../actions/promotions.actions";
import { get, isEqual, isNil, map, sortBy, find, concat, filter } from "lodash";
import {
  PROMOTION_CHANNEL_DELETED,
  PROMOTION_CHANNEL_SAVED,
  PROMOTION_DELETED,
  PROMOTION_LOADED
} from "../actions/promotion.actions";

const INITIAL_STATE = {
  allPromotions: [],
  allChannels: [],
  isProcessing: false,
  isError: false,
  message: null,
  sortByField: "promotion_name"
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMOTIONS_LOAD:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case PROMOTIONS_LOADED:
      return {
        ...state,
        allPromotions: sortBy(action.data, state.sortByField),
        isProcessing: action.isProcessing,
        isError: action.isError,
        message: action.message
      };
    case PROMOTION_DELETED:
      return {
        ...state,
        allPromotions: filter(
          state.allPromotions,
          (p) => !isEqual(action.promotionUuid, p.promotion_uuid)
        )
      };
    case PROMOTION_LOADED:
      const promotionUuid = get(action.data, "promotion_uuid");
      const isNewPromotion = isNil(
        find(state.allPromotions, (p) =>
          isEqual(promotionUuid, p.promotion_uuid)
        )
      );
      const allPromotions = isNewPromotion
        ? sortBy(concat(state.allPromotions, action.data), state.sortByField)
        : map(state.allPromotions, (p) => {
            if (isEqual(promotionUuid, p.promotion_uuid)) {
              return action.data;
            } else {
              return p;
            }
          });

      return {
        ...state,
        allPromotions
      };
    case PROMOTION_CHANNELS_LOADED: {
      return {
        ...state,
        allChannels: sortBy(action.data, (p) => p.channel_name)
      };
    }
    case PROMOTION_CHANNEL_SAVED: {
      return {
        ...state,
        allChannels: concat(state.allChannels, action.data)
      };
    }
    case PROMOTION_CHANNEL_DELETED:
      return {
        ...state,
        allChannels: filter(
          state.allChannels,
          (p) => !isEqual(action.channelUuid, p.channel_uuid)
        )
      };
    default:
      return state;
  }
};

export default reducer;
