import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import ModerationLandingPage from "./moderation/ModerationLandingPage";
import ModerationCommentsPage from "./moderation/ModerationCommentsPage";
import TaggingLandingPage from "./moderation/TaggingLandingPage";
import { MODERATION_MODULE_NAME } from "../constants/route-constants";
import GlobalHeader from "../components/GlobalHeader";
import { userSignedIn } from "../utils/route-utils";
import GlobalMessageToast from "../components/common/GlobalMessageToast";
import LoadingPage from "./home/LoadingPage";
import {
  isModerationViewable,
  isModerator,
  isTagger
} from "../utils/permission-utils";

class ModerationApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent, taggingPage = false) => {
    const { auth, userRoles } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    if (!isModerationViewable(userRoles)) {
      return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    }

    if (taggingPage && !isTagger(userRoles)) {
      return <ModerationLandingPage />;
    }

    if (!taggingPage && !isModerator(userRoles)) {
      return <TaggingLandingPage />;
    }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={MODERATION_MODULE_NAME} />
        <GlobalMessageToast />
        <Switch>
          <Route exact path={ROUTES.MODERATION_PARTNER_CASES}>
            {this.conditionallyRenderDefaultOrPage(
              <ModerationLandingPage activeTab="partnerCases" />
            )}
          </Route>
          <Route exact path={`${ROUTES.MODERATION_PARTNER_CASES}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(
              <ModerationLandingPage activeTab={"partnerCases"} />
            )}
          </Route>
          <Route exact path={ROUTES.MODERATION_COMMENTS}>
            {this.conditionallyRenderDefaultOrPage(<ModerationCommentsPage />)}
          </Route>
          <Route exact path={`${ROUTES.MODERATION_COMMENTS}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(<ModerationCommentsPage />)}
          </Route>
          <Route exact path={ROUTES.MODERATION_COMMENTS_HISTORY}>
            {this.conditionallyRenderDefaultOrPage(
              <ModerationCommentsPage activeState="commentsHistory" />
            )}
          </Route>
          <Route
            exact
            path={`${ROUTES.MODERATION_COMMENTS_HISTORY}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(
              <ModerationCommentsPage activeState="commentsHistory" />
            )}
          </Route>
          <Route exact path={ROUTES.TAGGING_LANDING}>
            {this.conditionallyRenderDefaultOrPage(
              <TaggingLandingPage />,
              true
            )}
          </Route>
          <Route exact path={`${ROUTES.TAGGING_LANDING}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(
              <TaggingLandingPage />,
              true
            )}
          </Route>
          <Route exact path={ROUTES.MODERATION_CASES}>
            {this.conditionallyRenderDefaultOrPage(<ModerationLandingPage />)}
          </Route>
          <Route exact path={`${ROUTES.MODERATION_CASES}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(<ModerationLandingPage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.user.userRoles
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(ModerationApp);
