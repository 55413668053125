import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { savePriorityFilters } from "../../actions/verification.actions";
import { find, get, isEqual, map } from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";

const VerificationPriorityModal = (props) => {
  const { isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.verification.priorityFilters);
  const saving = useSelector((state) => state.moderation.editSaving);
  const professions = useSelector((state) => state.reference.professions);
  const professionCategories = useSelector(
    (state) => state.reference.professionCategories
  );
  const countries = useSelector((state) => state.reference.countries);
  const selectedProfession = find(professions, (s) =>
    isEqual(s.typeUuid, formData.professionUuid)
  );
  const specialties = get(selectedProfession, "specialties", []);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  const onSubmit = async (event) => {
    event.preventDefault();

    toggle();

    await dispatch(savePriorityFilters(formData));
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    if (isEqual(name, "professionUuid")) {
      setFormData({ ...formData, [name]: value, specialtyUuid: "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const onChangeCountries = (values) => {
    setFormData({ ...formData, countryUuids: values });
  };

  const onChangeProfessionCategories = (values) => {
    setFormData({ ...formData, professionCategories: values });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Edit priority flag criteria
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}

          <Row>
            <FormGroup className="col-12">
              <Label className="text-11 text-uppercase font-weight-normal">
                profession categories
              </Label>
              <Typeahead
                id="professionalCategoryTypeahead"
                multiple={true}
                labelKey="name"
                onChange={onChangeProfessionCategories}
                options={professionCategories}
                placeholder="Select a professional category..."
                selected={formData.professionCategories || []}
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="professionUuid">
                profession category
              </Label>
              <Input
                type="select"
                name="professionCategory"
                id="professionCategory"
                value={formData.professionCategory || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(professionCategories, (c) => {
                  return (
                    <option key={`profession-cat-${c.value}`} value={c.value}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="professionUuid">
                profession
              </Label>
              <Input
                type="select"
                name="professionUuid"
                id="professionUuid"
                value={formData.professionUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(professions, (c) => {
                  return (
                    <option key={`profession-${c.typeUuid}`} value={c.typeUuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="specialtyUuid">
                specialty
              </Label>
              <Input
                type="select"
                name="specialtyUuid"
                id="specialtyUuid"
                value={formData.specialtyUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(specialties, (c) => {
                  return (
                    <option key={`specialty-${c.typeUuid}`} value={c.typeUuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col-12">
              <Label className="text-11 text-uppercase font-weight-normal">
                countries
              </Label>
              <Typeahead
                id="countryTypeahead"
                multiple={true}
                labelKey="name"
                onChange={onChangeCountries}
                options={countries}
                placeholder="Select a country..."
                selected={formData.countryUuids || []}
              />
            </FormGroup>
          </Row>

          <Row>
            <Label className="col-12 text-11 text-uppercase font-weight-normal mb-0">
              Request Date
            </Label>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateFrom">
                From
              </Label>
              <Input
                id="requestDateFrom"
                name="requestDateFrom"
                value={formData.requestDateFrom || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateTo">
                To
              </Label>
              <Input
                id="requestDateTo"
                name="requestDateTo"
                value={formData.requestDateTo || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                case count (contains at least)
              </Label>
              <Input
                id="approvedCaseCount"
                name="approvedCaseCount"
                value={formData.approvedCaseCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                comment count (contains at least)
              </Label>
              <Input
                id="approvedCommentCount"
                name="approvedCommentCount"
                value={formData.approvedCommentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                deleted case count (no more than)
                <br />
                <span className="font-weight-bold d-block">(BE Pending)</span>
              </Label>
              <Input
                id="deletedCaseCount"
                name="deletedCaseCount"
                value={formData.deletedCaseCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                deleted comment count (no more than)
              </Label>
              <Input
                id="deletedCommentCount"
                name="deletedCommentCount"
                value={formData.deletedCommentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                Date Last Uploaded Case{" "}
                <span className="font-weight-bold d-block">(BE Pending)</span>
              </Label>
              <Input
                id="dateLastUploaded"
                name="dateLastUploaded"
                value={formData.dateLastUploaded || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                Date Last Commented{" "}
                <span className="font-weight-bold">(BE Pending)</span>
              </Label>
              <Input
                id="dateLastCommented"
                name="dateLastCommented"
                value={formData.dateLastCommented || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button className="btn-md" color="secondary" onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            className="btn-md"
            color="primary-dark"
            type="submit"
            loading={saving}
            disabled={saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

VerificationPriorityModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

VerificationPriorityModal.defaultProps = {};

export default VerificationPriorityModal;
