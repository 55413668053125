import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import PropTypes from "prop-types";
import { get, isEmpty, isEqual, join, map } from "lodash";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const VerificationSubNavigation = (props) => {
  const { active, toggle, collection, userData } = props;
  const notes = get(userData, "verificationNotes", []);

  return (
    <Nav tabs className="verification-tabs d-flex">
      {map(collection, (value) => {
        const classNames = [];
        if (isEqual(active, value.tabName)) {
          classNames.push("active");
        }
        return (
          <NavItem key={value.tabName} className={join(classNames, " ")}>
            <NavLink
              href="#"
              className="text-center"
              onClick={() => {
                toggle(value.tabName);
                return false;
              }}>
              {value.displayName}{" "}
              {value.tabName === "notes" && !isEmpty(notes) && (
                <FontAwesomeIcon icon={faStar} className="suggested-color" />
              )}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

VerificationSubNavigation.propTypes = {
  toggle: PropTypes.func.isRequired,
  active: PropTypes.string,
  collection: PropTypes.object,
  userData: PropTypes.object
};

export default VerificationSubNavigation;
