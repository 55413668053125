import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row
} from "reactstrap";
import { CAMPAIGN_SECONDARY_NAVIGATION } from "../../constants/route-constants";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isEqual, map } from "lodash";
import "./components/style.scss";
import Loading from "../../components/Loading";
import { CAMPAIGN_PAGE_CONFIG } from "../../utils/campaign-utils";
import CaseLeftNavigation from "../moderation/components/CaseLeftNavigation";
import * as ROUTES from "../../constants/routes";
import {
  CAMPAIGN_COMMENTS_SUB_COLLECTIONS,
  MODERATION_COMMENTS_DATE_KEYS
} from "../../db/db-constants";
import EmptyContainer from "../../components/common/EmptyContainer";
import { CommentDetail } from "../moderation/components/CommentSections";
import CommentsPaginationRow from "../moderation/components/CommentsPaginationRow";
import {
  getComments,
  getCommentsByState,
  updateCommentsDateFilter
} from "../../actions/comments.actions";

const CampaignCommentQueue = (props) => {
  const {
    filterType = CAMPAIGN_COMMENTS_SUB_COLLECTIONS.QUEUED.statsKey
  } = useParams();

  const activeState = "comments";
  const dispatch = useDispatch();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const processing = useSelector((state) => state[activeState].processing);
  const comments = useSelector((state) => state[activeState].comments);
  const dateFilter = useSelector((state) => state[activeState].dateFilter);
  const stats = useSelector((state) => state[activeState].stats);
  const allAuthorMetadata = useSelector(
    (state) => state[activeState].authorMetadata
  );
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const activeFilter = filterType;

  useEffect(() => {
    dispatch(getCommentsByState(filterType, dateFilter, 0));
  }, [location, filterType, dateFilter, dispatch]);

  const changeDateFilter = (selectedFilter) => {
    dispatch(updateCommentsDateFilter(selectedFilter));

    toggle();
  };

  const renderFilters = () => {
    return (
      <Row>
        <Col>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              className="nav-link text-secondary"
              color="text-secondary"
              caret>
              {dateFilter.displayName}
            </DropdownToggle>
            <DropdownMenu>
              {map(MODERATION_COMMENTS_DATE_KEYS, (v, key) => {
                return (
                  <DropdownItem
                    key={`dropdown-item-${key}`}
                    onClick={() => changeDateFilter(v)}
                    active={isEqual(dateFilter, key)}>
                    {v.displayName}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col>
          <CommentsPaginationRow
            stateLocation={activeState}
            getPage={getComments}
          />
        </Col>
      </Row>
    );
  };

  const renderInnerBody = () => {
    return (
      <Row>
        <Col className="comment-col" fluid="lg1" style={{ marginBottom: 80 }}>
          {renderFilters()}

          {isEmpty(comments) && (
            <EmptyContainer
              title={`No Comments Found`}
              description={`There were no comments found with this filter within the ${dateFilter.displayName}.`}
            />
          )}
          {map(comments, (comment, index) => {
            return (
              <CommentDetail
                key={`comment-detail-${index}`}
                comment={comment}
                allAuthorMetadata={allAuthorMetadata}
              />
            );
          })}
        </Col>
      </Row>
    );
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0">
        <Col className="moderation-left-nav col-border-right py-4 px-0 min-vh-100">
          <CaseLeftNavigation
            active={activeFilter}
            baseUrl={ROUTES.CAMPAIGN_COMMENT_QUEUE}
            stats={stats}
            collection={CAMPAIGN_COMMENTS_SUB_COLLECTIONS}
          />
        </Col>
        <Col lx="10" lg="10" md="10" className="my-0">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="campaign-page">
      <SecondaryNavigation
        links={CAMPAIGN_SECONDARY_NAVIGATION}
        active={CAMPAIGN_PAGE_CONFIG.COMMENT_QUEUE.pageKey}
        centerAlign={false}
      />
      <Container className="bg-white mt-3 px-0">{renderBody()}</Container>
    </div>
  );
};

export default CampaignCommentQueue;
