import {
  ADMIN_USERS_LOAD,
  ADMIN_USERS_LOADED,
  SAVE_USER_ROLES_COMPLETE,
  SAVE_USER_ROLES_START
} from "../actions/users.actions";
import { orderBy, toLower, get, map, isEqual } from "lodash";

const INITIAL_STATE = {
  users: null,
  processing: false,
  saving: false,
  isError: false
};

function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_USER_ROLES_START: {
      return {
        ...state,
        saving: true,
        isError: false
      };
    }
    case SAVE_USER_ROLES_COMPLETE: {
      return {
        ...state,
        saving: false,
        isError: action.isError || false,
        users: map(state.users, (u) => {
          if (action.result && isEqual(u.uid, action.uid) && action.userRoles) {
            return { ...u, ...action.userRoles };
          }

          return u;
        })
      };
    }
    case ADMIN_USERS_LOAD: {
      return {
        ...state,
        processing: true,
        isError: false,
        users: []
      };
    }
    case ADMIN_USERS_LOADED: {
      return {
        ...state,
        processing: false,
        users: orderBy(action.users, [(u) => toLower(get(u, "name"))], ["asc"]),
        isError: action.isError
      };
    }
    default:
      return state;
  }
}

export default usersReducer;
