import {
  HIDE_GLOBAL_MESSAGE,
  SHOW_GLOBAL_MESSAGE
} from "../actions/global.actions";
import { get, isNil, isObject } from "lodash";

const INITIAL_STATE = {
  message: null,
  title: null,
  fixToTop: false
};

const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_MESSAGE: {
      let errorMessage = action.message;
      if (action.error && action.error.message) {
        const errorResponse = get(JSON.parse(action.error.message), "store");
        if (errorResponse) {
          errorMessage = isObject(errorResponse)
            ? JSON.stringify(errorResponse)
            : errorResponse;
        }
      }

      return {
        ...state,
        message: errorMessage,
        title: action.title,
        isError: isNil(action.isError) ? true : action.isError,
        error: action.error,
        fixToTop: action.fixToTop || false
      };
    }
    case HIDE_GLOBAL_MESSAGE: {
      return {
        ...state,
        message: null,
        title: null,
        error: null
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
