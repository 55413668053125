import React, { useState } from "react";
import { Alert, Form, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import LoadingButton from "../common/LoadingButton";

const SignUpForm = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: ""
  });

  const { processing, signUp, message, isError } = props;

  const onCreateAccount = async (event) => {
    event.preventDefault();

    await signUp(
      formData.email,
      formData.password,
      formData.firstName,
      formData.lastName
    );
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Form
      onSubmit={onCreateAccount}
      className="form-box shadow bg-white rounded">
      {message && (
        <Alert color={isError ? "danger" : "success"}>{message}</Alert>
      )}
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          name="email"
          value={formData.email}
          onChange={onChange}
          type="text"
          className="form-control"
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          id="password"
          name="password"
          value={formData.password}
          onChange={onChange}
          type="password"
          className="form-control"
        />
      </FormGroup>
      <FormGroup>
        <Label for="confirmPassword">Confirm Password</Label>
        <Input
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={onChange}
          type="password"
          className="form-control"
        />
      </FormGroup>
      <FormGroup>
        <Label for="firstName">First Name</Label>
        <Input
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={onChange}
          type="text"
          className="form-control"
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastName">Last Name</Label>
        <Input
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={onChange}
          type="text"
          className="form-control"
        />
      </FormGroup>
      <FormGroup>
        <LoadingButton
          color="primary"
          block={true}
          type="submit"
          loading={processing}
          disabled={
            !formData.email ||
            !formData.password ||
            !formData.firstName ||
            !formData.lastName ||
            formData.confirmPassword !== formData.password ||
            processing
          }>
          Create Account
        </LoadingButton>
      </FormGroup>
    </Form>
  );
};

SignUpForm.propTypes = {
  processing: PropTypes.bool,
  signUp: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  message: PropTypes.string
};

export default SignUpForm;
