import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner
} from "reactstrap";
import { isNil, map, size } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import {
  getImageUrl,
  startImageDownload,
  startMediaDownloadZip
} from "../../utils/media-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const CaseExplorerDownloadImagesModal = (props) => {
  const { media, isOpen, toggle } = props;
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    return () => {};
  }, [media]);

  const download = (image) => {
    startImageDownload(image, setDownloading);
  };

  const downloadZip = async () => {
    await startMediaDownloadZip(media, setDownloading);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={"download-image-modal"}
      size="lg">
      <ModalHeader toggle={toggle} className="border-0">
        Images
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between pb-3">
          <div className="text-12 text-uppercase text-secondary font-weight-bold ">
            Total Images: {size(media)}
          </div>
          {downloading && <Spinner size="sm" color="primary" />}
          {!downloading && (
            <Button
              className="text-12 p-0 font-weight-bold text-uppercase"
              color="link"
              onClick={downloadZip}>
              <FontAwesomeIcon icon={faDownload} size={"sm"} className="mr-1" />
              Download All
            </Button>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-start image-selection">
          {map(media, (m, index) => {
            const imageUrl = getImageUrl(m, 225, 225);
            if (isNil(imageUrl)) {
              return null;
            }

            return (
              <div
                key={`download-url-${index}`}
                className="pb-3 pr-3 image-container">
                <div
                  className="cursor-pointer btn-link"
                  onClick={(e) => {
                    e.preventDefault();
                    download(m);
                  }}>
                  <img src={imageUrl} className="img-thumbnail border" alt="" />
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-end">
        <Button color="primary" outline onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CaseExplorerDownloadImagesModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  media: PropTypes.array
};

CaseExplorerDownloadImagesModal.defaultProps = {
  media: []
};

export default CaseExplorerDownloadImagesModal;
