import React, { useEffect, useState } from "react";
import { Button, CustomInput, FormGroup, FormText, Label } from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import { clone, get, isEmpty, map, pullAt, set, size, find } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  deleteCampaignCase,
  saveCampaignQuizOrPollCase,
  saveCampaignStaticCase
} from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  changeIsiFields,
  DEFAULT_QUIZ_ANSWER_SHAPE,
  getCampaignPageConfigFromTacticState,
  getTacticFormData,
  getTacticState,
  isIsiActive,
  TACTIC_TYPES
} from "../../../utils/campaign-utils";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit
} from "../../../utils/form-utils";
import SimpleMDEEditor from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import { CampaignTacticSettings } from "./CampaignTacticSettings";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { CampaignCaseImageSection } from "./CampaignCaseImageSection";
import { CampaignIsiSettings } from "./CampaignIsiSettings";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { TacticActionButtons } from "./CampaignSections";

export const CampaignQuizOrPollForm = (props) => {
  const { caseData, activeSection } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [formData, setFormData] = useState({});
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const isQuiz = get(activeSection, "key") === TACTIC_TYPES.QUIZ.key;

  useEffect(() => {
    setFormData(getTacticFormData(campaignUuid, caseData, activeSection, user));
  }, [campaignUuid, caseData, activeSection, user]);

  const onSubmit = async () => {
    await dispatch(saveCampaignQuizOrPollCase(formData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
      <CampaignTacticSettings formData={formData} onChange={onChange} />
      <CampaignIsiSettings formData={formData} onChange={onChange} />

      <div className="mt-4 p-3 bg-white">
        <CampaignQuizOrPollInputs
          formData={formData}
          onChange={onChange}
          setFormData={setFormData}
          isQuiz={isQuiz}
          isiActive={isIsiActive(formData)}
        />

        <CampaignFormButtons saving={saving} caseData={caseData} />
      </div>
    </AvForm>
  );
};

CampaignQuizOrPollForm.propTypes = {
  activeSection: PropTypes.object,
  caseData: PropTypes.object
};

export const CampaignQuizOrPollInputs = (props) => {
  const {
    formData,
    onChange,
    setFormData,
    isQuiz = true,
    prefix = "",
    isiActive = false,
    includeIsiToggle = false,
    singleImage = false
  } = props;
  const questionOptions = getFieldValue(formData, "question_options", prefix);

  useEffect(() => {
    if (isEmpty(questionOptions)) {
      setFormData(
        clone(
          set(formData, getFieldName(`question_options`, prefix), [
            clone(DEFAULT_QUIZ_ANSWER_SHAPE)
          ])
        )
      );
    }
  }, [questionOptions, setFormData, formData, prefix]);

  const onChangeCheckbox = (event, name) => {
    const { checked } = event.target;
    onChange({ target: { name, value: checked } });
  };

  const onAddQuestionOption = () => {
    questionOptions.push(clone(DEFAULT_QUIZ_ANSWER_SHAPE));
    setFormData(clone(formData));
  };

  const onRemoveQuestionOption = (index) => {
    pullAt(questionOptions, [index]);
    setFormData(clone(formData));
  };

  const validate = (value, ctx) => {
    if (isEmpty(questionOptions)) {
      return "You need to provide at least 1 question";
    }

    const selectedAnswer = find(questionOptions, (q) => q.is_answer);
    if (!selectedAnswer) {
      return "You must select at least 1 correct answer";
    }

    return true;
  };

  return (
    <>
      <CampaignCaseMainElements
        formData={formData}
        onChange={onChange}
        titleLabel="Title/Question"
        prefix={prefix}
      />
      <CampaignCaseImageSection
        formData={formData}
        onChange={onChange}
        setFormData={setFormData}
        prefix={prefix}
        singleImage={singleImage}
      />
      <div>
        {isQuiz && (
          <AvField
            name={getFieldName("quiz-question", prefix)}
            value="empty"
            groupAttrs={{ className: "hidden-validation" }}
            type="hidden"
            validate={{ myValidation: validate }}
          />
        )}
        <div className="d-flex justify-content-between">
          <Label className="text-11 text-uppercase font-weight-normal">
            options
          </Label>
          {isQuiz && (
            <Label className="text-11 text-uppercase font-weight-normal">
              select correct answer(s)
            </Label>
          )}
        </div>

        {map(questionOptions, (o, index) => {
          const disableRemove = size(questionOptions) <= 1;

          return (
            <div
              key={`option-value-${index}`}
              className="d-flex justify-content-between align-middle ">
              <AvField
                name={getFieldName(`question_options[${index}].text`, prefix)}
                value={o.text || ""}
                groupAttrs={{ className: "add-remove-icons flex-fill" }}
                placeholder={`Option ${index + 1}`}
                type="text"
                onChange={onChange}
                helpMessage={getFieldValueCharacters(o.text || "", 80)}
                errorMessage="Enter a valid answer option"
                required
              />

              <div>
                <Button
                  onClick={() => onRemoveQuestionOption(index)}
                  disabled={disableRemove}
                  color="link"
                  className="border-right border-top border-bottom rounded-0">
                  <FontAwesomeIcon icon={faMinus} size={"sm"} />
                </Button>
                <Button
                  onClick={onAddQuestionOption}
                  color="link"
                  className="border-right border-top border-bottom rounded-0">
                  <FontAwesomeIcon icon={faPlus} size={"sm"} />
                </Button>
              </div>

              {isQuiz && (
                <CustomInput
                  id={getFieldName(
                    `question_options[${index}].is_answer`,
                    prefix
                  )}
                  type="checkbox"
                  checked={o.is_answer || false}
                  className="ml-4 pt-1"
                  onChange={(e) =>
                    onChangeCheckbox(
                      e,
                      getFieldName(
                        `question_options[${index}].is_answer`,
                        prefix
                      )
                    )
                  }
                />
              )}
            </div>
          );
        })}
      </div>
      <AvField
        name={getFieldName("question_answer_details", prefix)}
        value={getFieldValue(formData, "question_answer_details", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "question_answer_details", prefix),
          1400
        )}
        label="answer details (markdown supported)"
        placeholder="Details here..."
        type="textarea"
        rows="8"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required={isQuiz}
      />
      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={prefix}
        isiActive={isiActive}
        includeIsiToggle={includeIsiToggle}
      />
    </>
  );
};

CampaignQuizOrPollInputs.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  onChange: PropTypes.func,
  isQuiz: PropTypes.bool,
  isiActive: PropTypes.bool,
  includeIsiToggle: PropTypes.bool
};

export const CampaignContentForm = (props) => {
  const { caseData, activeSection, toggleAuthorModal } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid");

  useEffect(() => {
    setFormData(getTacticFormData(campaignUuid, caseData, activeSection, user));
  }, [campaignUuid, caseData, activeSection, user]);

  const onSubmit = async () => {
    await dispatch(saveCampaignStaticCase(formData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
      <CampaignTacticSettings
        formData={formData}
        onChange={onChange}
        toggleAuthorModal={toggleAuthorModal}
      />
      <CampaignIsiSettings formData={formData} onChange={onChange} />

      <div className="mt-4 p-3 bg-white">
        <CampaignCaseMainElements formData={formData} onChange={onChange} />
        <CampaignCaseImageSection
          formData={formData}
          onChange={onChange}
          setFormData={setFormData}
        />
        <CampaignFormCommonElements
          formData={formData}
          onChange={onChange}
          isiActive={isIsiActive(formData)}
        />
        <CampaignFormButtons saving={saving} caseData={caseData} />
      </div>
    </AvForm>
  );
};

CampaignContentForm.propTypes = {
  caseData: PropTypes.object
};

export const CampaignCaseMainElements = (props) => {
  const {
    formData,
    onChange,
    titleLabel = "POST TITLE (WHAT IS SHOWN IN-APP)",
    captionLabel = "CAPTION",
    prefix = ""
  } = props;

  const handleChange = (value) => {
    onChange({ target: { name: getFieldName("caption", prefix), value } });
  };

  return (
    <>
      <AvField
        name={getFieldName("title", prefix)}
        value={getFieldValue(formData, "title", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "title", prefix),
          55
        )}
        label={titleLabel}
        placeholder="Title here"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />

      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          {captionLabel}
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"]
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <AvField
        name={getFieldName("references", prefix)}
        value={getFieldValue(formData, "references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />
    </>
  );
};

CampaignCaseMainElements.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  titleLabel: PropTypes.string,
  captionLabel: PropTypes.string
};

export const CampaignFormCommonElements = (props) => {
  const {
    formData,
    onChange,
    prefix = "",
    isiActive = false,
    includeIsiToggle = false
  } = props;

  return (
    <>
      <AvField
        name={getFieldName("external_link.external_link_text", prefix)}
        value={getFieldValue(
          formData,
          "external_link.external_link_text",
          prefix
        )}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "external_link.external_link_text", prefix),
          54
        )}
        label="display text (external cta)"
        placeholder="Enter display text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />
      <AvField
        name={getFieldName("external_link.external_link_url", prefix)}
        value={getFieldValue(
          formData,
          "external_link.external_link_url",
          prefix
        )}
        label="external link"
        placeholder="Enter URL here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        errorMessage="A valid URL is required"
        validate={{ url: true }}
      />

      {isiActive && (
        <CampaignFormIsiElements
          onChange={onChange}
          formData={formData}
          prefix={prefix}
          includeIsiToggle={includeIsiToggle}
        />
      )}
    </>
  );
};

CampaignFormCommonElements.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  isiActive: PropTypes.bool,
  includeIsiToggle: PropTypes.bool
};

export const CampaignFormIsiElements = (props) => {
  const { formData, onChange, prefix = "", includeIsiToggle = false } = props;

  const renderToggleSwitch = (fieldName) => {
    const prefixedName = getFieldName(fieldName, prefix);
    const value = getFieldValue(formData, fieldName, prefix, false);

    return (
      <F1ToggleButton
        value={value}
        onClick={() => {
          onChange({
            target: {
              name: prefixedName,
              value: !value
            }
          });

          if (value) {
            changeIsiFields(onChange, prefix);
          }
        }}
      />
    );
  };

  const toggleActive =
    !includeIsiToggle ||
    getFieldValue(formData, "isi.isi_enabled", prefix, false);

  return (
    <>
      {includeIsiToggle && (
        <FormGroup className="w-25">
          <Label className="text-11 text-uppercase font-weight-normal">
            isi content
          </Label>
          {renderToggleSwitch("isi.isi_enabled")}
        </FormGroup>
      )}

      <AvField
        name={getFieldName("isi.isi_link", prefix)}
        value={getFieldValue(formData, "isi.isi_link", prefix)}
        label="ISI link to full information"
        placeholder="Enter ISI link url..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        disabled={!toggleActive}
        errorMessage="A valid URL is required"
        validate={{ url: true }}
        required={toggleActive}
      />
      <AvField
        name={getFieldName("isi.isi_text", prefix)}
        value={getFieldValue(formData, "isi.isi_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "isi.isi_text", prefix),
          59
        )}
        label="ISI Link Display"
        placeholder="Enter link display text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        disabled={!toggleActive}
        required={toggleActive}
      />
      <AvField
        name={getFieldName("isi.isi_embedded_content_link", prefix)}
        value={getFieldValue(formData, "isi.isi_embedded_content_link", prefix)}
        label="ISI embedded content URL"
        placeholder="Enter embedded content url here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        errorMessage="A valid URL is required"
        validate={{ url: true }}
        disabled={!toggleActive}
        required={toggleActive}
      />
    </>
  );
};

CampaignFormIsiElements.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  includeIsiToggle: PropTypes.bool
};

export const CampaignFormButtons = (props) => {
  const { saving = false, caseData = {} } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const deleting = useSelector((state) => state.campaigns.deleting);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const tacticState = getTacticState(caseData.caseState);

  const removeTactic = async () => {
    dispatch(deleteCampaignCase(caseData.caseUuid, campaignUuid, history));
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4 pt-4 border-top">
        <div>
          {caseData.caseUuid && (
            <>
              <Button
                size="lg"
                className="mr-3"
                color="danger"
                onClick={toggleDeleteModal}>
                Delete
              </Button>

              <TacticActionButtons
                showEdit={false}
                campaign={activeCampaign}
                tacticUuid={caseData.caseUuid}
                tacticState={tacticState}
                pageConfig={getCampaignPageConfigFromTacticState(tacticState)}
              />
            </>
          )}
        </div>
        <div className="d-inline-flex justify-content-end">
          <Button
            size="lg"
            color="secondary"
            onClick={() => {
              history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
            }}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Save
          </LoadingButton>
        </div>
      </div>
      <ConfirmModal
        toggle={toggleDeleteModal}
        isOpen={deleteModal}
        message="Are you sure you want to delete this tactic?"
        onConfirm={removeTactic}
        saving={deleting}
      />
    </>
  );
};

CampaignFormButtons.propTypes = {
  saving: PropTypes.bool,
  caseData: PropTypes.object
};
