import { isEmpty, isNil, omitBy, isString } from "lodash";
import { commentSearchCall } from "../api/cloud-functions";

const actionsPrefix = "commentsHistory";

export const GET_COMMENTS_START = `${actionsPrefix}/GET_COMMENTS_START`;
export const GET_COMMENTS_COMPLETE = `${actionsPrefix}/GET_COMMENTS_COMPLETE`;
export const UPDATE_DATE_FILTER = `${actionsPrefix}/UPDATE_DATE_FILTER`;
export const UPDATE_SEARCH_FILTER = `${actionsPrefix}/UPDATE_SEARCH_FILTER`;

export const getCommentsHistory = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_COMMENTS_START
    });

    const filters = omitBy(
      {
        authorUsername: getState().commentsHistory.searchFilter,
        dateFilter: getState().commentsHistory.dateFilter.getDateFilter()
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const sortDirection = getState().commentsHistory.sortDirection;

    try {
      const results = await commentSearchCall(filters, page, sortDirection);

      dispatch({
        type: GET_COMMENTS_COMPLETE,
        results,
        page
      });
    } catch (error) {
      console.log("Error retrieving comments", error);
      dispatch({
        type: GET_COMMENTS_COMPLETE,
        error: error
      });
    }
  };
};

export const updateCommentsHistorySearchFilter = (searchFilter) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SEARCH_FILTER,
      searchFilter
    });
  };
};

export const updateCommentsHistoryDateFilter = (dateFilter) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_DATE_FILTER,
      dateFilter
    });
  };
};
