import {
  ADD_NOTE_COMPLETE,
  ADD_NOTE_START,
  APPROVE_COMMENT_COMPLETE,
  APPROVE_COMMENT_START,
  APPROVE_COMPLETE,
  APPROVE_IMMEDIATE_COMPLETE,
  APPROVE_IMMEDIATE_START,
  APPROVE_START,
  CASE_LOCKS_LISTENER,
  CASE_LOCKS_UPDATED,
  EDIT_COMPLETE,
  EDIT_START,
  FLAG_COMMENT_COMPLETE,
  FLAG_COMMENT_START,
  FLAG_COMPLETE,
  FLAG_START,
  PARTNER_STATE_CHANGE_COMPLETE,
  PARTNER_STATE_CHANGE_START,
  REJECT_COMMENT_COMPLETE,
  REJECT_COMMENT_START,
  REJECT_COMPLETE,
  REJECT_START,
  REPORT_COMPLETE,
  REPORT_START,
  REVIEW_EDIT_COMPLETE,
  REVIEW_EDIT_START,
  SUBMIT_SUGGESTION_COMPLETE,
  SUBMIT_SUGGESTION_START,
  TINEYE_COMPLETE,
  TINEYE_START
} from "../actions/moderation.actions";

const INITIAL_STATE = {
  addNoteSaving: false,
  flaggedSaving: false,
  reportedSaving: false,
  approvedSaving: false,
  approveImmediateSaving: false,
  rejectedSaving: false,
  partnerCaseSaving: false,
  suggestSaving: false,
  reviewEditSaving: false,
  tineyeProcessing: false,
  tineyeResults: [],
  tineyeError: false,
  editSaving: false,
  commentApprovedSaving: false,
  commentFlaggedSaving: false,
  commentRejectedSaving: false,
  lockListener: null,
  allLocks: null
};

const moderationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTE_START: {
      return {
        ...state,
        addNoteSaving: true
      };
    }
    case ADD_NOTE_COMPLETE: {
      return {
        ...state,
        addNoteSaving: false
      };
    }
    case APPROVE_START: {
      return {
        ...state,
        approvedSaving: true
      };
    }
    case APPROVE_COMPLETE: {
      return {
        ...state,
        approvedSaving: false
      };
    }
    case APPROVE_IMMEDIATE_START: {
      return {
        ...state,
        approveImmediateSaving: true
      };
    }
    case APPROVE_IMMEDIATE_COMPLETE: {
      return {
        ...state,
        approveImmediateSaving: false
      };
    }
    case FLAG_START: {
      return {
        ...state,
        flaggedSaving: true
      };
    }
    case FLAG_COMPLETE: {
      return {
        ...state,
        flaggedSaving: false
      };
    }
    case REPORT_START: {
      return {
        ...state,
        reportedSaving: true
      };
    }
    case REPORT_COMPLETE: {
      return {
        ...state,
        reportedSaving: false
      };
    }
    case SUBMIT_SUGGESTION_START: {
      return {
        ...state,
        suggestSaving: true
      };
    }
    case SUBMIT_SUGGESTION_COMPLETE: {
      return {
        ...state,
        suggestSaving: false
      };
    }
    case REJECT_START: {
      return {
        ...state,
        rejectedSaving: true
      };
    }
    case REJECT_COMPLETE: {
      return {
        ...state,
        rejectedSaving: false
      };
    }
    case REVIEW_EDIT_START: {
      return {
        ...state,
        reviewEditSaving: true
      };
    }
    case REVIEW_EDIT_COMPLETE: {
      return {
        ...state,
        reviewEditSaving: false
      };
    }
    case TINEYE_START: {
      return {
        ...state,
        tineyeProcessing: true,
        tineyeResults: [],
        tineyeError: false
      };
    }
    case TINEYE_COMPLETE: {
      return {
        ...state,
        tineyeProcessing: false,
        tineyeResults: action.results,
        tineyeError: action.error || false
      };
    }
    case EDIT_START: {
      return {
        ...state,
        editSaving: true
      };
    }
    case EDIT_COMPLETE: {
      return {
        ...state,
        editSaving: false
      };
    }
    case APPROVE_COMMENT_START: {
      return {
        ...state,
        commentApprovedSaving: true
      };
    }
    case APPROVE_COMMENT_COMPLETE: {
      return {
        ...state,
        commentApprovedSaving: false
      };
    }
    case FLAG_COMMENT_START: {
      return {
        ...state,
        commentFlaggedSaving: true
      };
    }
    case FLAG_COMMENT_COMPLETE: {
      return {
        ...state,
        commentFlaggedSaving: false
      };
    }
    case REJECT_COMMENT_START: {
      return {
        ...state,
        commentRejectedSaving: true
      };
    }
    case REJECT_COMMENT_COMPLETE: {
      return {
        ...state,
        commentRejectedSaving: false
      };
    }
    case PARTNER_STATE_CHANGE_START: {
      return {
        ...state,
        partnerCaseSaving: true
      };
    }
    case PARTNER_STATE_CHANGE_COMPLETE: {
      return {
        ...state,
        partnerCaseSaving: false
      };
    }
    case CASE_LOCKS_LISTENER: {
      return {
        ...state,
        lockListener: action.lockListener
      };
    }
    case CASE_LOCKS_UPDATED: {
      return {
        ...state,
        allLocks: action.allLocks
      };
    }
    default:
      return state;
  }
};

export default moderationReducer;
