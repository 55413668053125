import React from "react";
import { FormGroup, Input, Label, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getVerificationUsers,
  resetBulkUsers,
  setAllBulkUsers
} from "../../../actions/verification.actions";
import { formatNumber } from "../../../utils/general-utils";
import { difference, get, isEmpty, map } from "lodash";

export const VerificationPagination = (props) => {
  const { bulkEditActive } = props;
  const dispatch = useDispatch();
  const verificationType = useSelector(
    (state) => state.verification.activeVerificationType
  );
  const verificationStatus = useSelector(
    (state) => state.verification.activeVerificationStatus
  );
  const currentPage = useSelector((state) => state.verification.currentPage);
  const totalUsers = useSelector((state) => state.verification.totalUsers);
  const fromPage = useSelector((state) => state.verification.fromPage);
  const toPage = useSelector((state) => state.verification.toPage);
  const hasPrevious = useSelector((state) => state.verification.hasPrevious);
  const hasNext = useSelector((state) => state.verification.hasNext);
  const allUsers = useSelector((state) => state.verification.users);
  const userUids = useSelector((state) => state.verification.bulkUsers);
  const allUserUids = map(allUsers, (u) => get(u, "userUid"));
  const allSelected = isEmpty(difference(allUserUids, userUids));

  const onPreviousPage = () => {
    dispatch(
      getVerificationUsers(
        verificationType,
        verificationStatus,
        currentPage - 1
      )
    );
    return false;
  };

  const onNextPage = () => {
    dispatch(
      getVerificationUsers(
        verificationType,
        verificationStatus,
        currentPage + 1
      )
    );
    return false;
  };

  const onSelectAll = () => {
    if (allSelected) {
      dispatch(resetBulkUsers());
    } else {
      dispatch(setAllBulkUsers(allUserUids));
    }
  };

  const disableClick = () => {
    return false;
  };

  return (
    <div className="d-flex text-secondary px-2 py-2 align-items-center justify-content-between">
      <div>
        {bulkEditActive && (
          <FormGroup check className="align-self-center ml-1">
            <Label check className="text-secondary">
              <Input
                type="checkbox"
                checked={allSelected}
                onClick={onSelectAll}
                onChange={() => {}}
              />{" "}
              Select page
            </Label>
          </FormGroup>
        )}
      </div>
      <div className="d-flex">
        <div className="text-12 pagination-text">
          {fromPage}-{toPage} of {formatNumber(totalUsers)}
        </div>
        <div>
          <div
            className={`pagination-link ml-3 ${!hasPrevious && "disabled"}`}
            onClick={hasPrevious ? onPreviousPage : disableClick}>
            &#8249;
          </div>
          <div
            className={`pagination-link ml-4 mr-1 ${!hasNext && "disabled"}`}
            onClick={hasNext ? onNextPage : disableClick}>
            &#8250;
          </div>
        </div>
      </div>
    </div>
  );
};

VerificationPagination.propTypes = {};

export const VerificationRecordPagination = (props) => {
  const onPreviousRecord = () => {
    return false;
  };

  const onNextRecord = () => {
    return false;
  };

  if (true) {
    return null;
  }

  return (
    <Row className="bg-primary py-2 px-3 d-flex justify-content-between">
      <div className="text-white" onClick={onPreviousRecord}>
        <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
        Previous Record
      </div>
      <div className="text-white" onClick={onNextRecord}>
        Next Record (Ctrl-N)
        <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
      </div>
    </Row>
  );
};

VerificationPagination.propTypes = {};
