import React, { useEffect, useRef, useState } from "react";
import { Button, Collapse, FormGroup, FormText, Label } from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from "availity-reactstrap-validation";
import {
  clone,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isEqual,
  map,
  pullAt,
  set,
  size
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  deleteCampaignCase,
  saveCampaignSeriesCase
} from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  getCampaignPageConfigFromTacticState,
  getTacticSeriesFormData,
  getTacticState,
  handleSwitchChange,
  ISI_FEEDCARD_TYPES,
  isIsiActive,
  isJobCodeActive,
  POLL_SERIES_SLIDE_TYPES,
  QUIZ_SERIES_FEEDCARD_TYPES,
  QUIZ_SERIES_SLIDE_TYPES,
  TACTIC_TYPES
} from "../../../utils/campaign-utils";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit
} from "../../../utils/form-utils";
import "easymde/dist/easymde.min.css";
import { CampaignTacticSettings } from "./CampaignTacticSettings";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import RowToggler from "../../../components/common/RowToggler";
import SimpleMDEEditor from "react-simplemde-editor";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import {
  CampaignFormCommonElements,
  CampaignQuizOrPollInputs
} from "./CampaignForms";
import { arrayMove } from "../../../utils/general-utils";
import F1ToggleButton from "../../../components/common/F1ToggleButton";
import { CampaignCaseSingleImageSection } from "./CampaignCaseSingleImageSection";
import { CampaignCaseImageSection } from "./CampaignCaseImageSection";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  TacticActionButtons,
  TacticExpandCollapseAll
} from "./CampaignSections";

const EMPTY_SLIDE = {
  slideType: ""
};

export const CampaignQuizOrPollSeriesForm = (props) => {
  const { caseData, activeSection } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [formData, setFormData] = useState({});

  const campaignUuid = get(activeCampaign, "campaignUuid");
  const slides = get(formData, "questions", []);
  const slidesBottom = useRef(null);
  const slideTypes = get(activeSection, "slideTypes");

  const [reorderActive, setReorderActive] = useState(false);
  const toggleReorder = () => setReorderActive(!reorderActive);

  useEffect(() => {
    setFormData(
      getTacticSeriesFormData(campaignUuid, caseData, activeSection, user)
    );
  }, [campaignUuid, caseData, activeSection, user]);

  const onSubmit = async () => {
    const submitData = {
      ...formData,
      questions: filter(
        formData.questions,
        (q) =>
          !includes(
            [
              QUIZ_SERIES_SLIDE_TYPES.FEEDCARD,
              QUIZ_SERIES_SLIDE_TYPES.QUIZ_CONCLUSION,
              POLL_SERIES_SLIDE_TYPES.POLL_CONCLUSION
            ],
            q.slideType
          )
      )
    };

    console.log(submitData);

    await dispatch(saveCampaignSeriesCase(submitData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const addSlide = () => {
    slides.push(clone(EMPTY_SLIDE));

    setFormData(clone(formData));

    slidesBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    setFormData(
      clone(set(formData, "questions", arrayMove(slides, oldIndex, newIndex)))
    );
  };

  const renderSlide = (slide, index) => {
    return (
      <CampaignSlideContainer
        key={index}
        slideIndex={index}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  };

  const SortableSlide = SortableElement(({ position, slide }) => {
    return (
      <CampaignSlideContainer
        key={position}
        slideIndex={position}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  });

  const SortableSlidesContainer = SortableContainer(({ items }) => {
    return (
      <div>
        {map(items, (slide, index) => {
          return (
            <SortableSlide
              key={`slide-${index}`}
              index={index}
              position={index}
              slide={slide}
            />
          );
        })}
      </div>
    );
  });

  const renderSlides = () => {
    if (reorderActive) {
      return (
        <SortableSlidesContainer
          items={slides}
          onSortEnd={onSortEnd}
          axis="xy"
          useDragHandle
        />
      );
    }

    return map(slides, (slide, index) => renderSlide(slide, index));
  };

  const validate = (value, ctx) => {
    if (isEmpty(slides)) {
      return "You must include at least 1 slide";
    }

    const conclusionSlide = find(slides, (q) =>
      includes(
        [
          POLL_SERIES_SLIDE_TYPES.POLL_CONCLUSION,
          QUIZ_SERIES_SLIDE_TYPES.QUIZ_CONCLUSION
        ],
        q.slideType
      )
    );
    if (!conclusionSlide) {
      return "You must include a conclusion slide";
    }

    return true;
  };

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}>
      <TacticExpandCollapseAll />

      <CampaignTacticSettings
        formData={formData}
        onChange={onChange}
        includeSeriesField={true}
        seriesFieldLabel={
          isEqual(activeSection.key, TACTIC_TYPES.POLL_SERIES.key)
            ? "Poll multi banner"
            : "Quiz multi banner"
        }
      />

      {renderSlides()}

      <div className="mt-4">
        <AvField
          name="conclusion-slide"
          value="empty"
          groupAttrs={{ className: "hidden-validation" }}
          type="hidden"
          validate={{ myValidation: validate }}
        />

        <div className="add-slide mt-0" onClick={addSlide}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          ADD SLIDE
        </div>
      </div>

      {size(slides) > 1 && (
        <div className="d-flex justify-content-end">
          <Button color="link" onClick={toggleReorder}>
            {reorderActive
              ? "Disable Slide Reordering"
              : "Enable Slide Reordering"}
          </Button>
        </div>
      )}

      <CampaignFormButtons saving={saving} caseData={caseData} />
      <div ref={slidesBottom} />
    </AvForm>
  );
};

CampaignQuizOrPollSeriesForm.propTypes = {
  activeSection: PropTypes.object,
  caseData: PropTypes.object
};

const CampaignFormButtons = (props) => {
  const { saving = false, caseData } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const deleting = useSelector((state) => state.campaigns.deleting);
  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDeleteModal = () => setDeleteModal(!deleteModal);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const caseUuid = get(caseData, "caseUuid");
  const caseState = get(caseData, "caseState");
  const tacticState = getTacticState(caseState);

  const removeTactic = async () => {
    dispatch(deleteCampaignCase(caseUuid, campaignUuid, history));
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4 p-3 ">
        <div>
          {caseUuid && (
            <>
              <Button
                color="red"
                className="mr-3"
                size="lg"
                onClick={toggleDeleteModal}>
                Delete Tactic
              </Button>
              <TacticActionButtons
                showEdit={false}
                campaign={activeCampaign}
                tacticUuid={caseUuid}
                tacticState={tacticState}
                pageConfig={getCampaignPageConfigFromTacticState(tacticState)}
              />
            </>
          )}
        </div>
        <div className="d-inline-flex justify-content-end">
          <Button
            size="lg"
            color="secondary"
            onClick={() => {
              history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
            }}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Save
          </LoadingButton>
        </div>
      </div>
      <ConfirmModal
        toggle={toggleDeleteModal}
        isOpen={deleteModal}
        message="Are you sure you want to delete this tactic?"
        onConfirm={removeTactic}
        saving={deleting}
      />
    </>
  );
};

CampaignFormButtons.propTypes = {
  saving: PropTypes.bool,
  caseData: PropTypes.object
};

const CampaignSlideContainer = (props) => {
  const { formData, setFormData, slideIndex, slideTypes, reorderActive } =
    props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);
  const [removeSlideModal, setRemoveSlideModal] = useState(false);
  const toggleRemoveSlideModal = () => setRemoveSlideModal(!removeSlideModal);
  const prefix = `questions[${slideIndex}]`;
  const slideType = get(formData, `${prefix}.slideType`);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const removeSlide = () => {
    const slides = getFieldValue(formData, "questions");
    pullAt(slides, [slideIndex]);
    setFormData(clone(formData));
  };

  const renderSlideTypeData = () => {
    switch (slideType) {
      case QUIZ_SERIES_SLIDE_TYPES.FEEDCARD:
        return <SlideQuizFeedcard formData={formData} onChange={onChange} />;
      case POLL_SERIES_SLIDE_TYPES.FEEDCARD:
        return <SlidePollFeedcard formData={formData} onChange={onChange} />;
      case QUIZ_SERIES_SLIDE_TYPES.QUIZ:
        return (
          <SlideQuiz
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case POLL_SERIES_SLIDE_TYPES.POLL:
        return (
          <SlidePoll
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case QUIZ_SERIES_SLIDE_TYPES.QUIZ_CONCLUSION:
        return (
          <SlideQuizOrPollConclusion
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
          />
        );
      case POLL_SERIES_SLIDE_TYPES.POLL_CONCLUSION:
        return (
          <SlideQuizOrPollConclusion
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            isPoll={true}
          />
        );
      default:
        return null;
    }
  };

  const renderSlideName = () => {
    if (isEmpty(slideType)) {
      return `Slide ${slideIndex + 1}`;
    }

    return `Slide ${slideIndex + 1}: ${slideType}`;
  };

  const DragHandle = SortableHandle(() => (
    <span className="drag-handle mr-2 text-secondary cursor-grab">
      <FontAwesomeIcon icon={faArrowsAlt} />
    </span>
  ));

  return (
    <div className="bg-white p-3 mt-4">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">
          {reorderActive && <DragHandle />}
          {renderSlideName()}
        </div>
        <RowToggler
          toggle={toggleCollapse}
          collapse={collapse}
          large={true}
          disabled={reorderActive}
        />
      </div>
      {!reorderActive && (
        <Collapse isOpen={collapse} className="pt-2 slide-body">
          <AvField
            type="select"
            name={getFieldName("slideType", prefix)}
            labelClass="text-11 text-uppercase font-weight-normal"
            label="slide type"
            value={slideType || ""}
            onChange={onChange}>
            <option value="">Select...</option>
            {map(slideTypes, (v, key) => {
              return (
                <option key={`slide-type-${key}`} value={v}>
                  {v}
                </option>
              );
            })}
          </AvField>

          {renderSlideTypeData()}

          <div className="d-flex justify-content-start pt-3">
            <Button size="md" color="red" onClick={toggleRemoveSlideModal}>
              Delete Slide
            </Button>
          </div>
        </Collapse>
      )}

      <ConfirmModal
        toggle={toggleRemoveSlideModal}
        isOpen={removeSlideModal}
        message="Are you sure you want to delete slide?"
        onConfirm={removeSlide}
      />
    </div>
  );
};

CampaignSlideContainer.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  slideIndex: PropTypes.number,
  slideTypes: PropTypes.object,
  reorderActive: PropTypes.bool
};

const SlideQuizFeedcard = (props) => {
  const { formData, onChange, prefix } = props;
  const feedcards = isIsiActive(formData)
    ? ISI_FEEDCARD_TYPES
    : QUIZ_SERIES_FEEDCARD_TYPES;

  return (
    <SlideFeedcard
      formData={formData}
      onChange={onChange}
      prefix={prefix}
      feedcardTypes={feedcards}
      typeLabel={"quiz feedcard type"}
    />
  );
};

const SlidePollFeedcard = (props) => {
  const { formData, onChange, prefix } = props;
  const feedcards = isIsiActive(formData)
    ? ISI_FEEDCARD_TYPES
    : QUIZ_SERIES_FEEDCARD_TYPES;

  return (
    <SlideFeedcard
      formData={formData}
      onChange={onChange}
      prefix={prefix}
      feedcardTypes={feedcards}
      typeLabel={"poll feedcard type"}
    />
  );
};

const SlideFeedcard = (props) => {
  const { formData, onChange, prefix, feedcardTypes, typeLabel = "" } = props;

  const feedCardTypeField = getFieldName("feed_card.feed_card_type");
  const buttonTextField = getFieldName("feed_card.button_text");
  const feedcardType = getFieldValue(formData, "feed_card.feed_card_type_key");

  const onChangeFeedcardType = (event) => {
    const { value } = event.target;
    const type = QUIZ_SERIES_FEEDCARD_TYPES[value];
    if (type) {
      onChange({ target: { name: feedCardTypeField, value: type.value } });
      onChange({ target: { name: buttonTextField, value: "" } });
    }

    onChange(event);
  };

  const renderFeedcardTypeData = () => {
    switch (feedcardType) {
      case QUIZ_SERIES_FEEDCARD_TYPES.GENERAL.key:
        return (
          <HighlightFeedCard
            formData={formData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case QUIZ_SERIES_FEEDCARD_TYPES.CTA_BUTTON.key:
        return (
          <CTAButtonFeedcard
            formData={formData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case QUIZ_SERIES_FEEDCARD_TYPES.REGULAR.key:
      default:
        return <ExpandCollapseFeedcard />;
    }
  };

  return (
    <>
      <AvField
        type="select"
        name={getFieldName("feed_card.feed_card_type_key")}
        labelClass="text-11 text-uppercase font-weight-normal"
        label={typeLabel}
        value={feedcardType}
        onChange={onChangeFeedcardType}>
        <option value="">Select...</option>
        {map(feedcardTypes, (v, key) => {
          return (
            <option key={`feedcard-type-${key}`} value={key}>
              {v.displayName}
            </option>
          );
        })}
      </AvField>

      {renderFeedcardTypeData()}
    </>
  );
};

const HighlightFeedCard = (props) => {
  const { formData, onChange } = props;

  const renderToggleSwitch = (fieldName) => {
    const value = getFieldValue(formData, fieldName);

    return (
      <F1ToggleButton
        value={value}
        onClick={() => handleSwitchChange(onChange, fieldName, !value)}
      />
    );
  };

  return (
    <>
      <FormGroup className="w-25">
        <Label className="text-11 text-uppercase font-weight-normal">
          job code
        </Label>
        {renderToggleSwitch("features.job_code_enabled")}
      </FormGroup>
      <AvField
        name={getFieldName("sponsored_content.job_code")}
        value={getFieldValue(formData, "sponsored_content.job_code")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "sponsored_content.job_code"),
          40
        )}
        label="Job code"
        placeholder="Enter code here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        disabled={!isJobCodeActive(formData)}
        onChange={onChange}
      />
      <AvField
        name={getFieldName("feed_card.feed_card_label")}
        value={getFieldValue(formData, "feed_card.feed_card_label")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card.feed_card_label"),
          23
        )}
        label="Content label"
        placeholder="Enter label here…"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("feed_card.feed_card_title")}
        value={getFieldValue(formData, "feed_card.feed_card_title")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card.feed_card_title"),
          84
        )}
        label="banner title"
        placeholder="Enter banner here…"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <CampaignCaseSingleImageSection
        formData={formData}
        onChange={onChange}
        fieldName={getFieldName("feed_card.feed_card_media")}
      />
      <AvField
        name={getFieldName("feed_card.colour")}
        value={getFieldValue(formData, "feed_card.colour")}
        label="banner colour code"
        placeholder="Hex value here…"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("feed_card.button_text")}
        value={getFieldValue(formData, "feed_card.button_text")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card.button_text"),
          21
        )}
        label="CTA text"
        placeholder="Enter cta text here…"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
    </>
  );
};

const CTAButtonFeedcard = (props) => {
  const { formData, onChange } = props;

  return (
    <>
      <AvField
        name={getFieldName("feed_card.button_text")}
        value={getFieldValue(formData, "feed_card.button_text")}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card.button_text"),
          25
        )}
        label="CTA text"
        placeholder="Enter cta text here…"
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
    </>
  );
};

const ExpandCollapseFeedcard = (props) => {
  return <></>;
};

const SlideQuiz = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={true}
        prefix={prefix}
        isiActive={isIsiActive(formData)}
        includeIsiToggle={true}
        singleImage={true}
      />
    </>
  );
};

const SlidePoll = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={false}
        prefix={prefix}
        isiActive={isIsiActive(formData)}
        includeIsiToggle={true}
        singleImage={true}
      />
    </>
  );
};

const SlideQuizOrPollConclusion = (props) => {
  const { formData, setFormData, onChange, prefix, isPoll } = props;

  const handleChange = (value) => {
    onChange({
      target: { name: getFieldName("conclusion.caption", prefix), value }
    });
  };

  return (
    <>
      {isPoll && (
        <CampaignCaseImageSection
          formData={formData}
          onChange={onChange}
          setFormData={setFormData}
          prefix={getFieldName("conclusion", prefix)}
          singleImage={true}
        />
      )}

      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "conclusion.caption", prefix)}
          options={{
            hideIcons: ["image"]
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("conclusion.caption", prefix)}
          value={getFieldValue(formData, "conclusion.caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <AvField
        name={getFieldName("conclusion.references", prefix)}
        value={getFieldValue(formData, "conclusion.references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />

      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={getFieldName("conclusion", prefix)}
        isiActive={isIsiActive(formData)}
        includeIsiToggle={true}
      />
    </>
  );
};
