import {
  CAMPAIGN_ACTIVE,
  CAMPAIGN_ARCHIVED,
  CAMPAIGN_COMMENT_QUEUE,
  CAMPAIGN_DRAFTS,
  CAMPAIGN_PROFILE,
  CAMPAIGN_REVIEW,
  MODERATION_CASES,
  TAGGING_LANDING
} from "./routes";
import { CAMPAIGN_PAGE_CONFIG } from "../utils/campaign-utils";
import { isModerator, isTagger } from "../utils/permission-utils";

export const HOME_MODULE_NAME = "home";
export const MODERATION_MODULE_NAME = "moderation";
export const VERIFICATION_MODULE_NAME = "verification";
export const CAMPAIGN_MODULE_NAME = "campaign";
export const CASE_EXPLORER_MODULE_NAME = "case_explorer";
export const TAGGING_MODULE_NAME = "tagging";
export const ADMIN_MODULE_NAME = "admin";
export const USER_SEARCH_MODULE_NAME = "user_search";

export const MODERATION_SECONDARY_NAVIGATION = [
  {
    to: MODERATION_CASES,
    displayName: "Moderation",
    activeName: MODERATION_MODULE_NAME,
    hasAccess: (roles) => isModerator(roles)
  },
  {
    to: TAGGING_LANDING,
    displayName: "Tagging",
    activeName: TAGGING_MODULE_NAME,
    hasAccess: (roles) => isTagger(roles)
  }
];

export const CAMPAIGN_SECONDARY_NAVIGATION = [
  {
    to: CAMPAIGN_DRAFTS,
    displayName: "Drafts",
    activeName: CAMPAIGN_PAGE_CONFIG.DRAFT.pageKey,
    hasAccess: (roles) => true
  },
  {
    to: CAMPAIGN_REVIEW,
    displayName: "Review",
    activeName: CAMPAIGN_PAGE_CONFIG.REVIEW.pageKey,
    hasAccess: (roles) => true
  },
  {
    to: CAMPAIGN_ACTIVE,
    displayName: "Active",
    activeName: CAMPAIGN_PAGE_CONFIG.ACTIVE.pageKey,
    hasAccess: (roles) => true
  },
  {
    to: CAMPAIGN_ARCHIVED,
    displayName: "Archived",
    activeName: CAMPAIGN_PAGE_CONFIG.ARCHIVED.pageKey,
    hasAccess: (roles) => true
  },
  {
    to: CAMPAIGN_PROFILE,
    displayName: "Profile",
    activeName: CAMPAIGN_PAGE_CONFIG.PROFILE.pageKey,
    hasAccess: (roles) => true
  },
  {
    to: CAMPAIGN_COMMENT_QUEUE,
    displayName: "Comment Queue",
    activeName: CAMPAIGN_PAGE_CONFIG.COMMENT_QUEUE.pageKey,
    hasAccess: (roles) => true
  }
];
