import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem
} from "reactstrap";
import PropTypes from "prop-types";
import { get, isEqual, join, map } from "lodash";
import { Link } from "react-router-dom";
import "./style.scss";
import "../../../components/navigation/style.scss";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/general-utils";

const CaseLeftNavigation = (props) => {
  const {
    active,
    baseUrl,
    stats,
    collection,
    platform,
    isPartner = false
  } = props;
  const userRoles = useSelector((state) => state.user.userRoles);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleCaseDropdown = () => setDropdownOpen((prevState) => !prevState);

  const desktop = (
    <Nav className="global-left-nav" vertical>
      {map(collection, (value) => {
        if (value.isViewable && !value.isViewable(userRoles)) {
          return null;
        }

        if (value.partnerOnly && !isPartner) {
          return null;
        }

        const count = get(stats, value.statsKey, 0);
        const classNames = ["nav-link"];
        if (isEqual(active, value.statsKey)) {
          classNames.push("active");
        }
        if (count > 0 && value.activeAuxClassName) {
          classNames.push(value.activeAuxClassName);
        }
        return (
          <NavItem key={value.statsKey}>
            <Link
              className={join(classNames, " ")}
              to={`${baseUrl}/${value.statsKey}`}>
              {value.displayName} ({formatNumber(count)})
            </Link>
          </NavItem>
        );
      })}
    </Nav>
  );

  const mobile = (
    <Dropdown
      className="global-left-nav d-sm-block d-md-none mobile w-50"
      isOpen={dropdownOpen}
      toggle={toggleCaseDropdown}>
      <DropdownToggle caret className="w-100 rounded-0 ml-1">
        Select Case
      </DropdownToggle>
      <DropdownMenu>
        {map(collection, (value) => {
          if (value.isViewable && !value.isViewable(userRoles)) {
            return null;
          }

          const count = get(stats, value.statsKey, 0);
          const classNames = ["nav-link"];
          if (isEqual(active, value.statsKey)) {
            classNames.push("active");
          }
          if (count > 0 && value.activeAuxClassName) {
            classNames.push(value.activeAuxClassName);
          }
          return (
            <DropdownItem key={value.statsKey}>
              <Link
                className={join(classNames, " ")}
                to={`${baseUrl}/${value.statsKey}`}>
                {value.displayName} ({formatNumber(count)})
              </Link>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );

  return platform === "mobile" ? mobile : desktop;
};

CaseLeftNavigation.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  active: PropTypes.string,
  collection: PropTypes.object,
  stats: PropTypes.object
};

export default CaseLeftNavigation;
