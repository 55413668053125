import { get, reduce, compact, map } from "lodash";
import {
  ADMIN_ROOT,
  CAMPAIGN_ROOT,
  CASE_EXPLORER_ROOT,
  MODERATION_ROOT,
  TOOLS_ROOT,
  VERIFICATION_ROOT
} from "../constants/routes";

// https://figure1.atlassian.net/browse/F1PADMIN-351

export const ROLE_ATTRIBUTES = Object.freeze({
  ADMIN: { key: "isAdmin", displayName: "Administrator", color: "primary" },
  MODERATOR_MANAGER: {
    key: "isModeratorManager",
    displayName: "Moderator Manager",
    color: "success"
  },
  MODERATOR_EDITOR: {
    key: "isModeratorEditor",
    displayName: "Moderator Editor",
    color: "success"
  },
  CONTENT_MANAGER: {
    key: "isContentManager",
    displayName: "Account / Content Manager",
    color: "warning"
  },
  CONTENT_EDITOR: {
    key: "isContentEditor",
    displayName: "Account / Content Editor",
    color: "warning"
  },
  VERIFICATION_MANAGER: {
    key: "isVerificationManager",
    displayName: "Verification Manager",
    color: "info"
  },
  VERIFICATION_EDITOR: {
    key: "isVerificationEditor",
    displayName: "Verification Editor",
    color: "info"
  },
  TAGGING_MANAGER: {
    key: "isTaggingManager",
    displayName: "Tagging Manager",
    color: "dark"
  },
  TAGGING_EDITOR: {
    key: "isTaggingEditor",
    displayName: "Tagging Editor",
    color: "dark"
  },
  USER_SEARCH_ACCESS: {
    key: "accessUserSearch",
    displayName: "User Search Access",
    color: "info"
  }
});

export const MODULE_LIST = Object.freeze({
  ADMIN: {
    displayName: "Admin",
    description: "Manage user access and roles for the Admin Tool",
    link: ADMIN_ROOT,
    icon: "icon-admin",
    hasAccess: (roles) => isAdmin(roles)
  },
  MODERATION: {
    displayName: "Moderation / Tagging",
    description: "Manage user access and roles for the Admin Tool",
    link: MODERATION_ROOT,
    icon: "icon-moderation",
    hasAccess: (roles) => isModerationViewable(roles)
  },
  VERIFICATION: {
    displayName: "Verification",
    description: "Manage user access and roles for the Admin Tool",
    link: VERIFICATION_ROOT,
    icon: "icon-verification",
    hasAccess: (roles) => isVerifier(roles)
  },
  CAMPAIGN: {
    displayName: "Campaigns",
    description: "Manage user access and roles for the Admin Tool",
    link: CAMPAIGN_ROOT,
    icon: "icon-campaigns",
    hasAccess: (roles) => isCampaignViewable(roles)
  },
  CASE_EXPLORER: {
    displayName: "Case Explorer",
    description: "Manage user access and roles for the Admin Tool",
    link: CASE_EXPLORER_ROOT,
    icon: "icon-case-explorer",
    hasAccess: (roles) => isCaseExplorerViewable(roles)
  },
  USER_SEARCH: {
    displayName: "User Search",
    description: "Ability to search all users accounts within Figure 1",
    link: TOOLS_ROOT,
    icon: "icon-tools",
    hasAccess: (roles) => isUserSearchViewable(roles)
  }
});

export const getRolesInitialFormData = (roles) => {
  return reduce(
    ROLE_ATTRIBUTES,
    (acc, value) => {
      return {
        ...acc,
        [value.key]: Boolean(get(roles, value.key, false))
      };
    },
    {}
  );
};

export const isAdmin = (roles) => {
  return Boolean(get(roles, ROLE_ATTRIBUTES.ADMIN.key, false));
};

export const isModeratorManager = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.MODERATOR_MANAGER.key, false));
};

export const isModeratorEditor = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.MODERATOR_EDITOR.key, false));
};

export const isModerator = (roles) => {
  return isModeratorManager(roles) || isModeratorEditor(roles);
};

export const isVerificationManager = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.VERIFICATION_MANAGER.key, false));
};

export const isVerificationEditor = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.VERIFICATION_EDITOR.key, false));
};

export const isVerifier = (roles) => {
  return isVerificationManager(roles) || isVerificationEditor(roles);
};

export const isContentManager = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.CONTENT_MANAGER.key, false));
};

export const isContentEditor = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.CONTENT_EDITOR.key, false));
};

export const isCampaignViewable = (roles) => {
  return isContentManager(roles) || isContentEditor(roles);
};

export const isTagger = (roles) => {
  return (
    isTaggingManager(roles) ||
    isTaggingEditor(roles) ||
    isModeratorManager(roles)
  );
};

export const isTaggingManager = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.TAGGING_MANAGER.key, false));
};

export const isTaggingEditor = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.TAGGING_EDITOR.key, false));
};

export const getRoleList = (roles) => {
  return compact(
    map(ROLE_ATTRIBUTES, (value) => {
      if (!Boolean(get(roles, value.key, false))) {
        return null;
      }

      return value;
    })
  );
};

export const getModuleList = (roles) => {
  return compact(
    map(MODULE_LIST, (value) => {
      if (!value.hasAccess(roles)) {
        return null;
      }

      return value;
    })
  );
};

export const isModerationViewable = (roles) => {
  return isModerator(roles) || isTagger(roles);
};

export const isCaseExplorerViewable = (roles) => {
  return isContentManager(roles) || isContentEditor(roles);;
};

export const isUserSearchViewable = (roles) => {
  if (isAdmin(roles)) {
    return true;
  }

  return Boolean(get(roles, ROLE_ATTRIBUTES.USER_SEARCH_ACCESS.key, false));
};
