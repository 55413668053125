import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import GlobalHeader from "../components/GlobalHeader";
import HomePage from "./home/HomePage";
import NotAuthorizedPage from "./home/NotAuthorizedPage";
import { userSignedIn } from "../utils/route-utils";
import { HOME_MODULE_NAME } from "../constants/route-constants";
import LoadingPage from "./home/LoadingPage";

class HomeApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    // if (isEmpty(userRoles)) {
    //   return null;
    // }
    //
    // if (!isCampaignViewable(userRoles)) {
    //   return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    // }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={HOME_MODULE_NAME} />
        <Switch>
          <Route exact path={ROUTES.NOT_AUTHORIZED}>
            {this.conditionallyRenderDefaultOrPage(<NotAuthorizedPage />)}
          </Route>
          <Route exact path={ROUTES.HOME_ROOT}>
            {this.conditionallyRenderDefaultOrPage(<HomePage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default compose(withRouter, connect(mapStateToProps, null))(HomeApp);
