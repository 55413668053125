import React, { useEffect } from "react";
import { Container, Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import { compact, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  ToolsUserTableHeading,
  ToolsUserTableRow
} from "./components/ToolsSections";
import { ToolsUserPagination } from "./components/ToolsNavigation";
import EmptyContainer from "../../components/common/EmptyContainer";
import Loading from "../../components/Loading";
import { getUsers } from "../../actions/tools.actions";
import {
  getAllProfessionAndSpecialties,
  getAllGroups
} from "../../actions/reference.actions";

const ToolsLandingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.tools.processing);
  const users = useSelector((state) => state.tools.users);

  useEffect(() => {
    dispatch(getUsers(0));
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllGroups());
  }, [dispatch]);

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    const table = compact(
      map(users, (c, index) => {
        return <ToolsUserTableRow key={`user-${index}`} data={c} />;
      })
    );

    return (
      <div className="px-4 pt-4 pb-1 bg-white">
        <ToolsUserPagination />
        {isEmpty(table) && (
          <EmptyContainer
            title={`No Users Found`}
            description="Please adjust your search criteria to locate your desired users."
          />
        )}

        {!isEmpty(table) && (
          <div
            className="table-responsive"
            style={{ height: "calc(100vh - 235px)" }}>
            <Table bordered>
              <ToolsUserTableHeading />
              <tbody>{table}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container className="tools force-show-scrollbars mt-4 mb-0">
      <div>{renderBody()}</div>
    </Container>
  );
};

export default ToolsLandingPage;
