import {
  clone,
  concat,
  find,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  omitBy,
  size
} from "lodash";
import {
  CAMPAIGN_FILTERS,
  CAMPAIGN_STATES,
  CAMPAIGN_TACTIC_STATE_VALUES,
  getCampaignTacticType
} from "../utils/campaign-utils";
import {
  CAMPAIGN_CASE_REFRESH,
  CAMPAIGN_REFRESH,
  CAMPAIGNS_REFRESH,
  DELETE_CAMPAIGN_TACTIC_COMPLETE,
  DELETE_CAMPAIGN_TACTIC_START,
  GET_AUTHORS_COMPLETE,
  GET_AUTHORS_START,
  GET_CAMPAIGN_COMPLETE,
  GET_CAMPAIGN_START,
  GET_CAMPAIGNS_COMPLETE,
  GET_CAMPAIGNS_START,
  SAVE_CAMPAIGN_COMPLETE,
  SAVE_CAMPAIGN_START,
  SAVE_CAMPAIGN_STATE_COMPLETE,
  SAVE_CAMPAIGN_STATE_START,
  SAVE_CAMPAIGN_TACTIC_COMPLETE,
  SAVE_CAMPAIGN_TACTIC_START,
  SAVE_TACTIC_STATE_COMPLETE,
  SAVE_TACTIC_STATE_START,
  SET_ACTIVE_CAMPAIGN,
  UPDATE_CAMPAIGN_AUTHOR_FILTER,
  UPDATE_CAMPAIGN_FILTERS,
  UPDATE_SEARCH_FILTER
} from "../actions/campaigns.actions";

const defaultPage = {
  sortDirection: "desc",
  pageSize: 50,
  currentPage: 0,
  campaigns: [],
  filters: {}
};

const draftPage = {
  ...defaultPage,
  stateFilter: CAMPAIGN_STATES.DRAFT,
  tacticStateFilter: CAMPAIGN_TACTIC_STATE_VALUES.SC_DRAFT,
  searchFilter: null
};

const reviewPage = {
  ...defaultPage,
  stateFilter: CAMPAIGN_STATES.REVIEW,
  tacticStateFilter: CAMPAIGN_TACTIC_STATE_VALUES.SC_REVIEW,
  searchFilter: null
};

const activePage = {
  ...defaultPage,
  stateFilter: CAMPAIGN_STATES.ACTIVE,
  tacticStateFilter: CAMPAIGN_TACTIC_STATE_VALUES.SC_APPROVED,
  searchFilter: null
};

const archivedPage = {
  ...defaultPage,
  stateFilter: CAMPAIGN_STATES.ARCHIVED,
  tacticStateFilter: CAMPAIGN_TACTIC_STATE_VALUES.SC_ARCHIVED,
  searchFilter: null
};

const INITIAL_STATE = {
  processing: false,
  saving: false,
  savingState: false,
  deleting: false,
  isError: false,
  message: null,
  activeCampaign: null,
  campaignFilter: CAMPAIGN_FILTERS.ALL,
  draftPage: draftPage,
  activePage: activePage,
  reviewPage: reviewPage,
  archivedPage: archivedPage,
  authorsSearching: false,
  authors: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_START: {
      return {
        ...state,
        processing: true
      };
    }
    case GET_CAMPAIGNS_COMPLETE: {
      const currentPageData = state[action.pageName];
      const campaignsPage = get(action.results, "pageData", []);
      const campaigns = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(campaignsPage, "pageSize", 10);
      const total = get(campaignsPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(campaigns);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < total;

      return {
        ...state,
        processing: false,
        [action.pageName]: {
          ...currentPageData,
          campaigns,
          pageSize,
          total,
          fromPage,
          toPage,
          hasPrevious,
          hasNext,
          currentPage
        }
      };
    }
    case UPDATE_SEARCH_FILTER: {
      const currentPageData = state[action.pageName];
      return {
        ...state,
        [action.pageName]: {
          ...currentPageData,
          searchFilter: action.searchFilter
        }
      };
    }
    case UPDATE_CAMPAIGN_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case UPDATE_CAMPAIGN_AUTHOR_FILTER: {
      return {
        ...state,
        campaignFilter: action.campaignFilter
      };
    }
    case SAVE_CAMPAIGN_START: {
      return {
        ...state,
        saving: true
      };
    }
    case SAVE_CAMPAIGN_COMPLETE: {
      return {
        ...state,
        saving: false
      };
    }
    case SAVE_CAMPAIGN_STATE_START:
    case SAVE_TACTIC_STATE_START: {
      return {
        ...state,
        savingState: true
      };
    }
    case SAVE_CAMPAIGN_STATE_COMPLETE:
    case SAVE_TACTIC_STATE_COMPLETE: {
      return {
        ...state,
        savingState: false
      };
    }
    case GET_CAMPAIGN_START: {
      return {
        ...state,
        processing: true
      };
    }
    case GET_CAMPAIGN_COMPLETE: {
      return {
        ...state,
        processing: false
      };
    }
    case SAVE_CAMPAIGN_TACTIC_START: {
      return {
        ...state,
        saving: true
      };
    }
    case SAVE_CAMPAIGN_TACTIC_COMPLETE: {
      return {
        ...state,
        saving: false
      };
    }
    case SET_ACTIVE_CAMPAIGN: {
      const activeCampaign = action.activeCampaign;
      if (activeCampaign) {
        activeCampaign.cases = map(get(activeCampaign, "cases", []), (c) => {
          return {
            ...c,
            tacticType: getCampaignTacticType(c)
          };
        });
      }

      return {
        ...state,
        activeCampaign
      };
    }
    case CAMPAIGN_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const activeCampaign = action.response;
      const currentCampaign = state.activeCampaign;
      if (activeCampaign) {
        activeCampaign.cases = map(get(currentCampaign, "cases", []), (c) => {
          return {
            ...c,
            tacticType: getCampaignTacticType(c)
          };
        });
      }

      return {
        ...state,
        activeCampaign: clone(activeCampaign)
      };
    }
    case CAMPAIGNS_REFRESH: {
      if (isNil(action.response) || isNil(action.pageName)) {
        return state;
      }

      const currentPageData = state[action.pageName];
      const campaign = action.response;
      const campaignUuid = get(campaign, "campaignUuid");
      const campaigns = map(currentPageData.campaigns, (c) => {
        if (isEqual(campaignUuid, c.campaignUuid)) {
          return campaign;
        } else {
          return c;
        }
      });

      return {
        ...state,
        [action.pageName]: {
          ...currentPageData,
          campaigns
        }
      };
    }
    case CAMPAIGN_CASE_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const caseResponse = {
        ...action.response,
        tacticType: getCampaignTacticType(action.response)
      };

      const caseUuid = get(caseResponse, "caseUuid");
      const activeCampaign = state.activeCampaign;
      const caseExists = !isNil(
        find(activeCampaign.cases, (c) => isEqual(caseUuid, c.caseUuid))
      );
      activeCampaign.cases = caseExists
        ? map(activeCampaign.cases, (c) => {
            if (isEqual(caseUuid, c.caseUuid)) {
              return caseResponse;
            } else {
              return c;
            }
          })
        : concat(activeCampaign.cases, caseResponse);
      return {
        ...state,
        activeCampaign: clone(activeCampaign)
      };
    }
    case DELETE_CAMPAIGN_TACTIC_START: {
      return {
        ...state,
        deleting: true
      };
    }
    case DELETE_CAMPAIGN_TACTIC_COMPLETE: {
      return {
        ...state,
        deleting: false
      };
    }
    case GET_AUTHORS_START: {
      return {
        ...state,
        authorsSearching: true
      };
    }
    case GET_AUTHORS_COMPLETE: {
      return {
        ...state,
        authorsSearching: false,
        authors: action.authors
      };
    }
    default:
      return state;
  }
};

export default reducer;
