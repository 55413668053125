import React from "react";
import PropTypes from "prop-types";
import { NavItem, NavLink, Pagination, Row } from "reactstrap";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../utils/general-utils";

const CommentsPaginationRow = (props) => {
  const { stateLocation, getPage } = props;

  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state[stateLocation].currentPage);
  const totalComments = useSelector(
    (state) => state[stateLocation].totalComments
  );
  const fromPage = useSelector((state) => state[stateLocation].fromPage);
  const toPage = useSelector((state) => state[stateLocation].toPage);
  const hasPrevious = useSelector((state) => state[stateLocation].hasPrevious);
  const hasNext = useSelector((state) => state[stateLocation].hasNext);

  const onPreviousPage = () => {
    dispatch(getPage(currentPage - 1));
    return false;
  };

  const onNextPage = () => {
    dispatch(getPage(currentPage + 1));
    return false;
  };

  if (totalComments === 0) {
    return null;
  }

  return (
    <Row className="comments-pagination text-secondary px-4">
      <span>
        {formatNumber(fromPage)}-{formatNumber(toPage)} of{" "}
        {formatNumber(totalComments)}
      </span>
      <Pagination>
        <NavItem>
          <NavLink href="#" disabled={!hasPrevious} onClick={onPreviousPage}>
            <div className="ml-3 mr-2">&#8249;</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" disabled={!hasNext} onClick={onNextPage}>
            <div className="ml-3 mr-2">&#8250;</div>
          </NavLink>
        </NavItem>
      </Pagination>
    </Row>
  );
};

CommentsPaginationRow.propTypes = {
  stateLocation: PropTypes.string.isRequired,
  getPage: PropTypes.func.isRequired
};

export default CommentsPaginationRow;
