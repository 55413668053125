import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
  Table
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import {
  concat,
  filter,
  flatMap,
  get,
  includes,
  isEmpty,
  isEqual,
  join,
  map,
  size,
  uniq,
  find,
  compact,
  cloneDeep
} from "lodash";
import { saveCampaign } from "../../actions/campaigns.actions";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RowToggler from "../common/RowToggler";
import { convertCountries } from "../../utils/reference-utils";
import CampaignDefineAudienceSearchBar, {
  CATEGORIES
} from "./CampaignDefineAudienceSearchBar";

const SECTIONS = {
  COUNTRY: "Country",
  LANGUAGES: "Languages",
  VERIFICATION: "Verification",
  PROFESSION: "Profession & Specialities",
  OVERVIEW: "Overview"
};

const CampaignDefineAudienceModal = (props) => {
  const {
    isOpen,
    toggle,
    activeCampaign,
    showOverview = false,
    isEditable
  } = props;
  const [activeSection, setActiveSection] = useState(SECTIONS.COUNTRY);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [verificationStatuses, setVerificationStatuses] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [allProfessionsSelected, setAllProfessionsSelected] = useState(false);
  const [countryCollapse, setCountryCollapse] = useState(true);
  const toggleCountryCollapse = () => setCountryCollapse(!countryCollapse);
  const [professionCollapse, setProfessionCollapse] = useState({});

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.campaigns.saving);
  const allCountries = useSelector((state) => state.reference.countries);
  const allCountryUuids = map(allCountries, (c) => c.uuid);
  const allProfessions = useSelector((state) =>
    state.reference.professions.filter((p) => !!p.uuid)
  );
  const [professionsPrimary, setProfessionsPrimary] = useState([]);
  const [professionsStudents, setProfessionsStudents] = useState([]);
  const [professionsOtherHCP, setProfessionsOtherHCP] = useState([]);
  const [hcpOpen, setHcpOpen] = useState(false);
  const [studentsOpen, setStudentsOpen] = useState(false);

  const locales = useSelector((state) => state.reference.sortedLocales);
  const allVerificationStatuses = ["VERIFIED", "NOT_VERIFIED"];
  const campaignUuid = get(activeCampaign, "campaignUuid");
  const [searchStructure, setSearchStructure] = useState({
    value: "",
    category: CATEGORIES.SPECIALTIES
  });
  const isSearchResults = searchStructure.value && searchStructure.category;

  const setInitialData = useCallback(() => {
    setCountries(
      map(get(activeCampaign, "targetCountries", []), (c) => c.countryUuid)
    );
    const targetSpecialties = map(
      get(activeCampaign, "targetSpecialties", []),
      (c) => c.treeUuid
    );

    setSpecialties(targetSpecialties);

    setAllProfessionsSelected(
      !!allProfessions.length &&
        targetSpecialties.length ===
          uniq(
            flatMap(allProfessions, (p) => {
              const uuids = flatMap(p.specialties, (s) =>
                concat(s.uuid, s.subSpecialtyUuids)
              );
              return concat(uuids, p.uuid);
            })
          ).length
    );

    setLanguages(
      map(get(activeCampaign, "targetLanguages", []), (l) => l.language)
    );
  }, [activeCampaign]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData, activeCampaign]);

  useEffect(() => {
    if (allProfessions?.length) {
      let professionsToUse = cloneDeep(allProfessions);

      if (searchStructure.value && searchStructure.category) {
        if (searchStructure.category === CATEGORIES.PROFESSION) {
          professionsToUse = professionsToUse.filter((p) => {
            return (
              p.name
                ?.toLowerCase()
                .indexOf(searchStructure.value?.toLowerCase()) >= 0
            );
          });
          // strip out specialties
          professionsToUse.forEach((p) => (p.specialties = []));
          setProfessionsPrimary(
            professionsToUse.filter(
              (p) =>
                p.categoryLabel !== "otherstudent" &&
                p.categoryLabel !== "otherhcp"
            )
          );
        }
        if (searchStructure.category === CATEGORIES.SPECIALTIES) {
          // whittle down list to only professions that have valid specialties/subspecialties
          professionsToUse = compact(
            professionsToUse.map((p) => {
              // strip out specialties that don't match
              p.specialties = p.specialties.length
                ? compact(
                    p.specialties.map((s) => {
                      if (
                        s.name
                          ?.toLowerCase()
                          .indexOf(searchStructure.value?.toLowerCase()) >= 0
                      ) {
                        return s;
                      }
                      return null;
                    })
                  )
                : [];

              p.specialtyUuids = p.specialties.map((s) => s.uuid);

              if (p.specialties.length) {
                return p;
              }
              return null;
            })
          );

          setProfessionsPrimary(
            professionsToUse.filter((p) => p.specialties.length > 0)
          );
        }
      } else {
        setProfessionsPrimary(
          professionsToUse.filter((p) => p.specialties.length > 0)
        );
      }
      setProfessionsStudents(
        professionsToUse.filter((p) => p.categoryLabel === "otherstudent")
      );
      setProfessionsOtherHCP(
        professionsToUse.filter((p) => p.categoryLabel === "otherhcp")
      );
    }
  }, [allProfessions.length, searchStructure]);

  const onOpened = () => {
    setInitialData();
    setProfessionCollapse({});
    setCountryCollapse(true);
    setActiveSection(showOverview ? SECTIONS.OVERVIEW : SECTIONS.COUNTRY);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(
      saveCampaign({
        campaignUuid: campaignUuid,
        targetCountryUuids: countries,
        targetLanguages: languages,
        targetSpecialtyUuids: specialties,
        isSponsored: get(activeCampaign, "isSponsored", "").toString()
      })
    ).then(() => {
      toggle();
    });
  };

  const onSelectEveryValue = () => {
    setCountries(allCountryUuids);
    setLanguages(map(locales, (v) => v.code));
    setVerificationStatuses(allVerificationStatuses);
    setSpecialties(
      uniq(
        flatMap(allProfessions, (p) => {
          const uuids = flatMap(p.specialties, (s) =>
            concat(s.uuid, s.subSpecialtyUuids)
          );
          return concat(uuids, p.uuid);
        })
      )
    );
  };

  const onSelectAllProfessions = () => {
    if (allProfessionsSelected) {
      setSpecialties([]);
    } else {
      setSpecialties(
        uniq(
          flatMap(allProfessions, (p) => {
            const uuids = flatMap(p.specialties, (s) =>
              concat(s.uuid, s.subSpecialtyUuids)
            );
            return concat(uuids, p.uuid);
          })
        )
      );
    }
    setAllProfessionsSelected(!allProfessionsSelected);
  };

  const onCountriesChange = (event) => {
    const { name } = event.target;

    const onAddCountry = (uuid) => {
      setCountries(uniq(concat(countries, uuid)));
    };

    const onRemoveCountry = (uuid) => {
      setCountries(filter(countries, (u) => !isEqual(u, uuid)));
    };

    includes(countries, name) ? onRemoveCountry(name) : onAddCountry(name);
  };

  const onLanguagesChange = (event) => {
    const { name } = event.target;

    const onAddLanguage = (selected) => {
      setLanguages(uniq(concat(languages, selected)));
    };

    const onRemoveLanguage = (name) => {
      setLanguages(filter(languages, (u) => !isEqual(u, name)));
    };

    includes(languages, name) ? onRemoveLanguage(name) : onAddLanguage(name);
  };

  const onVerficationChange = (event) => {
    const { name } = event.target;

    const onAddStatus = (selected) => {
      setVerificationStatuses(uniq(concat(verificationStatuses, selected)));
    };

    const onRemoveStatus = (name) => {
      setVerificationStatuses(
        filter(verificationStatuses, (u) => !isEqual(u, name))
      );
    };

    includes(verificationStatuses, name)
      ? onRemoveStatus(name)
      : onAddStatus(name);
  };

  const onProfessionChange = (toggledProfession, addSubItems) => {
    const onAddProfession = () => {
      let uuids = [];
      if (addSubItems)
        uuids = flatMap(toggledProfession.specialties, (s) =>
          concat(s.uuid, s.subSpecialtyUuids)
        );

      setSpecialties(uniq(concat(specialties, toggledProfession.uuid, uuids)));
    };

    const onRemoveProfession = () => {
      setAllProfessionsSelected(false);
      // Is a profession is removed, we will remove all specialties
      if (!isEmpty(toggledProfession.specialtyUuids)) {
        onRemoveAllSpecialties(
          concat(toggledProfession.uuid, toggledProfession.specialtyUuids)
        );
      } else {
        setSpecialties(
          filter(specialties, (u) => !isEqual(u, toggledProfession.uuid))
        );
      }
    };

    const onRemoveAllSpecialties = (uuids) => {
      setSpecialties(filter(specialties, (u) => !includes(uuids, u)));
    };

    includes(specialties, toggledProfession.uuid)
      ? onRemoveProfession(toggledProfession.uuid)
      : onAddProfession(toggledProfession.uuid);
  };

  const onSelectAllProfession = (profession) => {
    const uuids = flatMap(profession.specialties, (s) =>
      concat(s.uuid, s.subSpecialtyUuids)
    );
    setSpecialties(uniq(concat(specialties, uuids)));
  };

  const onDeselectAllProfession = (profession) => {
    const uuids = flatMap(profession.specialties, (s) =>
      concat(s.uuid, s.subSpecialtyUuids)
    );
    setSpecialties(filter(specialties, (u) => !includes(uuids, u)));
  };

  const onSearchEvent = (category, value) => {
    setSearchStructure({ value, category });
  };

  const hasSpecialtySelections = (specialtyList) => {
    return !isEmpty(getSpecialtySelections(specialtyList));
  };

  const getSpecialtySelections = (specialtyList) => {
    return map(
      filter(specialtyList, (s) => includes(specialties, s.uuid)),
      (s) => s.name
    );
  };

  const getProfessionOverviewSelections = (profession) => {
    if (!includes(specialties, profession.uuid)) {
      return null;
    }
    const profShape = {
      name: profession.name,
      selectedSpecialties: [],
      allSpecialtiesSelected: true,
      specialtiesLength: profession.specialties.length
    };

    if (
      !hasSpecialtySelections(profession.specialties) &&
      profShape.specialtiesLength === 0
    ) {
      return profShape;
    }

    const result = [];

    map(profession.specialties, (specialty) => {
      const subSpecialties = getSpecialtySelections(specialty.subSpecialties);
      const isSelected = includes(specialties, specialty.uuid);
      if (!isEmpty(subSpecialties) || isSelected) {
        result.push({
          name: specialty.name,
          isSelected,
          selectedSubspecialties: subSpecialties,
          allSubspecialtiesSelected: specialty.subSpecialties.length
            ? specialty.subSpecialties.length === subSpecialties.length
            : true
        });
      }
    });

    profShape.selectedSpecialties = result;
    const hasAllSubSpecialties = !result.find(
      (s) => s.allSubspecialtiesSelected === false
    );
    const hasAllSpecialties = !result.find((s) => s.isSelected === false);

    profShape.allSpecialtiesSelected =
      hasAllSubSpecialties &&
      hasAllSpecialties &&
      profShape.specialtiesLength === profShape.selectedSpecialties.length;

    return profShape;
  };

  const renderSectionHeader = (
    title,
    collapse,
    sectionToggle = () => {},
    onSelectAll = null,
    allSelected = false,
    classNames = ""
  ) => {
    const rowClickHandler = onSelectAll ? null : sectionToggle;
    return (
      <div
        className={`audience-section-heading d-flex justify-content-between ${classNames}`}
        onClick={rowClickHandler}>
        <div onClick={onSelectAll}>
          {onSelectAll && (
            <Input
              type="checkbox"
              checked={allSelected}
              onChange={onSelectAll}
              disabled={!isEditable}
            />
          )}
          {title}
        </div>
        <RowToggler
          toggle={sectionToggle}
          collapse={collapse}
          large={true}
          color="link"
          iconColor="gray"
        />
      </div>
    );
  };

  const renderProfessionHeader = (profession, isChecked = true, cb = null) => {
    const isProfessionSelected = includes(specialties, profession.uuid);
    const checked = { check: isChecked, className: isChecked ? "" : "mb-0" };

    return (
      <FormGroup {...checked}>
        <Label {...checked}>
          <Input
            type="checkbox"
            checked={isProfessionSelected}
            onChange={() => {
              cb ? cb(profession) : onProfessionChange(profession, true);
            }}
            disabled={!isEditable}
          />
          {profession.name}
        </Label>
      </FormGroup>
    );
  };

  const renderCountries = () => {
    const allSelected = size(allCountries) === size(countries);
    const onSelectAll = () => {
      !allSelected ? setCountries(allCountryUuids) : setCountries([]);
    };

    return (
      <div>
        {renderSectionHeader(
          "All Countries",
          countryCollapse,
          toggleCountryCollapse,
          onSelectAll,
          allSelected,
          "py-3 select-all-container font-weight-bold"
        )}
        <Collapse isOpen={countryCollapse}>
          <div className="py-3">
            {map(allCountries, (value) => {
              return (
                <FormGroup key={value.uuid} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name={value.uuid}
                      checked={includes(countries, value.uuid)}
                      onChange={onCountriesChange}
                      disabled={!isEditable}
                    />{" "}
                    {value.name}
                  </Label>
                </FormGroup>
              );
            })}
          </div>
        </Collapse>
      </div>
    );
  };

  const renderLanguages = () => {
    const allSelected = size(locales) === size(languages);
    const onSelectAll = () => {
      !allSelected
        ? setLanguages(map(locales, (v) => v.code))
        : setLanguages([]);
    };

    return (
      <div>
        <div className="py-3 select-all-container">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={allSelected}
                onChange={onSelectAll}
                disabled={!isEditable}
              />{" "}
              <span className="font-weight-bold">All Languages</span>
            </Label>
          </FormGroup>
        </div>
        <div className="py-3">
          {map(locales, (v) => {
            return (
              <FormGroup key={`lang-${v.code}`} check>
                <Label check>
                  <Input
                    type="checkbox"
                    name={v.code}
                    checked={includes(languages, v.code)}
                    onChange={onLanguagesChange}
                    disabled={!isEditable}
                  />{" "}
                  {v.languageShortName}
                </Label>
              </FormGroup>
            );
          })}
        </div>
      </div>
    );
  };

  const renderVerification = () => {
    return (
      <div>
        <div className="audience-section-heading last">Verification Status</div>
        <div className="py-3">
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name={"VERIFIED"}
                checked={includes(verificationStatuses, "VERIFIED")}
                onChange={onVerficationChange}
                disabled={!isEditable}
              />{" "}
              {"Verified"}
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name={"NOT_VERIFIED"}
                checked={includes(verificationStatuses, "NOT_VERIFIED")}
                onChange={onVerficationChange}
                disabled={!isEditable}
              />{" "}
              {"Unverfied"}
            </Label>
          </FormGroup>
        </div>
      </div>
    );
  };

  const renderFlatProfessions = (toggle, isOpen, title, professions) => {
    const onClick = () => toggle(!isOpen);

    if (!professions.length) return null;

    return (
      <>
        <div
          className="audience-section-heading d-flex cursor-pointer"
          onClick={onClick}>
          <RowToggler
            toggle={onClick}
            collapse={isOpen}
            large={true}
            color="link"
            iconColor="gray"
          />
          <span>{title}</span>
        </div>
        <Collapse isOpen={isOpen}>
          {map(professions, (profession) => (
            <div className="py-3" key={`profession-${profession.uuid}`}>
              {renderProfessionHeader(profession)}
            </div>
          ))}
        </Collapse>
      </>
    );
  };

  const renderSpecialtyToggleHeader = (specialty, classNames = "") => {
    const isProfessionSelected = includes(specialties, specialty.uuid);
    const isProfessionOpen = professionCollapse[specialty.uuid] || false;

    const toggleSpecialtyOpen = () => {
      setProfessionCollapse({
        ...professionCollapse,
        [specialty.uuid]: !isProfessionOpen
      });
    };
    const toggleSpecialtySelect = () => {
      if (!isProfessionSelected) {
        toggleSpecialtyOpen(false);
      }
      onProfessionChange(specialty);
    };

    return renderSectionHeader(
      specialty.name,
      isProfessionOpen,
      toggleSpecialtyOpen,
      toggleSpecialtySelect,
      isProfessionSelected,
      `pt-3 sub ${classNames}`
    );
  };

  const renderExpandableProfession = (profession) => {
    const isProfessionSelected = includes(specialties, profession.uuid);
    const currentSelected = filter(specialties, (s) =>
      includes(profession.specialtyUuids, s)
    );
    const allSelected = size(profession.specialties) === size(currentSelected);
    const noneSelected = size(currentSelected) === 0;
    const isProfessionOpen =
      professionCollapse[profession.uuid] || (!allSelected && !noneSelected);

    const onNoneClick = () => {
      setProfessionCollapse({
        ...professionCollapse,
        [profession.uuid]: false
      });

      onDeselectAllProfession(profession);
    };

    const onCustomClick = () => {
      setProfessionCollapse({
        ...professionCollapse,
        [profession.uuid]: true
      });
    };

    const onAllClick = () => {
      onSelectAllProfession(profession);

      setProfessionCollapse({
        ...professionCollapse,
        [profession.uuid]: false
      });
    };

    const onCheckClick = (profession) => {
      onProfessionChange(profession, true);

      setProfessionCollapse({
        ...professionCollapse,
        [profession.uuid]: false
      });
    };

    const actions =
      isProfessionSelected && profession.specialties.length ? (
        <div className="profession-expansion-buttons">
          <span>Specialties/Subspecialties:</span>
          <Button
            type="button"
            style={{ width: "80px" }}
            color="primary"
            outline={!isProfessionOpen && allSelected ? false : true}
            onClick={onAllClick}>
            All
          </Button>
          <Button
            type="button"
            style={{ width: "80px" }}
            color="primary"
            outline={!isProfessionOpen && noneSelected ? false : true}
            onClick={onNoneClick}>
            None
          </Button>
          <Button
            type="button"
            style={{ width: "80px" }}
            color="primary"
            outline={isProfessionOpen ? false : true}
            onClick={onCustomClick}>
            Custom
          </Button>
        </div>
      ) : null;

    const professionList =
      isProfessionOpen && profession.specialties.length ? (
        <div className="bg-lighter-gray px-4 mt-3">
          {map(profession.specialties, renderSubSpecialtyList)}
        </div>
      ) : null;
    return (
      <div className="py-3" key={`profession-${profession.uuid}`}>
        {renderProfessionHeader(profession, true, onCheckClick)}

        {actions}
        {professionList}
      </div>
    );
  };

  const renderSubSpecialtyList = (specialty) => {
    const isProfessionOpen = professionCollapse[specialty.uuid] || false;

    const selectAllSubspecialties = () => {
      const uuids = flatMap(specialty.subSpecialties, (s) => concat(s.uuid));
      setSpecialties(uniq(concat(specialties, uuids)));
    };

    const deselectAllSubspecialties = () => {
      setSpecialties(
        filter(specialties, (u) => !includes(specialty.subSpecialtyUuids, u))
      );
    };

    const header = specialty?.subSpecialties?.length ? (
      renderSpecialtyToggleHeader(specialty)
    ) : (
      <div className="py-3 audience-section-heading sub">
        {renderProfessionHeader(specialty, false)}
      </div>
    );

    return (
      <div className="subspecialty-item" key={`specialty-${specialty.uuid}`}>
        {header}
        {isProfessionOpen && (
          <div className="pt-3">
            <div className="d-flex mb-1 text-12 align-items-center">
              Subspecialties and practice areas:
              <Button
                type="button"
                color="link"
                className="py-0 px-2 text-14"
                onClick={() => selectAllSubspecialties(specialty)}>
                Select all
              </Button>
              {" | "}
              <Button
                type="button"
                color="link"
                className="py-0 px-2 text-14"
                onClick={() => deselectAllSubspecialties(specialty)}>
                Unselect all
              </Button>
            </div>
            <div className="subspecialty-list pb-3">
              {map(specialty.subSpecialties, (subSpecialty) => (
                <div className="py-1" key={`subSpecialty-${subSpecialty.uuid}`}>
                  {renderProfessionHeader(subSpecialty)}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderNoResults = () => (
    <div>
      <p>
        No results found for{" "}
        <span className="font-weight-bold">{searchStructure.value}</span> in{" "}
        <span className="font-weight-bold">{searchStructure.category}</span>
      </p>
    </div>
  );

  const renderSearchResultProfession = (profession) => {
    if (
      searchStructure.category === CATEGORIES.SPECIALTIES &&
      !profession.specialties.length
    )
      return null;

    const onCheckClick = (specialty) => {
      const ids = [];
      if (specialties.indexOf(specialty.uuid) === -1) {
        ids.push(specialty.uuid);
        ids.push(profession.uuid);

        setSpecialties(
          uniq(concat(specialties, ids, specialty?.subSpecialtyUuids))
        );
      } else {
        setSpecialties(filter(specialties, (u) => !isEqual(u, specialty.uuid)));
      }
    };

    const header = !!profession.specialties.length ? (
      <span>{profession.name}</span>
    ) : (
      renderProfessionHeader(profession, false, onCheckClick)
    );

    return (
      <>
        <div className="audience-section-heading d-flex cursor-pointer">
          {header}
        </div>
        {profession.specialties.length > 0 && (
          <div className="bg-lighter-gray px-3">
            {map(profession.specialties, (specialty) => (
              <div
                className="subspecialty-item"
                key={`search-specialty-${specialty.uuid}`}>
                <div className="py-3 audience-section-heading sub">
                  {renderProfessionHeader(specialty, true, onCheckClick)}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const renderProfessions = () => {
    const results =
      professionsPrimary.length ||
      professionsOtherHCP.length ||
      professionsStudents.length ? (
        <>
          {isSearchResults &&
            map(professionsPrimary, (profession) => {
              return renderSearchResultProfession(profession);
            })}

          {!isSearchResults &&
            map(professionsPrimary, (profession) => {
              return renderExpandableProfession(profession);
            })}

          {renderFlatProfessions(
            setHcpOpen,
            hcpOpen,
            "Other HCP",
            professionsOtherHCP
          )}

          {renderFlatProfessions(
            setStudentsOpen,
            studentsOpen,
            "Other Student",
            professionsStudents
          )}
        </>
      ) : (
        renderNoResults()
      );

    const banner = isSearchResults ? (
      <div className="search-result-banner text-12">
        <p className="text-light-gray mb-1">
          Showing professions with {searchStructure.category} ‘
          {searchStructure.value}’.
        </p>
        {searchStructure.category === CATEGORIES.SPECIALTIES && (
          <p className="font-weight-bold text-dark-gray">
            * Selecting specialty will automatically select the profession as
            well.
          </p>
        )}
      </div>
    ) : (
      <div className="py-3 select-all-container">
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={allProfessionsSelected}
              onChange={onSelectAllProfessions}
              disabled={!isEditable}
            />{" "}
            <span className="font-weight-bold">
              All professions & specialties
            </span>
          </Label>
        </FormGroup>
      </div>
    );

    return (
      <div>
        {banner}

        {results}
      </div>
    );
  };

  const renderOverview = () => {
    const selectedCountries = convertCountries(countries, allCountries);

    let countryString = "None";
    if (selectedCountries.length === allCountries.length) {
      countryString = "All countries";
    } else if (selectedCountries.length > 0) {
      countryString = join(
        map(selectedCountries, (c) => c.name),
        ", "
      );
    }

    let languageString = "None";
    if (languages.length === locales.length) {
      languageString = "All languages";
    } else if (languages.length > 0) {
      languageString = join(
        compact(
          map(languages, (l) =>
            get(
              find(locales, (v) => isEqual(v.code, l)),
              "languageShortName"
            )
          )
        ),
        ", "
      );
    }

    let specialtiesString = null;
    if (specialties.length === 0) {
      specialtiesString = "None";
    } else if (allProfessionsSelected) {
      specialtiesString = "All";
    }

    const selectedProfessions = compact(
      allProfessions.map((profession) => {
        const professionSelections = getProfessionOverviewSelections(
          profession
        );

        return professionSelections;
      })
    );

    return (
      <div>
        <div className="overview-section">Countries</div>
        <div className="overview-body">{countryString}</div>

        <div className="overview-section">Languages</div>
        <div className="overview-body">{languageString}</div>

        <div className="overview-section">Verification</div>
        <div className="overview-body">
          {isEmpty(verificationStatuses)
            ? "None"
            : join(verificationStatuses, ", ")}
        </div>

        <div className="overview-section">
          Profession/Specialty/Subspecialty/Practice Areas
        </div>
        <div className="overview-body">
          {specialtiesString
            ? specialtiesString
            : map(selectedProfessions, (profession, i) => {
                return (
                  <Table bordered key={`profession-result-${i}`}>
                    <thead>
                      <tr>
                        <th colSpan="2" className="bg-lighter-gray">
                          {profession.name}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!profession.specialtiesLength &&
                        profession.selectedSpecialties.length === 0 && (
                          <tr>
                            <td colSpan="2">
                              No specialties and no subspecialties
                            </td>
                          </tr>
                        )}

                      {(!profession.specialtiesLength ||
                        (!!profession.specialtiesLength &&
                          profession.allSpecialtiesSelected)) && (
                        <tr>
                          <td colSpan="2">All</td>
                        </tr>
                      )}
                      {!!profession.specialtiesLength &&
                        !profession.allSpecialtiesSelected &&
                        map(profession.selectedSpecialties, (specialty, o) => {
                          const subspecialtiesString = specialty.allSubspecialtiesSelected
                            ? specialty.selectedSubspecialties.length
                              ? "All subspecialties"
                              : null
                            : join(
                                specialty.isSelected
                                  ? [
                                      "No Subspecialties",
                                      ...specialty.selectedSubspecialties
                                    ]
                                  : specialty.selectedSubspecialties,
                                ", "
                              );

                          return (
                            <tr key={`speciality-result-${o}`}>
                              {specialty.allSubspecialtiesSelected &&
                                specialty.isSelected && (
                                  <td colSpan="2">{specialty.name} - All</td>
                                )}

                              {((!!specialty.selectedSubspecialties.length &&
                                !specialty.allSubspecialtiesSelected) ||
                                (specialty.allSubspecialtiesSelected &&
                                  !specialty.isSelected)) && (
                                <>
                                  <td>{specialty.name}</td>
                                  <td>{subspecialtiesString}</td>
                                </>
                              )}

                              {!specialty.selectedSubspecialties.length &&
                                !specialty.allSubspecialtiesSelected &&
                                specialty.isSelected && (
                                  <>
                                    <td>{specialty.name}</td>
                                    <td>No subspecialties</td>
                                  </>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                );
              })}
        </div>
      </div>
    );
  };

  const renderActiveSection = () => {
    switch (activeSection) {
      case SECTIONS.COUNTRY:
        return renderCountries();
      case SECTIONS.LANGUAGES:
        return renderLanguages();
      case SECTIONS.VERIFICATION:
        return renderVerification();
      case SECTIONS.PROFESSION:
        return renderProfessions();
      case SECTIONS.OVERVIEW:
        return renderOverview();
      default:
        return null;
    }
  };

  const renderSectionButton = (
    currentSection,
    nextSection,
    activeSection,
    label
  ) => {
    return (
      <ListGroupItem
        active={activeSection === currentSection}
        tag="button"
        action
        className={`d-flex justify-content-between${
          activeSection === nextSection ? " no-border" : ""
        }`}
        onClick={() => setActiveSection(currentSection)}>
        {label}
        <span className="action-icon">
          <FontAwesomeIcon icon={faAngleRight} />
        </span>
      </ListGroupItem>
    );
  };

  return (
    <Modal
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      size={"xl"}
      keyboard={false}
      className="campaign-audience-modal modal-btn-spaced">
      <ModalBody className="p-0">
        <Row>
          <Col className="bg-nav-color left-nav" md={3}>
            <div className="p-4">
              <h6 className="pt-4 pb-2 mx-n3">
                Build Your Audience {"("}
                <Button
                  onClick={onSelectEveryValue}
                  className="p-0 text-15 align-baseline"
                  color="link">
                  select all
                </Button>
                {")"}
              </h6>
              <ListGroup flush>
                {renderSectionButton(
                  SECTIONS.COUNTRY,
                  SECTIONS.LANGUAGES,
                  activeSection,
                  "Countries"
                )}
                {renderSectionButton(
                  SECTIONS.LANGUAGES,
                  SECTIONS.VERIFICATION,
                  activeSection,
                  "Languages"
                )}
                {renderSectionButton(
                  SECTIONS.VERIFICATION,
                  SECTIONS.PROFESSION,
                  activeSection,
                  "Verification"
                )}
                {renderSectionButton(
                  SECTIONS.PROFESSION,
                  SECTIONS.OVERVIEW,
                  activeSection,
                  "Profession & Specialty"
                )}
                {renderSectionButton(
                  SECTIONS.OVERVIEW,
                  null,
                  activeSection,
                  "Overview"
                )}
              </ListGroup>
            </div>
          </Col>
          <Col md={9}>
            <Form onSubmit={(e) => e.preventDefault()}>
              <div className="audience-header d-flex clearfix">
                {activeSection === SECTIONS.PROFESSION && (
                  <CampaignDefineAudienceSearchBar onInput={onSearchEvent} />
                )}
                <button
                  type="button"
                  onClick={toggle}
                  className="close position-absolute mr-4"
                  style={{ right: "0px", top: "0px" }}
                  aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="audience-body">{renderActiveSection()}</div>

              <div className="audience-footer shadow-sm d-flex justify-content-between py-4">
                <Button color="primary" outline onClick={toggle}>
                  Cancel
                </Button>

                <LoadingButton
                  outline
                  color="primary"
                  loading={saving}
                  disabled={saving || !isEditable}
                  onClick={onSubmit}>
                  Save
                </LoadingButton>
              </div>
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CampaignDefineAudienceModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  activeCampaign: PropTypes.object,
  showOverview: PropTypes.bool
};

CampaignDefineAudienceModal.defaultProps = {};

export default CampaignDefineAudienceModal;
