import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import {
  compact,
  concat,
  get,
  head,
  isEmpty,
  join,
  map,
  size,
  find,
  isEqual,
  sortBy
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faLightbulb,
  faStethoscope
} from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getImageUrl } from "../../../utils/media-utils";
import CaseExplorerDownloadImagesModal from "../../../components/modals/CaseExplorerDownloadImagesModal";
import {
  CASE_REACTIONS,
  getWebCaseUrl,
  isCaseActive
} from "../../../utils/case-utils";
import F1Badge from "../../../components/common/F1Badge";
import CaseExplorerPromotionModal from "../../../components/modals/CaseExplorerPromotionModal";
import { getActivePromotions } from "../../../utils/case-explorer-utils";
import { sortCases } from "../../../actions/case-explorer.actions";

export const CaseExplorerTableHeading = () => {
  const dispatch = useDispatch();

  const sortDirection = useSelector(
    (state) => state.caseExplorer.sortDirection
  );
  const sortColumn = useSelector((state) => state.caseExplorer.sortColumn);

  const sortData = (columnName) => {
    dispatch(
      sortCases(columnName, isEqual(sortDirection, "desc") ? "asc" : "desc")
    );

    return false;
  };

  const getSortColumn = (columnName, columnLabel) => {
    const onClick = (e) => {
      e.preventDefault();

      return sortData(columnName);
    };

    if (!isEqual(sortColumn, columnName)) {
      return (
        <span
          className="btn-link font-weight-bold cursor-pointer"
          onClick={onClick}>
          {columnLabel}
        </span>
      );
    }

    return (
      <span
        className="btn-link font-weight-bold cursor-pointer"
        onClick={onClick}>
        {columnLabel}
        <FontAwesomeIcon
          icon={isEqual(sortDirection, "desc") ? faArrowDown : faArrowUp}
          className="ml-1"
        />
      </span>
    );
  };

  return (
    <thead>
      <tr>
        <th
          style={{ minWidth: "130px" }}
          className="table-heading-style border-left-0">
          image preview
        </th>
        <th className="table-heading-style">username</th>
        <th className="table-heading-style">
          {getSortColumn("authors.isVerified", "uploader verified")}
        </th>
        <th className="table-heading-style">legacy</th>
        <th className="table-heading-style">title / caption</th>
        <th className="table-heading-style">
          {getSortColumn("caseState", "state")}
        </th>
        <th className="table-heading-style">uploader profession</th>
        <th className="table-heading-style">uploader country</th>
        <th className="table-heading-style">
          {getSortColumn("commentCount", "comment count")}
        </th>
        <th style={{ minWidth: "120px" }} className="table-heading-style">
          {getSortColumn("publishedAt", "date published")}
        </th>
        <th className="table-heading-style">case labels</th>
        <th className="table-heading-style">case reactions</th>
        <th
          style={{ minWidth: "160px" }}
          className="table-heading-style border-right-0">
          promotion info
        </th>
      </tr>
    </thead>
  );
};

export const CaseExplorerTableRow = (props) => {
  const { caseData } = props;
  const allLabels = useSelector((state) => state.reference.labels);
  const allPromotions = useSelector((state) => state.promotions.allPromotions);
  const countries = useSelector((state) => state.reference.countries);
  const states = useSelector((state) => state.reference.states);
  const [downloadModal, setDownloadModal] = useState(false);
  const toggleDownloadModal = () => setDownloadModal(!downloadModal);
  const [promotionModal, setPromotionModal] = useState(false);
  const togglePromotionModal = () => setPromotionModal(!promotionModal);

  const caseUuid = get(caseData, "caseUuid");
  const publishedAt = get(caseData, "publishedAt");

  const renderPreviewImage = () => {
    const media = sortBy(get(caseData, "media", []), "displayOrder");
    if (isEmpty(media)) {
      return null;
    }

    const firstMedia = head(media);
    const imageUrl = getImageUrl(firstMedia, 120, 120);
    if (!imageUrl) {
      return null;
    }

    return (
      <div>
        <img src={imageUrl} className="img-thumbnail mb-2" alt="" />
        <div className="d-flex justify-content-center">
          <F1Badge
            name="See Images"
            onClick={toggleDownloadModal}
            className="mr-0"
          />
        </div>
        <CaseExplorerDownloadImagesModal
          media={media}
          isOpen={downloadModal}
          toggle={toggleDownloadModal}
        />
      </div>
    );
  };

  const renderLabels = () => {
    const labels = get(caseData, "labels", []);
    if (isEmpty(labels)) {
      return null;
    }

    return map(labels, (v, index) => {
      return (
        <F1Badge
          key={`case-label-${v}-${index}`}
          color={"green"}
          className="mr-2 mb-2"
          name={get(allLabels, v, v)}
        />
      );
    });
  };

  const assignIcon = (reaction) => {
    switch (reaction) {
      case CASE_REACTIONS.AGREE:
        return <FontAwesomeIcon icon={faCheck} size="sm" />;
      case CASE_REACTIONS.CLINICALLY_USEFUL:
        return <FontAwesomeIcon icon={faStethoscope} size="sm" />;
      case CASE_REACTIONS.INFORMATIVE:
        return <FontAwesomeIcon icon={faLightbulb} size="sm" />;
      default:
        return null;
    }
  };

  const renderReactions = () => {
    const reactions = get(caseData, "reactions", []);
    if (isEmpty(reactions)) {
      return null;
    }

    return map(reactions, (v, k, index) => {
      return (
        <div key={`reaction-${caseUuid}-${k}`} className="reaction">
          <div>{assignIcon(k)}</div>
          <span className="text-11 font-weight-bold">{size(v)}</span>
        </div>
      );
    });
  };

  const renderProfession = () => {
    const profession = get(
      caseData,
      "authors[0].tree.profession.professionName"
    );
    const specialty = get(caseData, "authors[0].tree.specialty.specialtyName");
    const subSpecialty = get(
      caseData,
      "authors[0].tree.subspecialty.specialtyName"
    );
    const allValues = compact(concat(profession, specialty, subSpecialty));
    if (isEmpty(allValues)) {
      return "";
    }

    return isEmpty(allValues) ? "" : join(allValues, " | ");
  };

  const renderCountry = () => {
    const countryUuid = get(caseData, "authors[0].countryUuid");
    const country = countryUuid
      ? find(countries, (c) => isEqual(c.uuid, countryUuid))
      : null;
    const stateUuid = get(caseData, "authors[0].stateUuid");
    const state = countryUuid
      ? find(states, (s) => isEqual(s.uuid, stateUuid))
      : null;
    const allValues = compact(concat(get(country, "name"), get(state, "name")));
    if (isEmpty(allValues)) {
      return "";
    }

    return isEmpty(allValues) ? "" : join(allValues, " | ");
  };

  const renderVerified = () => {
    return get(caseData, "authors[0].isVerified") ? "Yes" : "No";
  };

  const renderLegacy = () => {
    return isEmpty(get(caseData, "legacyCaseId")) ? "No" : "Yes";
  };

  const renderPromotions = () => {
    const promotions = getActivePromotions(allPromotions, caseUuid);

    return (
      <>
        <Button
          style={{ lineHeight: 1 }}
          color="primary-dark"
          onClick={togglePromotionModal}
          size="sm"
          className="px-2 text-13 font-weight-bold">
          Add Promotion
        </Button>
        {map(promotions, (p, index) => {
          return (
            <F1Badge
              key={`promotion-${caseUuid}-${p.promotion_uuid}`}
              className="mr-2 mt-2"
              name={p.promotion_name}
            />
          );
        })}
        <CaseExplorerPromotionModal
          toggle={togglePromotionModal}
          isOpen={promotionModal}
          caseData={caseData}
        />
      </>
    );
  };

  const caseState = get(caseData, "caseState");

  return (
    <tr>
      <td className="table-row-style border-left-0">{renderPreviewImage()}</td>
      <td className="table-row-style">{get(caseData, "author_username")}</td>
      <td className="table-row-style">{renderVerified()}</td>
      <td className="table-row-style">{renderLegacy()}</td>
      <td className="table-row-style" style={{ minWidth: 350 }}>
        {isCaseActive(caseState) && (
          <a
            className="d-block pb-2 text-13 font-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
            href={getWebCaseUrl(caseUuid)}>
            View Case on Web
          </a>
        )}
        <div className="text-11 text-uppercase font-weight-bolder">
          {get(caseData, "title")}
        </div>{" "}
        <div className="text-13 text-secondary">{get(caseData, "caption")}</div>
        <div className="pt-3 text-11 text-secondary">Case UUID: {caseUuid}</div>
      </td>
      <td className="table-row-style">
        <div className="text-14">{caseState}</div>
      </td>
      <td className="table-row-style">{renderProfession()}</td>
      <td className="table-row-style">{renderCountry()}</td>
      <td className="table-row-style">{get(caseData, "commentCount")}</td>
      <td className="table-row-style">
        {publishedAt ? moment(publishedAt).format("MM/DD/YY") : "N/A"}
      </td>
      <td className="table-row-style">{renderLabels()}</td>
      <td className="table-row-style align-top">{renderReactions()}</td>
      <td className="table-row-style align-top border-right-0">
        {renderPromotions()}
      </td>
    </tr>
  );
};

CaseExplorerTableRow.propTypes = {
  caseData: PropTypes.object
};
