import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CAMPAIGN_SECONDARY_NAVIGATION } from "../../constants/route-constants";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import { useDispatch, useSelector } from "react-redux";
import {
  CampaignContentForm,
  CampaignQuizOrPollForm
} from "./components/CampaignForms";
import CampaignLeftNavigation from "./components/CampaignLeftNavigation";
import {
  getTacticFromCampaign,
  getTacticTypeByKey,
  TACTIC_TYPES
} from "../../utils/campaign-utils";
import { getCampaignById } from "../../actions/campaigns.actions";
import { get, isEmpty, isEqual } from "lodash";
import {
  getAllCountriesAndStates,
  getAllProfessionAndSpecialties,
  getAllTaggingSpecialties
} from "../../actions/reference.actions";
import "./components/style.scss";
import Loading from "../../components/Loading";
import { CampaignQuizOrPollSeriesForm } from "./components/CampaignMultiForm";
import * as ROUTES from "../../constants/routes";
import { CampaignClinicalMomentForm } from "./components/CampaignClinicalMomentForm";
import { CampaignClinicalMomentCMEForm } from "./components/CampaignClinicalMomentCMEForm";
import { CampaignPromotionCardForm } from "./components/CampaignPromotionForms";

const CampaignTacticManage = () => {
  const { campaignId = "", caseId = "", type = "" } = useParams();
  const [stateChangeActive, setStateChangeActive] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [activeCase, setActiveCase] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const processing = useSelector((state) => state.campaigns.processing);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const savingState = useSelector((state) => state.campaigns.savingState);
  const campaignUuid = get(activeCampaign, "campaignUuid", "");
  const campaignName = get(activeCampaign, "name");
  const newCaseTypeActive = !isEmpty(type) ? getTacticTypeByKey(type) : null;

  useEffect(() => {
    dispatch(getAllCountriesAndStates());
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllTaggingSpecialties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCampaignById(campaignId));
  }, [campaignId, location, dispatch]);

  useEffect(() => {
    if (savingState) {
      setStateChangeActive(true);
    } else if (stateChangeActive) {
      history.go(0);
    }
  }, [savingState, dispatch, stateChangeActive, history]);

  useEffect(() => {
    if (activeCampaign && isEqual(campaignId, campaignUuid)) {
      if (newCaseTypeActive) {
        setActiveSection(newCaseTypeActive);
        setActiveCase({});
      } else if (!isEmpty(caseId)) {
        const tactic = getTacticFromCampaign(activeCampaign, caseId);
        const tacticType = get(tactic, "tacticType");
        if (tacticType) {
          setActiveCase(tactic);
          setActiveSection(tacticType);
        } else {
          history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
        }
      } else {
        history.push(`${ROUTES.CAMPAIGN_MANAGE}/${campaignUuid}`);
      }
    }
  }, [
    activeCampaign,
    caseId,
    newCaseTypeActive,
    campaignId,
    campaignUuid,
    history
  ]);

  const renderInnerBody = () => {
    const activeSectionKey = activeSection ? activeSection.key : "";

    switch (activeSectionKey) {
      case TACTIC_TYPES.IMAGE.key:
      case TACTIC_TYPES.IMAGE_SERIES.key:
      case TACTIC_TYPES.TEXT.key:
      case TACTIC_TYPES.VIDEO.key:
        return (
          <CampaignContentForm
            caseData={activeCase}
            activeSection={activeSection}
          />
        );
      case TACTIC_TYPES.QUIZ.key:
      case TACTIC_TYPES.POLL.key:
        return (
          <CampaignQuizOrPollForm
            caseData={activeCase}
            activeSection={activeSection}
          />
        );
      case TACTIC_TYPES.QUIZ_SERIES.key:
      case TACTIC_TYPES.POLL_SERIES.key:
        return (
          <CampaignQuizOrPollSeriesForm
            caseData={activeCase}
            activeSection={activeSection}
          />
        );
      case TACTIC_TYPES.CLINICAL_MOMENT.key:
        return (
          <CampaignClinicalMomentForm
            caseData={activeCase}
            activeSection={activeSection}
          />
        );
      case TACTIC_TYPES.CLINICAL_MOMENT_CME.key:
        return (
          <CampaignClinicalMomentCMEForm
            caseData={activeCase}
            activeSection={activeSection}
          />
        );
      case TACTIC_TYPES.PROMOTION.key:
        return (
          <CampaignPromotionCardForm
            activeSection={activeSection}
            caseData={activeCase}
          />
        );

      default:
        return <CampaignContentForm activeSection={activeSection} />;
    }
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0 mb-4 min-vh-100">
        <Col className="col-border-right px-4 pt-5 bg-white" xl="3">
          <div className="font-weight-500 mb-3">{campaignName}</div>
          <CampaignLeftNavigation
            caseUuidActive={caseId}
            newCaseTypeActive={newCaseTypeActive}
          />
        </Col>
        <Col lx="9" className="mt-5 ml-4 campaign-form">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="campaign-page">
      <SecondaryNavigation
        links={CAMPAIGN_SECONDARY_NAVIGATION}
        centerAlign={false}
      />
      <Container>{renderBody()}</Container>
    </div>
  );
};

CampaignTacticManage.propTypes = {};

export default CampaignTacticManage;
