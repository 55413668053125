import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { filter, map } from "lodash";
import { CASE_FILTERS } from "../../utils/case-utils";

const TaggingFilterModal = (props) => {
  const { isOpen, toggle, message, isError, filters, applyFilters } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const taggingUploaderSpecialties = useSelector(
    (state) => state.reference.taggingUploaderSpecialties
  );
  const taggingSpecialties = useSelector(
    (state) => state.reference.taggingSpecialties
  );
  const taggingSubSpecialties = useSelector(
    (state) => state.reference.taggingSubSpecialties
  );

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  const onSubmit = async (event) => {
    event.preventDefault();

    toggle();

    await dispatch(applyFilters(formData));
  };

  const clearFilters = () => {
    toggle();

    dispatch(applyFilters({}));
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Select filters
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="caseTerm">
                term search
              </Label>
              <Input
                id="caseTerm"
                name="caseTerm"
                placeholder="Title, caption, case ID..."
                value={formData.caseTerm || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="caseState">
                case status
              </Label>
              <Input
                type="select"
                name="caseState"
                id="caseState"
                value={formData.caseState || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(
                  filter(CASE_FILTERS, (s) => true),
                  (c) => {
                    return (
                      <option
                        key={`case-state-${c.stateName}`}
                        value={c.stateName}>
                        {c.displayName}
                      </option>
                    );
                  }
                )}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="author_username">
                username
              </Label>
              <Input
                id="author_username"
                name="author_username"
                value={formData.author_username || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="userId">
                user id
              </Label>
              <Input
                id="userId"
                name="userId"
                value={formData.userId || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="specialtyUuid">
                speciality tagged
              </Label>
              <Input
                type="select"
                name="specialtyUuid"
                id="specialtyUuid"
                value={formData.specialtyUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(taggingSpecialties, (c) => {
                  return (
                    <option key={`specialty-${c.uuid}`} value={`${c.uuid}`}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="subspecialtyUuid">
                subspeciality tagged
              </Label>
              <Input
                type="select"
                name="subspecialtyUuid"
                id="subspecialtyUuid"
                value={formData.subspecialtyUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(taggingSubSpecialties, (c) => {
                  return (
                    <option key={`sub-specialty-${c.uuid}`} value={c.uuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <Label className="col-12 text-11 text-uppercase font-weight-normal mb-0">
              Uploaded Date
            </Label>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateFrom">
                From
              </Label>
              <Input
                id="requestDateFrom"
                name="requestDateFrom"
                value={formData.requestDateFrom || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateTo">
                To
              </Label>
              <Input
                id="requestDateTo"
                name="requestDateTo"
                value={formData.requestDateTo || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col-6">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="uploadedSpecialtyUuid">
                uploader speciality
              </Label>
              <Input
                type="select"
                name="uploadedSpecialtyUuid"
                id="uploadedSpecialtyUuid"
                value={formData.uploadedSpecialtyUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(taggingUploaderSpecialties, (c) => {
                  return (
                    <option key={`uploader-spec-${c.uuid}`} value={c.uuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                has labels
              </Label>
              <Input
                type="select"
                name="hasLabels"
                value={formData.hasLabels || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-6">
              <Label className="text-11 text-uppercase font-weight-normal">
                is partner case
              </Label>
              <Input
                type="select"
                name="partnerCases"
                value={formData.partnerCases || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-6">
              <Label className="text-11 text-uppercase font-weight-normal">
                is paging case
              </Label>
              <Input
                type="select"
                name="isPagingCase"
                value={formData.isPagingCase || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={clearFilters}>
            Clear filters
          </Button>

          <LoadingButton outline color="primary" type="submit">
            Apply filters
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

TaggingFilterModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filters: PropTypes.object,
  applyFilters: PropTypes.func
};

TaggingFilterModal.defaultProps = {};

export default TaggingFilterModal;
