import {
  concat,
  isEmpty,
  isEqual,
  remove,
  sortBy,
  reduce,
  get,
  map
} from "lodash";
import {
  loadAllSchools,
  loadCountriesAndStates,
  loadGroups,
  loadLabels,
  loadLocales,
  loadPlatforms,
  loadProfessionAndSpecialties,
  loadTaggingSpecialties,
  loadVerificationTags
} from "../db/reference-db";

const actionsPrefix = "reference";

export const PROFESSIONS_SPECIALTIES_LOADED = `${actionsPrefix}/SPECIALTIES_LOADED`;
export const VERIFICATION_TAGS_LOADED = `${actionsPrefix}/VERIFICATION_TAGS_LOADED`;
export const COUNTRIES_STATES_LOADED = `${actionsPrefix}/COUNTRIES_STATES_LOADED`;
export const SCHOOLS_LOADED = `${actionsPrefix}/SCHOOLS_LOADED`;
export const TAGGING_SPECIALTIES_LOADED = `${actionsPrefix}/TAGGING_SPECIALTIES_LOADED`;
export const LABELS_LOADED = `${actionsPrefix}/LABELS_LOADED`;
export const PLATFORMS_LOADED = `${actionsPrefix}/PLATFORMS_LOADED`;
export const LOCALES_LOADED = `${actionsPrefix}/LOCALES_LOADED`;
export const GROUPS_LOADED = `${actionsPrefix}/GROUPS_LOADED`;

export const getAllProfessionAndSpecialties = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.specialties;
    if (isEmpty(existing)) {
      const {
        professions,
        specialties,
        subspecialties
      } = await loadProfessionAndSpecialties();

      dispatch({
        type: PROFESSIONS_SPECIALTIES_LOADED,
        professions: sortBy(professions, ["name"]),
        specialties: sortBy(specialties, ["name"]),
        subspecialties: sortBy(subspecialties, ["name"])
      });
    }
  };
};

export const getAllTaggingSpecialties = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.taggingAllSpecialties;
    if (isEmpty(existing)) {
      const {
        allSpecialties,
        uploaderSpecialties,
        specialties,
        subSpecialties
      } = await loadTaggingSpecialties();

      dispatch({
        type: TAGGING_SPECIALTIES_LOADED,
        allSpecialties,
        uploaderSpecialties: sortBy(uploaderSpecialties, ["name"]),
        specialties: sortBy(specialties, ["name"]),
        subSpecialties: sortBy(subSpecialties, ["name"])
      });
    }
  };
};

export const getVerificationTags = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.verificationTags;
    if (isEmpty(existing)) {
      await loadVerificationTags((data) => {
        dispatch({
          type: VERIFICATION_TAGS_LOADED,
          verificationTags: data
        });
      });
    }
  };
};

export const getAllCountriesAndStates = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.countries;
    if (isEmpty(existing)) {
      const { countries, states } = await loadCountriesAndStates();
      const unitedStates = remove(countries, (c) =>
        isEqual(c.name, "United States")
      );

      dispatch({
        type: COUNTRIES_STATES_LOADED,
        countries: concat(unitedStates, sortBy(countries, ["name"])),
        states
      });
    }
  };
};

export const getAllSchools = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.schools;
    if (isEmpty(existing)) {
      const schools = await loadAllSchools();

      dispatch({
        type: SCHOOLS_LOADED,
        schools: reduce(
          schools,
          (acc, value, key) => {
            acc[key] = sortBy(value, ["name"]);
            return acc;
          },
          {}
        )
      });
    }
  };
};

export const getAllLabels = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.labels;
    if (isEmpty(existing)) {
      const labels = await loadLabels();

      dispatch({
        type: LABELS_LOADED,
        labels: reduce(
          labels,
          (acc, v) => {
            const label = get(v, "labelKind");
            const value = get(v, "name.en");
            acc[label] = value;
            return acc;
          },
          {}
        ),
        sortedLabels: sortBy(
          map(labels, (v, uuid) => {
            return {
              key: get(v, "labelKind"),
              uuid: uuid,
              value: get(v, "name.en")
            };
          }),
          ["key"]
        )
      });
    }
  };
};

export const getAllPlatforms = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.platforms;
    if (isEmpty(existing)) {
      const platforms = await loadPlatforms();

      dispatch({
        type: PLATFORMS_LOADED,
        platforms
      });
    }
  };
};

export const getAllLocales = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.locales;
    if (isEmpty(existing)) {
      const locales = await loadLocales();

      dispatch({
        type: LOCALES_LOADED,
        locales,
        sortedLocales: sortBy(
          map(locales, (v) => {
            return {
              code: get(v, "code"),
              languageShortName: get(v, "languageShortName"),
              name: get(v, "name")
            };
          }),
          ["languageShortName"]
        )
      });
    }
  };
};

export const getAllGroups = () => {
  return async function(dispatch, getState) {
    // Let's try to avoid loading these all the time
    const existing = getState().reference.groups;
    if (isEmpty(existing)) {
      const groups = await loadGroups();

      dispatch({
        type: GROUPS_LOADED,
        groups
      });
    }
  };
};
