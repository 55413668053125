import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import ModerationTabs from "../../components/navigation/ModerationTabs";
import {
  MODERATION_MODULE_NAME,
  MODERATION_SECONDARY_NAVIGATION
} from "../../constants/route-constants";
import {
  MODERATION_COMMENTS_DATE_KEYS,
  MODERATION_COMMENTS_HISTORY_COLLECTIONS,
  MODERATION_COMMENTS_SUB_COLLECTIONS
} from "../../db/db-constants";
import { useDispatch, useSelector } from "react-redux";
import {
  MODERATION_COMMENTS,
  MODERATION_COMMENTS_HISTORY
} from "../../constants/routes";
import { useLocation, useParams } from "react-router-dom";
import CaseLeftNavigation from "./components/CaseLeftNavigation";
import { isEmpty, isEqual, map } from "lodash";
import { CommentDetail } from "./components/CommentSections";
import {
  getComments,
  getCommentsByState,
  updateCommentsDateFilter
} from "../../actions/comments.actions";
import CommentsPaginationRow from "./components/CommentsPaginationRow";
import EmptyContainer from "../../components/common/EmptyContainer";
import {
  getCommentsHistory,
  updateCommentsHistoryDateFilter,
  updateCommentsHistorySearchFilter
} from "../../actions/comments-history.actions";
import Loading from "../../components/Loading";

const ModerationCommentsPage = (props) => {
  const { activeState = "comments" } = props;
  const {
    filterType = MODERATION_COMMENTS_SUB_COLLECTIONS.REPORTED.statsKey
  } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const processing = useSelector((state) => state[activeState].processing);
  const comments = useSelector((state) => state[activeState].comments);
  const dateFilter = useSelector((state) => state[activeState].dateFilter);
  const searchFilter = useSelector(
    (state) => state.commentsHistory.searchFilter
  );
  const [formData, setFormData] = useState({ searchFilter });
  const stats = useSelector((state) => state[activeState].stats);
  const allAuthorMetadata = useSelector(
    (state) => state[activeState].authorMetadata
  );
  const isMainComments = isEqual(activeState, "comments");

  const baseUrl = isMainComments
    ? MODERATION_COMMENTS
    : MODERATION_COMMENTS_HISTORY;
  const leftNavFilters = isMainComments
    ? MODERATION_COMMENTS_SUB_COLLECTIONS
    : MODERATION_COMMENTS_HISTORY_COLLECTIONS;
  const activeFilter = isMainComments ? filterType : "all";

  useEffect(() => {
    isMainComments
      ? dispatch(getCommentsByState(filterType, dateFilter, 0))
      : dispatch(getCommentsHistory(0));
  }, [location, filterType, dateFilter, dispatch, isMainComments]);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const changeDateFilter = (selectedFilter) => {
    isMainComments
      ? dispatch(updateCommentsDateFilter(selectedFilter))
      : dispatch(updateCommentsHistoryDateFilter(selectedFilter));
    toggle();
  };

  const onKeyPressed = async (event) => {
    if (event.key === "Enter") {
      await dispatch(updateCommentsHistorySearchFilter(formData.searchFilter));

      dispatch(getCommentsHistory(0));
    }
  };

  const onChangeSearch = async (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });

    if (!isEmpty(searchFilter) && isEmpty(value)) {
      await dispatch(updateCommentsHistorySearchFilter(null));

      dispatch(getCommentsHistory(0));
    }
  };

  const renderFilters = () => {
    return (
      <Row>
        <Col>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
              className="nav-link text-secondary"
              color="text-secondary"
              caret>
              {dateFilter.displayName}
            </DropdownToggle>
            <DropdownMenu>
              {map(MODERATION_COMMENTS_DATE_KEYS, (v, key) => {
                return (
                  <DropdownItem
                    key={`dropdown-item-${key}`}
                    onClick={() => changeDateFilter(v)}
                    active={isEqual(dateFilter, key)}>
                    {v.displayName}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col>
          <CommentsPaginationRow
            stateLocation={activeState}
            getPage={isMainComments ? getComments : getCommentsHistory}
          />
        </Col>
      </Row>
    );
  };

  const renderInnerBody = () => {
    return (
      <Row>
        <Col className="comment-col" fluid="lg1" style={{ marginBottom: 80 }}>
          {renderFilters()}

          {isEmpty(comments) && (
            <EmptyContainer
              title={`No Comments Found`}
              description={`There were no comments found with this filter within the ${dateFilter.displayName}.`}
            />
          )}
          {map(comments, (comment, index) => {
            return (
              <CommentDetail
                key={`comment-detail-${index}`}
                comment={comment}
                allAuthorMetadata={allAuthorMetadata}
              />
            );
          })}
        </Col>
      </Row>
    );
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0">
        <Col
          className="moderation-left-nav col-border-right py-4 px-0 min-vh-100"
          xl="2"
          lg="2"
          md="2">
          {!isMainComments && (
            <div className="px-2 pb-4">
              <Input
                type="search"
                name="searchFilter"
                placeholder="Search username..."
                className="mx-auto"
                value={formData.searchFilter || ""}
                onChange={onChangeSearch}
                onKeyPress={onKeyPressed}
              />
            </div>
          )}
          <CaseLeftNavigation
            active={activeFilter}
            baseUrl={baseUrl}
            stats={stats}
            collection={leftNavFilters}
          />
        </Col>
        <Col lx="10" lg="10" md="10" className="my-0">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="comments-page">
      <SecondaryNavigation
        links={MODERATION_SECONDARY_NAVIGATION}
        active={MODERATION_MODULE_NAME}
      />
      <Container className="moderation-page-container">
        <ModerationTabs active={activeState} />
        <TabContent className="bg-white" activeTab={"1"}>
          <TabPane tabId="1" className="min-vh-100">
            {renderBody()}
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
};

export default ModerationCommentsPage;
