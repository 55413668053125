import React from "react";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { isFunction } from "lodash";

const F1Badge = (props) => {
  const { name, onRemove, onClick, active, color = "", className = "" } = props;

  const onClickAction = isFunction(onClick)
    ? onClick
    : isFunction(onRemove)
    ? onRemove
    : null;

  return (
    <Badge
      size="large"
      pill
      className={`f1-badge  d-inline-flex align-items-center right ${className} ${active &&
        "active"} ${color} ${onClickAction && "clickable"}`}
      onClick={onClickAction}>
      {name}
      {isFunction(onRemove) && (
        <FontAwesomeIcon
          icon={faTimes}
          size={"lg"}
          color="white"
          className="ml-2"
        />
      )}
    </Badge>
  );
};

F1Badge.propTypes = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string
};

export default F1Badge;
