import { get, head, isEmpty, isEqual, isNil, last, split } from "lodash";
import { loadUser, setUserAndRole } from "./user.actions";
import {
  createSignupUserDoc,
  getUserAndRoles,
  getUserByEmail,
  getUserDocByUid
} from "../db/users-db";
import { createUserCall } from "../api/cloud-functions";
import {
  RESET_MESSAGE,
  signInResult,
  VALIDATING_USER
} from "./anon-user.actions";
import { startModerationLockListener } from "./moderation.actions";

const actionsPrefix = "auth";

export const SET_AUTH_USER = `${actionsPrefix}/SET_AUTH_USER`;
export const CHECK_INITIALIZED = `${actionsPrefix}/CHECK_INITIALIZED`;

export const setAuthUser = (authUser) => {
  return async function(dispatch, getState) {
    dispatch({ type: VALIDATING_USER });

    const manualSignup = getState().anonUser.manualSignup;
    const uid = get(authUser, "uid");
    const email = get(authUser, "email");
    const displayName = get(authUser, "displayName");
    if (!uid || !email) {
      dispatch(setNotAuthenticated());
      return dispatch({ type: RESET_MESSAGE });
    }

    const userDoc = await getUserDocByUid(uid);
    if (!userDoc && manualSignup) {
      return;
    }

    const userUid = get(userDoc, "userUid");
    if (isNil(userUid) || isEmpty(userUid)) {
      const existingUser = await getUserByEmail(email);
      if (existingUser && !isEqual(existingUser.userUid, uid)) {
        dispatch(setNotAuthenticated());
        return dispatch(
          signInResult(
            "The user uid matched a previous user with the same email address.",
            true
          )
        );
      }

      const names = split(displayName, " ", 2);
      const firstName = head(names) || "Unknown";
      const lastName = last(names) || "Unknown";
      try {
        await createUserCall(email, firstName, lastName);
        await createSignupUserDoc(uid, email, firstName, lastName);
      } catch (error) {
        dispatch(setNotAuthenticated());
        return dispatch(
          signInResult(
            "The user was not created on the backend successfully.",
            true
          )
        );
      }
    }

    const { user, userRoles } = await getUserAndRoles(uid);
    dispatch(setUserAndRole(user, userRoles));
    dispatch(setAuthenticated(authUser, uid));
    dispatch(loadUser(uid));
    dispatch(startModerationLockListener());
    dispatch({ type: RESET_MESSAGE });
  };
};

const setAuthenticated = (authUser, authUid) => {
  return {
    type: SET_AUTH_USER,
    authUser,
    authUid
  };
};

const setNotAuthenticated = () => {
  return {
    type: SET_AUTH_USER,
    authUser: null,
    authUid: null
  };
};
