import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { filterVerificationUsers } from "../../actions/verification.actions";
import { find, get, isEqual, map } from "lodash";
import { Typeahead } from "react-bootstrap-typeahead";

const FilterModal = (props) => {
  const { isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector((state) => state.verification.filters);
  const saving = useSelector((state) => state.moderation.editSaving);
  const professions = useSelector((state) => state.reference.professions);
  const professionCategories = useSelector(
    (state) => state.reference.professionCategories
  );
  const countries = useSelector((state) => state.reference.countries);
  const states = useSelector((state) => state.reference.states);
  const verificationTags = useSelector(
    (state) => state.reference.verificationTagsArray
  );
  const selectedProfession = find(professions, (s) =>
    isEqual(s.typeUuid, formData.professionUuid)
  );
  const specialties = get(selectedProfession, "specialties", []);

  useEffect(() => {
    setFormData(filters);
  }, [filters]);

  const onSubmit = async (event) => {
    event.preventDefault();

    toggle();

    await dispatch(filterVerificationUsers(formData, history));
  };

  const clearFilters = () => {
    toggle();

    dispatch(filterVerificationUsers({}));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onChangeTag = (values) => {
    setFormData({ ...formData, tagUuids: values });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Select filters
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="username">
                username
              </Label>
              <Input
                id="username"
                name="username"
                value={formData.username || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                user uuid
              </Label>
              <Input
                id="userUuid"
                name="userUuid"
                value={formData.userUuid || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="firstName">
                first name
              </Label>
              <Input
                id="firstName"
                name="firstName"
                value={formData.firstName || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="lastName">
                last name
              </Label>
              <Input
                id="lastName"
                name="lastName"
                value={formData.lastName || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                display name
              </Label>
              <Input
                id="displayName"
                name="displayName"
                value={formData.displayName || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="email">
                email
              </Label>
              <Input
                id="email"
                name="email"
                value={formData.email || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="professionUuid">
                profession category
              </Label>
              <Input
                type="select"
                name="professionCategory"
                id="professionCategory"
                value={formData.professionCategory || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(professionCategories, (c) => {
                  return (
                    <option key={`profession-cat-${c.value}`} value={c.value}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="professionUuid">
                profession
              </Label>
              <Input
                type="select"
                name="professionUuid"
                id="professionUuid"
                value={formData.professionUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(professions, (c) => {
                  return (
                    <option key={`profession-${c.typeUuid}`} value={c.typeUuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="specialtyUuid">
                specialty
              </Label>
              <Input
                type="select"
                name="specialtyUuid"
                id="specialtyUuid"
                value={formData.specialtyUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(specialties, (c) => {
                  return (
                    <option key={`specialty-${c.typeUuid}`} value={c.typeUuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="countryUuid">
                country
              </Label>
              <Input
                type="select"
                name="countryUuid"
                id="countryUuid"
                value={formData.countryUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(countries, (c) => {
                  return (
                    <option key={`country-${c.uuid}`} value={c.uuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="stateUuid">
                location (state/province)
              </Label>
              <Input
                type="select"
                name="stateUuid"
                id="stateUuid"
                value={formData.stateUuid || ""}
                onChange={onChange}>
                <option value="">Select...</option>
                {map(get(states, formData.countryUuid, []), (c) => {
                  return (
                    <option key={`state-${c.uuid}`} value={c.uuid}>
                      {c.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>

          <Row>
            <Label className="col-12 text-11 text-uppercase font-weight-normal mb-0">
              Request Date
            </Label>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateFrom">
                From
              </Label>
              <Input
                id="requestDateFrom"
                name="requestDateFrom"
                value={formData.requestDateFrom || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-10" for="requestDateTo">
                To
              </Label>
              <Input
                id="requestDateTo"
                name="requestDateTo"
                value={formData.requestDateTo || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col-12">
              <Label
                className="text-11 text-uppercase font-weight-normal"
                for="tagUuid">
                tags
              </Label>
              <Typeahead
                id="tagsTypeahead"
                multiple={true}
                labelKey="name"
                onChange={onChangeTag}
                options={verificationTags}
                placeholder="Select a tag..."
                selected={formData.tagUuids || []}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                case count (contains at least)
              </Label>
              <Input
                id="approvedCaseCount"
                name="approvedCaseCount"
                value={formData.approvedCaseCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                comment count (contains at least)
              </Label>
              <Input
                id="approvedCommentCount"
                name="approvedCommentCount"
                value={formData.approvedCommentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                deleted case count (no more than){" "}
                <span className="font-weight-bold">(BE Pending)</span>
              </Label>
              <Input
                id="deletedCaseCount"
                name="deletedCaseCount"
                value={formData.deletedCaseCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                deleted comment count (no more than)
              </Label>
              <Input
                id="deletedCommentCount"
                name="deletedCommentCount"
                value={formData.deletedCommentCount || ""}
                onChange={onChange}
                type="text"
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                Date Last Uploaded Case
                <span className="font-weight-bold d-block">(BE Pending)</span>
              </Label>
              <Input
                id="dateLastUploaded"
                name="dateLastUploaded"
                value={formData.dateLastUploaded || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                Date Last Commented
                <span className="font-weight-bold d-block">(BE Pending)</span>
              </Label>
              <Input
                id="dateLastCommented"
                name="dateLastCommented"
                value={formData.dateLastCommented || ""}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="secondary" className="btn-md" onClick={clearFilters}>
            Clear filters
          </Button>

          <LoadingButton
            className="btn-md"
            color="primary-dark"
            type="submit"
            loading={saving}
            disabled={saving}>
            Apply filters
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

FilterModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

FilterModal.defaultProps = {};

export default FilterModal;
