import React, { useState } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { rejectComment } from "../../actions/moderation.actions";
import { get, isEmpty, isEqual, map } from "lodash";
import LoadingButton from "../common/LoadingButton";

const RejectCommentModal = (props) => {
  const { isOpen, toggle, comment } = props;
  const [formData, setFormData] = useState({ reason: "" });

  const dispatch = useDispatch();
  const saving = useSelector((state) => state.moderation.commentRejectedSaving);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const commentUuid = get(comment, "commentUuid");

  const onReject = async () => {
    await dispatch(rejectComment(commentUuid, formData.reason));

    toggle();
  };

  const deleteValues = {
    disrespectful_patient: "Disrespectful to patient",
    privacy_issue: "Privacy issue",
    personal_question_comment: "Personal comment/question",
    disrespectful_user: "Disrespectful to user",
    unsupported: "Unsupported",
    promotional: "Promotional",
    unprofessional: "Unprofessional",
    unsupported_language: "Unsupported language",
    treatment_reference: "Treatment reference",
    off_topic: "Off topic",
    no_email: "No email"
  };

  const renderDetails = () => {
    return (
      <Form>
        <ModalBody className="pt-0 px-5">
          <div className="bordered-text font-weight-bolder">
            <div className="w-75">
              State the reason why you are removing this comment:
            </div>
          </div>
          <div className="py-4">
            {map(deleteValues, (value, k) => {
              return (
                <FormGroup key={k} className="mb-0">
                  <CustomInput
                    type="radio"
                    name="reason"
                    id={`delete-${k}`}
                    value={k}
                    checked={isEqual(formData.reason, k)}
                    onChange={onChange}
                    label={value}
                  />
                </FormGroup>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 justify-content-between px-5 pt-3 px-5 pb-5">
          <Button color="primary" outline onClick={toggle}>
            Go Back
          </Button>
          <LoadingButton
            color="primary"
            outline
            disabled={isEmpty(formData.reason) || saving}
            loading={saving}
            onClick={onReject}>
            Save
          </LoadingButton>
        </ModalFooter>
      </Form>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      {renderDetails()}
    </Modal>
  );
};

RejectCommentModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  comment: PropTypes.object
};

export default RejectCommentModal;
