import React from "react";

const F1SubLabel = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { color = "dark-gray", children } = props;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return <div className={`text-11 text-${color} mb-1 mt-n1 `}>{children}</div>;
};

export default F1SubLabel;
