import React from "react";
import { Button, Nav, NavItem } from "reactstrap";
import PropTypes from "prop-types";
import { faAngleRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";

const ProfileLeftNavigation = (props) => {
  const { existing = false } = props;

  const history = useHistory();

  return (
    <Nav className="campaign-left-nav" vertical>
      <NavItem>
        <Button
          outline
          onClick={() => history.push(`${ROUTES.CAMPAIGN_PROFILE}`)}
          className={`nav-link action ${!existing ? "active" : ""}`}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          Create New Account
          <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
        </Button>
      </NavItem>
      <NavItem>
        <Button
          outline
          onClick={() => history.push(`${ROUTES.CAMPAIGN_PROFILE}/existing`)}
          className={`nav-link action ${existing ? "active" : ""}`}>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          Modify Account
          <FontAwesomeIcon icon={faAngleRight} className="align-self-end" />
        </Button>
      </NavItem>
    </Nav>
  );
};

ProfileLeftNavigation.propTypes = {
  existing: PropTypes.bool
};

export default ProfileLeftNavigation;
