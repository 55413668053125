import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Figure1Image from "../../assets/images/default.png";
import { Link } from "react-router-dom";
import "./pages.scss";
import GoogleSignInForm from "../../components/forms/GoogleSignInForm";
import { useDispatch, useSelector } from "react-redux";
import { signInGoogleResult } from "../../actions/anon-user.actions";
import Loading from "../../components/Loading";

const GoogleSigninPage = (props) => {
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.anonUser.isProcessing);

  useEffect(() => {
    dispatch(signInGoogleResult());
  }, [dispatch]);

  if (processing) {
    return <Loading />;
  }

  return (
    <Container>
      <Row>
        <Col className="p-5 d-flex justify-content-center">
          <Link to={"/"}>
            <img className="header-icon" src={Figure1Image} alt="Figure1" />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg={{ size: 6, offset: 3 }}>
          <GoogleSignInForm />
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleSigninPage;
