import React from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "reactstrap";
import "./common.scss";

const LoadingButton = (props) => {
  const { loading, className = "", children, ...rest } = props;
  const { size = "sm", outline = false, color = "primary" } = props;

  return (
    <Button className={`${className} loading-button`} {...rest}>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner size={size} color={outline ? color : "light"} />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool
};

export default LoadingButton;
