import React from "react";
import PropTypes from "prop-types";
import "./common.scss";
import { Container, Jumbotron } from "reactstrap";

const EmptyContainer = (props) => {
  const { title, description } = props;

  return (
    <Jumbotron className={"mt-3 border"}>
      <Container fluid>
        <h1 className="display-4">{title}</h1>
        <p className="lead">{description}</p>
      </Container>
    </Jumbotron>
  );
};

EmptyContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default EmptyContainer;
