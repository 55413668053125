import React, { useState } from "react";
import moment from "moment";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import { compact, get, isEmpty, map, orderBy } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { addNote } from "../../actions/moderation.actions";
import LoadingButton from "../common/LoadingButton";

const NotesViewerModal = (props) => {
  const { caseDoc, isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({ text: "" });
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.moderation.addNoteSaving);
  const caseId = get(caseDoc, "caseUuid");
  const notes = get(caseDoc, "moderationNotes", []);

  const onSubmit = async (event) => {
    event.preventDefault();

    await dispatch(addNote(caseId, formData.text));

    setFormData({ text: "" });

    toggle();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const notesTable = compact(
    map(orderBy(notes, ["createdAt"], ["desc"]), (n, index) => {
      const createdAt = get(n, "createdAt");
      return (
        <tr key={`note-${index}`}>
          <td
            className="font-weight-bold align-middle"
            style={{ width: "25%" }}>
            {get(n, "moderatorName")}
          </td>
          <td className="date-column align-middle" style={{ width: "25%" }}>
            {createdAt ? moment(createdAt).format("MMM DD, YYYY h:mm A") : ""}
          </td>
          <td className="align-middle">{get(n, "text")}</td>
        </tr>
      );
    })
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="notes-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Notes
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {!isEmpty(notes) && (
            <div className="pb-3">
              <div className="overflow-auto" style={{ maxHeight: "180px" }}>
                <Table bordered>
                  <tbody>{notesTable}</tbody>
                </Table>
              </div>
            </div>
          )}

          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <FormGroup className={"form-group"}>
            <Label for="text" className="font-weight-bold">
              Add note
            </Label>
            <Input
              id="text"
              name="text"
              value={formData.text}
              onChange={onChange}
              type="textarea"
              placeholder="Enter note here…"
              rows="8"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-5 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            disabled={!formData.text || saving}
            loading={saving}>
            Add Note
          </LoadingButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

NotesViewerModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object
};

export default NotesViewerModal;
