import { isEmpty, isEqual, isNil, isString, omitBy, get } from "lodash";
import { caseSearchCall } from "../api/cloud-functions";
import {
  getCasesRefreshPage,
  getCasesSortDirection
} from "../utils/case-utils";
import { fetchUsersByUuids, loadProfileForUid } from "../db/users-db";

const actionsPrefix = "cases";

export const GET_CASES_START = `${actionsPrefix}/GET_CASES_START`;
export const GET_CASES_COMPLETE = `${actionsPrefix}/GET_CASES_COMPLETE`;
export const UPDATE_STATE_FILTER = `${actionsPrefix}/UPDATE_STATE_FILTER`;
export const UPDATE_CASE_FILTERS = `${actionsPrefix}/UPDATE_CASE_FILTERS`;
export const UPDATE_SORT_DIRECTION = `${actionsPrefix}/UPDATE_SORT_DIRECTION`;
export const SET_ACTIVE_CASE = `${actionsPrefix}/SET_ACTIVE_CASE`;
export const CASE_REFRESH = `${actionsPrefix}/CASE_REFRESH`;
export const UPDATE_LOCAL_MEDIA = `${actionsPrefix}/UPDATE_LOCAL_MEDIA`;
export const ADD_LOCAL_MEDIA_EDIT = `${actionsPrefix}/ADD_LOCAL_MEDIA_EDIT`;
export const ADD_LOCAL_CONTENT_EDIT = `${actionsPrefix}/ADD_LOCAL_CONTENT_EDIT`;
export const GET_CASE_AUTHOR_COMPLETE = `${actionsPrefix}/GET_CASE_AUTHOR_COMPLETE`;

export const getCases = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_CASES_START
    });

    const filters = omitBy(
      {
        ...getState().cases.filters,
        stateFilter: getState().cases.stateFilter,
        partnerCases: false
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const stateSortDirections = getState().cases.stateSortDirections;
    const sortDirection = getCasesSortDirection(
      filters.stateFilter,
      stateSortDirections
    );
    const pageSize = getState().cases.pageSize;
    const currentPage = !isNil(page)
      ? page
      : getCasesRefreshPage(
          getState().cases.currentPage,
          getState().cases.cases
        );

    try {
      const results = await caseSearchCall(
        filters,
        currentPage,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_CASES_COMPLETE,
        results,
        page: currentPage,
        resetCaseIndex: isNil(page)
      });
    } catch (error) {
      console.log("Error retrieving cases", error);
      dispatch({
        type: GET_CASES_COMPLETE,
        error: error
      });
    }
  };
};

export const getCasesByState = (stateFilter, page) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_STATE_FILTER,
      stateFilter
    });

    dispatch(getCases(page));
  };
};

export const filterCases = (filters, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_CASE_FILTERS,
      filters
    });

    dispatch(getCases(page));
  };
};

export const setCasesSortDirection = (sortDirection) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SORT_DIRECTION,
      sortDirection
    });

    dispatch(getCases(0));
  };
};

export const setActiveCase = (activeCase) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ACTIVE_CASE,
      activeCase
    });
  };
};

export const getCaseAuthor = (authorUid) => {
  return async function(dispatch, getState) {
    const existing = getState().cases.caseAuthor;
    if (!isEqual(authorUid, get(existing, "userUid"))) {
      const caseAuthor = await loadProfileForUid(authorUid);
      await dispatch({
        type: GET_CASE_AUTHOR_COMPLETE,
        caseAuthor
      });
    }
  };
};

export const getCaseAuthorByUuid = (authorUuid) => {
  return async function(dispatch, getState) {
    const existing = getState().cases.caseAuthor;
    if (!isEqual(authorUuid, get(existing, "userUuid"))) {
      const users = await fetchUsersByUuids([authorUuid]);
      await dispatch({
        type: GET_CASE_AUTHOR_COMPLETE,
        caseAuthor: users[authorUuid]
      });
    }
  };
};

export const refreshCase = (response) => {
  return async function(dispatch) {
    dispatch({
      type: CASE_REFRESH,
      response
    });
  };
};
