import {
  APPROVE_COMPLETE,
  APPROVE_START,
  FLAG_COMPLETE,
  FLAG_START,
  FORCE_COMPLETE,
  FORCE_START,
  GET_CASES_COMPLETE,
  GET_CASES_START,
  REJECT_COMPLETE,
  REJECT_START,
  SAVE_MESH_TAGS_COMPLETE,
  SAVE_MESH_TAGS_START,
  SAVE_SPECIALTY_TAGS_COMPLETE,
  SAVE_SPECIALTY_TAGS_START,
  SET_ACTIVE_CASE,
  UPDATE_CASE_FILTERS,
  UPDATE_SEARCH_TEXT,
  UPDATE_STATE_FILTER,
  UPDATE_SORT_DIRECTION,
  SAVE_LABEL_START,
  SAVE_LABEL_COMPLETE
} from "../actions/tagging.actions";
import {
  findIndex,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  omitBy,
  size
} from "lodash";
import { CASE_FILTERS } from "../utils/case-utils";
import { CASE_REFRESH } from "../actions/cases.actions";
import { getNextActiveCase } from "./cases.reducer";

const INITIAL_STATE = {
  processing: false,
  approveSaving: false,
  flagSaving: false,
  rejectSaving: false,
  forceSaving: false,
  tagsSaving: false,
  isError: false,
  message: null,
  activeCase: null,
  stats: null,
  authorMetadata: null,
  searchTextFilter: null,
  stateFilter: CASE_FILTERS.APPROVED.collectionName,
  sortDirection: "desc",
  pageSize: 6,
  currentPage: 0,
  cases: [],
  filters: {},
  stateSortDirections: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CASES_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        comments: [],
        stats: null,
        authorMetadata: null,
        activeCase: null,
        previousActiveCase: state.activeCase,
        previousActiveCaseIndex: findIndex(state.cases, (c) =>
          isEqual(get(c, "caseUuid"), get(state.activeCase, "caseUuid"))
        )
      };
    }
    case GET_CASES_COMPLETE: {
      const casesPage = get(action.results, "pageData", []);
      const cases = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(casesPage, "pageSize", 10);
      const totalCases = get(casesPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(cases);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalCases;

      return {
        ...state,
        processing: false,
        stats: get(action.results, "allStats.casesStatusStats", {}),
        authorMetadata: get(action.results, "allStats.authorMetadata", {}),
        cases,
        pageSize: pageSize,
        totalCases,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage,
        activeCase: getNextActiveCase(state, cases, action.resetCaseIndex),
        previousActiveCase: null,
        previousActiveCaseIndex: null
      };
    }
    case UPDATE_STATE_FILTER: {
      return {
        ...state,
        stateFilter: action.stateFilter
      };
    }
    case UPDATE_CASE_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case UPDATE_SORT_DIRECTION: {
      const directions = state.stateSortDirections || {};
      if (state.stateFilter) {
        directions[state.stateFilter] = action.sortDirection;
      }

      return {
        ...state,
        stateSortDirections: directions
      };
    }
    case SET_ACTIVE_CASE: {
      return {
        ...state,
        activeCase: action.activeCase
      };
    }
    case SAVE_SPECIALTY_TAGS_START: {
      return {
        ...state,
        tagsSaving: true
      };
    }
    case SAVE_SPECIALTY_TAGS_COMPLETE: {
      return {
        ...state,
        tagsSaving: false
      };
    }
    case SAVE_MESH_TAGS_START: {
      return {
        ...state,
        tagsSaving: true
      };
    }
    case SAVE_MESH_TAGS_COMPLETE: {
      return {
        ...state,
        tagsSaving: false
      };
    }
    case SAVE_LABEL_START: {
      return {
        ...state,
        tagsSaving: true
      };
    }
    case SAVE_LABEL_COMPLETE: {
      return {
        ...state,
        tagsSaving: false
      };
    }
    case APPROVE_START: {
      return {
        ...state,
        approveSaving: true
      };
    }
    case APPROVE_COMPLETE: {
      return {
        ...state,
        approveSaving: false
      };
    }
    case FLAG_START: {
      return {
        ...state,
        flagSaving: true
      };
    }
    case FLAG_COMPLETE: {
      return {
        ...state,
        flagSaving: false
      };
    }
    case REJECT_START: {
      return {
        ...state,
        rejectSaving: true
      };
    }
    case REJECT_COMPLETE: {
      return {
        ...state,
        rejectSaving: false
      };
    }
    case FORCE_START: {
      return {
        ...state,
        forceSaving: true
      };
    }
    case FORCE_COMPLETE: {
      return {
        ...state,
        forceSaving: false
      };
    }
    case UPDATE_SEARCH_TEXT: {
      return {
        ...state,
        searchTextFilter: action.searchTextFilter
      };
    }
    case CASE_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const caseUuid = get(action.response, "caseUuid");
      return {
        ...state,
        activeCase: isEqual(get(state.activeCase, "caseUuid"), caseUuid)
          ? action.response
          : state.activeCase,
        cases: map(state.cases, (c) => {
          if (isEqual(caseUuid, c.caseUuid)) {
            return action.response;
          } else {
            return c;
          }
        })
      };
    }
    default:
      return state;
  }
};

export default reducer;
