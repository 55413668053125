import React, { Component } from "react";
import PropTypes from "prop-types";
import { Nav, NavItem } from "reactstrap";
import { get } from "lodash";
import {
  VERIFICATION_LICENSE,
  VERIFICATION_PHOTO_ID,
  VERIFICATION_ALL,
  VERIFICATION_NPI,
  VERIFICATION_INSTITUTIONAL
} from "../../constants/routes";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import "./style.scss";
import { VERIFICATION_TYPES } from "../../utils/verification-utils";

class VerificationTabs extends Component {
  render() {
    const { stats, active = "/verification" } = this.props;
    const npiTotal = get(stats, "npi");
    const licenseTotal = get(stats, "license");
    const photoTotal = get(stats, "photo");
    const institutionalTotal = get(stats, "institutional_email");
    const allTotal = get(stats, "all");

    return (
      <Nav className="tertiaryNav" tabs md={12}>
        <NavItem className="active">
          <Link
            className={isEqual(active, "npi") ? "active nav-link" : "nav-link"}
            to={VERIFICATION_NPI}>
            {npiTotal ? `NPI (${npiTotal})` : `NPI`}
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={
              isEqual(active, "license") ? "active nav-link" : "nav-link"
            }
            to={VERIFICATION_LICENSE}>
            {licenseTotal
              ? `Medical License (${licenseTotal})`
              : `Medical License`}
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={
              isEqual(active, VERIFICATION_TYPES.PHOTO.statsKey)
                ? "active nav-link"
                : "nav-link"
            }
            to={VERIFICATION_PHOTO_ID}>
            {photoTotal
              ? `${VERIFICATION_TYPES.PHOTO.displayName} (${photoTotal})`
              : VERIFICATION_TYPES.PHOTO.displayName}
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={
              isEqual(active, VERIFICATION_TYPES.INSTITUTIONAL_EMAIL.statsKey)
                ? "active nav-link"
                : "nav-link"
            }
            to={VERIFICATION_INSTITUTIONAL}>
            {institutionalTotal
              ? `${VERIFICATION_TYPES.INSTITUTIONAL_EMAIL.displayName} (${institutionalTotal})`
              : VERIFICATION_TYPES.INSTITUTIONAL_EMAIL.displayName}
          </Link>
        </NavItem>
        <NavItem>
          <Link
            className={isEqual(active, "all") ? "active nav-link" : "nav-link"}
            to={VERIFICATION_ALL}>
            {allTotal ? `All (${allTotal})` : `All`}
          </Link>
        </NavItem>
      </Nav>
    );
  }
}

VerificationTabs.propTypes = {
  active: PropTypes.string,
  stats: PropTypes.object
};

export default VerificationTabs;
