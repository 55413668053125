import React from "react";
import { Nav, NavItem } from "reactstrap";
import PropTypes from "prop-types";
import { get, isEqual, join, map } from "lodash";
import { Link } from "react-router-dom";
import "./style.scss";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/general-utils";

const VerificationLeftNavigation = (props) => {
  const { active, baseUrl, stats, collection } = props;
  const userRoles = useSelector((state) => state.user.userRoles);

  return (
    <Nav className="global-left-nav" vertical>
      {map(collection, (value) => {
        if (value.isViewable && !value.isViewable(userRoles)) {
          return null;
        }

        const count = get(stats, value.statsKey, 0);
        const classNames = ["nav-link"];
        if (isEqual(active, value.statsKey)) {
          classNames.push("active");
        }
        if (count > 0 && value.activeAuxClassName) {
          classNames.push(value.activeAuxClassName);
        }
        return (
          <NavItem key={value.statsKey}>
            <Link
              className={join(classNames, " ")}
              to={`${baseUrl}/${value.statsKey}`}>
              {value.displayName} ({formatNumber(count)})
            </Link>
          </NavItem>
        );
      })}
    </Nav>
  );
};

VerificationLeftNavigation.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  active: PropTypes.string,
  collection: PropTypes.object,
  stats: PropTypes.object
};

export default VerificationLeftNavigation;
