import React, { useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";

import "./authentication-forms.scss";

const SignInForm = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { processing, signIn, message, isError } = props;

  const onSubmit = async (event) => {
    event.preventDefault();

    signIn(formData.email, formData.password, () => {
      console.log("Complete!");
    });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Form onSubmit={onSubmit} className="form-box shadow bg-white rounded">
      {isError && message && <Alert color={"danger"}>{message}</Alert>}
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          name="email"
          className="form-control"
          value={formData.email}
          onChange={onChange}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <Input
          id="password"
          name="password"
          className="form-control"
          value={formData.password}
          onChange={onChange}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        <Button
          disabled={!formData.email || !formData.password || processing}
          block={true}
          type="submit"
          color="primary">
          Sign In
        </Button>
      </FormGroup>
    </Form>
  );
};

SignInForm.propTypes = {
  processing: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  signIn: PropTypes.func.isRequired
};

export default SignInForm;
