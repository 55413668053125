import React, { useMemo, useState } from "react";
import { Button, FormGroup, Label, Modal, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import ModalBody from "reactstrap/es/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import ModalFooter from "reactstrap/es/ModalFooter";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  clone,
  find,
  get,
  head,
  isEmpty,
  isNil,
  map,
  omitBy,
  set
} from "lodash";
import {
  clearUserError,
  getUsers,
  updateUser
} from "../../../actions/tools.actions";
import { onInvalidSubmit } from "../../../utils/form-utils";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Loader } from "react-bootstrap-typeahead";
import { generateDropdown } from "../../../utils/reference-utils";
import {
  getProfessionUuid,
  getSpecialtyUuid,
  getSubSpecialtyUuid
} from "../../../utils/verification-utils";
import {
  USER_TYPE_MAPPING,
  USER_TYPE_MAPPING_DISPLAY
} from "../../../utils/campaign-profile-utils";
import Select from "react-select";

const EditUserModal = (props) => {
  const { isOpen, toggle, user } = props;

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const saving = useSelector((state) => state.tools.userSaving);
  const error = useSelector((state) => state.tools.userSaveError);

  const [selectedProfession, setSelectedProfession] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  const [selectedSubSpecialty, setSelectedSubSpecialty] = useState(null);
  const [loadingProfessions, setLoadingProfessions] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const userUid = get(user, "userUid");
  const email = get(user, "email", "");
  const username = get(user, "username", "");
  const userType =
    USER_TYPE_MAPPING[get(user, "userType")] || USER_TYPE_MAPPING.Figure1Member;
  const professionUuid = getProfessionUuid(user, "");
  const specialtyUuid = getSpecialtyUuid(user, "");
  const subSpecialtyUuid = getSubSpecialtyUuid(user, "");
  const groupUuid = head(get(user, "groups", []))?.groupUuid;

  const allProfessions = useSelector((state) => state.reference.professions);
  const userTypeOptions = map(USER_TYPE_MAPPING_DISPLAY, (displayName, key) => {
    return { value: key, label: displayName };
  });
  const allGroups = useSelector((state) =>
    Object.values(state.reference.groups)
  );

  const findSpecialty = (container, targetId, targetProp) => {
    let returnVal = null;

    if (container) {
      if (targetId) {
        returnVal = container[targetProp].find(
          (specialty) => specialty.typeUuid === targetId
        );
        return returnVal;
      } else {
        return undefined;
      }
    }
  };

  useMemo(() => {
    if (!isEmpty(allProfessions)) {
      setLoadingProfessions(false);
    }

    const prof = find(allProfessions, (p) => {
      return p.typeUuid === professionUuid;
    });
    setSelectedProfession(prof);

    let currentSpecialty = findSpecialty(prof, specialtyUuid, "specialties");
    setSelectedSpecialty(currentSpecialty);

    let currentSubSpecialty = findSpecialty(
      currentSpecialty,
      subSpecialtyUuid,
      "subSpecialties"
    );
    setSelectedSubSpecialty(currentSubSpecialty);

    const group = find(allGroups, (g) => g.groupUuid === groupUuid);
    setSelectedGroup(group);

    setFormData({
      email,
      username,
      userType,
      _profession: prof,
      _specialty: currentSpecialty,
      _subSpecialty: currentSubSpecialty,
      group
    });
  }, [
    professionUuid,
    allProfessions,
    email,
    specialtyUuid,
    subSpecialtyUuid,
    userType,
    username
  ]);

  const onOpen = () => {
    dispatch(clearUserError());
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    formData.primarySpecialty =
      formData._subSpecialty?.uuid ||
      formData._specialty?.uuid ||
      formData._profession?.uuid;

    const result = await dispatch(
      updateUser(
        userUid,
        omitBy(formData, (v) => isNil(v) || isEmpty(v))
      )
    );

    if (result) {
      toggle();

      dispatch(getUsers(0));
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const onChangeProfession = (value) => {
    setSelectedProfession(value);
    setSelectedSpecialty(null);
    setSelectedSubSpecialty(null);
    const newData = clone(formData);
    newData._profession = value;
    newData._specialty = null;

    setFormData(clone(newData));
  };

  const onChangeSpecialty = (value) => {
    setSelectedSpecialty(value);
    setSelectedSubSpecialty(null);
    const newData = clone(formData);
    newData._specialty = value;
    newData._subSpecialty = null;
    setFormData(clone(newData));
  };

  const onChangeSubSpecialty = (value) => {
    setSelectedSubSpecialty(value);
    setFormData(clone(set(formData, "_subSpecialty", value)));
  };

  const onChangeUsertype = (o) => {
    setFormData(clone(set(formData, "userType", o.value)));
  };

  const onChangeGroup = (o) => {
    setSelectedGroup(o);
    setFormData(clone(set(formData, "group", o)));
  };

  const professionSegment = generateDropdown(
    "profession",
    "Profession",
    allProfessions,
    "Select a profession",
    onChangeProfession,
    selectedProfession
  );

  const specialtySegement =
    !selectedProfession?.specialties || !selectedProfession?.specialties.length
      ? null
      : generateDropdown(
          "specialty",
          "Speciality",
          selectedProfession?.specialties,
          "Select a specialty",
          onChangeSpecialty,
          selectedSpecialty
        );

  const subSpecialtySegment =
    !selectedSpecialty?.subSpecialties ||
    !selectedSpecialty?.subSpecialties.length
      ? null
      : generateDropdown(
          "subSpecialty",
          "Subspeciality",
          selectedSpecialty?.subSpecialties,
          "Select a subspecialty",
          onChangeSubSpecialty,
          selectedSubSpecialty
        );

  const groups = generateDropdown(
    "group",
    "Part of Group",
    allGroups,
    "None",
    onChangeGroup,
    selectedGroup,
    "groupUuid",
    "groupName",
    true
  );

  const professionsSegments = loadingProfessions ? (
    <Loader />
  ) : (
    <>
      {professionSegment}
      {specialtySegement}
      {subSpecialtySegment}
    </>
  );

  if (!userUid) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="caption-edit-modal modal-btn-spaced">
        <ModalHeader toggle={toggle}>Edit User</ModalHeader>
        <ModalBody>
          <p>
            This user has not logged into Pro yet, and does not have a UID. As a
            result you are not yet able to edit any of their details.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary-dark" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onOpened={onOpen}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>Edit User</ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody>
          {error && (
            <div className="alert alert-danger" role="alert">
              An error occurred when saving the data. Please try again.
            </div>
          )}
          <AvField
            id="username"
            name="username"
            label="username"
            type="username"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.username || ""}
            onChange={onChange}
          />

          <AvField
            id="email"
            name="email"
            label="email"
            type="email"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.email || ""}
            onChange={onChange}
            errorMessage="A valid email is required"
            required
          />

          {professionsSegments}

          <FormGroup>
            <Label
              for="userType"
              className="text-11 text-uppercase font-weight-normal">
              User Type
            </Label>
            <Select
              id="userType"
              name="userType"
              options={userTypeOptions}
              placeholder="Select user type"
              onChange={onChangeUsertype}
              value={find(
                userTypeOptions,
                (v) => v.value === formData.userType
              )}
            />
          </FormGroup>
          {groups}
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <LoadingButton
            loading={saving}
            disabled={saving}
            color="primary-dark"
            size="lg"
            className="ml-3"
            type="submit">
            Save
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

EditUserModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  user: PropTypes.object
};

export default EditUserModal;
