import {
  defaultTo,
  isEmpty,
  get,
  compact,
  map,
  has,
  isEqual,
  size
} from "lodash";
import { STATIC_MEDIA_TYPE } from "./campaign-utils";
import { showGlobalMessage } from "../actions/global.actions";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const onInvalidSubmit = () => {
  scrollToTop();
};

export const onInvalidSubmitWithMessage = (dispatch) => {
  dispatch(
    showGlobalMessage(
      "The form could not be submitted because data was missing or invalid.  Please review the form and correct any errors.",
      "Invalid Form Data",
      true,
      null
    )
  );
};

export const onInvalidTacticSubmit = (dispatch) => {
  const errorNode = document.querySelector(".form-group.text-danger");
  const errorFeedbackNode = document.querySelector(".invalid-feedback");

  if (errorNode || errorFeedbackNode) {
    if (errorNode) {
      errorNode.scrollIntoView();
    } else {
      errorFeedbackNode.scrollIntoView();
    }
  } else {
    onInvalidSubmitWithMessage(dispatch);
  }
};

export const getFieldName = (name, prefix = "") => {
  if (isEmpty(prefix)) {
    return name;
  }

  return `${prefix}.${name}`;
};

export const getFieldValue = (
  formData,
  name,
  prefix = "",
  defaultValue = ""
) => {
  return defaultTo(get(formData, getFieldName(name, prefix)), defaultValue);
};

export const getFieldValueCharacters = (value, maxCharacters) => {
  const characters = size(value);
  return `${maxCharacters -
    characters} remaining (${maxCharacters} max characters)`;
};

export const scrubMediaField = (mediaType, media, vimeoFileName) => {
  if (isEqual(mediaType, STATIC_MEDIA_TYPE.NO_MEDIA)) {
    return [];
  }

  if (isEqual(mediaType, STATIC_MEDIA_TYPE.VIDEO)) {
    return !isEmpty(vimeoFileName)
      ? [{ type: "video", filename: vimeoFileName }]
      : [];
  }

  return compact(
    map(media, (m) => {
      if (!has(m, "filename")) {
        return null;
      }

      if (isEqual(m.type, STATIC_MEDIA_TYPE.VIDEO)) {
        return null;
      }

      const defaultData = {
        type: "image",
        filename: get(m, "filename"),
        height: get(m, "height"),
        width: get(m, "width")
      };

      if (has(m, "mediaUuid")) {
        defaultData["mediaUuid"] = get(m, "mediaUuid");
      }

      return defaultData;
    })
  );
};
