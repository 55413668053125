import { get, isEmpty, map, omitBy, size } from "lodash";
import {
  GET_USERS_COMPLETE,
  GET_USERS_START,
  RESET_USER_PASSWORD_COMPLETE,
  RESET_USER_PASSWORD_START,
  UPDATE_USER_COMPLETE,
  UPDATE_USER_FILTERS,
  UPDATE_USER_START
} from "../actions/tools.actions";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  userSaving: false,
  userResetting: false,
  message: null,
  sortDirection: "desc",
  sortColumn: "publishedAt",
  pageSize: 25,
  currentPage: 0,
  users: [],
  filters: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USERS_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        users: []
      };
    }
    case GET_USERS_COMPLETE: {
      const usersPage = get(action.results, "pageData", []);
      const users = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(usersPage, "pageSize", 10);
      const totalUsers = get(usersPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(users);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalUsers;

      return {
        ...state,
        processing: false,
        users,
        pageSize: pageSize,
        totalUsers,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage
      };
    }
    case UPDATE_USER_FILTERS: {
      return {
        ...state,
        filters: omitBy(action.filters, isEmpty)
      };
    }
    case UPDATE_USER_START: {
      return {
        ...state,
        userSaving: true
      };
    }
    case UPDATE_USER_COMPLETE: {
      return {
        ...state,
        userSaving: false,
        userSaveError: action.userSaveError || false
      };
    }
    case RESET_USER_PASSWORD_START: {
      return {
        ...state,
        userResetting: true
      };
    }
    case RESET_USER_PASSWORD_COMPLETE: {
      return {
        ...state,
        userResetting: false,
        userResetError: action.userResetError || false
      };
    }
    default:
      return state;
  }
};

export default reducer;
