import { isEmpty, isNil, isString, omitBy } from "lodash";
import { caseSearchCall } from "../api/cloud-functions";
import {
  getCasesRefreshPage,
  getCasesSortDirection
} from "../utils/case-utils";

const actionsPrefix = "partnerCases";

export const GET_CASES_START = `${actionsPrefix}/GET_CASES_START`;
export const GET_CASES_COMPLETE = `${actionsPrefix}/GET_CASES_COMPLETE`;
export const UPDATE_STATE_FILTER = `${actionsPrefix}/UPDATE_STATE_FILTER`;
export const UPDATE_CASE_FILTERS = `${actionsPrefix}/UPDATE_CASE_FILTERS`;
export const UPDATE_SORT_DIRECTION = `${actionsPrefix}/UPDATE_SORT_DIRECTION`;
export const SET_ACTIVE_CASE = `${actionsPrefix}/SET_ACTIVE_CASE`;
export const CASE_REFRESH = `${actionsPrefix}/CASE_REFRESH`;
export const PARTNER_UPDATE_LOCAL_MEDIA = `${actionsPrefix}/PARTNER_UPDATE_LOCAL_MEDIA`;
export const PARTNER_ADD_LOCAL_MEDIA_EDIT = `${actionsPrefix}/PARTNER_ADD_LOCAL_MEDIA_EDIT`;
export const PARTNER_ADD_LOCAL_CONTENT_EDIT = `${actionsPrefix}/PARTNER_ADD_LOCAL_CONTENT_EDIT`;

export const getPartnerCases = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_CASES_START
    });

    const filters = omitBy(
      {
        ...getState().partnerCases.filters,
        stateFilter: getState().partnerCases.stateFilter,
        partnerCases: true
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const stateSortDirections = getState().partnerCases.stateSortDirections;
    const sortDirection = getCasesSortDirection(
      filters.stateFilter,
      stateSortDirections
    );
    const pageSize = getState().partnerCases.pageSize;
    const currentPage = !isNil(page)
      ? page
      : getCasesRefreshPage(
          getState().partnerCases.currentPage,
          getState().partnerCases.cases
        );

    try {
      const results = await caseSearchCall(
        filters,
        currentPage,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_CASES_COMPLETE,
        results,
        page: currentPage,
        resetCaseIndex: isNil(page)
      });
    } catch (error) {
      console.log("Error retrieving cases", error);
      dispatch({
        type: GET_CASES_COMPLETE,
        error: error
      });
    }
  };
};

export const getPartnerCasesByState = (stateFilter, page) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_STATE_FILTER,
      stateFilter
    });

    dispatch(getPartnerCases(page));
  };
};

export const filterPartnerCases = (filters, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_CASE_FILTERS,
      filters
    });

    dispatch(getPartnerCases(page));
  };
};

export const setPartnerCasesSortDirection = (sortDirection) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_SORT_DIRECTION,
      sortDirection
    });

    dispatch(getPartnerCases(0));
  };
};

export const setActivePartnerCase = (activeCase) => {
  return async function(dispatch) {
    dispatch({
      type: SET_ACTIVE_CASE,
      activeCase
    });
  };
};

export const refreshPartnerCase = (response) => {
  return async function(dispatch) {
    dispatch({
      type: CASE_REFRESH,
      response
    });
  };
};
