import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { CAMPAIGN_MODULE_NAME } from "../constants/route-constants";
import GlobalHeader from "../components/GlobalHeader";
import { userSignedIn } from "../utils/route-utils";
import CampaignManage from "./campaigns/CampaignManage";
import CampaignListPage from "./campaigns/CampaignListPage";
import GlobalMessageToast from "../components/common/GlobalMessageToast";
import LoadingPage from "./home/LoadingPage";
import { isCampaignViewable } from "../utils/permission-utils";
import CampaignTacticManage from "./campaigns/CampaignTacticManage";
import CampaignProfileManage from "./campaigns/CampaignProfileManage";
import CampaignCommentQueue from "./campaigns/CampaignCommentQueue";

class CampaignsApp extends React.Component {
  conditionallyRenderDefaultOrPage = (pageComponent) => {
    const { auth, userRoles } = this.props;

    if (!auth.initialized) {
      return <LoadingPage />;
    }

    if (!userSignedIn(auth)) {
      return <Redirect to={ROUTES.SIGN_IN} />;
    }

    if (!isCampaignViewable(userRoles)) {
      return <Redirect to={ROUTES.NOT_AUTHORIZED} />;
    }

    return pageComponent;
  };

  render() {
    return (
      <>
        <GlobalHeader active={CAMPAIGN_MODULE_NAME} />
        <GlobalMessageToast />
        <Switch>
          <Route exact path={`${ROUTES.CAMPAIGN_COMMENT_QUEUE}`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignCommentQueue />)}
          </Route>
          <Route exact path={`${ROUTES.CAMPAIGN_COMMENT_QUEUE}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignCommentQueue />)}
          </Route>
          <Route exact path={`${ROUTES.CAMPAIGN_PROFILE}`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignProfileManage />)}
          </Route>
          <Route exact path={`${ROUTES.CAMPAIGN_PROFILE}/existing`}>
            {this.conditionallyRenderDefaultOrPage(
              <CampaignProfileManage existing={true} />
            )}
          </Route>
          <Route exact path={`${ROUTES.CAMPAIGN_PROFILE}/:userUid`}>
            {this.conditionallyRenderDefaultOrPage(
              <CampaignProfileManage existing={true} />
            )}
          </Route>

          <Route exact path={`${ROUTES.CAMPAIGN_TACTIC_NEW}`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignTacticManage />)}
          </Route>
          <Route
            exact
            path={`${ROUTES.CAMPAIGN_MANAGE}/:campaignId/tactic/:caseId`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignTacticManage />)}
          </Route>
          <Route exact path={`${ROUTES.CAMPAIGN_MANAGE}/:campaignId`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignManage />)}
          </Route>

          <Route exact path={`${ROUTES.CAMPAIGN_ROOT}/:filterType`}>
            {this.conditionallyRenderDefaultOrPage(<CampaignListPage />)}
          </Route>
          <Route exact path={ROUTES.CAMPAIGN_ROOT}>
            {this.conditionallyRenderDefaultOrPage(<CampaignListPage />)}
          </Route>
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userRoles: state.user.userRoles
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(CampaignsApp);
